import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Form, Button, Input, Spin, Alert } from "antd";
import OtpInput from "react-otp-input";
import { strings } from "../../../helpers";

const FormItem = Form.Item;

class VerificationView extends Component {
  render() {
    const { props } = this;
    const buttonDisabled = props.verificationCode.trim() === "";
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="signup-right-section">
        <div className="verification-container">
          <div className="auth-form-section">
            {/* sign up form start */}
            <div className="auth-form-main-wrapper verify-form-wrapper">
              <div className="auth-form-top-heading">
                {strings.complete_verification}
              </div>
              <div className="auth-form-subtext">
                {props.emailId
                  ? strings.formatString(
                      strings.sentVerifyCodeOn,
                      <span className="text-dark text-bold">
                        +91-{props.phoneNumber}
                      </span>,
                      <span className="text-dark text-bold">
                        {props.emailId}
                      </span>
                    )
                  : strings.formatString(
                      strings.sentCodeOn,
                      <span className="text-dark text-bold">
                        +91-{props.phoneNumber}
                      </span>
                    )}
              </div>
              <div className="form-alerts-wrapper mt-20">
                {props.error && props.errorMsg && (
                  <Alert message={props.errorMsg} type="error" showIcon />
                )}
                {props.verifyServerError && (
                  <Alert
                    message={strings.invalid_verify_code}
                    type="error"
                    showIcon
                  />
                )}
                {props.resendServerError && (
                  <Alert
                    message={strings.verify_code_error}
                    type="error"
                    showIcon
                  />
                )}

                {props.serverError && props.verifyCodeError && (
                  <Alert
                    message={strings.invalid_verify_code}
                    type="error"
                    showIcon
                  />
                )}
              </div>
              <Form onSubmit={props.handleSubmit} className="relative">
                <div className="resend-box text-right">
                  {!props.showTimer && (
                    <a href="#" onClick={e => props.handleResendCode(e)}>
                      {strings.resend_code}
                    </a>
                  )}
                  {props.showTimer && (
                    <span className="timer-count">
                      {`${props.timerCount} ${strings.signup.sec}`}
                    </span>
                  )}
                  {props.isResendCodeProcessing && (
                    <span>
                      <Spin size="small" />
                    </span>
                  )}
                </div>
                <div className="auth-form-input-block-container mobile-icon mt-30">
                  <FormItem>
                    <label className="label-prop">
                      {strings.verification_code}
                    </label>
                    <OtpInput
                      // name="verificationCode"
                      value={props.verificationCode}
                      onChange={props.handleOTPChange}
                      numInputs={5}
                      separator={<div className="field_sep"></div>}
                      containerStyle={"otp_input_box"}
                      isInputNum
                    />
                  </FormItem>
                </div>

                <div className="mt-20">
                  <Button
                    type="primary"
                    className="form-primary-btn"
                    htmlType="submit"
                    loading={props.isProcessing}
                  >
                    {props.emailId ? "Complete sign up" : "Verify OTP"}
                  </Button>
                </div>
              </Form>
              {/* auth secondary button */}
            </div>
            {/* sign up form end */}
          </div>
        </div>
      </div>
    );
  }
}

export default Form.create()(VerificationView);
