import axios from "axios";
import moment from "moment";
import {
  getAuthHeaders,
  getBasicAuthHeaders,
  getUserId,
  getBrowserDetails,
  userTimezone,
  ActionableIntents
} from "../helpers";

const scheduleTimeFormat = "HH:mm";

export const postLogin = (phoneNumber, password, utmParams) => {
  let mixpanel_data = getBrowserDetails();
  mixpanel_data.app = "Doctor";
  const signInObj = {
    user_signin_form: {
      country_code: "91",
      phone_number: phoneNumber,
      password
    },
    platform: "web",
    mixpanel_data: mixpanel_data
  };

  if (utmParams) {
    signInObj.utm_params = {
      utm_medium: utmParams.utm_medium,
      utm_source: utmParams.utm_source,
      utm_content: utmParams.utm_content,
      utm_campaign: utmParams.utm_campaign
    };
  }

  return axios.post(
    `${process.env.REACT_APP_SERVER_URL}/auth/sign_in`,
    signInObj
  );
};

/**
 * @function signup
 * @param phoneNumber
 * @param password
 * @desc This signs up the user.
 */
export const signup = (
  phoneNumber,
  password,
  doctorName,
  email,
  signUpExtraParams,
  claimDocId,
  signedUpAs,
  signupIntent,
  firstName,
  lastName
) => {
  let mixpanel_data = getBrowserDetails();
  mixpanel_data.app = "CMS";
  let refer = "cms_web";
  const referrer = localStorage.getItem("referrer", undefined);
  if (referrer !== undefined) {
    if (referrer === "growth") {
      refer = "growth_web";
    } else if (referrer === "cms") {
      refer = "cms_web";
    }
  }

  const signUpObj = {
    user_signup_form: {
      signed_up_as: signedUpAs, // since auth sign up is for general account only
      country_code: "91",
      phone_number: phoneNumber,
      password,
      email,
      account: signedUpAs == "general" ? "clinic" : "doctor",
      platform: signedUpAs == "doctor" ? "doctor_web" : refer,
      timezone: userTimezone()
    },
    mixpanel_data: mixpanel_data
  };
  // save name or firstname and lastname
  if (firstName && firstName.trim() !== "") {
    signUpObj.user_signup_form.first_name = firstName;
    signUpObj.user_signup_form.last_name = lastName;
  } else {
    signUpObj.user_signup_form.name = doctorName;
  }
  // save signup intent
  if (signupIntent && ActionableIntents.includes(signupIntent)) {
    signUpObj.user_signup_form.sign_up_intent = signupIntent;
  }

  if (signUpExtraParams && signUpExtraParams.param_type === "utm") {
    signUpObj.utm_params = {
      utm_medium: signUpExtraParams.utm_medium,
      utm_source: signUpExtraParams.utm_source,
      utm_content: signUpExtraParams.utm_content,
      utm_campaign: signUpExtraParams.utm_campaign
    };
  } else if (signUpExtraParams && signUpExtraParams.param_type === "invite") {
    signUpObj.user_signup_form.invite_code = signUpExtraParams.invite_code;
  }

  if (claimDocId) signUpObj.user_signup_form.claim_doctor_id = claimDocId;

  return axios.post(
    `${process.env.REACT_APP_SERVER_URL}/auth/sign_up`,
    signUpObj
  );
};

/**
 * @function patientSignup
 * @param params
 * @desc This signs up the user.
 */
export const patientSignup = params => {
  return axios.post(`${process.env.REACT_APP_SERVER_URL}/auth/sign_up`, params);
};

/**
 * @function checkUserExistence
 * @param phoneNumber
 * @desc Check if the user is already signed up.
 */
export const checkUserExistence = phoneNumber => {
  return axios.get(
    `${process.env.REACT_APP_SERVER_URL}/users/user_exists?country_code=91&phone_number=${phoneNumber}`
  );
};

export const verifyUser = (
  phoneNumber,
  countryCode,
  verificationCode,
  mixpanelFromParam
) => {
  let mixpanel_data = getBrowserDetails();
  mixpanel_data.type = "Signup";
  mixpanel_data.app = "CMS";
  mixpanel_data.from = mixpanelFromParam;
  const dataObj = {
    country_code: countryCode,
    phone_number: phoneNumber,
    verification_code: verificationCode,
    mixpanel_data: mixpanel_data
  };
  return axios.post(
    `${process.env.REACT_APP_SERVER_URL}/auth/verify_user`,
    dataObj
  );
};

export const resendVerificationCode = (phoneNumber, countryCode) => {
  var dataObj = {
    country_code: countryCode,
    phone_number: phoneNumber,
    mixpanel_data: getBrowserDetails()
  };
  return axios.post(
    `${process.env.REACT_APP_SERVER_URL}/auth/resend_verification_code`,
    dataObj
  );
};

export const resetPasswordInstructions = (countryCode, phoneNumber) => {
  var dataObj = {
    login_id: phoneNumber,
    country_code: countryCode,
    mixpanel_data: getBrowserDetails()
  };
  return axios.post(
    `${process.env.REACT_APP_SERVER_URL}/auth/reset_password`,
    dataObj
  );
};

export const verifyForgotPasswordCode = (userId, verificationCode) => {
  let mixpanel_data = getBrowserDetails();
  mixpanel_data.type = "Forgot";
  const dataObj = {
    login_id: userId,
    verification_code: verificationCode,
    mixpanel_data: mixpanel_data
  };
  return axios.post(
    `${process.env.REACT_APP_SERVER_URL}/auth/verify_reset_password_code`,
    dataObj
  );
};

/**
 * @function changePassword
 * @param userId
 * @param password - new password
 * @param current_password - current password
 * @desc This function gets called on
 * -reset password (userId and password)
 * - change password (userId, password, current_password).
 */
export const changePassword = (userId, password, current_password = null) => {
  let mixpanel_data = getBrowserDetails();
  mixpanel_data.app = "Forgot";
  const dataObj = {
    // login_id: userId,
    password,
    current_password,
    mixpanel_data: mixpanel_data
  };
  return axios.post(
    `${process.env.REACT_APP_SERVER_URL}/auth/change_password`,
    dataObj,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("atk")}`
      }
    }
  );
};

/**
 * @function saveDoctorDetails
 * @param params
 * @desc This service allows saving doctor details
 */
export const saveDoctorDetails = params => {
  const url = `${process.env.REACT_APP_SERVER_URL}/doctors`;

  return axios.post(url, params, {
    headers: getAuthHeaders()
  });
};

/**
 * @function fetchDoctorDetail
 * @param doctorId
 * @desc This service allows fetching doctor detail corresponding to doctorId
 */
export const fetchDoctorDetail = doctorId => {
  const url = `${process.env.REACT_APP_SERVER_URL}/doctors/${doctorId}`;
  return axios.get(url, {
    headers: getAuthHeaders()
  });
};

/**
 * @function saveClinicSchedule
 * @param "userId", "params"
 * @desc This service allows saving clinic schedule corresponding to a userId
 */
export const saveClinicSchedule = (userId, params) => {
  const schedule = params.schedule_details;
  if (schedule && schedule.length > 0) {
    schedule.map(scheduleObj => {
      moment(scheduleObj.start_time)
        .utc()
        .format(scheduleTimeFormat);
      moment(scheduleObj.end_time)
        .utc()
        .format(scheduleTimeFormat);
    });
  }

  const url = `${process.env.REACT_APP_SERVER_URL}/users/${userId}/clinic_doctors`;
  return axios.post(url, params, {
    headers: getAuthHeaders()
  });
};

/**
 * @function fetchClinicDetail
 * @param "userId"
 * @desc This service allows fetching clinic detail corresponding to a userId
 */
export const fetchClinicDetail = userId => {
  const url = `${process.env.REACT_APP_SERVER_URL}/users/${userId}/clinic_doctors`;
  return axios.get(url, {
    headers: getAuthHeaders()
  });
};

/**
 * @function saveDoctorDetails
 * @param params
 * @desc This service allows saving doctor details
 */
export const settingViewedByClinic = clinicId => {
  const url = `${process.env.REACT_APP_SERVER_URL}/clinics/${clinicId}/settings_viewed`;

  return axios.post(url, null, {
    headers: getAuthHeaders()
  });
};

/**
 * @function saveClinicDoctorDetail
 * @param "clinicId", "params"
 * @desc This service saves clinic doctor detail, added during clinic onboarding
 */
export const saveClinicDoctorDetail = (clinicId, params) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/clinics/${clinicId}/cms_onboard_doctor`;

  return axios.post(url, params, {
    headers: getAuthHeaders()
  });
};

/**
 * @function getClinicSetUpStatus
 * @param "clinicId"
 * @desc This service fetches clinic set up params
 */
export const getClinicSetUpStatus = clinicId => {
  const url = `${process.env.REACT_APP_SERVER_URL}/clinics/${clinicId}/setup_status`;
  return axios.get(url, {
    headers: getAuthHeaders()
  });
};

/**
 * @function sendMagicLink
 * @param "userId", "email"
 * @desc This service sends the magic link to the user on email
 */
export const sendMagicLink = (userId, email, pageName, linkType) => {
  let url = `${process.env.REACT_APP_SERVER_URL}/users/${userId}/magic_link?link_type=${linkType}`;
  if (pageName) {
    url = `${url}&r=${pageName}`;
  }

  let params = null;
  if (email) {
    params = {
      email
    };
  }
  return axios.post(url, params, {
    headers: getBasicAuthHeaders()
  });
};

/**
 * @function magicLinkSignIn
 * @param "params"
 * @desc This service allows user to sign in through magic link
 */
export const magicLinkSignIn = params => {
  const url = `${process.env.REACT_APP_SERVER_URL}/auth/magic_link_sign_in`;

  return axios.post(url, params, {
    headers: getBasicAuthHeaders()
  });
};

/**
 * @function updateUserStartGuideInteractionFlag
 * @desc This service updates user interation flag with start guide
 */
export const updateUserStartGuideInteractionFlag = () => {
  const userId = getUserId();
  const url = `${process.env.REACT_APP_SERVER_URL}/users/${userId}/start_guide_interaction`;

  return axios.post(url, null, {
    headers: getAuthHeaders()
  });
};

/**
 * @function getUserProfile
 * @desc gets user's profile
 */
export const getUserProfile = () =>
  axios.get(`${process.env.REACT_APP_SERVER_URL}/users/profile`, {
    headers: getAuthHeaders()
  });

/**
 * @function createClinic
 * @desc This service updates user interation flag with start guide
 */
export const createClinic = param => {
  const url = `${process.env.REACT_APP_SERVER_URL}/clinics`;
  return axios.post(url, param, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${param.tkn}`
    }
  });
};
