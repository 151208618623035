import React from "react";
import _ from "lodash";
import SelectAccountView from "./SelectAccountView";
import {
  buildUrl,
  strings,
  logMixpanelEvent,
  history,
  getCookie,
  getAppEnv,
  getUser
} from "../../helpers";
import { setCookieRelatedData } from "../Onboarding/Signin/ClinicLoginActions";

export default class SelectAccountContainer extends React.Component {
  state = {
    accounts: [
      {
        key: "cms",
        value: strings.selectAccount.clinicText,
        description: strings.selectAccount.clinicDescription
      },
      {
        key: "growth",
        value: strings.selectAccount.growthText,
        description: strings.selectAccount.growthDescription
      }
      // { key: "billing", value: strings.selectAccount.billingText }
    ],
    userObj: {}
  };

  componentDidMount() {
    let paramObj = {};

    if (this.props && this.props.location && this.props.location.state) {
      paramObj = this.props.location.state;

      if (paramObj && paramObj.userData) {
        const userObj = JSON.parse(paramObj.userData);
        this.setState({ userObj });
      }
    } else if (!getUser()) {
      history.push("/sign-in");
    }
  }

  onSelectAccount = accountType => {
    let userObj = this.state.userObj;
    let userId = null;
    if (!_.isEmpty(userObj)) {
      setCookieRelatedData(userObj);
      userId = userObj.data.user.id;
    } else {
      const appEnv = getAppEnv();
      userObj = getCookie(`${appEnv}_user`);
      userId = userObj.id;
    }

    // if redirect url is present then go to that requested url
    // build url

    const redirectUrl = buildUrl(accountType);

    localStorage.removeItem("referrer");
    if (accountType == "cms") {
      logMixpanelEvent("Select App", "CMS", "prompt", null, null, userId);
    } else {
      logMixpanelEvent("Select App", "Growth", "prompt", null, null, userId);
    }
    window.open(`${redirectUrl}`, "_self");
  };

  render() {
    const { accounts } = this.state;
    return (
      <SelectAccountView
        onSelectAccount={this.onSelectAccount}
        accounts={accounts}
      />
    );
  }
}
