import React, { Component } from "react";

class AuthIntro extends Component {
  render() {
    return (
      <div className="signup-left-section">
        <div className="signup-text-container">
          <div>
            <div className="img-block"></div>
          </div>
          <div className="signup-content">
            <div className="signup-text-heading font-color-blue font-weight-bold">
              Get started quickly
            </div>
            <div className="signup-text-content signup-text">
              Start scheduling and collecting payments in minutes
            </div>
          </div>
        </div>

        <div className="signup-text-container">
          <div>
            <div className="img-block"></div>
          </div>
          <div className="singup-content">
            <div className="signup-text-heading font-color-blue font-weight-bold">
              Delight patients &#38; grow your business
            </div>
            <div className="signup-text-content signup-text">
              Keep patients coming back with easy booking, payments, and
              telemedicine
            </div>
          </div>
        </div>

        <div className="signup-text-container">
          <div>
            <div className="img-block"></div>
          </div>
          <div className="singup-content">
            <div className="signup-text-heading font-color-blue font-weight-bold">
              Stay up-to-date on medical news
            </div>
            <div className="signup-text-content signup-text">
              Join 4,000+ doctors who trust Kulcare for timely, specialized
              medical news
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AuthIntro;
