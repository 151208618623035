import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Form, Alert, Button } from "antd";
import { strings } from "../../../helpers";
import NumericInput from "../../../helpers/NumericInput";
import AuthIntro from "../AuthIntro";
import VerifyClinicPhoneNumberContainer from "./VerifyClinicPhoneNumberContainer";
const FormItem = Form.Item;

class ForgotClinicPasswordView extends Component {
  render() {
    const { props } = this;
    const { goToSignIn, accountType } = this.props;
    const { hasForgot, countryCode, phoneNumber, userId } = this.props;
    const { getFieldDecorator } = this.props.form;
    const buttonDisabled = props.phoneNumber.trim() === "";

    return (
      <div className="auth-form-section signup-wrapper">
        {/* <div className="auth-form-subtext">
          Please enter your mobile number to continue
        </div> */}
        <Row type="flex" justify="center" align="middle">
          {accountType && accountType === "patient" ? (
            <div className="auth-left-screen patient-account-screen"></div>
          ) : accountType && accountType === "hospital" ? (
            <div className="auth-left-screen hospital-account-screen"></div>
          ) : (
            <AuthIntro />
          )}

          {/* sign up form start */}
          {hasForgot ? (
            <VerifyClinicPhoneNumberContainer
              userId={userId}
              phoneNumber={phoneNumber}
            />
          ) : (
            <div className="signup-right-section">
              <div className="auth-form-main-wrapper">
                <div className="auth-form-top-heading">Forgot password?</div>
                <div className="form-alerts-wrapper mt-20">
                  {props.error && props.errorMsg && (
                    <Alert type="error" message={props.errorMsg} showIcon />
                  )}
                  {props.serverError && props.forgotPasswordError && (
                    <Alert
                      type="error"
                      message={strings.account_error}
                      showIcon
                    />
                  )}
                </div>
                <Form onSubmit={props.handleSubmit}>
                  <div className="auth-form-input-block-container mobile-icon mt-20">
                    <FormItem>
                      <label className="label-prop">
                        {strings.enter_mobile_number}
                      </label>
                      {getFieldDecorator("phoneNumber", {
                        initialValue: `${props.phoneNumber}`,
                        rules: [
                          {
                            required: true,
                            message: `${strings.signup.enter_phone_number}`
                          }
                        ]
                      })(
                        <NumericInput
                          name="phoneNumber"
                          onChange={props.handleChange}
                          maxLength="10"
                          className="input-with-border-bottom"
                          onBlur={props.checkUserExistence}
                          // placeholder={strings.signup.enter_phone_number}
                        />
                      )}
                    </FormItem>
                  </div>

                  <div className="mt-20">
                    <Button
                      type="primary"
                      className="form-primary-btn"
                      htmlType="submit"
                      disabled={buttonDisabled}
                      loading={props.isProcessing}
                    >
                      {strings.careplan.continue}
                    </Button>
                  </div>
                </Form>
                {/* auth secondary button */}
              </div>
            </div>
          )}
          {/* sign up form end */}
        </Row>
      </div>
    );
  }
}

export default Form.create()(ForgotClinicPasswordView);
