export default {
  no_internet_connection: "कोई इंटरनेट कनेक्शन नहीं!",
  back: "वापस",
  LabsList: "प्रयोगशाला सूची",
  staffList: "कर्मचारी सूची",
  staffSubtitle:
    "अपने क्लिनिक में उन्हें जोड़ने के लिए अपने मोबाइल नंबर का उपयोग करके एक स्टाफ सदस्य को आमंत्रित करें। स्टाफ का सदस्य खाता व्यवसाय और रोगी के विवरण तक नहीं पहुंच सकता है।",
  doctorsList: "डॉक्टरों की सूची",
  doctorsListSubtitle:
    "अपने क्लिनिक में एक सलाहकार के रूप में उन्हें जोड़ने के लिए अपने मोबाइल नंबर का उपयोग करके डॉक्टर खोजें। एक सलाहकार खाता केवल क्लिनिक में अपने रोगियों से संबंधित डेटा देख सकता है।",
  LabsListSubtitle:
    "एक प्रयोगशाला के लिए खोजें और उन्हें अपने नेटवर्क में जोड़ें। फिर आप इन प्रयोगशालाओं को सीधे CMS से एक प्रिस्क्रिप्शन भेज सकते हैं",
  PatientsSubtitle:
    "यह उन सभी रोगियों की सूची है जो आपके क्लिनिक में गए थे। आप एक नया रोगी जोड़ सकते हैं या किसी मौजूदा रोगी का विवरण संपादित कर सकते हैं।",
  vitalsSubtitle:
    "क्लिनिक में आने वाले रोगी के लिए रिकॉर्ड करने वाले विटाल को जोड़ें",
  servicesSubtitle:
    "उन सेवाओं को जोड़ें जो आपके क्लिनिक प्रदान करते हैं। बिल जनरेट करते समय आप इनका चयन कर सकते हैं।",
  pharmaciesSubtitleOne:
    "किसी फ़ार्मेसी की खोज करें और उन्हें अपने नेटवर्क में जोड़ें। फिर आप इन फ़ार्मेसियों को सीधे CMS से भेज सकते हैं",
  pharmaciesSubtitleTwo: "फिर आप इन फ़ार्मेसियों को सीधे CMS से भेज सकते हैं",
  edit: "ऐडिट",
  Patients_List: "रोगी सूची",
  walk_in: "वाक इन",
  on_call: "ऑन कॉल",
  yes: "हाँ",
  no: "नहीं",
  info_title: "जानकारी",
  success_title: "सफलता!",
  error_title: "एरर!",
  save: "सेव",
  add: "ऐड",
  phone_number: "मोबाइलन नंबर",
  mobile_number: "मोबाइल नंबर",
  your_mobile_number: "आपका मोबाइल नम्बर",
  phone_number_short: "फ़ोन नं",
  password: "पासवर्ड",
  your_email: "ईमेल",
  by_clicking_signup: "साइन अप पर क्लिक करके, आप सहमत हैं",
  phone_and_password_required: "मोबाइल नंबर और पासवर्ड की आवश्यकता है।",
  language: "भाषा",
  forgot_password: "पासवर्ड भूल गए",
  invalid_valid_phone_number: "मान्य मोबाइल नंबर दर्ज करें।",
  sign_up: "साइन-अप",
  sign_up_heading: "अब खाता बनाएँ",
  sign_up_sub_heading_1:
    "आसानी से एक कुलीकेयर खाते के साथ अपने क्लिनिक का प्रबंधन और विकास करें।",
  sign_up_sub_heading_2:
    "बस नीचे अपना क्लिनिक विवरण दर्ज करें और साइन अप करें पर क्लिक करें",
  sign_up_sub_heading_3: "पहले से ही एक खाता है ?",
  error_occured: "एरर",
  error_msg: "अनुरोध संसाधित करते समय कुछ एरर आ गया है।",
  loading: "लोड हो रहा है",
  invalid_verify_code: "वेरिफिकेशन कोड अमान्य है।",
  verify_code_error: "वेरिफिकेशन कोड भेजते समय कुछ एरर आ गया है।",
  code_sent_success: "वेरिफिकेशन कोड सफलतापूर्वक भेजा जाता है।",
  verify_code_title:
    "हमने आपको यह वेरीफाई करने के लिए एक कोड भेजा है कि आप ही हैं!",
  enter_code: "कोड दर्ज करें",
  verify: "वेरीफाई",
  resend_code: "कोड फिर से भेजें",
  empty_code_error: "कृपया वेरिफिकेशन कोड दर्ज करें।",
  param_missing: "मोबाइल नंबर या देश कोड अनुपस्थित है।",
  verify_success:
    "यूज़र सफलतापूर्वक सत्यापित किया गया है, कृपया अपनी प्रोफ़ाइल बनाएं।",
  password_hint:
    "आपसे एक मजबूत पासवर्ड का उपयोग करने का अनुरोध किया जाता है। पासवर्ड 1 अपरकेस और 1 न्यूमेरिक या विशेष चरित्र के साथ 8 वर्णों से अधिक होना चाहिए।",
  is_account_existing: "क्या आपके पास पहले से एक खाता मौजूद है?",
  existing_account: "उपयोगकर्ता पहले ही साइन अप है।",
  weak: "कमज़ोर",
  medium: "मध्यम",
  strong: "मज़बूत",
  error: "एरर",
  page_not_found: "पेज नहीं मिला",
  required_phone_number: "कृपया मोबाइल नंबर दर्ज करें",
  forgot_pwd_title:
    "अपना पासवर्ड रीसेट करने के लिए निर्देश प्राप्त करने के लिए कृपया अपना मोबाइल नंबर दर्ज करें।",
  account_error: "अकाउंट मौजूद नहीं है",
  cancel: "रद्द करें",
  skip: "छोड़ें",
  next: "आगे",
  new: "नया",
  password_required: "कृपया पासवर्ड दर्ज करें।",
  confirm_password_error: "पुनः दर्ज पासवर्ड मेल नहीं खाता है।",
  password_error: "नया पासवर्ड पिछले जैसा नहीं होना चाहिए।",
  follow_pwd_hint: "कृपया पासवर्ड संकेत का पालन करें।",
  error_saving_pwd: "पासवर्ड सहेजने में एरर",
  reset: "रीसेट",
  reset_pwd: "पासवर्ड रीसेट",
  enter_new_pwd: "कृपया अपने अकाउंट के लिए एक नया पासवर्ड दर्ज करें",
  confirm_password: "पासवर्ड फिर से दर्ज करें",
  verify_contact: "मोबाइल नंबर सत्यापित करें",
  verify_contact_text: "आपके मोबाइल पर एक सत्यापन कोड भेजा गया है",
  invalid_code: "अवैध सत्यापन कोड",
  invalid_username_pwd: "उपयोगकर्ता फ़ोन नंबर या पासवर्ड अमान्य है।",
  invalid_password: "पासवर्ड गलत है।",
  short_pwd_error: "पासवर्ड बहुत छोटा है।",
  patientName: "रोगी का नाम",
  doctorName: "डॉक्टर का नाम",
  all: "सब",
  searchByPatientName: "रोगी के नाम से खोजें",
  sign_in: "लॉग इन",
  log_in: "लॉग इन करें",
  terms_condition: "नियम व शर्त",
  contactUs: "हमसे संपर्क करें",
  status: {
    open: "ओपन",
    lab_Accepted: "लैब ने स्वीकार कर लिया",
    lab_report_generated: "लैब रिपोर्ट जनरेट हुई",
    sample_picked_up: "सैंपल उठाया",
    closed: "बन्द है",
    cancelled: "रद्द",
    out_for_delivery: "डिलिवरी के लिए रवाना",
    delivered: "पहुंचा दिया",
    ready_for_pickup: "पिक अप के लिए तैयार",
    confirmed: "कनफर्म्ड"
  },
  signup: {
    password_must_be: "पासवर्ड में आवश्यक है",
    password_must_1: "8 से अधिक अक्षर",
    password_must_3: "1 संख्यात्मक या विशेष चरित्र",
    doctorName: "डॉक्टर का नाम",
    enter_name: "कृपया नाम दर्ज करें",
    enter_phone_number: "कृपया मोबाइल नंबर दर्ज करें",
    agree_to: "सहमत हैं",
    terms_n_conditions: "नियम और शर्तें",
    have_account: "क्या आपके पास खाता है?",
    serverError: "आपके अनुरोध को संसाधित करते समय कुछ त्रुटि हुई थी",
    sec: "सेकंड",
    agree_to_error: "कृपया साइन अप करने के लिए कुलकेयर शर्तों से सहमत हों"
  },
  signin: {
    sign_in_heading_text: "कृपया अपना मोबाइल नंबर और पासवर्ड दर्ज करें",
    keep_me_logged_in: "मुझे लोग्ड इन रखें",
    need_have_account: "एक खाता चाहिए?"
  },
  forgotPassword: {
    forgot_heading_text:
      "अपना पासवर्ड रीसेट करने के लिए अपना मोबाइल नंबर दर्ज करें"
  },
  changePassword: {
    text: "पासवर्ड बदलें",
    submit: "सबमिट",
    current_password: "वर्तमान पासवर्ड",
    new_password: "नया पासवर्ड",
    change_password_success_msg: "आपने अपना पासवर्ड सफलतापूर्वक बदल दिया है",
    current_pwd_incorrect: "वर्तमान पासवर्ड गलत है"
  },
  profile: {
    invitationSent: "डॉक्टर को भेजा गया निमंत्रण.",
    invite: "आमंत्रण",
    find: "Find",
    enterCity: "कृपया शहर दर्ज करें!",
    emptyText: "खाली नहीं हो सकता है",
    firstAddressLine: "पता पंक्ति 1",
    zipcode: "पिनकोड",
    open24: "ओपन 24x7",
    timings: "समय",
    save: "सेव",
    city: "शहर",
    searchPlaces: "जगह खोजें",
    state: "राज्य",
    schedule: "अनुसूची",
    mon: "सोम",
    tue: "मंगल",
    wed: "बुध",
    thu: "गुरूवार",
    fri: "शुक्र",
    sat: "शनिवार",
    sun: "रवि",
    startTime: "प्रारंभ समय",
    closingTime: "समापन समय",
    discounted_schedule: "रियायती समय",
    regular_schedule: "नियमित समय",
    complete_profile: "अपना क्लिनिक प्रोफाइल पूरा करें",
    confirm: "confirm"
  },
  support: {
    helpText:
      "हाय !, कृपया हमें बताएं कि आप क्या जानना चाहते हैं और हम आपकी मदद करने के लिए थोड़ी देर में आपको कॉल करेंगे",
    msgError: "कृपया आपकी मदद करने के लिए कुछ लिखें",
    msgPlaceholder: "मुझे जिस समस्या का सामना करना पड़ रहा है वह है",
    offlineMsg:
      "नमस्ते! आप इस समय ऑफ़लाइन हैं। आप हमें एक संदेश भेज सकते हैं या हमें इस नंबर पर कॉल कर सकते हैं + 91-9773905392",
    successMsg: "हमें आपकी समस्या मिली है और हम जल्द ही वापस कॉल करेंगे"
  },
  doctors: {
    errorMsg: "कृपया एक मान्य 10 अंकों का मोबाइल नंबर दर्ज करें।",
    noDocFound:
      "कोई भी डॉक्टर इस नंबर के साथ कुलकेयर पर पंजीकृत नहीं है। उन्हें आमनत्रन दो!",
    wantToAdd: "क्या आप जोड़ना चाहते हैं?",
    wantToRefer: "क्या आप संदर्भित करना चाहते हैं",
    addDoctor: "डॉक्टर जोड़ें",
    typeToSearchDoc: "डॉक्टर का 10 अंकों का मोबाइल नंबर दर्ज करें",
    daily: "रोज",
    name: "नाम",
    speciality: "विशेषता",
    fees: "फीस",
    feesPlaceholder: "शुल्क दर्ज करें",
    days: "दिन",
    saveDoctor: "सेव डॉक्टर",
    saveDoctorSuccess: "डॉक्टर की जानकारी सफलतापूर्वक सहेजी गई",
    selectDoctorError: "कृपया एक डॉक्टर को खोजें और चुनें",
    updateFees: "अपडेट शुल्क",
    emptyFeesError: "कृपया शुल्क राशि दर्ज करें",
    updatePatientCount: "रोगी गिनती अद्यतन करें",
    inValidPatientCount: "कृपया वैध रोगी गिनती दर्ज करें",
    updateDoctor: "डॉक्टर की जानकारी अपडेट करें",
    updateDoctorSuccess: "डॉक्टर की जानकारी सफलतापूर्वक अपडेट की गई",
    removeDoctor: "डॉक्टर रिमूव करें",
    removeDoctorSuccess: "डॉक्टर हटा दिया गया है",
    noMatch: "कोई मेल नहीं मिला",
    addSchedule: "अनुसूची जोड़ें",
    backToList: "दोबारा सूची को जाएं",
    notAvailable: "उपलब्ध नहीं है",
    patientsPerHour: "मरीज/ घंटा",
    id: "आईडी",
    gender: "लिंग",
    removeDoctorConfirmation: "क्या आप वाकई इस डॉक्टर को हटाना चाहते हैं?",
    remove: "हटाओ",
    updateSchedule: "अपडेट अनुसूची",
    removed: "सफलतापूर्वक हटा दिया गया",
    scheduleInfo: "अनुसूची जानकारी",
    addedSuccess: "सफलतापूर्वक जोड़ा गया",
    emptyDays: "कृपया दिन का चयन करें",
    emptyTimings: "कृपया समय का चयन करें",
    invalidTiming: "कृपया वैध समय का चयन करें",
    removeScheduleConfirmText: "क्या आप वाकई इस कार्यक्रम को हटाना चाहते हैं?",
    selectLocationError: "शहर ऐड करें",
    doctorName: "डॉक्टर का नाम",
    phoneNumber: "मोबाइल नंबर",
    noDoc: "कोई डॉक्टर नहीं मिला ...",
    doctor_info: "डॉक्टर की जानकारी",
    resend: "फिर भेजें"
  },
  leftnav: {
    Doctors: "डॉक्टर",
    Billing: "बिलिंग",
    Patients: "मरीज",
    Todays_Queue: "आज की कतार",
    Settings: "सेटिंग्स",
    Appointments: "नियुक्ति",
    Labs: "लैब्स",
    Pharmacies: "फार्मेसी",
    staff: "कर्मचारी",
    Referrals: "रेफरल",
    Business: "व्यापार",
    Help: "मदद"
  },
  generalNote: "क्या आप क्लिनिक विशिष्ट Ids उत्पन्न करना चाहते हैं?",
  generalPrefix: "क्लिनिक विशिष्ट आईडी उपसर्ग",
  general: "सामान्य",
  serviceName: "सर्विस का नाम",
  price: "मूल्य",
  action: "कार्य",
  mandatoryService: "सर्विस का नाम खाली नहीं हो सकता है",
  mandatoryPrice: "कीमत खाली नहीं हो सकती है",
  billNo: "बिल #",
  patientId: "मरीज आईडी",
  billDate: "बिल की तिथि",
  billStatus: "बिल स्थिति",
  unitPrice: "यूनिट मूल्य",
  service: "सर्विस",
  discount: "डिस्काउंट",
  printBill: "प्रिंट बिल",
  print: "प्रिंट",
  print_patient_details: "मरीज का विवरण प्रिंट करें",
  add_next_visit: "अगली यात्रा जोड़ें",
  print_patient_details_confirm:
    "क्या आप वाकई रोगी विवरण मुद्रित करना चाहते हैं?",
  gst: "जीएसटी",
  billAmount: "बिल की राशि",
  amountReceived: "रकम प्राप्त",
  amountPending: "लंबित राशि",
  noBills: "कोई बिल नहीं",
  total: "संपूर्ण",
  cash: "कैश",
  card: "कार्ड",
  noAmount: "कोई राशि नहीं",
  others: "अन्य",
  billingSummary: "भुगतान का प्रकार",
  billingAmount: "चेतावनी सारांश",
  noData: "कोई आकड़ा उपलब्ध नहीं है",
  services: "सेवाएं",
  modePayment: "भुगतान का प्रकार",
  mode: "प्रकार",
  view: "आलोकन",
  serviceList: "सेवा",
  paid: "सवेतन",
  unpaid: "अवैतनिक",
  male: "पुरुष",
  services_already_exists: "सेवा पहले से मौजूद है",
  service_add_success_msg: "सेवा सफलतापूर्वक जोड़ा गया",
  service_add_error_msg: "सेवा जोड़ने के दौरान कुछ त्रुटि हुई थी",
  service_edit_success_msg: "सेवा सफलतापूर्वक अपडेट की गई",
  service_edit_error_msg: "सेवा अद्यतन करते समय कुछ त्रुटि हुई थी",
  service_delete_success_msg: "सेवा सफलतापूर्वक हटा दी गई",
  service_delete_error_msg: "सेवा हटाने के दौरान कुछ त्रुटि हुई थी",
  service_form_validation_msg: "या तो नाम या मूल्य मान्य नहीं है",
  no_bill_text: "चयनित अवधि के दौरान कोई बिल जनरेट नहीं किया गया।",
  no_bill_patient_text: "इस रोगी के लिए कोई बिल जनरेट नहीं किया गया।",
  download_report: "Download Report",
  queue: {
    select_doctor: "डॉक्टर का चयन करें",
    no_timings_text: "डॉ {doctorName} के लिए कोई समय उपलब्ध नहीं है",
    no_timings_for_doc: "डॉ के लिए कोई समय उपलब्ध नहीं है",
    no_appointment_text: "कतार में कोई मरीज नहीं",
    no_doctors_text: "डॉक्टर उपलब्ध नहीं हैं",
    WithDoc: "डॉक्टर के साथ",
    InQueue: "कतार मे",
    Check_out: " चेक औट",
    CheckedOut: "चेक औट करना",
    CheckedIn: "आगमन सूचना",
    Remove: "कतार से हटाना",
    NotConfirmed: "कनफर्म्ड नहीं है",
    all: "सब",
    token_number: "टोकन संख्या",

    ref_by: "द्वारा",
    generate_bill: "बिल उत्पन्न करें",
    view_bill: "बिल देखें",
    new_appointment: "नव नियुक्ति!",
    new_patient: "Patient added",
    new_appointment_desc: "नियुक्ति सफलतापूर्वक बनाई गई है",
    new_patient_desc: "नए मरीज को कतार में जोड़ा गया है",
    no_bills_error: "इस नियुक्ति के लिए बिल नहीं बनाया गया है।",
    payment_recieved: "भुगतान सफलतापूर्वक प्राप्त हुआ",
    add_referral_code: "रेफरल कोड ऐड करें",
    referral_code: "रेफरल कोड ",
    empty_referral_error: "कृपया रेफरल कोड दर्ज करें।",
    confirmDeleteAppointment: "क्या आप वाकई इस अपॉइंटमेंट को हटाना चाहते हैं?",
    confirmDeleteFollowUpAppointment:
      "क्या आप वाकई इस अपॉइंटमेंट को हटाना चाहते हैं?",
    deleteAppointmentSuccess: "अपॉइंटमेंट सफलतापूर्वक हटा दी गई।",
    add_vitals: "वाइटलस ऐड करें",
    checkIn: "चेक इन",
    add_patient: "रोगी ऐड करे ",
    docCheckedInMsg: "डॉक्टर ने सफलतापूर्वक क्लिनिक मैं चेकइन किया है",
    no_patient_with_filter: `कोई भी मरीज "{status}" कतार में नहीं है`,
    view_all: "सभी को देखें"
  },
  selectServiceName: "कृपया सेवा का नाम चुनें",
  discountExceeded: "छूट मूल्य मूल्य से अधिक नहीं होना चाहिए",
  receivePayment: "भुगतान प्राप्त करें",
  recieve_payment_confirm:
    "बिल उत्पन्न करने के बाद, आप इस बिल को संपादित नहीं कर सकते। क्या आप आगे बढ़ना चाहते हैं ?",
  emptyPrice: "कृपया कीमत दर्ज करें",
  patientsPerHourNo: "मरीज़ / घंटा",
  please_confirm: "कृपया पुष्टि करें",
  queue_status_confirm_desc: "क्या आप वाकई इस रोगी को {status} करना चाहते हैं?",
  age: "उम्र",
  lastAppointment: "अंतिम अपॉइंटमेंट",
  AppointmentBillDetails: {
    YourReceipt: "आपकी रसीद",
    PatientName: "रोगी का नाम:",
    PatientID: "रोगी आईडी",
    DoctorsName: "डॉक्टर का नाम",
    BillDate: "बिल की तिथि",
    BillNo: "बिल संख्या",
    BillStatus: "बिल स्थिति",
    ServiceDetails: "सेवा विवरण",
    BillAmount: "बिल की राशि",
    GST: "जीएसटी",
    Discount: "डिस्काउंट",
    Total: "कुल",
    PoweredBy: "द्वारा संचालित",
    Bill: "बिल",
    Amount: "रकम",
    NoSignatureText:
      "यह एक कंप्यूटर जेनरेट किया गया दस्तावेज़ है। कोई हस्ताक्षर आवश्यक नहीं है।"
  },
  today: "आज",
  year: "वर्षों",
  month: "महीने",
  unidentifiedService: "अज्ञात सेवा",
  billGenerated: "बिल सफलतापूर्वक सहेजा गया।",
  serviceAlreadyAdded: "सेवा पहले ही जोड़ी जा चुकी है।",
  paymentReceived: "भुगतान सफलतापूर्वक प्राप्त हुआ।",
  addAppointmentsView: {
    AppointmentTime: "मुलाकात का वक़्त",
    SelectAppointmentType: "नियुक्ति प्रकार का चयन करें!",
    SelectAppointmentTime: "नियुक्ति समय का चयन करें!",
    Male: "पुरुष",
    Female: "महिला",
    SelectPatientGender: "रोगी का लिंग चुनें!",
    PatientDOB: "रोगी के जन्म की तारीख",
    EnterPatientName: "रोगी का नाम दर्ज करें",
    EnterPhoneNumber: "मोबाइल नंबर दर्ज करें",
    EnterMobileNumber: "एक मान्य मोबाइल नंबर दर्ज करें",
    invalidPhone: "अवैध मोबाइल नंबर!",
    SearchPatient: "रोगी की खोज करें",
    AddAppointment: "नियुक्ति जोड़ें",
    UpdateAppointment: "अद्यतन अपॉइंटमेंट",
    year: "वर्षों",
    month: "महीने",
    day: "दिन",
    patientPhoneNumber: "रोगी का मोबाइल नंबर",
    patientPhonenumber: "रोगी का मोबाइल नंबर",
    dateOfBirth: "जन्म की तारीख",
    WalkIn: "वाक इन",
    OnCall: "ऑन कॉल",
    Telephonic: "टेलीफ़ोनिक",
    fixed: "फिक्स्ड",
    typeOfAppointment: "नियुक्ति का प्रकार",
    type: "प्रकार",
    time_to_doctor: "डॉक्टर से मिलने का समय",
    newAppointmentError: "नई नियुक्ति करते समय कुछ त्रुटि थी",
    addressLineOne: "पता पंक्ति 1",
    addressLineTwo: "पता पंक्ति नं। 2",
    invalid_date_error: "कृपया वैध जन्म तारीख दर्ज करें"
  },
  unauthorized: "अनधिकृत",
  signInContinue: "जारी रखने के लिए कृपया लॉग इन करें",
  Labs: {
    LabsListContainer: {
      LabAdded: "लैब जोड़ा गया!",
      addedSuccessfully: "सफलतापूर्वक जोड़ा गया है।",
      LabAlreadyExists: "लैब पहले से मौजूद है।",
      LabDeleted: "लैब हटा दिया गया!",
      LabHasBeenDeletedSucessfully: "को सफलतापूर्वक हटा दिया गया है।",
      AddLab: "लैब जोड़ें",
      LabFromYourList: "आपकी सूची से?",
      LabToYourList: "आपकी सूची से?",
      DeleteLab: "लैब हटाएं"
    },
    LabsListView: {
      SearchWithLabName: "प्रयोगशाला नाम के साथ खोजें"
    }
  },
  Pharmacy: {
    PharmacyListContainer: {
      success: "सफलता",
      PharmacyAdded: "फार्मेसी जोड़ा गया!",
      addedSuccessfully: " सफलतापूर्वक जोड़ा गया है।",
      error: "एरर",
      Error: "एरर",
      pharmacyExists: "फार्मेसी पहले से मौजूद है",
      pharmacyAddedSuccessfully: "फार्मेसी को सफलतापूर्वक जोड़ा गया है।",
      PharmacyDeleted: "फार्मेसी हटा दी गई!",
      PharmacyDeletedSuccessfully: "फार्मेसी को सफलतापूर्वक हटा दिया गया है।",
      DoYouWantToAdd: "क्या आप जोड़ना चाहते हैं",
      PharmacyFromList: "आपकी सूची से?",
      PharmacyToList: "आपकी सूची से?",
      DoYouWantToDelete: "क्या आप हटाना चाहते हैं?",
      AddPharmacy: "फार्मेसी जोड़ें",
      DeletePharmacy: "फार्मेसी हटाएं",
      EnterPhoneNumber: "वैध मोबाइल नंबर दर्ज करें",
      EnterName: "कृपया नाम दर्ज करें",
      EnterAddressLine1: "पता पंक्ति 1 दर्ज करें",
      EnterAddressLine2: "पता पंक्ति 2 दर्ज करें",
      EnterZipcode: "कृपया पिन कोड डालें",
      SearchPlaces: "जगह खोजें"
    },
    PharmacyListView: {
      Name: "नाम",
      PhoneNumber: "फ़ोन नंबर",
      Location: "स्थान",
      Delete: "हटाना",
      SearchWithPharmacyName: "फार्मेसी नाम के साथ खोजें"
    }
  },
  vitals: {
    delete: "डिलीट  करें",
    text: "वाइटल",
    vital_name: "वाइटल का नाम",
    vital_name_empty: "वाइटल खाली नहीं हो सकते",
    remove_vital: "वाइटल को डिलीट करें",
    remove_vital_confirmation: "क्या आप वाकई वाइटल हटाना चाहते हैं?",
    vitals_not_present: "विटाल पेश नहीं हुए"
  },
  patients: {
    search: "खोज",
    searchText: "नाम, आईडी या मोबाइल नंबर द्वारा खोजें",
    photos_attached: "आपने तस्वीरें संलग्न की हैं",
    patient_visit: "रोगी आप का दौरा किया"
  },
  careplan: {
    clear_prescription: "प्रिस्क्रिप्शन रिमूव करें",
    backSetup: "बैक टू स्टार्ट गाइड",
    prescriptionTemplate: "प्रिस्क्रिप्शन टेम्पलेट",
    templateSubtitle:
      "एक डॉक्टर का चयन करें और उनके पर्चे टेम्पलेट्स सेट करें। ये टेम्पलेट नुस्खे बनाते समय इस्तेमाल किए जा सकते हैं ताकि डॉक्टर को हर बार फिर से सब कुछ टाइप न करना पड़े।",
    adviceGroupAdded: "सलाह समूह जोड़ा गया",
    adviceGroupSuccess: "सलाह समूह सफलतापूर्वक जोड़ा गया",
    unableToAdd: "जोड़ने में असमर्थ",
    errorAddingGroup: "समूह जोड़ने में त्रुटि",
    updatedAdviceGroup: "सलाह समूह",
    updateAdviceGroup: "सलाह समूह सफलतापूर्वक अपडेट किया गया",
    errorUpdatingGroup: "समूह अपडेट करने में त्रुटि हुई",
    unableToUpdate: "अपडेट करने में असमर्थ",
    adviceGroupExists: "समूह पहले से मौजूद है",
    groupDeleted: "सलाह समूह हटा दिया गया",
    adviceDeleted: "सलाह समूह सफलतापूर्वक हटा दिया गया",
    adviceGroupName: "सलाह समूह का नाम",
    adviceSubtitle: "उपशीर्षक",
    emptyAdvice: "सलाह समूह खाली नहीं हो सकता।",
    enterAdviceGroupName: "एक समूह का नाम दर्ज करें",
    emptyAdviceName: "सलाह खाली नहीं हो सकती",
    enterAdviceName: "सलाह दर्ज करें",
    dietGroupAdded: "डाइट ग्रुप जोड़ा",
    dietGroupAddedSuccess: "डाइट ग्रुप सफलतापूर्वक जोड़ा गया",
    dietGroupUpdated: "आहार समूह अपडेट किया गया",
    dietGroupUpdatedSuccess: "आहार समूह सफलतापूर्वक अपडेट किया गया।",
    errorUpdatingDietGroup: "आहार समूह को अपडेट करने में त्रुटि",
    dietGroupExists: "आहार समूह पहले से मौजूद है",
    errorAddingDiet: "आहार समूह को जोड़ने में त्रुटि",
    dietGroupDeleted: "आहार समूह हटाया गया",
    dietGroupDeletedSuccess: "आहार समूह को सफलतापूर्वक हटा दिया गया",
    errorDeletionDiet: "आहार समूह को हटाने में त्रुटि",
    errorCommonDiet: "डाइट ग्रुप को हटाते समय कुछ त्रुटि हुई थी",
    dietGroupname: "आहार समूह का नाम",
    dietGroups: "आहार समूह",
    dietGroupSubtitle: "उपशीर्षक",
    emptyDietGroupName: "आहार समूह का नाम खाली नहीं हो सकता।",
    enterDietGroupName: "कृपया आहार समूह नाम दर्ज करें",
    noEmptyDiet: "आहार खाली नहीं हो सकता।",
    enterDiet: "आहार दर्ज करें",
    medDeleted: "दवाई हटा दी",
    medDeletedSuccess: "दवा सफलतापूर्वक हटा दी गई",
    medEmptyFields: "खाली क्षेत्र",
    medMandatoryFields: "कृपया अनिवार्य फ़ील्ड इनपुट करें",
    medUpdatedSuccess: "दवा सफलतापूर्वक अपडेट की गई",
    medUpdated: "दवा अद्यतन किया गया",
    medAddedSuccess: "दवा सफलतापूर्वक जोड़ा गया",
    medAdded: "दवा सफलतापूर्वक जोड़ा गया",
    medicineSubtitle:
      "आप एक दवा जोड़ सकते हैं जो हमारे डेटा बेस में नहीं है या आप संबंधित जानकारी (फ़्रीक्वेंसी, अवधि आदि) जोड़ सकते हैं ताकि जब आप पर्चे जोड़ते समय दवा का चयन करें तो आपको बार-बार टाइप न करना पड़े।",
    medGroupExists: "दवा समूह पहले से मौजूद है!",
    medUnableAdd: "समूह जोड़ने में असमर्थ",
    numbericValue: "अंकीय मान",
    invalidInputMed: "कृपया मान्य इनपुट दर्ज करें",
    medGroupAddSuccess: "दवा समूह सफलतापूर्वक जोड़ा गया",
    medGroupAdded: "दवा समूह जोड़ा गया",
    medGroupUpdateSuccess: "दवा समूह सफलतापूर्वक जोड़ा गया",
    medGroupUpdated: "दवा समूह जोड़ा गया",
    medGroupDeleteSuccess: "दवा समूह सफलतापूर्वक हटा दिया गया",
    medGroupDeleted: "दवा समूह हटा दिया गया",
    medGroupName: "दवा समूह का नाम",
    medicineGroups: "दवा समूह",
    medGroupSubtitle: "उपशीर्षक",
    emptyGroupName: "दवा समूह खाली नहीं हो सकता",
    medGroupNamePlaceholder: "दवा समूह का नाम",
    medNameEmpty: "दवाई खाली नहीं हो सकती",
    addSelectMed: "एक या अधिक दवाएं जोड़ें",
    medicines: "दवाई",
    testUnabletoAdd: "परीक्षण समूह जोड़ने में असमर्थ",
    testGroupExists: "टेस्ट ग्रुप पहले से मौजूद है",
    testGroupUpdated: "परीक्षण समूह अपडेट किया गया",
    testGroupUpdateSuccess: "परीक्षण समूह सफलतापूर्वक अपडेट किया गया",
    testGroupAdded: "परीक्षण समूह जोड़ा गया",
    testGroupAddedSuccess: "परीक्षण समूह सफलतापूर्वक जोड़ा गया",
    testGroupDeletSuccess: "परीक्षण समूह सफलतापूर्वक हटा दिया गया",
    testGroupDeleted: "समूह हटा दिया गया",
    testGroupName: "टेस्ट समूह का नाम",
    testGroups: "परीक्षण समूह",
    testGroupSubtitle: "उपशीर्षक",
    enterTestGroupName: "समूह का नाम दर्ज करें",
    selectTests: "परीक्षण का चयन करें",
    addSelectTest: "एक या अधिक परीक्षण जोड़ें",

    sendPrescriptionTo: "प्रिस्क्रिप्शन भेजें:",
    printPrescription: "प्रिस्क्रिप्शन प्रिंट करें",
    sendPrescription: "प्रिस्क्रिप्शन भेजें",
    pharmacies: "औषधालय",
    labs: "प्रयोगशालाओं",
    printConfirmation: "क्या आप वाकई प्रिस्क्रिप्शन प्रिंट करना चाहते हैं?",
    noGoBack: "नहीं, वापस जाओ",
    yesPrint: "हाँ, प्रिंट",
    PrescriptionSentTo: "{patientName} के लिए पर्चे भेजा गया है:",
    okPrint: "ठीक, प्रिंट",
    saveDone: "सेव एंड डन",
    noPrint: "नहीं, प्रिंट",
    continue: "जारी रहना",
    printConfirmationTitle: "प्रिंट पुष्टीकरण",
    sendPrint: "भेजें और प्रिंट करें",
    emptyProviderListText:
      'आप बाईं ओर के "{प्रदाताओं}" टैब से इस नुस्खे को भेजने के लिए एक या एक से अधिक "{प्रदाता}" जोड़ सकते हैं',
    complaintErrorDesc:
      "कृपया इस केयरप्लान को सेव करने के लिए अवलोकन दर्ज करें",
    complaintErrorTitle: "प्रिस्क्रिप्शन त्रुटि सहेजें",
    saveCareplanTitle: "प्रिस्क्रिप्शन सहेजें",
    saveCareplanDesc: "प्रिस्क्रिप्शन सफलतापूर्वक बच गया",
    saveCareplanErrorTitle: "प्रिस्क्रिप्शन त्रुटि सहेजें",
    saveCareplanErrorDesc: "प्रिस्क्रिप्शन को बचाया नहीं जा सका",
    careplanText: "प्रिस्क्रिप्शन",
    noPrevCareplan: "पिछला प्रिस्क्रिप्शन नहीं मिला",
    careplanPopulatedTitle: "प्रिस्क्रिप्शन आबाद",
    careplanPopulatedDesc: "प्रिस्क्रिप्शन सफलतापूर्वक पिछले डेटा के साथ आबादी",
    complaints: "शिकायतें",
    vitals: "नब्ज",
    tests: "टेस्ट",
    medicines: "दवाएँ",
    noKeyAddedPrev1: "कोई {keyVal} पहले नहीं जोड़ा गया",
    advice: "सलाह",
    Advice: "सलाह",
    diet: "आहार",
    unsavedDataWarning:
      "आपके पास बिना सहेजे सामग्री है। क्या आप वाकई इस पृष्ठ को छोड़ना चाहते हैं?",
    observation: "अवलोकन",
    investigation: "जाँच पड़ताल",
    medication: "इलाज",
    dietExercise: "आहार और व्यायाम",
    nextVisit: "अगली भेंट",
    adviceGroups: "सलाह समूह",
    prevAdvice: "पूर्व सलाह",
    addAdvice: "सलाह जोड़ें",
    select: "चयन",
    addFromAdviceGroup: "सलाह समूह से जोड़ें",
    addFromPreviousCareplan: "पिछले प्रिस्क्रिप्शन से जोड़ें",
    addFromDietGroup: "आहार समूह से जोड़ें",
    dietGroup: "आहार समूह",
    prevDiet: "प्रीव डाइट",
    addDiet: "आहार जोड़ें",
    addFromTestGroup: "परीक्षण समूहों से जोड़ें",
    testGroups: "परीक्षण समूह",
    prevTests: "प्रीव टेस्ट",
    notes: "नोट्स",
    addToTestList: "परीक्षण सूची में जोड़ें",
    testName: "परीक्षण का नाम",
    emptyTestError: "कृपया परीक्षण दर्ज करें",
    alternateMedicine: "वैकल्पिक चिकित्सा",
    when: "कब",
    whenPlaceholder: "दिन में 4 बार",
    duration: "अवधि",
    durationPlaceholder: "जैसे 10",
    frequency: "आवृत्ति",
    addFromMedsGroup: "मेड्स ग्रुप से जोड़ें",
    medsGroup: "मेड्स ग्रुप",
    selectMedsFromGroup: "मेड्स ग्रुप से दवाओं का चयन करें",
    medicineName: "दवा का नाम",
    instructions: "निर्देश का चयन करें या लिखें",
    alternate: "एक वैकल्पिक दवा दर्ज करें",
    specialInstructions: "विशेष निर्देश",
    enterMedicineName: "दवा का नाम दर्ज करें",
    addFromPrevMeds: "पिछले मेड्स से जोड़ें",
    prevMeds: "प्रीव मेड्स",
    nextVisitAfter: "अगली भेंट बाद में",
    complaint: "शिकायत",
    sinceWhen: "कब से",
    day: "दिन",
    days: "दिन",
    month: "महीना",
    months: "महीने",
    week: "सप्ताह",
    weeks: "सप्ताह",
    addFromPrevComplaints: "पिछली शिकायतों में से जोड़ें",
    prevComplaints: "पिछली शिकायतों",
    emptyComplaintsError: "कृपया शिकायतें दर्ज करें",
    physicalExamination: "शारीरिक परीक्षा / अवलोकन:",
    diagnosis: "मूल्यांकन",
    pharmacyOrLab: "फार्मेसी / लैब",
    pharmaciesOrLabs: "फार्मेसी / लैब",
    lab: "प्रयोगशाला",
    pharmacy: "औषधालय",
    prevPres: "पिछली कॉपी",
    back: "वापस जाओ",
    since: "जबसे",
    back_to_setup: "बैक टू स्टार्ट गाइड"
  },
  Patient: {
    PatientListView: {
      Id: "आईडी",
      NameGenderAge: "नाम / लिंग / आयु",
      LastAppointment: "अंतिम नियुक्ति",
      SearchWith: "रोगी का नाम / मोबाइल नंबर / रोगी आईडी के साथ खोजें",
      Observations: "टिप्पणियों",
      PrescribedBy: "द्वारा निर्धारित",
      PrescriptionFor: "के लिए प्रिस्क्रिप्शन",
      PatientHistory: "रोगी का इतिहास",
      PhysicalExamination: "शारीरिक परीक्षा",
      LabResults: "लैब परिणाम",
      ViewReport: "रिपोर्ट देखें",
      NoRecords: "इस रोगी के लिए कोई रिकॉर्ड मौजूद नहीं है",
      youCreatedPrescription: "आपने प्रिस्क्रिप्शन बनाए"
    }
  },
  Settings: {
    headerHeight: "हैडर की ऊँचाई(इंच)",
    footherHeight: "पाद ऊँचाई(इंच)",
    kulcare: "कुलकेयर",
    customFormat: "कस्टम",
    prescriptionOption: "आप रोगी के पर्चे पर क्या छापना चाहते हैं?",
    prescriptionFormat: "आप किस प्रकार के पर्चे लेआउट का उपयोग करना चाहते हैं?",
    prescription: "प्रिस्क्रिप्शन",
    Tests: "टेस्ट",
    Medicines: "दवाएँ",
    network: "नेटवर्क",
    clinic_settings: "क्लिनिक सेटिंग्स",
    users: "उपयोगकर्ता",
    settings_saved_msg: "सेटिंग सफलतापूर्वक सहेजी गई।",
    left_margin: "बायां मार्जिन(इंच)",
    right_margin: "दायां मार्जिन(इंच)",
    left_margin_error: "बायां मार्जिन 3 से अधिक नहीं होना चाहिए",
    right_margin_error: "दायां मार्जिन 3 से अधिक नहीं होना चाहिए"
  },
  carePlan: "प्रिस्क्रिप्शन",
  history: "इतिहास",
  tags_placehoder: "टाइप करें और जोड़ने के लिए या दबाएँ चुनें",
  profileTxt: "प्रोफाइल",
  logout: "लोग आउट",
  test_results_for: "{labName} के लिए परिणाम भेजे गए",
  test_result: "परीक्षा परिणाम",
  test_result_image_loading: "loading...",
  test_result_loading: "परीक्षण के परिणाम लोड हो रहे हैं ...",
  select_clinic: "क्लिनिक का चयन करें",
  staff: {
    add_staff: "कर्मचारी जोड़ें",
    saveStaffSuccess: "स्टाफ सफलतापूर्वक जोड़ा गया।.",
    removeDoctorConfirmation: "क्या आप वाकई स्टाफ सदस्य को हटाना चाहते हैं?",
    status: "स्थिति",
    removeStaffSuccess: "कर्मचारी सदस्य सफलतापूर्वक हटा दिया गया",
    delete_staff: "स्टाफ सदस्य हटाएं"
  },
  ok: "ठीक",
  referrals: {
    referralNotes: "रेफ़रल नोट्स",
    viewNotes: "नोट देखे",
    referredBy: "संदर्भित द्वारा",
    referPatient: "रोगी को देखें",
    refer: "भेजना",
    referSuccess: "हम डॉक्टर और मरीज को संपर्क में रखेंगे",
    referThisPatient: "इस रोगी को देखें",
    noReferralNotes: "डॉक्टर द्वारा जोड़ा गया कोई भी रेफरल नोट नहीं",
    referPatientNotificationHeading: "रेफरल सफल!",
    patientDetail: "मरीज विस्तार",
    referredTo: "संदर्भित किया",
    referredOn: "संदर्भित दिनांक",
    referred_to_you: "संदर्भित द्वारा",
    referred_by_you: "संदर्भित किया",
    status: "स्थिति"
  },
  confirm: "पुष्टि करें",
  future: "भविष्य",
  followup: "ऊपर का पालन करें",
  AppointmentDate: "मिलने की तारीख",
  confirmFollowupAppointmentModalTitle: "अनुवर्ती नियुक्ति की पुष्टि करें",
  selectDate: "तारीख़ चुनें",
  followUps: "पालन ​​करें",
  fixed_appts: "अनुसूचित नियुक्ति",
  visitDueOn: "यात्रा के कारण",
  result: "परिणाम",
  results: "परिणाम",
  patient: "मरीज",

  referredToText: "सजिन रोगियों को आपने अन्य डॉक्टरों के पास भेजा था।",
  referredByText: "मरीजों को अन्य डॉक्टरों ने आपके पास भेजा।",
  pharmacyReferrelText:
    "इस क्लिनिक में एक डॉक्टर द्वारा जिन रोगियों को फार्मेसी में भेजा गया था।",
  labReferrelText:
    "इस क्लिनिक में एक डॉक्टर द्वारा मरीजों को एक प्रयोगशाला में भेजा गया था।",
  networkPharmaciesText:
    "किसी फ़ार्मेसी की खोज करें और उन्हें मरीजों को संदर्भित करने के लिए अपने नेटवर्क में जोड़ें.",
  networkLabsText:
    "एक लैब की खोज करें और उन्हें मरीजों को संदर्भित करने के लिए अपने नेटवर्क में जोड़ें.",
  doctorUserText:
    "एक डॉक्टर के लिए मोबाइल नंबर से खोजें जिसे आप अपने क्लिनिक में जोड़ना चाहते हैं.",
  staffUserText:
    "एक कर्मचारी को मोबाइल नंबर द्वारा आमंत्रित करें जिसे आप अपने क्लिनिक में जोड़ना चाहते हैं।",
  selectDoctor: "डॉक्टर का चयन करे",
  savePrescriptionWarning:
    "आप प्रिस्क्रिप्शन को बाद में संपादित करने में सक्षम नहीं होंगे। आप इस नुस्खे को रोगी के इतिहास में पा सकते हैं। जारी रहना?",
  createPrescriptionFor: "प्रिस्क्रिप्शन बनाएँ",
  editPatient: "रोगी को ऐडिट करें",
  patientAdded: "आपने सूची में एक नया रोगी सफलतापूर्वक जोड़ा है",
  patientUpdated: "आपने रोगी जानकारी को सफलतापूर्वक अपडेट कर दिया है।",
  infoUpdated: "जानकारी अपडेट की गई",
  resendInvite: "फिर निमंत्रण भेजें",
  reinviteDoctorConfirmation:
    "क्या आप वाकई इस डॉक्टर को फिर से निमंत्रण भेजना चाहते हैं?",
  inviteDoctor: "डॉक्टर को आमंत्रित करें",
  inviteSuccess: "डॉक्टर को सफलतापूर्वक आमंत्रित किया गया!",
  perPage: "प्रति पृष्ठ",
  booked: "बुक्ड",
  nextVisit: "अगली मुलाकात",
  nextVisitSaved: "अगला दौरा सहेजा गया",
  nextVisitSaveDesc: "अगली यात्रा सफलतापूर्वक बच गई।",
  nextVisitUpdateSaved: "अगली मुलाकात अपडेटेड",
  nextVisitUpdateSaveDesc: "अगली यात्रा सफलतापूर्वक अपडेट की गई।",
  nextVisitDeleteSaved: "अगली भेंट हटा दी गई।",
  nextVisitDeleteSaveDesc: "अगली यात्रा सफलतापूर्वक हटा दी गई।",
  update: "अद्यतन करें",
  delete: "हटाना",
  enterVisitAfter: "अगली यात्रा विवरण दर्ज करें",
  manageNextVisit: "अगली यात्रा की व्यवस्था करें",
  nextVisitUpdateSaveDescError: "अगली यात्रा अपडेट करने में असमर्थ।",
  nextVisitSaveDescError: "अगली यात्रा सहेजने में असमर्थ।",
  nextVisitDeleteSaveDescError: "अगली यात्रा को हटाने में असमर्थ।",
  photo: "तस्वीर",
  photos: "तस्वीरें",
  inside: "अंदर",
  click_to_open: "खोलने के लिए क्लिक करें",
  days: "दिन",
  forPatients: "मरीजों के लिए",
  forPharmacies: "फार्मासिस्टों के लिए",
  forLabs: "लैब्स के लिए",
  downloadApp: "एप्लिकेशन डाउनलोड करें",
  signUpHeading:
    "आप अपने रोगियों की देखभाल करते हैं, और हम आपकी परिचालन चुनौतियों का ध्यान रखेंगे",
  signUpSubHeading:
    "कुलकेयर सबसे सरल और सबसे आसान क्लिनिक प्रबंधन सॉफ्टवेयर प्रदान करता है। यह आपको सबसे अधिक लागत प्रभावी तरीके से अपने अभ्यास को डिजिटल बनाने में मदद करता है।",
  yourPhoneNumber: "आपकी दूरभाष संख्या",
  referredBySomeone: "किसी के द्वारा संदर्भित?",
  enterReferralCode: "रेफरल कोड दर्ज करें",
  phoneNumberVerification: "मोबाइल नंबर का सत्यापन",
  sentCodeOn: "हमने आपको {0} पे एक सत्यापन कोड भेजा है",
  enterVerificationCode: "सत्यापन कोड दर्ज करें",
  na: "लागू नहीं",
  delete_bill_confirm: "क्या आप वास्तव में इस बिल को हटाना चाहते हैं?",
  providerInfo: "अपनी जानकारी प्रदान करें!",
  provideClinicInfo: "अपनी क्लिनिक जानकारी प्रदान करें!",
  yourName: "आपका नाम",
  createPassword: "अपना पासवर्ड बनाएँ",
  confirmPassword: "पासवर्ड की पुष्टि कीजिये",
  step: "सटेप",
  patientPerHourTip:
    "औसत दर्ज करें। कतार में प्रतीक्षा समय का अनुमान लगाने के लिए हर घंटे डॉक्टर द्वारा देखे जाने वाले रोगियों की संख्या।",
  yourAvailablity: "क्लिनिक में आपकी उपलब्धता",
  consultationFees: "आपकी परामर्श फीस",
  patientYouSee: "मरीजों को आप प्रति घंटे देखते हैं",
  allDone: "सब कुछ कर दिया",
  addAvailability: "उपलब्धता जोड़ें",
  addMore: "अधिक जोड़ें",
  done: "किया हुआ",
  congratulations: "बधाई हो!",
  allSetText: "{0} सभी को kulcare के माध्यम से प्रबंधित करने के लिए तैयार है।",
  takeYouThroughtKulcare: "आइए हम आपको एक बार इसके माध्यम से लेते हैं।",
  showMeAround: "मुझे आसपास दिखाओ",
  // figureOutMyself: "मैं इसका खुद पता लगाऊंगा",
  agreeToTerms: "मैं {0} से सहमत हूं",
  kulcareTerms: "कुलकेयर की शर्तें",
  confirmPwdRequired: "कृपया पुष्टि पासवर्ड दर्ज करें",
  nonExistingAccount: "इस उपयोगकर्ता के लिए खाता मौजूद नहीं है",
  addDaysTimings: "कृपया किसी स्कॉलर के लिए दिनों और समय का चयन करें",
  overlapTimingsError:
    "दर्ज की गई समय-सारिणी कुछ अन्य अनुसूची के साथ ओवरलैप होती है",
  addScheduleError: "कृपया अन्य जोड़ने के लिए कम से कम एक शेड्यूल जोड़ें",
  addAvailabilityError: "कृपया डॉक्टर का शेड्यूल जोड़ें",
  amountCollectedValidation: "राशि कुल राशि से अधिक नहीं हो सकती",
  billDiscountValidation: "छूट कुल राशि से अधिक नहीं हो सकती",
  checkBillPaid: "भुगतान के रूप में बिल को चिह्नित करें",
  unbilled_amount: "बकाया राशि",
  service_date: "सेवा की तारीख",
  amount_collected: "एकत्रित की गई राशि",
  in_rupees: "रुपए में",
  generated_bill_amount: "पेड बिल राशि",
  receive_payment_confirm:
    "क्या आप वाकई बिल को भुगतान के रूप में चिह्नित करना चाहते हैं?",
  tour: {
    popover_btn_title: "आपका टूर गाइड",
    popover_btn_content:
      "आप इस टैब के विभिन्न घटकों को समझने में मदद करने के लिए ऐप टूर शुरू करने के लिए कभी भी इस बटन पर क्लिक कर सकते हैं।",
    queue_tour: {
      step1: {
        title: "मरीजों को जोड़ें",
        content:
          "आप ऐड बटन पर क्लिक करके एक मरीज को कतार में जोड़ सकते हैं। मरीज का नाम, मोबाइल नंबर लिंग और उम्र और उसके साथ जोड़ें"
      },
      step2: {
        title: "रोगी की जानकारी",
        content:
          "पंक्ति का यह हिस्सा आपको रोगियों के बीच अंतर करने में मदद करने के लिए आपको तुरंत मूल रोगी जानकारी देता है।"
      },
      step3: {
        title: "डॉक्टर का समय",
        content:
          "यह अनुमानित समय है जब रोगी डॉक्टर को देख सकता है। इसकी गणना विभिन्न कतार मापदंडों के आधार पर की जाती है। नियत नियुक्ति वाले रोगियों के लिए यह उनकी नियुक्ति का समय दिखाएगा।"
      },
      step4: {
        title: "कुल बीजक राशि",
        content:
          "इससे पता चलता है कि रोगी ने कितनी राशि का भुगतान किया है (हरे रंग में) और लंबित राशि (लाल में) एकत्र की जानी है। बिलिंग जानकारी जोड़ते ही यह प्रतिबिंबित हो जाएगा।"
      },
      step5: {
        title: "रोगी की स्थिति",
        content: {
          p1:
            "यह कतार में रोगी की स्थिति है जिसे आप कभी भी संपादित कर सकते हैं।",
          p2_title: "इन कयू",
          p2_text: "मरीज जो कतार में हैं लेकिन क्लिनिक में नहीं।",
          p3_title: "चेकड इन",
          p3_text: "जिन मरीजों को क्लिनिक में है।",
          p4_title: "विद डाक ",
          p4_text: "डॉक्टर के कक्ष के अंदर रोगी",
          p5_title: "चेकड आउट",
          p5_text: "जिन मरीजों ने अपनी यात्रा पूरी कर ली है।",
          p6_title: "नो शो",
          p6_text: "रोगी जो क्लिनिक में नहीं आ सकते थे।"
        }
      },
      step6: {
        title: "Actions",
        content: {
          p1: "ये ऐसी क्रियाएं हैं जो आप एक मरीज पर कर सकते हैं।",
          p2_title: "बिलिंग",
          p2_text:
            "आप सेवाएं जोड़ सकते हैं, बिल जेनरेट कर सकते हैं और जेनरेट किए गए बिल देख सकते हैं।",
          p3_title: "विटल जोड़ें",
          p3_text: "आप रोगी के विटाल जोड़ सकते हैं।",
          p4_title: "रोगी विवरण प्रिंट करें",
          p4_text:
            "आप सिर्फ अपने पर्चे पैड पर रोगी का विवरण प्रिंट कर सकते हैं।",
          p5_title: "अगली मुलाकात",
          p5_text:
            "आप रोगी की अगली यात्रा को यहां जोड़ सकते हैं। हम आपको फॉलोअप यात्रा के लिए रोगी को वापस लाने में मदद करेंगे।"
        }
      },
      step7: {
        title: "चेक आउट / नो शो कतार",
        content:
          "जिन रोगियों की आप कतार से जाँच करते हैं या जो क्लिनिक में नहीं आते हैं उन्हें यहाँ सूचीबद्ध किया जाएगा। आप उन्हें कतार में वापस जोड़ सकते हैं।"
      },
      step8: {
        title: "आपका टूर गाइड",
        content:
          "आप इस टैब के विभिन्न घटकों को समझने में मदद करने के लिए ऐप टूर शुरू करने के लिए कभी भी इस बटन पर क्लिक कर सकते हैं"
      }
    },
    appointments_tour: {
      step1: {
        title: "जोड़ना नियुक्ति",
        content:
          "आप इस बटन का उपयोग करके एक नई नियुक्ति जोड़ सकते हैं। एक रोगी जोड़ें (या मौजूदा से खोज करें) और एक समय स्लॉट चुनें।"
      },
      step2: {
        title: "ऐडिड नियुक्ति",
        content:
          "सभी जोड़े गए अपॉइंटमेंट समय स्लॉट्स के आधार पर जोड़े जाएंगे। समय स्लॉट की गणना रोगियों की औसत संख्या पर विचार करके की जाती है, जिसे डॉक्टर एक घंटे में देख सकते हैं।"
      },
      step3: {
        title: "फॉलो उप ",
        content:
          "ये एक मरीज के लिए अगली यात्रा के लिए आपके इनपुट के आधार पर संभावित अनुवर्ती जोड़ हैं। आप रोगी को कॉल कर सकते हैं और फॉलोअप की पुष्टि या रद्द कर सकते हैं। रेड डॉट्स कल के फॉलोअप को दिखाते हैं, आज के लिए ग्रीन डॉट्स और कल के लिए ऑरेंज डॉट्स को।"
      }
    }
  },
  Pharmacies_List: "फार्मेसी की सूची",
  alreadyVerified: "यह खाता हमारे पास पहले से मौजूद है",
  youHaveAvailability: "आपने 1 उपलब्धता जोड़ी",
  youHaveAvailabilities: "आपने {0} उपलब्धियां जोड़ीं",
  roles: {
    owner: "मालिक",
    consultant: "सलाहकार",
    staff: "कर्मचारी"
  },
  email: "ईमेल",
  yourEmail: "आपकी ईमेल",
  emailToolTip:
    "ईमेल महत्वपूर्ण है, क्योंकि यह वह जगह है जहाँ आप हमसे अधिकतर संचार प्राप्त करेंगे। विशेष रूप से, शानदार विशेषताएं, जो आपको पसंद आएंगी, जल्द ही आएँगी!",
  enter_valid_email: "कृपया वैध ईमेल दर्ज़ करें",
  verification_code: "सत्यापन कोड दर्ज करें",
  networkError: "नेटवर्क कनेक्टिविटी समस्या। कृपया पुन: प्रयास करें!",
  clickToReload: "रीलोड करें",
  newEntry: "नविन प्रवेश",
  previousUnbilled: "पिछला अन बिलड",
  createNewBill: "एक नया बिल बनाएं",
  createNewBillText:
    "आज के लिए पहले से ही एक अन बिलड प्रवेश है। क्या आप एक नया बनाना चाहते हैं?",
  previousBilled: "पिछला बिलड",
  saveBillDataWarning: "क्या आप बिल डेटा सेव करना चाहते हैं?",
  serviceDateError: "कृपया सेवा की तारीख का चयन करें",
  phoneNumberTipText:
    "हम कुलकेयर पर साइनअप करने के लिए लिंक के साथ डॉक्टर को एक संदेश भेजेंगे।",
  addaDoctor: "डॉक्टर ऐड करें।",
  enterDoctorName: "डॉक्टर का नाम दर्ज करें",
  enterDoctorPhoneNumber: "डॉक्टर का मोबाइल नंबर दर्ज करें",
  enterDoctorAvailabilty: "अभ्यास में डॉक्टर की उपलब्धता दर्ज करें",
  patientPerHourPlaceholder: "प्रति घंटे देखे गए रोगियों की औसत संख्या",
  doctorPracticeNamePlaceholder: "आपका अभ्यास नाम उदा. XYZ Clinic",
  existingUser: "मौजूदा उपयोगकर्ता?",
  practicePhoneNumber: "अभ्यास का मोबाइल नंबर",
  setPassword: "एक पासवर्ड सेट करें",
  youAreSetUp: "आप अब तैयार हैं!",
  quickTourText: "आइए हम आपको टूल का त्वरित दौरा प्रदान करते हैं।",
  tourBestSeenOn:
    "kulcare अभ्यास प्रबंधन लैपटॉप/कंप्यूटर ब्राउज़र या टैब ब्राउज़र पर सबसे अच्छी तरह से देखी जाती है। आप हमारे मोबाइल ऐप भी डाउनलोड कर सकते हैं।",
  tourModalContent:
    "आपका अभ्यास सेटअप है और आप आसानी से अपने अभ्यास का प्रबंधन करने के लिए तैयार हैं {0} के साथ।",
  signUpSuccessText: "आपने {0} से सफलतापूर्वक साइन अप कर लिया है।",
  oneStepMoreText: "सिर्फ {0} सेटअप पूरा करने के लिए",
  letUsAddDoctorText: "चलो आपके क्लिनिक में डॉक्टर को ऐड करते हैं।",
  oneStepMoreContent: "एक और कदम आगे बढ़ने के लिए",
  looksLikeYou: "लगता है कि आप इसे मोबाइल मोबाइल पर देख रहे हैं।",
  enterDoctorAvailabiltyTruncated: "डॉक्टर की उपलब्धता दर्ज करें",
  add_timings_later: "आप बाद में और जोड़ सकते हैं",
  add_doctors_later: "आप बाद में और डॉक्टर जोड़ सकते हैं",
  go_home: "घर जाओ",
  selectPrescription: "प्रिस्क्रिप्शन का चयन करें",
  clear: "चयनित प्रिस्क्रिप्शन रिमूव करें",
  clearPrescriptionConfirm:
    "क्या आप वाकई चयनित प्रिस्क्रिप्शन को हटाना चाहते हैं? वर्तमान प्रगति खो जाएगी।",
  optionalText: "वैकल्पिक",
  locality: "स्थान",
  localityPlaceholder: "सेक्टर 21, चंडीगढ़",
  emptyFollowUpText:
    "जब आप एक मरीज के लिए अगली यात्रा को जोड़ते हैं, तो वे तदनुसार यहां दिखाएंगे।",
  prompts: {
    take_me_there: "ठीक है, मुझे वहाँ ले चलो",
    do_it_later: "मैं इसे बाद में करुँगा",
    change_print_setings_title: "प्रिंट सेटिंग्स बदलें",
    change_print_settings_desc_1:
      "आप सेटिंग्स में अपने पर्चे के अनुसार प्रिंट लेआउट को बदल सकते हैं।",
    change_print_settings_desc_2:
      "सेटिंग्स बदलने के लिए एक कर्मचारी या व्यवस्थापक खाते से पूछें।",
    update_address_title: "क्लिनिक का पता अपडेट करें",
    update_address_desc:
      "आपने अपना क्लिनिक का पता नहीं जोड़ा है। बिल केवल क्लिनिक का नाम दिखाएगा न कि पता।",
    med_reg_no_validation: "मेडिकल पंजीकरण नंबर की आवश्यकता है",
    med_reg_no_placeholder: "चिकित्सा पंजीकरण संख्या",
    med_reg_modal_title: "मेडिकल पंजीकरण संख्या जोड़ें",
    med_reg_modal_desc:
      "आपने डॉक्टर का मेडिकल पंजीकरण नंबर नहीं जोड़ा है। इसे प्रिस्क्रिप्शन पर प्रिंट करना आवश्यक है।"
  },
  setUp: {
    welcome: "Kulcare में आपका स्वागत है!",
    setUpSubtitle:
      "हमें अपना खाता सेटअप करने में मदद करें। वीडियो देखें और नीचे दिए गए चरणों को पूरा करें।",
    setUpQueue: "कतार प्रबंधन के लिए सेटअप",
    setUpQueueOne: "कतार अब सेटअप है",

    // setUpQueueDescription:
    //   "क्यू क्लिनिक में आपका रोगी रजिस्टर है। एक मरीज को कतार में जोड़कर सब कुछ शुरू होता है। अपनी कतार सेटअप करने के लिए इन कार्यों को पूरा करें।",
    howToSetUpQueue: "कतार प्रबंधन के लिए सेटअप कैसे करें?",
    addConsultantDoctor: "एक सलाहकार चिकित्सक जोड़ें",
    // goTo: "गो टू",
    addTimingsPatientsPerHour: "प्रति घंटे डॉक्टर के समय और रोगियों को जोड़ें",
    addDoctorFees: "डॉक्टर की फीस सेव करें",
    setUpBilling: "सेटअप बिलिंग",
    setUpBillingOne: "अब बिलिंग तैयार है",

    // setUpBillingDesc:
    //   "एक त्वरित सेटअप आपको कुछ क्लिकों के साथ अपने बिल का प्रबंधन करने देगा। प्रत्येक रोगी के लिए आपके द्वारा प्रदान की गई सेवाओं को सहेजें और आसानी से अपनी दैनिक, साप्ताहिक या मासिक आय का प्रबंधन करें।",
    howToSetUpClinic: "बिलिंग कैसे सेट करें?",
    addClinicServices: "ऐसी सेवाएँ जोड़ें जो आपका क्लिनिक प्रदान करता है",
    addClinicAddress: "बिलों पर मुद्रित किए जाने वाले क्लिनिक का पता ेव करें",
    clinicProfile: "क्लिनिक प्रोफाइल",
    setUpCareplan: "डिजिटल प्रिस्क्रिप्शन के लिए सेटअप",
    setUpCareplanOne: "प्रिस्क्रिप्शन सेटिंग्स अब पूरी हो गई हैं",

    // setUpCareplanDesc:
    //   "एक प्रिस्क्रिप्शन एक डॉक्टर के लिए विशिष्ट है। एक डॉक्टर सेकंड में प्रिस्क्रिप्शन बनाने के लिए टेम्पलेट जोड़ सकता है। एक डॉक्टर के प्रिस्क्रिप्शन टेम्पलेट हमेशा उनके साथ बने रहते हैं, भले ही वे दूसरे क्लिनिक में जाते हों।",
    howToSetUpCareplan: "डिजिटल प्रिस्क्रिप्शन सेटअप कैसे करें?",
    addCareplanTemplates: "प्रिस्क्रिप्शन टेम्पलेट सेव करें",
    careplanTemplates: "प्रिस्क्रिप्शन टेम्प्लेट",
    addVitals: "वे विटाल जोड़ें जिन्हें आप कैप्चर करना चाहते हैं",
    addPrintSettings: "कैरप्लन प्रिंट सेटिंग्स ऐड करें",
    prescriptions: "प्रिस्क्रिप्शन",
    setUpText: "शुरुआत गाइड",
    left: "बचा है"
  },
  growthProfile: {
    switchToGrowthProfile: "ग्रोथ प्रोफाइल पर स्विच करें",
    switchToClinicProfile: "क्लिनिक प्रोफाइल पर स्विच करें",
    clinicWebsite: "क्लिनिक वेबसाइट",
    patientReviews: "रोगी समीक्षा",
    help: "हैल्प",
    packages: "पैकेज",
    createYourWebsite: "अपनी फ्री वेबसाइट बनाएं",
    manageYourClinic: "अपने क्लिनिक को मैनेज करें",
    services: {
      services_name: "सेवा का नाम",
      delete: "हटाओ",
      text: "वाइटलस",
      vital_name_empty: "सेवाएं खाली नहीं हो सकतीं",
      remove_vital: "सेवा हटाओ",
      remove_vital_confirmation: "क्या आप वाकई सेवा हटाना चाहते हैं?",
      vitals_not_present: "सेवाएं मौजूद नहीं हैं"
    },
    clinicDetails: {
      reception: "रिसेप्शन",
      opd: "ओपीडी",
      emergency: "आपातकालीन",
      address: "पता",
      about: "क्लिनिक के बारे मे बताइये",
      profileSaved: "प्रोफ़ाइल सफलतापूर्वक सेव की गई है",
      imageSizeWarningTitle: "फोटो आकार चेतावनी",
      imageSizeWarningInfo: "फोटो का आकार 5 एमबी से अधिक नहीं होना चाहिए",
      clickToUpload: "अपलोड करने के लिए क्लिक करें",
      markAsCoverPhoto: "कवर फोटो सेट करें",
      markedAsCover: "कवर फोटो",
      clinicInfo: "क्लिनिक इन्फो",
      maxPhotosLimitError: "एक बार में केवल 10 फ़ोटो अपलोड करने की अनुमति है",
      maxPhotosTitle: "अधिकतम सीमा तक पहुँच गया",
      see_site_live: "अपनी साइट का पूर्वावलोकन देखें"
    },
    startGuide: {
      welcomeSubtitle:
        "Kulcare चुनने के लिए धन्यवाद। वीडियो देखें और अपनी मुफ्त वेबसाइट बनाने के लिए नीचे दिए गए चरणों को पूरा करें।",
      completeClinicInfo: "क्लिनिक की मूल जानकारी को पूरा करें",
      addConsultingDoctor: "परामर्श चिकित्सक और उनकी जानकारी जोड़ें",
      addOfferedServices: "क्लिनिक द्वारा दी जाने वाली सेवाओं को जोड़ें",
      addPackages: "कोई भी पैकेज जोड़ें या क्लिनिक ऑफ़र प्रदान करें",
      lookAtPreview: "अपनी वेबसाइट के पूर्वावलोकन को देखें",
      publishWebsite: "अपनी वेबसाइट प्रकाशित करें",
      seeSampleWebsite: "एक नमूना वेबसाइट देखें",
      howToCreateWebsite: "अपनी वेबसाइट कैसे बनायें?"
    }
  },
  referredByKulcare: "kulcare द्वारा संदर्भित",
  phoneNumberHint1:
    "अपने व्यक्तिगत नंबर का उपयोग करें। यह आपके रोगियों को दिखाई नहीं देगा और लॉगिन करने के लिए आपका उपयोगकर्ता नाम होगा।",
  phoneNumberHint2: "हम नंबर को सत्यापित करने के लिए एक ओटीपी भेजेंगे।",
  shortLinkErrorMessage: "उफ़! आपका लिंक अमान्य है",
  magicLink: {
    magicLinkSentSuccess: "मैजिक लिंक सफलतापूर्वक भेजा गया!",
    linkExpiryTitle:
      "ऊप्स! साइन-इन करने के लिए आपका जादू लिंक समाप्त हो गया है",
    linkExpiryTexLine1:
      "मैजिक लिंक का उपयोग केवल एक बार किया जा सकता है और यह 8 घंटे तक के लिए वैध है।",
    linkExpiryTexLine2: "आप मैन्युअल रूप से एक और लिंक या {0} कर सकते हैं",
    requestAnotherLink: "एक और लिंक का अनुरोध करें",
    signIn: "लॉग इन",
    almostThere: "लगभग वहाँ!",
    magicLinkSentText:
      "बस अपने {0} पर अपना ईमेल देखें और हमारे द्वारा {1} पर भेजे गए लिंक पर क्लिक करें",
    laptopOrDesktop: "लैपटॉप या डेस्कटॉप",
    linkValidFor: "लिंक 5 दिन के लिए वैध है",
    looksLikeMobileDevice:
      "ऐसा लगता है कि आपने मोबाइल डिवाइस से साइन अप किया है। kulcare डेस्कटॉप या लैपटॉप पर सबसे अच्छा काम करता है।",
    toUseKulcare: "Kulcare का उपयोग करने के लिए",
    toGetStarted: "आरंभ करना",
    emailMeMagicLink:
      "मुझे अपने लैपटॉप या डेस्कटॉप पर लॉग इन करने के लिए एक मैजिक लिंक ईमेल करें",
    logMeOut: "मुझे लॉग आउट करें",
    userDoesNotExist: "उपयोगकर्ता मौजूद नहीं है",
    thisIsAwkward: "अच्छा, यह अजीब है…",
    sendMeMagicLink: "मुझे मैजिक लिंक भेजें",
    enterEmail: "ईमेल दर्ज करें",
    doNotHaveEmail: "हमारे पास आपका {0} पता अभी तक नहीं है",
    looksLikeMobileDevice2:
      "ऐसा लगता है कि आपने मोबाइल डिवाइस से लॉग इन किया है। kulcare डेस्कटॉप या लैपटॉप पर सबसे अच्छा काम करता है।"
  },
  help: {
    how_help_text: "How can we help you?",
    recomended_text: "Recommended for you",
    search_placeholder: "Search faqs",
    faq_text: "FAQ's",
    queue: "Queue",
    patient: "Patient",
    appointment: "Appointment",
    billing: "Billing",
    prescription: "Prescription",
    searched_text: "Showing results for '{searchText}'",
    empty_results_text1: "We could not find anything that matches your query.",
    empty_results_text2: "Don’t worry! Click on this icon",
    empty_results_text3: "at the bottom right corner and send us a message",
    empty_results_text4: "We will help you right away."
  },
  enterSpeciality: "कृपया एक विशेषता दर्ज करें",
  enterClinicName: "कृपया क्लिनिक का नाम दर्ज करें",
  enterAddress: "कृपया क्लिनिक का पता दर्ज करें",
  errorWhileUploading: "अपलोड करते समय एरर",
  errorWhileDeleting: "हटाते समय एरर",
  deletePhoto: "फोटो हटाएं",
  confirmRemovePhoto: "क्या आप निश्चित हैं कि आप इस फ़ोटो को हटाना चाहते हैं?",
  recievedAmountError: "कुछ राशि पहले ही एकत्र की जा चुकी है, कृपया जाँच करें",
  collectedAmountError: "एकत्रित राशि कुल राशि से अधिक नहीं हो सकती",
  noEditLater: "आप बाद में बिल को संपादित नहीं कर पाएंगे।",
  addPhotos: {
    attachPhotos: "Attach Photos",
    selectPhotos: "Select Photos",
    addNote: "Add a note...",
    dateError: "Please choose date",
    photosError: "Please choose photos",
    maxPhotosError: "You cannot upload more than 10 photos at once",
    photosSuccess: "Photos attached successfully!"
  },
  doctorDetail: "डॉक्टर विस्तार",
  selectAccount: {
    selectAccountHeading: "अकाउंट चुनें",
    growthText: "मेरा अभ्यास बढ़ाओ",
    clinicText: "मेरा अभ्यास प्रबंधित करें",
    growthDescription:
      "ऑनलाइन प्राप्त करने के लिए अपनी वेबसाइट बनाएं, अपनी समीक्षा और लिस्टिंग का प्रबंधन करें और अधिक रोगी प्राप्त करें...",
    clinicDescription:
      "अपॉइंटमेंट लें, मरीजों और नुस्खों को प्रबंधित करें, अपने व्यवसाय और अधिक का विश्लेषण करें..."
  },
  welcomeBack: "वापसी पर स्वागत है!",
  thanksForJoining: "Kulcare में शामिल होने के लिए धन्यवाद!",
  orDownloadApp: "या, ऐप डाउनलोड करें",
  questionsText: "प्रशन?",
  copyrightText: "कॉपीराइट © 2020 kulcare। सभी अधिकार सुरक्षित।",
  newAppVersionTitle: "कुलीकेयर का एक नया संस्करण उपलब्ध है",
  refreshTo: "नवीनतम संस्करण प्राप्त करने के लिए ताज़ा करें",
  laterText: "बाद में",
  refreshText: "रिफ्रेश",
  sentVerifyCodeOn: "हमने आपको आपके मोबाइल नंबर और {0} पर और आपके ईमेल {0} पर एक सत्यापन कोड भेजा है",
  patientFileDownload: "मरीजों का डेटा सफलतापूर्वक डाउनलोड किया गया।",
  billingFileDownload: "बिलिंग डेटा सफलतापूर्वक डाउनलोड किया गया।",
  errorInDownloading: "रोगी डेटा डाउनलोड करने में त्रुटि।",
  fileExistError: "अनुरोधित फ़ाइल मौजूद नहीं है।",
  internetRestored: "इंटरनेट कनेक्शन बहाल"
};
