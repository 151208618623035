import React, { Component } from "react";
import qs from "qs";
import { Spin } from "antd";
import mixpanel from "mixpanel-browser";
import ExpiredMagicLinkView from "./ExpiredMagicLinkView";
import {
  history,
  decryptId,
  showSuccessNotification,
  showErrorNotification,
  strings
} from "../../helpers";
import { sendMagicLink } from "../../services";

class ExpiredMagicLinkContainer extends Component {
  state = {
    userId: "",
    isProcessing: false,
    pageName: "",
    sendgridCategory: null,
    linkType: null
  };

  componentDidMount() {
    const params = qs.parse(this.props.location.search.slice(1));
    if (params) {
      const { u, r, sendgrid_category, link_type } = params;
      if (u) {
        const userId = decryptId(u);
        this.setState({ userId });
      }
      if (r) {
        this.setState({ pageName: r });
      }
      if (sendgrid_category) {
        this.setState({ sendgridCategory: sendgrid_category });
      }
      if (link_type) {
        this.setState({ linkType: link_type });
      }
    }
  }

  /**
   * @function handleSendMagicLink
   * @desc This function handles sending magic link to the user
   */
  handleSendMagicLink = () => {
    const { userId, pageName, linkType } = this.state;

    this.setState({ isProcessing: true });
    sendMagicLink(userId, null, pageName, linkType).then(response => {
      this.setState({ isProcessing: false });
      if (response.data && response.data.success) {
        showSuccessNotification(strings.magicLink.magicLinkSentSuccess);
        history.push("/magic-link-sent");
      } else {
        showErrorNotification(response.data.errors[0].title);
      }
    });
  };

  /**
   * @function goToSignIn
   * @desc This function handles redirection to clinic sign in page
   */
  goToSignIn = () => {
    history.push("/sign-in");
  };

  render() {
    const { isProcessing } = this.state;
    return (
      <Spin spinning={isProcessing}>
        <ExpiredMagicLinkView
          handleSendMagicLink={this.handleSendMagicLink}
          goToSignIn={this.goToSignIn}
        />
      </Spin>
    );
  }
}

export default ExpiredMagicLinkContainer;
