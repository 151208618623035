import React, { Component } from "react";
import { Button } from "antd";
import "../verifyUser/verify-user.css";
import { strings, history } from "../../helpers";

class PageNotFoundView extends Component {
  render() {
    return (
      <div>
        <div className="verification_wrapper appointment-content-container">
          <div className="logo_wrap">
            <img src={require("../../images/kc-logo.svg")} alt="logo" />
          </div>
          <h4 className="text-center">
            404 {strings.error} - {strings.page_not_found}
          </h4>
          <div className="text-center">
            <Button
              type="primary"
              size="small"
              onClick={() => {
                history.push("/");
              }}
            >
              {strings.go_home}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export { PageNotFoundView };
