import React, { Component } from "react";
import { Col, Row, Form, Alert, Button, Input, Popover } from "antd";
import { strings, PasswordInput } from "../../helpers";

const FormItem = Form.Item;
class ResetPasswordView extends Component {
  render() {
    const { props } = this;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="auth-form">
        {/* <div className="auth-form-wrapper"> */}
        <Row
          type="flex"
          justify="center"
          align="middle"
          className="auth-form-row"
        >
          <Col xs={20} md={12} sm={16} lg={8}>
            <div className="login-form-wrapper">
              <h2 className="login-title text-center">{strings.reset_pwd}</h2>

              <div className="form-alerts-wrapper">
                {props.error && (
                  <Alert
                    message={strings[props.errorMsg]}
                    type="error"
                    showIcon
                  />
                )}

                {props.serverError && (
                  <Alert
                    message={strings[props.serverErrorText]}
                    type="error"
                    showIcon
                  />
                )}
              </div>

              <Form onSubmit={props.handleSubmit} className="login-form">
                <FormItem className="password-field">
                  {getFieldDecorator("password", {
                    initialValue: `${props.password}`,
                    rules: [
                      {
                        required: true,
                        message: `${strings.password_required}`
                      }
                    ]
                  })(
                    <PasswordInput
                      screentype={props.screenType}
                      onChange={props.handleChange}
                      placement={props.screenType === "big" ? "right" : "top"}
                      getpopupcontainer={triggerNode => triggerNode.parentNode}
                      autocompletetext="off"
                      nametext="password"
                      placeholdertext={strings.password}
                      className="password-key"
                    />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("confirmPassword", {
                    initialValue: `${props.confirmPassword}`,
                    rules: [
                      {
                        required: true,
                        message: `${strings.password_required}`
                      }
                    ]
                  })(
                    <Input
                      type="password"
                      placeholder={strings.confirm_password}
                      name="confirmPassword"
                      onChange={props.handleChange}
                      autoComplete="off"
                    />
                  )}
                </FormItem>
                <FormItem>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={24}>
                      <Button
                        loading={props.isProcessing}
                        type="primary"
                        htmlType="submit"
                        className="login-form-button text-uppercase"
                      >
                        {strings.reset}
                      </Button>
                    </Col>
                  </Row>
                </FormItem>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Form.create()(ResetPasswordView);
