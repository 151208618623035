import React, { Component } from "react";
import { Col, Row, Form, Alert, Button, Input, Checkbox, Popover } from "antd";
import NumericInput from "../../../helpers/NumericInput";
import { strings, PasswordInput } from "../../../helpers";
import IntentIntro from "./IntentIntro";
import VerifyClinicContainer from "../VerifyClinic/VerifyClinicContainer";

const FormItem = Form.Item;

class ClinicSignupIntentView extends Component {
  showLocalizedErrors = errorText => {
    if (errorText) {
      let errorLocalizedStrings = "";
      errorLocalizedStrings = strings[errorText];
      return errorLocalizedStrings;
    } else {
      return null;
    }
  };

  render() {
    const { props } = this;
    const { goToSignIn, errorText, signupSuccess } = this.props;
    const { getFieldDecorator } = this.props.form;
    const errorMsg = props.feErrorMsg
      ? this.showLocalizedErrors(props.feErrorMsg)
      : errorText;

    return (
      <div className="auth-form-section">
        <div className="main-wrapper-container">
          <div className="signup-intent-container">
            {signupSuccess ? (
              <VerifyClinicContainer
                phoneNumber={props.phoneNumber}
                userEmail={props.userEmail}
                countryCode={props.countryCode}
                eventType={props.eventType}
              />
            ) : (
              <div className="left-section-block">
                {/* sign up form start */}
                <div className="auth-form-main-wrapper">
                  <div className="auth-form-top-heading text-left">
                    Get started for free
                  </div>
                  <div className="auth-form-subtext">
                    Free upto 10 patient/day. No credit card required.
                  </div>

                  <div className="form-alerts-wrapper mt-20">
                    {props.feError && errorMsg && (
                      <Alert type="error" message={errorMsg} showIcon />
                    )}
                    {props.userAlreadyExists &&
                      props.userAlreadyExists === true && (
                        <Alert
                          type="error"
                          message={strings.existing_account}
                          showIcon
                        />
                      )}

                    {props.serverError &&
                      props.submitErrors.length > 0 &&
                      props.submitErrors.map((err, index) => {
                        return (
                          <Alert
                            key={index}
                            type="error"
                            message={err}
                            showIcon
                          />
                        );
                      })}
                  </div>
                  <Form onSubmit={props.handleSubmit}>
                    {/* row 1 */}
                    <div className="auth-form-input-block-container user-icon mt-30">
                      <Row type="flex" gutter={20}>
                        <Col span={12}>
                          <FormItem>
                            <label className="label-prop">{"First name"}</label>
                            {getFieldDecorator("firstName", {
                              initialValue: `${props.firstName}`,
                              rules: [
                                {
                                  required: true,
                                  message: `${strings.signup.enter_name}`
                                }
                              ]
                            })(
                              <Input
                                name="firstName"
                                onChange={props.handleChange}
                                className="input-with-border-bottom"
                                // placeholder={strings.signup.enter_name}
                              />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={12}>
                          <FormItem>
                            <label className="label-prop">{"Last name"}</label>
                            {getFieldDecorator("lastName", {
                              initialValue: `${props.lastName}`
                            })(
                              <Input
                                name="lastName"
                                onChange={props.handleChange}
                                className="input-with-border-bottom"
                                // placeholder={strings.signup.enter_name}
                              />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    </div>
                    {/* row 2 */}
                    <div className="auth-form-input-block-container mobile-icon mt-10">
                      <FormItem help="For SMS verification to access your account.">
                        <label className="label-prop">
                          {strings.mobile_number}
                        </label>
                        {getFieldDecorator("phoneNumber", {
                          initialValue: "",
                          rules: [
                            {
                              required: true,
                              message: `${strings.signup.enter_phone_number}`
                            }
                          ]
                        })(
                          <Popover
                            content={props.phoneNumberHint()}
                            // title="Hint"
                            trigger="focus"
                            placement={
                              props.screenType === "big" ? "right" : "top"
                            }
                            getPopupContainer={triggerNode =>
                              triggerNode.parentNode
                            }
                            overlayClassName="wizard-popover"
                          >
                            <NumericInput
                              name="phoneNumber"
                              onChange={props.handleChange}
                              maxLength="10"
                              className="input-with-border-bottom"
                              // placeholder={strings.signup.enter_phone_number}
                            />
                          </Popover>
                        )}
                      </FormItem>
                    </div>

                    {/* row 3 */}
                    {!props.forStaff && (
                      <div className="auth-form-input-block-container email-icon">
                        <FormItem>
                          <label className="label-prop">
                            {strings.your_email}
                          </label>
                          {getFieldDecorator("userEmail", {
                            initialValue: "",
                            rules: [
                              {
                                type: "email",
                                message: strings.enter_valid_email
                              },
                              {
                                required: true,
                                message: strings.enter_valid_email
                              }
                            ]
                          })(
                            <Input
                              name="userEmail"
                              onChange={props.handleChange}
                              className="input-with-border-bottom"
                              // placeholder={strings.signup.enter_your_email}
                            />
                          )}
                        </FormItem>
                      </div>
                    )}
                    {/* row 4 */}
                    <div className="auth-form-input-block-container password-icon">
                      <FormItem className="password-field">
                        <label className="label-prop">{strings.password}</label>
                        {getFieldDecorator("password", {
                          initialValue: "",
                          rules: [
                            {
                              required: true,
                              message: `${strings.password_required}`
                            }
                          ]
                        })(
                          <PasswordInput
                            screentype={props.screenType}
                            onChange={props.handleChange}
                            placement={
                              props.screenType === "big" ? "right" : "top"
                            }
                            getpopupcontainer={triggerNode =>
                              triggerNode.parentNode
                            }
                            autocompletetext="off"
                            nametext="password"
                            // placeholdertext={strings.setPassword}
                            className="input-with-border-bottom password-key"
                          />
                        )}
                      </FormItem>
                    </div>

                    <div>
                      <Button
                        type="primary"
                        className="form-primary-btn"
                        htmlType="submit"
                        // onClick={this.handleVerficationModal}
                        loading={props.isProcessing}
                      >
                        Start my free plan
                      </Button>
                      <div className="mt-10 text-center">
                        <span> Already have an account?</span>{" "}
                        <a href="#" onClick={goToSignIn}>
                          Sign in
                        </a>
                      </div>
                      {/* {newsLink && (
                <div className="mt-10">
                  <span> Not a doctor?</span>{" "}
                  <a
                    href={newsLink}
                    target="_blank"
                    onClick={handleNewsClickEvent}
                  >
                    Click here to see the news
                  </a>
                </div>
              )} */}
                    </div>
                  </Form>
                  {/* auth secondary button */}
                  {/* <Button type="primary" className="auth-form-secondary-btn mt-30"> Create an account</Button> */}
                </div>
                {/* sign up form end */}
              </div>
            )}
            <div className="right-section-block">
              <IntentIntro />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Form.create()(ClinicSignupIntentView);
