import React, { Component } from "react";
import { Row, Col, Button, Checkbox } from "antd";

import NextStepIcon from "../../images/next-step-icon.svg";
import { strings } from "../../helpers";

export default class WebUpgradeView extends Component {
  render() {
    const { refreshApp } = this.props;
    return (
      <div className="prompt-block slide-in">
        <div className="prompt-wrapper">
          <div className={`prompt-border-top next-step-info-bg`}></div>
          <div className="prompt-heading">{strings.newAppVersionTitle}</div>
          <div className="prompt-image">
            <img src={NextStepIcon} />
          </div>
          <div className="prompt-message">{strings.refreshTo}</div>
          <div className="prompt-btn-wrap">
            <a href="" onClick={e => refreshApp(e, false)}>
              {strings.laterText}
            </a>

            <Button type="primary" onClick={e => refreshApp(e, true)}>
              {strings.refreshText}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
