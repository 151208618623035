import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Form, Alert, Button, Input, Checkbox } from "antd";
import { strings } from "../../../helpers";
import NumericInput from "../../../helpers/NumericInput";
import AuthIntro from "../AuthIntro";
import VerifyClinicContainer from "../VerifyClinic/VerifyClinicContainer";

const FormItem = Form.Item;
class ClinicLoginView extends Component {
  render() {
    const { props } = this;
    const {
      redirectUserToPage,
      isSignedIn,
      phoneNumber,
      password,
      countryCode,
      accountType
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    const buttonDisabled =
      props.phoneNumber.trim() === "" || props.password.trim() === "";
    return (
      <>
        <div className="auth-form-section signup-wrapper">
          {/* <div className="auth-form-subtext">
          Sign in to your account to continue
        </div> */}

          {/* sign up form start */}
          <Row type="flex" justify="center" align="middle">
            {accountType && accountType === "patient" ? (
              <div className="auth-left-screen patient-account-screen"></div>
            ) : accountType && accountType === "hospital" ? (
              <div className="auth-left-screen hospital-account-screen"></div>
            ) : (
              <AuthIntro />
            )}
            {/* sign up form start */}
            {isSignedIn ? (
              <VerifyClinicContainer
                phoneNumber={phoneNumber}
                password={password}
                countryCode={countryCode}
              />
            ) : (
              <div className="signup-right-section">
                <div className="auth-form-main-wrapper signin-form-main-wrapper">
                  <div className="auth-form-top-heading">
                    Sign in to your account
                  </div>
                  <div className="form-alerts-wrapper mt-20">
                    {props.error && (
                      <Alert message={props.errorMsg} type="error" showIcon />
                    )}

                    {props.serverErrorText && (
                      <Alert
                        message={props.serverErrorText}
                        type="error"
                        showIcon
                      />
                    )}
                  </div>
                  <Form onSubmit={props.handleSubmit} autoComplete="off">
                    <div className="auth-form-input-block-container mobile-icon mt-20">
                      <FormItem>
                        <label className="label-prop">
                          {strings.mobile_number}
                        </label>
                        {getFieldDecorator("phoneNumber", {
                          initialValue: "",
                          rules: [
                            {
                              required: true,
                              message: `${strings.signup.enter_phone_number}`
                            }
                          ]
                        })(
                          <NumericInput
                            name="phoneNumber"
                            onChange={props.handleChange}
                            maxLength="10"
                            className="input-with-border-bottom"
                            autoComplete="off"
                            // placeholder={strings.signup.enter_phone_number}
                          />
                        )}
                      </FormItem>
                    </div>

                    {/* row 4 */}
                    <div className="auth-form-input-block-container password-icon mt-20">
                      <FormItem className="password-field rel-pos">
                        <label className="label-prop">{strings.password}</label>
                        {getFieldDecorator("password", {
                          initialValue: `${props.password}`,
                          rules: [
                            {
                              required: true,
                              message: `${strings.password_required}`
                            }
                          ]
                        })(
                          <Input
                            type="text"
                            // placeholder={strings.signin.enter_your_password}
                            name="password"
                            onChange={props.handleChange}
                            className="input-with-border password-key"
                            autoComplete="new-password"
                          />
                        )}
                      </FormItem>
                    </div>
                    <div className="forgot-pwd-link">
                      <a
                        href="#"
                        onClick={e => redirectUserToPage(e, "forgot")}
                        // className="simple-btn"
                      >
                        Forgot password?
                      </a>
                    </div>

                    <div className="mt-20">
                      <Button
                        type="primary"
                        className="form-primary-btn"
                        htmlType="submit"
                        disabled={buttonDisabled}
                        loading={props.isProcessing}
                      >
                        {strings.sign_in}
                      </Button>

                      {/* <Button
                    type="primary"
                    className="auth-form-secondary-btn"
                    onClick={e => redirectUserToPage(e, "signup")}
                  >
                    {" "}
                    Sign up
                  </Button> */}
                    </div>
                  </Form>
                  {/* auth secondary button */}
                  <div className="mt-10 text-center">
                    <span>Don't have an account?</span>{" "}
                    <a href="#" onClick={e => redirectUserToPage(e, "signup")}>
                      Sign up
                    </a>
                  </div>
                </div>
                {/* sign up form end */}
              </div>
            )}
          </Row>
        </div>
      </>
    );
  }
}

export default Form.create()(ClinicLoginView);
