export const onlyAlphabetsWithSpace = /^[a-zA-Z ]+$/;

export const onlyAlphaNumericWithSpace = /^[a-zA-Z0-9 ]+$/;

export const maxTextLength = 100;

export const onlyNumeric = /^-?(0|[1-9][0-9]*)(\[0-9]*)?$/;

export const maxCurrencyLength = 6;

export const urlPattern = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

export const pincodeMaxLength = 9;

export const maxDurationLength = 4;

