import React, { Component } from "react";
import { connect } from "react-redux";
import { message } from "antd";
import { changePasswordAction } from "../forgotPassword/forgotPasswordActions";
import ChangePasswordView from "./ChangePasswordView";
import { history, getUser, strings, strongRegularExp } from "../../helpers";

class ResetPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMsg: "",
      password: "",
      currentPassword: "",
      userId: "",
      serverError: false,
      serverErrorText: "",
      passwordDetails: {
        strength: 0,
        strengthText: null,
        barColor: "red"
      },
      screenType: "big"
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.setScreenType);
    this.setScreenType();
    const user = getUser();
    if (user) {
      this.setState({ userId: user.id });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setScreenType);
  }

  /**
   * @function setScreenType
   * @desc This funtion sets the screen type
   * in local state
   */
  setScreenType = () => {
    const windowWidth = window.innerWidth;
    const screenType = windowWidth > 992 ? "big" : "small";
    this.setState({ screenType });
  };

  /**
   * @function checkPasswordStrength
   * @param value
   * @param nothing
   * @desc This checks the strength of
   * the password entered by the user in percentage
   */
  checkPasswordStrength = value => {
    return strongRegularExp.test(value);
    // let passwordStrengthText = null;
    // let passwordStrength = 0;
    // let barColor = "red";
    // if (strongRegularExp.test(value)) {
    //   // If password meets regular expression criterion
    //   if (value.length > 8) {
    //     // If password is more than 8 characters long
    //     passwordStrength = 100;
    //     passwordStrengthText = "strong";
    //     barColor = "green";
    //   } else {
    //     // If password is less than or equal to 8 characters
    //     passwordStrength = 60;
    //     passwordStrengthText = "medium";
    //     barColor = "olive";
    //   }
    // } else if (value) {
    //   const passwordLength = value.length;
    //   if (passwordLength >= 8) {
    //     // If password is equal to or more than 8 characters
    //     passwordStrength = 60;
    //     passwordStrengthText = "medium";
    //     barColor = "olive";
    //   } else if (passwordLength < 8 && passwordLength > 0) {
    //     // If password is less than 8 characters and more than 0 characters long
    //     passwordStrength = 20;
    //     passwordStrengthText = "weak";
    //     barColor = "red";
    //   } else if (passwordLength === 0) {
    //     // If password length is 0
    //     passwordStrength = 0;
    //     passwordStrengthText = null;
    //     barColor = "red";
    //   }
    // } else {
    //   // If password is blank
    //   passwordStrength = 0;
    //   passwordStrengthText = null;
    //   barColor = "red";
    // }

    // setTimeout(() => {
    //   this.setState({
    //     passwordDetails: {
    //       strength: passwordStrength,
    //       strengthText: passwordStrengthText,
    //       barColor
    //     }
    //   });
    // }, 100);
  };

  /**
   * @function handleChange
   * @param e
   * @return nothing
   * @desc This method handles input changes in reset password screen
   */
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value.replace(/\s/g, ""),
      error: false,
      errorMsg: "",
      serverError: false,
      serverErrorText: ""
    });
    // If password is being changed,
    // check user existence and password strength
    // if (name === "password") {
    //   this.checkPasswordStrength(value);
    // }
  };

  /**
   * @function setStateForInvalidData
   * @param errorText
   * @return nothing
   * @desc This method sets error messages state
   */
  setStateForInvalidData = errorText => {
    this.setState({
      error: true,
      errorMsg: errorText,
      serverError: false,
      serverErrorText: ""
    });
  };

  /**
   * @function handleSubmit
   * @param e
   * @return nothing
   * @desc This method checks for
   * valid input and submits
   * verify code for verification
   */
  handleSubmit = e => {
    e.preventDefault();

    const { password, currentPassword, userId } = this.state;

    if (password === "" || currentPassword === "") {
      this.setStateForInvalidData("password_required");
      return;
    }

    if (password === currentPassword) {
      this.setStateForInvalidData("password_error");
      return;
    }

    if (!this.checkPasswordStrength(password) || password.length <= 8) {
      this.setStateForInvalidData("follow_pwd_hint");
      return;
    }

    if (password && userId && currentPassword) {
      this.props.changePassword(userId, password, currentPassword);
    }
  };

  /**
   * @function handleCancel
   * @param e
   * @param nothing
   * @desc This handles redirection to sign in page
   */
  handleCancel = () => {
    history.push("/sign-in");
  };

  /**
   * @function processErrors
   * @param errors
   * @param nothing
   * @desc This handles setting state of
   * variable which is to be shown to
   * the user when api returns some error
   */
  processErrors = errors => {
    for (let i = 0; i < errors.length; i += 1) {
      switch (errors[i].source) {
        case "new_password":
          this.setState({
            serverErrorText: "password_error"
          });
          break;
        case "password":
          this.setState({
            serverErrorText: "follow_pwd_hint"
          });
          break;
        case "current_password":
          this.setState({
            serverErrorText: "changePassword.current_pwd_incorrect"
          });
          break;
        default:
          this.setState({
            serverErrorText: "error_saving_pwd"
          });
          break;
      }
    }
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.changePasswordError &&
      newProps.changePasswordError.length > 0 &&
      newProps.changePasswordError != this.props.changePasswordError
    ) {
      this.setState({ error: false, errorMsg: "", serverError: true });
      this.processErrors(newProps.changePasswordError);
    }
    if (
      newProps.changePasswordSuccess &&
      newProps.changePasswordSuccess === true &&
      newProps.changePasswordSuccess !== this.props.changePasswordSuccess
    ) {
      // show message on change password success
      message.success(strings.changePassword.change_password_success_msg);
    }
  }

  render() {
    return (
      <ChangePasswordView
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        handleCancel={this.handleCancel}
        isProcessing={this.props.isProcessing}
        error={this.state.error}
        errorMsg={this.state.errorMsg}
        serverError={this.state.serverError}
        serverErrorText={this.state.serverErrorText}
        passwordDetails={this.state.passwordDetails}
        screenType={this.state.screenType}
        password={this.state.password}
        currentPassword={this.state.currentPassword}
      />
    );
  }
}

const mapStateToProps = state => ({
  isProcessing: state.changePassword.isProcessing,
  changePasswordError: state.changePassword.error,
  changePasswordSuccess: state.changePassword.success
});

const mapDispatchToProps = dispatch => ({
  changePassword: (userId, password, currentPassword) =>
    dispatch(changePasswordAction(userId, password, currentPassword))
});

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordContainer);
export { connectedPage as default };
