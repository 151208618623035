import React, { Component } from "react";
import { Layout } from "antd";
import { Offline } from "react-detect-offline";
import Pusher from "react-pusher";
import * as Sentry from "@sentry/react";
import { AppHeader } from "./components/app/AppHeader";
import AppBody from "./components/app/AppBody";
import { setInitialLanguage, strings } from "./helpers/locales";

import _ from "lodash";
import {
  getUser,
  setCookie,
  getAppEnv,
  getUrlParam,
  goToLoggedInUrl,
  isAuthPageRendered,
  authImageSrc,
  getQueryParams
} from "./helpers";
import WebUpgradeContainer from "./components/WebUpgrade/WebUpgradeContainer";
import FooterView from "./components/Onboarding/Footer/FooterView";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLanguage: strings.getLanguage(),
      showWebUpgradePrompt: false,
      viewOnlineBanner: false,
      signupIntent: null
    };
    // localization code
    // initialize languages
    setInitialLanguage();
  }

  componentDidMount() {
    this.fetchIntentParams();
    // remove the page loader once app.js gets loaded
    const elem = window.document.getElementById("app-loader");
    if (elem) {
      elem.setAttribute("style", "display: none;");
    }
    // store referrer param from url in localStorage, if not present
    // if the user comes from any of the landing page: like "growth", "doctor"
    // then sign-in or sign-up link will have a referrer in the url as a param
    // so we need to get that param and store that in local storage
    // this value is cleared from localStorage once user get redirected to logged in site

    const referrer = localStorage.getItem("referrer");
    if (referrer === null) {
      const referrerFromUrl = getUrlParam("referrer");
      if (referrerFromUrl) {
        localStorage.setItem("referrer", referrerFromUrl);
      }
    }

    const pathName = window.location.pathname;
    const urlParams = getQueryParams(window.location.search);

    // if user is present the it should be redirected to the logges in CMS site
    if (
      getUser() &&
      pathName !== "/magic-link-signin" &&
      pathName !== "/expired-magic-link" &&
      pathName !== "/k" &&
      pathName !== "/export-patient-report"
    ) {
      // c10h is get listed intent for doctor, interested to get listed
      if (urlParams["su_int"] === "c10h" && pathName.includes("sign-up"))
        goToLoggedInUrl(
          `${process.env.REACT_APP_CMS_SITE_BASE_URL}/${process.env.REACT_APP_GROWTH_ROUTE_URL}/your-profile`
        );
      else goToLoggedInUrl();
    }
  }

  /**
   * @function changeLanguage
   * @param value
   * @return nothing
   * @desc This function sets the Language preference
   */
  changeLanguage = value => {
    this.setState({
      currentLanguage: value
    });
    const appEnv = getAppEnv();
    setCookie(`${appEnv}_current_language`, value);
    localStorage.setItem("current_language", value);
    strings.setLanguage(value);
  };

  /**
   * @function reloadApp
   * @desc This function refreshes the page
   */
  reloadApp = e => {
    if (e) e.preventDefault();
    window.location.reload();
  };

  /**
   * @function handleOfflineChange
   * @desc This function handles toggling viewOnlineBanner state, so that "Internet restored" message can be shown
   */
  handleOfflineChange = status => {
    if (status)
      this.setState({ viewOnlineBanner: true }, () =>
        setTimeout(() => {
          this.setState({ viewOnlineBanner: false });
        }, 10000)
      );
  };

  /**
   * @function handleWebUpgradePrompt
   * @desc This function handles toggling web upgrade notification
   */
  handleWebUpgradePrompt = () => {
    this.setState(prevState => ({
      showWebUpgradePrompt: !prevState.showWebUpgradePrompt
    }));
  };

  /**
   * @function fetchIntentParams
   * @desc This funtion fetches signup intent params in url
   */
  fetchIntentParams = () => {
    const params = getQueryParams(window.location.search);
    if (params && !_.isEmpty(params)) {
      const { su_int } = params;
      this.setState({
        signupIntent: su_int || null
      });
    }
  };

  render() {
    const { online } = this.props;
    const { showWebUpgradePrompt, viewOnlineBanner, signupIntent } = this.state;
    const pathName = window.location.pathname;
    let currentPage = null;
    const pathStr = (pathName.match(new RegExp("/", "g")) || []).length;
    let authPagesRendered = false;
    // fetch currentPage from window.location
    // find first string after "/" symbol in url
    if (pathStr === 1) {
      currentPage = pathName.replace("/", "");
    } else if (pathStr === 2 || pathStr === 3 || pathStr === 4) {
      currentPage = pathName.substring(1).split("/")[0];
    } else if (pathStr === 5) {
      currentPage = pathName.substring(1).split("/")[pathStr - 1];
    }
    // get current page title

    let showHeaderForClinic = false;

    // let noLeftNav = false;
    let showOnlyLogo = false;
    let hideExtraTopNavOptions = false;
    let magicLinkPresent = false;
    // hide the language dropdown and "Create your own website" buttons shown in top nav
    if (
      currentPage === "add-doctor" ||
      currentPage === "clinic-signup-success"
    ) {
      hideExtraTopNavOptions = true;
    }

    // showHeaderForClinic for following links,
    // as header for these pages is different
    if (
      currentPage === "verify-clinic" ||
      currentPage === "forgot-password-for-clinic" ||
      currentPage === "reset-password-for-clinic" ||
      currentPage === "verify-phone-number-for-clinic"
    ) {
      showHeaderForClinic = true;
    }

    if (
      currentPage === "expired-magic-link" ||
      currentPage === "magic-link-signin" ||
      currentPage === "magic-link-sent"
    ) {
      magicLinkPresent = true;
      hideExtraTopNavOptions = true;
    }

    // authPagesRendered = isAuthPageRendered(currentPage);
    // set the growth profile related data, to show the growth pages to the user
    // this allows switching user between growth profile and cms
    if (currentPage === "growth" || currentPage === "growth-clinic-website") {
      localStorage.setItem("logged_in_as_clinic_owner_for_growth", true);
    } else {
      localStorage.removeItem("logged_in_as_clinic_owner_for_growth");
    }

    return (
      <div id="wrapper">
        <Layout>
          <Offline onChange={this.handleOfflineChange} />
          {!online && (
            <div className="network-error-alert">
              <span className="no-network-text">
                <img src={require("./images/no-wifi.png")} />
                {strings.no_internet_connection}
              </span>
            </div>
          )}

          {viewOnlineBanner && online && (
            <div className="network-alert">
              <span className="network-text">
                <img src={require("./images/wifi.svg")} />
                {strings.internetRestored}
              </span>
              <span className="net-icon-block">
                <a href="" onClick={this.reloadApp}>
                  {strings.clickToReload}
                </a>
              </span>
            </div>
          )}

          <Pusher
            channel={process.env.REACT_APP_PUSHER_CHANNEL}
            event={`new_deployment_${process.env.REACT_APP_ENVIRONMENT}_auth`}
            onUpdate={this.handleWebUpgradePrompt}
          />

          {showWebUpgradePrompt && (
            <WebUpgradeContainer
              handleWebUpgradePrompt={this.handleWebUpgradePrompt}
            />
          )}

          {!magicLinkPresent && !authPagesRendered && (
            <AppHeader
              changeLanguage={this.changeLanguage}
              currentLanguage={this.state.currentLanguage}
              showHeaderForClinic={showHeaderForClinic}
              currentPage={currentPage}
              showOnlyLogo={showOnlyLogo}
              hideExtraTopNavOptions={hideExtraTopNavOptions}
              authPagesRendered={authPagesRendered}
            />
          )}

          <div
            className={`auth-main-wrapper ${authPagesRendered ? "show" : ""}`}
          >
            <div
              className={`auth-left-wrap ${authPagesRendered ? "show" : ""}`}
            >
              {/* top nav start */}
              {!magicLinkPresent && (
                <AppHeader
                  changeLanguage={this.changeLanguage}
                  currentLanguage={this.state.currentLanguage}
                  showHeaderForClinic={showHeaderForClinic}
                  currentPage={currentPage}
                  showOnlyLogo={showOnlyLogo}
                  hideExtraTopNavOptions={hideExtraTopNavOptions}
                  authPagesRendered={authPagesRendered}
                />
              )}
              {/* top nav ends */}
              <AppBody currentLanguage={this.state.currentLanguage} />
            </div>
            <div
              className="auth-right-wrap"
              style={{ backgroundImage: `url(${authImageSrc(signupIntent)})` }}
            ></div>
          </div>
        </Layout>

        {!authPagesRendered && (
          <Layout
            className={`${magicLinkPresent ? "magic-link-main-wrapper" : ""}`}
          >
            <AppBody currentLanguage={this.state.currentLanguage} />
          </Layout>
        )}
        <FooterView />
      </div>
    );
  }
}

export default Sentry.withProfiler(App, { name: "KulcareAuth" });
