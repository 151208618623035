import mixpanel from "mixpanel-browser";
import ReactPixel from "react-facebook-pixel";
import {
  setCookie,
  gTagEvent,
  logMixpanelEvent,
  showSuccessNotification,
  strings
} from "../../../helpers";
import {
  verifyClinicConstants,
  resendVerifyCodeConstants
} from "./VerifyClinicConstants";
import { verifyUser, resendVerificationCode } from "../../../services";
import {
  setCookieRelatedData,
  redirectToLoggedInSite,
  redirectToPatientSite
} from "../Signin/ClinicLoginActions";

export const verifyUserProcessing = isProcessing => ({
  type: verifyClinicConstants.VERIFY_USER_PROCESSING,
  isProcessing
});

export const verifyUserSuccess = user => ({
  type: verifyClinicConstants.VERIFY_USER_SUCCESS,
  user
});

export const verifyUserFailure = error => ({
  type: verifyClinicConstants.VERIFY_USER_FAILURE,
  error
});

export const resendVerifyCodeProcessing = isProcessing => ({
  type: resendVerifyCodeConstants.RESEND_VERIFY_CODE_PROCESSING,
  isProcessing
});

export const resendVerifyCodeSuccess = isCodeSent => ({
  type: resendVerifyCodeConstants.RESEND_VERIFY_CODE_SUCCESS,
  isCodeSent
});

export const resendVerifyCodeFailure = error => ({
  type: resendVerifyCodeConstants.RESEND_VERIFY_CODE_FAILURE,
  error
});

/**
 * @function verifyUserAction
 * @param phoneNumber
 * @param countryCode
 * @param verificationCode
 * @desc This method verifies the user
 */
export const verifyUserAction = (
  phoneNumber,
  countryCode,
  verificationCode,
  mixpanelFromParam,
  accountTypePatient
) => {
  return dispatch => {
    dispatch(resendVerifyCodeSuccess(false));
    dispatch(verifyUserProcessing(true));
    verifyUser(
      phoneNumber,
      countryCode,
      verificationCode,
      mixpanelFromParam
    ).then(
      async response => {
        if (response.data && response.data.data) {
          // if the user has signed in from mobile, then prompt user to continue rest of the app through web
          const responseObj = response.data;

          if (window.location.hostname === "auth.kulcare.com") {
            gTagEvent();
            // facebook pixel tracking
            ReactPixel.track("CompleteRegistration");
          }
          // setCookieRelatedData(responseObj);
          if(accountTypePatient) redirectToPatientSite(true, responseObj) 
          else redirectToLoggedInSite(responseObj, null, null, "signup");
          
        } else if (response.data && response.data.errors) {
          dispatch(verifyUserFailure(response.data.errors));
          dispatch(verifyUserProcessing(false));
        }
      },
      error => {
        dispatch(verifyUserFailure(error));
        dispatch(verifyUserProcessing(false));
      }
    );
  };
};

/**
 * @function resendVerificationCodeAction
 * @param phoneNumber
 * @param countryCode
 * @desc This method allows user to resend code
 */
export const resendVerificationCodeAction = (phoneNumber, countryCode) => {
  return dispatch => {
    dispatch(verifyUserSuccess([]));
    dispatch(resendVerifyCodeProcessing(true));
    dispatch(resendVerifyCodeSuccess(false));
    resendVerificationCode(phoneNumber, countryCode).then(
      response => {
        if (response.data && response.data.success === true) {
          dispatch(resendVerifyCodeSuccess(true));
          dispatch(resendVerifyCodeProcessing(false));
        } else if (response.data && response.data.success === false) {
          dispatch(resendVerifyCodeFailure(response.data.errors));
          dispatch(resendVerifyCodeProcessing(false));
          dispatch(resendVerifyCodeSuccess(false));
        }
      },
      error => {
        dispatch(resendVerifyCodeFailure(error));
        dispatch(resendVerifyCodeProcessing(false));
      }
    );
  };
};
