import { forgotPasswordConstants } from "./ForgotClinicPasswordConstants";

export const forgotClinicPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case forgotPasswordConstants.FORGOT_PASSWORD_PROCESSING:
      return {
        ...state,
        isProcessing: action.isProcessing
      };
    case forgotPasswordConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        data: action.data
      };
    case forgotPasswordConstants.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};

export const verifyForgotClinicPasswordCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case forgotPasswordConstants.VERIFY_FORGOT_PASSWORD_CODE_PROCESSING:
      return {
        ...state,
        isProcessing: action.isProcessing
      };
    case forgotPasswordConstants.VERIFY_FORGOT_PASSWORD_CODE_SUCCESS:
      return {
        ...state,
        data: action.data
      };
    case forgotPasswordConstants.VERIFY_FORGOT_PASSWORD_CODE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};

export const changeClinicPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case forgotPasswordConstants.CHANGE_PASSWORD_PROCESSING:
      return {
        ...state,
        isProcessing: action.isProcessing
      };
    case forgotPasswordConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        success: action.status
      };
    case forgotPasswordConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};

export const resendForgotClinicPasswordCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case forgotPasswordConstants.RESEND_FORGOT_PASSWORD_CODE_PROCESSING:
      return {
        ...state,
        isProcessing: action.isProcessing
      };
    case forgotPasswordConstants.RESEND_FORGOT_PASSWORD_CODE_SUCCESS:
      return {
        ...state,
        isCodeSent: action.isCodeSent
      };
    case forgotPasswordConstants.RESEND_FORGOT_PASSWORD_CODE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};
