import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Analytics from "react-router-ga";

import { PublicRoute } from "../app/PublicRoute";
import { history } from "../../helpers";

import ForgotPasswordContainer from "../forgotPassword/ForgotPasswordContainer";
import VerifyPhoneNumberContainer from "../forgotPassword/VerifyPhoneNumberContainer";
import ResetPasswordContainer from "../forgotPassword/ResetPasswordContainer";
import { PageNotFoundView } from "../PageNotFound/PageNotFoundView";
import ChangePasswordContainer from "../ChangePassword/ChangePasswordContainer";
import VerifyClinicContainer from "../Onboarding/VerifyClinic/VerifyClinicContainer";
import ClinicLoginContainer from "../Onboarding/Signin/ClinicLoginContainer";
import ResetClinicPasswordContainer from "../Onboarding/ForgotClinicPassword/ResetClinicPasswordContainer";
import VerifyClinicPhoneNumberContainer from "../Onboarding/ForgotClinicPassword/VerifyClinicPhoneNumberContainer";
import ClinicSignupContainer from "../Onboarding/Signup/ClinicSignupContainer";
import ForgotClinicPasswordContainer from "../Onboarding/ForgotClinicPassword/ForgotClinicPasswordContainer";
import CheckAuth from "../../CheckAuth";
import MagicLinkContainer from "../PromptWebViewForMobile/MagicLinkContainer";
import MagicLinkSentView from "../PromptWebViewForMobile/MagicLinkSentView";
import UserEmailContainer from "../PromptWebViewForMobile/UserEmailContainer";
import ExpiredMagicLinkContainer from "../PromptWebViewForMobile/ExpiredMagicLinkContainer";
import MagicLinkSignInContainer from "../PromptWebViewForMobile/MagicLinkSignInContainer";
import SelectAccountContainer from "../SelectAccount/SelectAccountContainer";
import ShortLinkContainer from "../Link/ShortLinkContainer";
import ExportPatientData from "../ExportData/ExportPatientData";

const { gtag } = window;
if (window.location.hostname === "auth.kulcare.com") {
  history.listen(location => {
    gtag("config", process.env.REACT_APP_GA_ID, {
      page_location: location
    });
  });
}

class AppContent extends Component {
  render() {
    return (
      <div>
        {/* Base page is login page for logged out user and dashboard for logged in user with doctor profile */}
        <Analytics id={process.env.REACT_APP_GA_ID}>
          <Switch>
            <Route exact path="/" component={CheckAuth} />

            <PublicRoute path="/sign-up" component={ClinicSignupContainer} />
            <PublicRoute path="/sign-in" component={ClinicLoginContainer} />

            <PublicRoute
              path="/verify-clinic"
              component={VerifyClinicContainer}
            />
            <PublicRoute
              path="/forgot-password"
              component={ForgotPasswordContainer}
            />
            <PublicRoute
              path="/reset-password-for-clinic"
              component={ResetClinicPasswordContainer}
            />
            <PublicRoute
              path="/verify-phone-number-for-clinic"
              component={VerifyClinicPhoneNumberContainer}
            />
            <PublicRoute
              path="/verify-phone-number"
              component={VerifyPhoneNumberContainer}
            />
            <PublicRoute
              path="/reset-password"
              component={ResetPasswordContainer}
            />

            <Route
              path="/change-password"
              component={ChangePasswordContainer}
            />

            <PublicRoute
              path="/forgot-password-for-clinic"
              component={ForgotClinicPasswordContainer}
            />

            <Route path="/magic-link" component={MagicLinkContainer} />

            <Route path="/magic-link-sent" component={MagicLinkSentView} />

            <Route path="/get-user-email" component={UserEmailContainer} />

            <Route
              path="/expired-magic-link"
              component={ExpiredMagicLinkContainer}
            />

            <Route
              path="/magic-link-signin"
              component={MagicLinkSignInContainer}
            />

            <Route path="/select-account" component={SelectAccountContainer} />
            <Route exact path="/k" component={ShortLinkContainer} />

            <Route
              path="/export-patient-report"
              component={ExportPatientData}
            />

            {/* 404 - Page not found */}
            <Route component={PageNotFoundView} />
          </Switch>
        </Analytics>
      </div>
    );
  }
}

export default AppContent;
