import React, { Component } from "react";
import { connect } from "react-redux";
import qs from "qs";

import { changePasswordAction } from "./ForgotClinicPasswordActions";
import ResetClinicPasswordView from "./ResetClinicPasswordView";
import { history, decryptId, strongRegularExp } from "../../../helpers";

class ResetClinicPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMsg: "",
      password: "",
      confirmPassword: "",
      userId: "",
      serverError: false,
      serverErrorText: "",
      passwordDetails: {
        strength: 0,
        strengthText: null,
        barColor: "red"
      },
      screenType: "big",
      isFirstLogin: false
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.setScreenType);
    this.setScreenType();
    const params = qs.parse(this.props.location.search.slice(1));
    const userId = decryptId(params.id);

    const isPasswordSet = JSON.parse(localStorage.getItem("pwd_set_by_user"));
    this.setState({ userId });
    if (isPasswordSet != null) {
      this.setState({ isFirstLogin: !isPasswordSet });
    }
  }

  componentWillUnmount(){
    localStorage.removeItem("pwd_set_by_user");
    window.removeEventListener("resize", this.setScreenType);
  }

  /**
   * @function setScreenType
   * @desc This funtion sets the screen type
   * in local state
   */
  setScreenType = () => {
    const windowWidth = window.innerWidth;
    const screenType = windowWidth > 992 ? "big" : "small";
    this.setState({ screenType });
  };

  /**
   * @function checkPasswordStrength
   * @param value
   * @param nothing
   * @desc This checks the strength of
   * the password entered by the user in percentage
   */
  checkPasswordStrength = value => {
    return strongRegularExp.test(value);
  };

  /**
   * @function handleChange
   * @param e
   * @return nothing
   * @desc This method handles input changes in reset password screen
   */
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value.replace(/\s/g, ""),
      error: false,
      errorMsg: "",
      serverError: false,
      serverErrorText: ""
    });
  };

  /**
   * @function setStateForInvalidData
   * @param errorText
   * @return nothing
   * @desc This method sets error messages state
   */
  setStateForInvalidData = errorText => {
    this.setState({
      error: true,
      errorMsg: errorText,
      serverError: false,
      serverErrorText: ""
    });
  };

  /**
   * @function handleSubmit
   * @param e
   * @return nothing
   * @desc This method checks for
   * valid input and submits
   * verify code for verification
   */
  handleSubmit = e => {
    e.preventDefault();

    const { password, confirmPassword, userId } = this.state;

    if (password === "" || confirmPassword === "") {
      this.setStateForInvalidData("password_required");
      return;
    }

    if (password !== confirmPassword) {
      this.setStateForInvalidData("confirm_password_error");
      return;
    }
    // check password strength
    if (!this.checkPasswordStrength(password)) {
      this.setStateForInvalidData("follow_pwd_hint");
      return;
    }

    if (password && userId && confirmPassword) {
      this.props.changePassword(userId, password);
    }
  };

  /**
   * @function handleCancel
   * @param e
   * @param nothing
   * @desc This handles redirection to sign in page
   */
  handleCancel = () => {
    history.push("/sign-in");
  };

  /**
   * @function processErrors
   * @param errors
   * @param nothing
   * @desc This handles setting state of
   * variable which is to be shown to
   * the user when api returns some error
   */
  processErrors = errors => {
    for (let i = 0; i < errors.length; i += 1) {
      switch (errors[i].source) {
        case "new_password":
          this.setState({
            serverErrorText: "password_error"
          });
          break;
        case "password":
          this.setState({
            serverErrorText: "follow_pwd_hint"
          });
          break;
        default:
          this.setState({
            serverErrorText: "error_saving_pwd"
          });
          break;
      }
    }
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.changePasswordError &&
      newProps.changePasswordError.length > 0
    ) {
      this.setState({ error: false, errorMsg: "", serverError: true });
      this.processErrors(newProps.changePasswordError);
    }
  }

  render() {
    const { isFirstLogin } = this.state;
    return (
      <ResetClinicPasswordView
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        handleCancel={this.handleCancel}
        isProcessing={this.props.isProcessing}
        translate={this.props.translate}
        error={this.state.error}
        errorMsg={this.state.errorMsg}
        serverError={this.state.serverError}
        serverErrorText={this.state.serverErrorText}
        passwordDetails={this.state.passwordDetails}
        password={this.state.password}
        confirmPassword={this.state.confirmPassword}
        screenType={this.state.screenType}
        isFirstLogin={isFirstLogin}
      />
    );
  }
}

const mapStateToProps = state => ({
  isProcessing: state.changeClinicPassword.isProcessing,
  changePasswordError: state.changeClinicPassword.error
});

const mapDispatchToProps = dispatch => ({
  changePassword: (userId, password) =>
    dispatch(changePasswordAction(userId, password))
});

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetClinicPasswordContainer);
export { connectedPage as default };
