import React from "react";
import { Row, Col, Button } from "antd";
import { strings } from "../../helpers";

export default class SelectAccountView extends React.Component {
  render() {
    const { onSelectAccount, accounts } = this.props;

    return (
      <div className="pt-55">
        <div className="select_account_wrap">
          <div className="big-heading-text text-center mb-30 ls_1">
            {strings.selectAccount.selectAccountHeading}
          </div>
          <ul>
            {accounts &&
              accounts.map(item => {
                return (
                  <li
                    className="select_account_box"
                    onClick={() => onSelectAccount(item.key)}
                  >
                    <div
                      className={`sa-image-wrapper ${
                        item.key === "cms" ? "clinic" : "growth"
                      }`}
                    />
                    <div className="sa-text-wrapper">
                      <h3>{item.value}</h3>
                      <p>{item.description}</p>
                    </div>
                    {item.key === "cms" ? (
                      <Button className="select_account_button" type="primary">
                        Great
                      </Button>
                    ) : (
                      <Button className="select_account_button" type="primary">
                        Ok let's go!
                      </Button>
                    )}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    );
  }
}
