import { createBrowserHistory } from "history";

const { gtag } = window;
/**
 * @function history
 * @desc This handles createBrowserHistory
 */
export const history = createBrowserHistory();

export const gTagEvent = () => {
  gtag("event", "conversion", {
    send_to: "AW-739843758/ZkkWCNOotqkBEK695OAC",
    transaction_id: ""
  });
};
