import React, { Component } from "react";
import { connect } from "react-redux";
import { forgotPasswordAction } from "./forgotPasswordActions";
import ForgotPasswordView from "./ForgotPasswordView";
import { history } from "../../helpers";

class ForgotPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMsg: "",
      countryCode: "91",
      serverError: false,
      isProcessing: false,
      showSendSuccessMsg: false
    };
  }

  /**
   * @function handleChange
   * @param e
   * @return nothing
   * @desc This method handles input changes in forgot password screen
   */
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value.replace(/\s/g, ""),
      error: false,
      errorMsg: "",
      serverError: false
    });
  };

  /**
   * @function setStateForInvalidData
   * @param errorText
   * @return nothing
   * @desc This method sets error messages state
   */
  setStateForInvalidData = errorText => {
    this.setState({
      error: true,
      errorMsg: errorText,
      serverError: false
    });
  };

  /**
   * @function handleSubmit
   * @param e
   * @return nothing
   * @desc This method checks for valid input and
   * submits phone number so that user
   *  recieved the verification code to
   *  change the password
   */
  handleSubmit = e => {
    e.preventDefault();

    const { phoneNumber, countryCode } = this.state;

    if (!phoneNumber) {
      this.setStateForInvalidData("required_phone_number");
      return;
    }

    if (phoneNumber && countryCode) {
      this.props.resetPasswordInstructions(countryCode, phoneNumber);
      this.setState({
        showSendSuccessMsg: true
      });
    } else {
      this.setStateForInvalidData("param_missing");
    }
  };

  /**
   * @function handleResendCode
   * @param e
   * @return nothing
   * @desc This method handles resend verification code
   */
  handleResendCode = e => {
    e.preventDefault();
    const { phoneNumber, countryCode } = this.state;
    this.props.resendCode(phoneNumber, countryCode);
  };

  /**
   * @function handleCancel
   * @param e
   * @param nothing
   * @desc This handles redirection to sign in page
   */
  handleCancel = () => {
    history.push("/sign-in");
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.forgotPasswordError &&
      newProps.forgotPasswordError.length > 0
    ) {
      this.setState({ serverError: true, error: false, errorMsg: "" });
    }
  }

  render() {
    return (
      <ForgotPasswordView
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        handleCancel={this.handleCancel}
        isProcessing={this.props.isProcessing}
        error={this.state.error}
        errorMsg={this.state.errorMsg}
        forgotPasswordError={this.props.forgotPasswordError}
        serverError={this.state.serverError}
        phoneNumber={this.state.phoneNumber}
        showSendSuccessMsg={this.state.showSendSuccessMsg}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    isProcessing: state.forgotPassword.isProcessing,
    forgotPasswordError: state.forgotPassword.error
  };
};

const mapDispatchToProps = dispatch => ({
  resetPasswordInstructions: (countryCode, phoneNumber) =>
    dispatch(forgotPasswordAction(countryCode, phoneNumber))
});

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordContainer);
export { connectedPage as default };
