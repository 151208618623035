
import mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { getAppEnv, buildUrl, buildUrlWithIntent } from "./utils";
import { getUserProfile } from "../services";

/**
 * @function getUser
 * @desc This returns user info if the user is logged in
 */
export const getUser = () => {
  // const user = localStorage.getItem("user");
  const appEnv = getAppEnv();
  const user = getCookie(`${appEnv}_user`);
  const authToken = getCookie(`${appEnv}_authToken`);
  // const authToken = localStorage.getItem("authToken");
  return user && authToken ? JSON.parse(user) : null;
};

/**
 * @function getClinic
 * @desc This returns clinic info if the clinic is logged in
 */
export const getClinic = () => {
  const id = localStorage.getItem("clinic_id");
  const name = localStorage.getItem("clinic_name");
  const clinic = {};

  if (id) {
    clinic.clinic_id = id;
  }
  if (name) {
    clinic.clinic_name = name;
  }
  return clinic;
};

/**
 * @function isLoggedIn
 * @desc This checks whether user is logged in or not
 */
export const isLoggedIn = () => {
  const user = localStorage.getItem("user");
  const authToken = localStorage.getItem("authToken");

  // Set rollbar user again after tab reopen
  if (user) {
    const loggedInUser = JSON.parse(user);
    const clinicAccount = loggedInUser.user_accounts.filter(ua => {
      return ua.account_type === "clinic";
    });

    const doctorAccount = loggedInUser.user_accounts.filter(ua => {
      return ua.account_type === "doctor";
    });
    // Set the person information to be sent with all items to Rollbar
    let userName = {};
    if (clinicAccount.length !== 0) {
      userName = clinicAccount[0].clinic_name;
    }
    if (doctorAccount.length !== 0) {
      userName = doctorAccount[0].doctor_name;
    }
    if (clinicAccount.length === 0 && doctorAccount.length === 0) {
      userName = loggedInUser.name;
    }

    // identify user
    if (
      process.env.REACT_APP_ENVIRONMENT === "production" &&
      getUser()
    ) {
      Sentry.configureScope(scope => {
        scope.setTag("username", userName);
        scope.setUser({
          id: loggedInUser.id,
          username: userName
        });
      });
    }
  }
  return user && authToken ? true : false;
};

/**
 * @function getClinicId
 * @desc returns clinic id
 */
export const getClinicId = () => localStorage.getItem("clinic_id");

/**
 * @function getUserId
 * @desc returns User id
 */
export const getUserId = () => {
  let userId = null;
  const user = localStorage.getItem("user");
  if (user) {
    userId = JSON.parse(user).id;
  }
  return userId;
};

/**
 * @function getClinicDOcId
 * @desc returns clinic doctor id
 */
export const getClinicDocId = () => localStorage.getItem("clinic_doctor_id");

/**
 * @function setMixpanelProfileInfo
 * @desc This function sets the user for mixpanel
 */
export const setMixpanelProfileInfo = mixpanelUserObj => {
  const { clinicName, emailVal, phoneNumber, userId } = mixpanelUserObj;

  mixpanel.people.set({
    $name: clinicName,
    $email: emailVal,
    "Clinic Name": clinicName,
    "Phone Number": phoneNumber,
    "Email address": emailVal
  });

  mixpanel.identify(userId);
};

/**
 * @function setCookie
 * @param "cname", "cvalue"
 * @desc This function sets the cookie
 */
export const setCookie = (cname, cvalue) => {
  let expires = "";
  const exdays = 30; // exdays -> number of days after which cookie will expire
  const date = new Date();
  date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
  expires = `; expires=${date.toGMTString()}`;

  document.cookie = `${cname}=${cvalue}${expires};domain=.kulcare.com;path=/;`;
};

/**
 * @function getCookie
 * @param "cname"
 * @desc This function get a cookie value
 */
export const getCookie = cname => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

/**
 * @function deleteAllCookies
 * @desc This function deletes all cookies
 */
export const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=; path=/; domain=.kulcare.com;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
};

/**
 * @function createDomainLink
 * @desc This function handles logic for redirecting a user to respective domains
 */
export const createDomainLink = (userObj = null) => {
  let redirectUrl = "";
  
  let user = userObj;
  // Redirect user to "CMS", if user is clinic owner or staff (old existing non doctors)
  
  const doctorAccount = user.user_accounts.filter(ua => {
    return ua.account_type === "doctor";
  });

  const staffAccount = user.user_accounts.filter(ua => {
    return ua.account_type === "clinic" && ua.role === "staff";
  });

  const clinicAccount = user.user_accounts.filter(ua => {
    return ua.account_type === "clinic" && ua.role === "owner";
  });
    
  

  // Existing doctors:
  // when there is one entry with "account_type": "doctor" in user_accounts
  // and allow_verified_doctors = true
  // &&
  // Old existing non doctors:
  // when there is no entry with "account_type": "doctor" in user_accounts
  // and allow_verified_doctors = true
  // else if(doctorAccount.length == 1 && user.allow_verified_doctors){
  //   redirectUrl = buildUrl("cms");
  //   handleRedirection(redirectUrl);
  // } 

  const {sign_up_intent, intent_completed} = user;
  
  if(user.allow_verified_doctors){ // Old users
    redirectUrl = doctorAccount.length == 1 && buildUrl("doctors");
    if(doctorAccount.length == 0  && (staffAccount.length > 0 || clinicAccount.length > 0)) redirectUrl = buildUrl("cms");
  }
  else{
    // New doctors:
    // allow_verified_doctors = false
    // if doctor and clinic staff both exists (case of multiple accounts), then priority given to doctor role
    if(doctorAccount.length > 0 && staffAccount.length > 0) redirectUrl = sign_up_intent && !intent_completed ? buildUrlWithIntent(sign_up_intent) : buildUrl("doctors");
    if(doctorAccount.length == 0 && (staffAccount.length > 0 || clinicAccount.length > 0)) redirectUrl = sign_up_intent && !intent_completed ? buildUrlWithIntent(sign_up_intent) : buildUrl("cms")
    else redirectUrl = sign_up_intent && !intent_completed ? buildUrlWithIntent(sign_up_intent) : buildUrl("doctors");
  }
  
  return redirectUrl;
}

/**
 * @function goToLoggedInUrl
 * @desc This function redirects user to requested url or CMS
 */
export const goToLoggedInUrl = async (url) => {
  // check if any return url is present in cookie, so that user can directly land into that return url
  // check if cookie exists "appenv_return_url"
  // const domainLink = user ? createDomainLink(user) : process.env.REACT_APP_CMS_SITE_BASE_URL;
  const userResponse = await getUserProfile();
  let userObj = null;

  if (userResponse && userResponse.data && userResponse.data.success) {
    userObj = userResponse.data.data.user;
  }
  
  const domainLink = userObj ? createDomainLink(userObj) : process.env.REACT_APP_CMS_SITE_BASE_URL;
  const redirectUrl = url ? url : domainLink;
  const appEnv = getAppEnv();
  const returnUrl = getCookie(`${appEnv}_return_url`);
  if(returnUrl) window.open(returnUrl, "_self")
  else window.open(redirectUrl, "_self");
};

export const setRollbarProfile = userObj => {
  if (
    process.env.REACT_APP_ENVIRONMENT === "production" &&
    userObj
  ) {
    Sentry.configureScope(scope => {
      scope.setTag("username", userObj.name);
      scope.setUser({
        id: userObj.id,
        username: userObj.name
      });
    });
  }
};