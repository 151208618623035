import React, { Component } from "react";
import business from "../../../images/join-business.svg";
import Support from "../../../images/suport.svg";
import Analytics from "../../../images/backscreen.png";
import { RiStethoscopeFill } from "react-icons/ri";
class IntentIntro extends Component {
  render() {
    return (
      <div>
        <div className="left-intent">
          <div className="main-card">
            <div className="card-1">
              <RiStethoscopeFill size={"30px"} color="#06c5ff" />
              <div className="card-heading">Get started quickly</div>
              <div className="card-text">
                Integrate with developer-friendly APIs or choose low-code or
                pre-built solutions.
              </div>
            </div>
          </div>
          <div className="main-card">
            <div className="card-2">
              <img src={Support} />
              <div className="card-heading">Support any business model</div>
              <div className="card-text">
                E-commerce, subscriptions, SaaS platforms, marketplaces, and
                more – all within a unified platform.
              </div>
            </div>
          </div>
          <div className="main-card">
            <div className="card-3">
              <img src={business} />
              <div className="card-heading">Join millions of businesses</div>
              <div className="card-text">
                Stripe is trusted by ambitious startups and enterprises of every
                size.
              </div>
            </div>
          </div>
        </div>
        <div className="right-intent">
          <div className="img-card">
            <img src={Analytics} />
          </div>
        </div>
      </div>
    );
  }
}

export default IntentIntro;
