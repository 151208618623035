import { getCookie } from "./user";
import {
  browserName,
  fullBrowserVersion,
  osName,
  osVersion,
  isMobile,
  mobileVendor,
  mobileModel
} from "react-device-detect";
import moment from "moment";
import momentTZ from "moment-timezone";
import { getAppEnv } from "./utils";
import { isResponsiveWidth } from "../helpers";

/**
 * @function getAuthHeaders
 * @desc This handles returns the auth headers array, which can be used in
 * making api requests
 */
export const getAuthHeaders = () => {
  const appEnv = getAppEnv();
  const authToken = getCookie(`${appEnv}_authToken`);
  if (authToken) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };
    return headers;
  }
  return null;
};

export const getBasicAuthHeaders = () => {
  const headers = {
    Authorization:
      "Basic " +
      btoa(
        process.env.REACT_APP_BASIC_AUTH_USERNAME +
          ":" +
          process.env.REACT_APP_BASIC_AUTH_PASSWORD
      )
  };
  return headers;
};

export const getAuthHeadersForUploading = () => {
  const authToken = localStorage.getItem("authToken");
  if (!authToken) {
    return null;
  }
  if (authToken) {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: authToken
    };
    return headers;
  }
};

export const getBrowserDetails = userId => {
  return {
    browser_name: browserName,
    browser_version: fullBrowserVersion,
    os_name: osName,
    os_version: osVersion,
    screen_width: window.innerWidth,
    screen_height: window.innerHeight,
    device_name:
      isMobile && !isResponsiveWidth()
        ? `${mobileVendor} ${mobileModel}`
        : null,
    url: window.location.href,
    user_id: userId || null,
    role: null,
    platform:
      isMobile && !isResponsiveWidth() ? "mobile_browser" : "web_browser"
  };
};

/**
 * @function userTimezone
 * @desc This function handles current user timezone
 */
export const userTimezone = () => {
  return moment.tz.guess();
};
