import React, { Component } from "react";
import { Row, Form, Alert, Button, Input, Select, Popover } from "antd";
import NumericInput from "../../../helpers/NumericInput";
import {
  strings,
  PasswordInput,
  onlyAlphabetsWithSpace
} from "../../../helpers";
import "./Signup.css";
import VerifyClinicContainer from "../VerifyClinic/VerifyClinicContainer";
import AuthIntro from "../AuthIntro";
const FormItem = Form.Item;
const Option = Select.Option;
class ClinicSignupView extends Component {
  showLocalizedErrors = errorText => {
    if (errorText) {
      let errorLocalizedStrings = "";
      errorLocalizedStrings = strings[errorText];
      return errorLocalizedStrings;
    } else {
      return null;
    }
  };

  render() {
    const { props } = this;
    const {
      goToSignIn,
      phoneNumber,
      doctorName,
      signupSuccess,
      handleChange,
      screenType,
      isProcessing,
      phoneNumberHint,
      userEmail,
      countryCode,
      eventType,
      errorText,
      signedUpAs,
      handleSelectProvider,
      staffAccPreselected,
      accountTypePatient,
      accountTypeHospital
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    const errorMsg = props.feErrorMsg
      ? this.showLocalizedErrors(props.feErrorMsg)
      : errorText;

    return (
      <>
        <div className="auth-form-section signup-wrapper">
          <Row type="flex" justify="center" align="middle">
            {accountTypePatient ? (
              <div className="auth-left-screen patient-account-screen"></div>
            ) : accountTypeHospital ? (
              <div className="auth-left-screen hospital-account-screen"></div>
            ) : (
              <AuthIntro />
            )}
            {/* <AuthIntro /> */}
            {/* signup form start  */}
            {signupSuccess ? (
              <VerifyClinicContainer
                phoneNumber={phoneNumber}
                userEmail={userEmail}
                countryCode={countryCode}
                eventType={eventType}
                accountTypePatient={accountTypePatient}
              />
            ) : (
              <div className="signup-right-section">
                <div className="signup-form-main-wrapper">
                  <div className="signup-form-top-heading">
                    Create your Kulcare account
                  </div>
                  <div className="form-alerts-wrapper mt-20">
                    {props.feError && errorMsg && (
                      <Alert type="error" message={errorMsg} showIcon />
                    )}
                  </div>
                  <Form onSubmit={props.handleSubmit}>
                    {!accountTypePatient && (
                      <div className="auth-form-input-block-container mobile-icon mt-20">
                        <FormItem>
                          <label className="label-prop">I am,</label>
                          {getFieldDecorator("signed_up_as", {
                            initialValue: signedUpAs,
                            rules: [
                              {
                                required: true,
                                message: `${strings.signup.enter_phone_number}`
                              }
                            ]
                          })(
                            <Select
                              onSelect={handleSelectProvider}
                              disabled={staffAccPreselected}
                            >
                              <Option value="doctor">Doctor</Option>
                              <Option value="psychsignup">Therapist</Option>
                              <Option value="clinic_staff">Staff</Option>
                            </Select>
                          )}
                        </FormItem>
                        {(signedUpAs === "doctor" ||
                          signedUpAs === "psychsignup") && (
                          <p className="formitem_hint_text">
                            <b>Note:</b> To get patients, you need to upload
                            your registration number after sign up.
                          </p>
                        )}
                      </div>
                    )}
                    <div className="auth-form-input-block-container mobile-icon mt-20">
                      <FormItem>
                        <label className="label-prop">
                          {strings.mobile_number}
                        </label>

                        {accountTypePatient ? (
                          <NumericInput
                            name="phoneNumber"
                            onChange={handleChange}
                            maxLength="10"
                            className="input-with-border-bottom"
                            autoFocus
                            value={phoneNumber}
                          />
                        ) : (
                          <Popover
                            content={phoneNumberHint()}
                            title="Hint"
                            trigger="focus"
                            placement={screenType === "big" ? "right" : "top"}
                            getPopupContainer={triggerNode =>
                              triggerNode.parentNode
                            }
                            overlayClassName="wizard-popover"
                          >
                            {getFieldDecorator("phoneNumber", {
                              initialValue: "",
                              rules: [
                                {
                                  required: true,
                                  message: `${strings.signup.enter_phone_number}`
                                }
                              ]
                            })(
                              <NumericInput
                                name="phoneNumber"
                                onChange={handleChange}
                                maxLength="10"
                                className="input-with-border-bottom"
                                autoFocus
                                autoComplete="off"
                              />
                            )}
                          </Popover>
                        )}
                      </FormItem>
                    </div>
                    {/* row 2 */}
                    <div className="auth-form-input-block-container user-icon mt-20">
                      <FormItem>
                        <label className="label-prop">
                          {strings.doctors.name}
                        </label>
                        {getFieldDecorator("doctorName", {
                          initialValue: `${doctorName}`,
                          rules: [
                            {
                              required: true,
                              message: `${strings.signup.enter_name}`
                            },
                            {
                              pattern: new RegExp(onlyAlphabetsWithSpace),
                              message: "Please enter valid name"
                            }
                          ]
                        })(
                          <Input
                            name="doctorName"
                            onChange={handleChange}
                            className="input-with-border-bottom"
                          />
                        )}
                      </FormItem>
                    </div>

                    {/* row 3 */}
                    {!props.forStaff && (
                      <div className="auth-form-input-block-container email-icon mt-20">
                        <FormItem>
                          <label className="label-prop">
                            {strings.your_email}
                          </label>
                          {getFieldDecorator("userEmail", {
                            initialValue: "",
                            rules: [
                              {
                                type: "email",
                                message: strings.enter_valid_email
                              },
                              {
                                required: true,
                                message: strings.enter_valid_email
                              }
                            ]
                          })(
                            <Input
                              name="userEmail"
                              onChange={handleChange}
                              className="input-with-border-bottom"
                            />
                          )}
                        </FormItem>
                      </div>
                    )}
                    {/* row 4 */}
                    <div className="auth-form-input-block-container password-icon mt-20">
                      <FormItem className="password-field">
                        <label className="label-prop">{strings.password}</label>
                        {getFieldDecorator("password", {
                          initialValue: "",
                          rules: [
                            {
                              required: true,
                              message: `${strings.password_required}`
                            }
                          ]
                        })(
                          <PasswordInput
                            screentype={screenType}
                            onChange={handleChange}
                            placement={screenType === "big" ? "right" : "top"}
                            getpopupcontainer={triggerNode =>
                              triggerNode.parentNode
                            }
                            autocompletetext="off"
                            nametext="password"
                            className="input-with-border-bottom password-key"
                          />
                        )}
                      </FormItem>
                    </div>
                    <div className="mt-20">
                      <Button
                        type="primary"
                        className="form-primary-btn"
                        htmlType="submit"
                        onClick={this.handleVerficationModal}
                        loading={isProcessing}
                      >
                        Try it free
                      </Button>
                      <div className="mt-10 text-center">
                        <span> Already have an account?</span>{" "}
                        <a href="#" onClick={goToSignIn}>
                          Sign in
                        </a>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            )}
            {/* signup form end  */}
          </Row>
        </div>
      </>
    );
  }
}
export default Form.create()(ClinicSignupView);
