import React, { Component } from "react";
import { Col, Row, Form, Alert, Button, Input, Popover } from "antd";
import { strings, PasswordInput } from "../../helpers";

const FormItem = Form.Item;
class ChangePasswordView extends Component {
  render() {
    const { props } = this;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="auth-form change-pwd-form">
        <Row>
          <Col>
            <div className="login-form-wrapper">
              <h2 className="login-title text-center">
                {strings.changePassword.text}
              </h2>

              <div className="form-alerts-wrapper">
                {props.error && (
                  <Alert
                    message={strings[props.errorMsg]}
                    type="error"
                    showIcon
                  />
                )}

                {props.serverError && (
                  <Alert
                    message={strings[props.serverErrorText]}
                    type="error"
                    showIcon
                  />
                )}
              </div>

              <Form onSubmit={props.handleSubmit} className="login-form">
                <FormItem>
                  {getFieldDecorator("currentPassword", {
                    initialValue: `${props.currentPassword}`,
                    rules: [
                      {
                        required: true,
                        message: `${strings.password_required}`
                      }
                    ]
                  })(
                    <Input
                      type="password"
                      placeholder={strings.changePassword.current_password}
                      name="currentPassword"
                      onChange={props.handleChange}
                      autoComplete="off"
                    />
                  )}
                </FormItem>
                <FormItem className="password-field">
                  {getFieldDecorator("password", {
                    initialValue: `${props.password}`,
                    rules: [
                      {
                        required: true,
                        message: `${strings.password_required}`
                      }
                    ]
                  })(
                    <PasswordInput
                      screentype={props.screenType}
                      onChange={props.handleChange}
                      placement={props.screenType === "big" ? "right" : "top"}
                      getpopupcontainer={triggerNode => triggerNode.parentNode}
                      autocompletetext="off"
                      nametext="password"
                      placeholdertext={strings.changePassword.new_password}
                      className="password-key"
                    />
                  )}
                </FormItem>

                <FormItem>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={24}>
                      <Button
                        loading={props.isProcessing}
                        type="primary"
                        htmlType="submit"
                        className="login-form-button text-uppercase"
                      >
                        {strings.changePassword.submit}
                      </Button>
                    </Col>
                  </Row>
                </FormItem>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Form.create()(ChangePasswordView);
