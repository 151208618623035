import React, { Component } from "react";
import { connect } from "react-redux";
import qs from "qs";
import {
  verifyForgotPasswordCodeAction,
  resendForgotPasswordCodeAction
} from "./ForgotClinicPasswordActions";
import VerificationView from "../VerifyClinic/VerificationView";
import { decryptId, strings } from "../../../helpers";

class VerifyClinicPhoneNumberContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verificationCode: "",
      error: false,
      errorMsg: "",
      userId: "",
      showSendSuccessMsg: false,
      serverError: false,
      showTimer: true,
      timerCount: 60,
      phoneNumber: ""
    };
  }

  componentDidMount() {
    this.onTimer();
    // const params = qs.parse(this.props.location.search.slice(1));
    // const userId = decryptId(params.id);
    // const phoneNumber = params.phone_number;
    this.setState({
      userId: decryptId(this.props.userId),
      phoneNumber: this.props.phoneNumber
    });
  }

  /**
   * @function handleChange
   * @param e
   * @return nothing
   * @desc This method handles input changes in verification code screen
   */
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value.replace(/\s/g, ""),
      error: false,
      errorMsg: "",
      serverError: false
    });
  };

  /**
   * @function onTimer
   * @desc This method shows the timer for 60 seconds
   */
  onTimer = () => {
    const timer = setInterval(() => {
      let count = this.state.timerCount;
      count -= 1;
      if (count < 0) {
        this.setState({
          showTimer: false,
          timerCount: 60
        });
        clearInterval(timer);
      } else {
        this.setState({
          timerCount: count
        });
      }
    }, 1000);
  };

  /**
   * @function handleResendCode
   * @param e
   * @return nothing
   * @desc This method handles resend verification code
   */
  handleResendCode = e => {
    e.preventDefault();
    const { userId } = this.state;
    this.props.resendCode(userId);
  };

  /**
   * @function handleSubmit
   * @param e
   * @return nothing
   * @desc This method checks for valid input and
   *  submits verify code for verification
   */
  handleSubmit = e => {
    e.preventDefault();

    const { verificationCode, userId } = this.state;
    if (!verificationCode) {
      this.setStateForInvalidData(strings.empty_code_error);
      return;
    }

    
    this.props.verifyCode(userId, verificationCode);
  };

  /**
   * @function setStateForInvalidData
   * @param errorText
   * @return nothing
   * @desc This method sets error messages state
   */
  setStateForInvalidData = errorText => {
    this.setState({
      error: true,
      errorMsg: errorText,
      showSendSuccessMsg: false,
      serverError: false
    });
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.verifyForgotPasswordCodeError ||
      (newProps.resendCodeError && newProps.resendCodeError.length > 0)
    ) {
      this.setState({
        error: false,
        errorMsg: "",
        showSendSuccessMsg: false,
        serverError: true
      });
      setTimeout(() => {
        this.setState({
          serverError: false
        });
      }, 3000);
    }
    if (
      newProps.verifyCodeSent &&
      newProps.verifyCodeSent === true &&
      newProps.verifyCodeSent !== this.props.verifyCodeSent
    ) {
      this.setState({
        showSendSuccessMsg: true,
        error: false,
        errorMsg: "",
        serverError: false,
        showTimer: true
      });
      this.onTimer();
      setTimeout(() => {
        this.setState({
          showSendSuccessMsg: false
        });
      }, 3000);
    }
  }

  handleOTPChange = otp => {
    this.setState({
      verificationCode: otp,
      error: false,
      errorMsg: "",
      verifyServerError: false,
      resendServerError: false
    });
  };

  render() {
    return (
      <VerificationView
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        isProcessing={this.props.isProcessing}
        error={this.state.error}
        errorMsg={this.state.errorMsg}
        verificationCode={this.state.verificationCode}
        verifyCodeError={this.props.verifyForgotPasswordCodeError}
        handleResendCode={this.handleResendCode}
        isResendCodeProcessing={this.props.isResendCodeProcessing}
        resendCodeError={this.props.resendCodeError}
        showSendSuccessMsg={this.state.showSendSuccessMsg}
        handleCancel={this.handleCancel}
        serverError={this.state.serverError}
        showTimer={this.state.showTimer}
        timerCount={this.state.timerCount}
        phoneNumber={this.state.phoneNumber}
        handleOTPChange={this.handleOTPChange}
      />
    );
  }
}

const mapStateToProps = state => ({
  isProcessing: state.verifyForgotClinicPasswordCode.isProcessing,
  verifyForgotPasswordCodeError: state.verifyForgotClinicPasswordCode.error,
  isResendCodeProcessing: state.resendForgotClinicPasswordCode.isProcessing,
  resendCodeError: state.resendForgotClinicPasswordCode.error,
  verifyCodeSent: state.resendForgotClinicPasswordCode.isCodeSent
});

const mapDispatchToProps = dispatch => ({
  verifyCode: (userId, verificationCode) =>
    dispatch(verifyForgotPasswordCodeAction(userId, verificationCode)),
  resendCode: userId => dispatch(resendForgotPasswordCodeAction(userId))
});

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyClinicPhoneNumberContainer);

export { connectedPage as default };
