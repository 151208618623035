import mixpanel from "mixpanel-browser";
import { history, deleteAllCookies } from "../../helpers";
import { loginConstants } from "./loginConstants";

export const loginProcessing = isProcessing => {
  return { type: loginConstants.LOGIN_PROCESSING, isProcessing };
};
export const loginSuccess = user => {
  return { type: loginConstants.LOGIN_SUCCESS, user };
};
export const loginFailure = error => {
  return { type: loginConstants.LOGIN_FAILURE, error };
};
export const userLoggedIn = userSignedIn => {
  return { type: loginConstants.USER_LOGGED_IN, userSignedIn };
};

export const resetReducers = () => {
  return { type: "RESET_REDUCERS" };
};

/**
 * @function logoutAction
 * @param dispatch
 * @desc This function logout the user.
 */
export const logoutAction = dispatch => {
  localStorage.clear();
  deleteAllCookies();
  dispatch(loginProcessing(false));
  dispatch(userLoggedIn(false));
  dispatch(resetReducers()); // clear store
  mixpanel.reset();
  // clearFreshChatUser();

  history.push("/sign-in");
};
