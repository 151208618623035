import React, { Component } from "react";
import qs from "qs";
import _ from "lodash";
import mixpanel from "mixpanel-browser";
import ClinicSignupView from "./ClinicSignupView";
import {
  strings,
  strongRegularExp,
  isValidMailAddress,
  history,
  decryptId,
  setPageMetaDesc,
  doctorIntentParams,
  userTimezone,
  getBrowserDetails,
  onlyAlphabetsWithSpace
} from "../../../helpers";
import { getNewsDetails, signup, patientSignup } from "../../../services";
import ClinicSignupIntentView from "./ClinicSignupIntentView";

let seconds = 0;
class ClinicSignupContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneNumber: "",
      password: "",
      doctorName: "",
      firstName: "",
      lastName: "",
      feError: false,
      feErrorMsg: "",
      serverError: false,
      submitErrors: [],
      passwordDetails: {
        strength: 0,
        strengthText: null,
        barColor: "red"
      },
      screenType: "big",
      agree: false,
      forStaff: false,
      showNetworkError: false,
      userEmail: "",
      inValidEmail: false,
      utmSource: null,
      utmMedium: null,
      utmContent: null,
      utmCampaign: null,
      inviteCode: null,
      docId: null,
      newsId: null,
      newsLink: null,
      signupIntent: null,
      showTestView: false,
      testValue: null,
      signupSuccess: false,
      countryCode: 91,
      isProcessing: false,
      signedUpAs: "doctor",
      staffAccPreselected: false,
      accountTypePatient: false,
      accountTypeHospital: false
    };
  }

  componentDidMount() {
    document.title = "Sign Up";
    // set meta description for login page
    setPageMetaDesc("Create an account");
    window.addEventListener("resize", this.setScreenType);
    this.setScreenType();
    // this.checkInviteType();
    this.fetchUTMParams();
    // log mixpanel event
    mixpanel.track("Signup Page Viewed");
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setScreenType);
  }

  clinicSignupAction = (
    username,
    password,
    doctorName,
    email,
    utmParams,
    claimDocId,
    signupIntent,
    firstName,
    lastName
  ) => {
    const { signedUpAs, accountTypePatient } = this.state;
    if (accountTypePatient) {
      const params = {
        user_signup_form: {
          phone_number: username,
          country_code: "91",
          alpha_2_code: "IN",
          password,
          email,
          signed_up_as: "patient",
          time_zone: userTimezone(),
          account: "patient",
          platform: "patient_web",
          name: doctorName
        },
        mixpanel_data: getBrowserDetails()
      };
      this.setState({
        isProcessing: true
      });
      patientSignup(params).then(response => {
        this.setState({
          isProcessing: false
        });
        if (response?.data?.success) {
          mixpanel.track("Account Selected", { type: "Patient" });
          this.setState({
            signupSuccess: true
          });
        } else {
          this.processErrors(response);
        }
      });
    } else {
      let mixpanelEventType = "";
      let signUpAs = "general";
      if (signupIntent) {
        if (doctorIntentParams.includes(signupIntent)) signUpAs = "doctor";
        else signUpAs = "general";
      } else {
        signUpAs = signedUpAs === "psychsignup" ? "general" : signedUpAs;
        if (signedUpAs === "psychsignup") signupIntent = "psychsignup";
      }
      this.setState({
        isProcessing: true
      });
      signup(
        username,
        password,
        doctorName,
        email,
        utmParams,
        claimDocId,
        signUpAs,
        signupIntent,
        firstName,
        lastName
      ).then(response => {
        this.setState({
          isProcessing: false
        });
        if (response.data && response.data.success) {
          if (signedUpAs === "clinic_staff") mixpanelEventType = "staff";
          else if (signedUpAs === "psychsignup")
            mixpanelEventType = "therapist";
          else mixpanelEventType = signedUpAs;
          mixpanel.track("Account Selected", { type: mixpanelEventType });
          let eventType = "";
          // check if intent is present and if present then does the intent is for CMS, doctor or growth
          if (utmParams && utmParams.utm_campaign == "news")
            eventType = "News Feed";
          this.setState({
            signupSuccess: true,
            eventType
          });
        } else {
          this.processErrors(response);
        }
      });
    }
  };

  /**
   * @function processErrors
   * @param errors
   * @param nothing
   * @desc This handles setting state
   *  of variable which
   * is to be shown to the user
   *  when api returns some error
   */
  processErrors = response => {
    const { errors } = response.data;
    let error = errors;
    if (Array.isArray(errors)) {
      // eslint-disable-next-line prefer-destructuring
      error = errors[0];
    }
    this.setState({
      feError: true,
      errorText:
        error.source === "staff_invite"
          ? "Looks like you are not invited by any clinic, you need to have staff invite."
          : error.title
    });
  };

  /**
   * @function fetchUTMParams
   * @desc This funtion fetches utm params in url
   */
  fetchUTMParams = () => {
    const params = qs.parse(this.props.location.search.slice(1));

    if (params && !_.isEmpty(params)) {
      const {
        utm_source,
        utm_medium,
        utm_content,
        utm_campaign,
        kic,
        dname,
        did,
        nid,
        su_int,
        a6t,
        acc,
        ph
      } = params;

      this.setState({
        utmSource: utm_source || null,
        utmMedium: utm_medium || null,
        utmContent: utm_content || null,
        utmCampaign: utm_campaign || null,
        inviteCode: kic || null,
        doctorName: dname || "",
        docId: did ? decryptId(did) : null,
        newsId: nid || null,
        signupIntent: su_int || null,
        showTestView: a6t ? true : false,
        testValue: a6t || null,
        signedUpAs: acc === "staff" ? "clinic_staff" : "doctor",
        staffAccPreselected: acc === "staff" ? true : false,
        accountTypePatient: acc === "patient" || false,
        accountTypeHospital: acc === "hospital" || false,
        phoneNumber: parseInt(ph, 10) || null
      });
      // when doctor share news
      if (nid) {
        this.getNewsDetails(nid);
      }
    }
  };

  /**
   * @function refreshPage
   * @desc This funtion refreshes the page
   */
  refreshPage = () => {
    window.location.reload();
  };

  /**
   * @function checkInviteType
   * @desc This funtion checks if sign up link is for staff or not, and sets forStaff state
   */
  // checkInviteType = () => {
  //   let currentPage = this.props.location.pathname;
  //   currentPage = currentPage.replace(/\//g, "");
  //   this.setState({ forStaff: currentPage === "staff-sign-up" });
  // };

  /**
   * @function setScreenType
   * @desc This funtion sets the screen type
   * in local state
   */
  setScreenType = () => {
    const windowWidth = window.innerWidth;
    const screenType = windowWidth > 992 ? "big" : "small";
    this.setState({ screenType });
  };

  /**
   * @function handleChange
   * @param e
   * @param nothing
   * @desc This handles the phone number and password input changes
   */
  handleChange = e => {
    const { name, value } = e.target;

    this.setState({
      [name]: name === "doctorName" ? value : value.replace(/\s/g, ""),
      // [name]: value.replace(/\s/g, ""),
      feError: false,
      serverError: false,
      submitErrors: []
    });
  };

  /**
   * @function checkPasswordStrength
   * @param value
   * @param nothing
   * @desc This checks the strength of the
   *  password entered by the user in percentage
   */
  checkPasswordStrength = value => {
    return strongRegularExp.test(value);
  };

  /**
   * @function validateForm
   * @desc This function will validate form
   */

  validateForm = () => {
    const { phoneNumber, password, userEmail, agree, doctorName } = this.state;
    let feError = false;
    let feErrorMsg = "";
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if (this.state.showTestView) {
      if (!this.state.firstName) {
        feError = true;
        feErrorMsg = "Enter your name";
      }
    }
    if (phoneNumber === null || phoneNumber.trim() === "") {
      feError = true;
      feErrorMsg = "phoneErrorMsg";
    } else if (phoneNumber && phoneNumber.trim() && !reg.test(phoneNumber)) {
      feError = true;
      feErrorMsg = "required_phone_number";
    } else if (
      userEmail === null ||
      userEmail.trim() === "" ||
      !isValidMailAddress(userEmail)
    ) {
      feError = true;
      feErrorMsg = "enter_valid_email";
    } else if (password === null || password.trim() === "") {
      feError = true;
      feErrorMsg = "password_required";
    } else if (
      (password !== null &&
        password.trim() !== "" &&
        !this.checkPasswordStrength(password)) ||
      password.length <= 8
    ) {
      feError = true;
      feErrorMsg = "follow_pwd_hint";
    }

    if (doctorName && !onlyAlphabetsWithSpace.test(doctorName)) {
      feError = true;
      feErrorMsg = "nameError";
    }
    // else if (!agree) {
    //   feError = true;
    //   feErrorMsg = strings.signup.agree_to_error;
    // }
    this.setState({
      feError,
      feErrorMsg
    });
    return feError;
  };

  /**
   * @function handleSubmit
   * @param e
   * @param nothing
   * @desc This handles the submission of login parameters, calls sign up action
   */
  handleSubmit = e => {
    e.preventDefault();
    const {
      phoneNumber,
      password,
      doctorName,
      firstName,
      lastName,
      userEmail,
      utmSource,
      utmMedium,
      utmContent,
      utmCampaign,
      inviteCode,
      docId,
      signupIntent
    } = this.state;

    const formError = this.validateForm();
    if (!formError) {
      this.setState({
        feError: false,
        feErrorMsg: ""
      });

      // Sign up user
      if (navigator && navigator.onLine) {
        this.onTimer();
        seconds = 0;
        if (utmSource || utmMedium || utmContent || utmCampaign) {
          const utmParams = {
            utm_source: utmSource,
            utm_medium: utmMedium,
            utm_campaign: utmCampaign,
            utm_content: utmContent,
            param_type: "utm"
          };
          this.clinicSignupAction(
            phoneNumber,
            password,
            doctorName,
            userEmail,
            utmParams,
            docId,
            signupIntent,
            firstName,
            lastName
          );
        } else if (inviteCode) {
          const inviteParam = {
            invite_code: inviteCode,
            param_type: "invite"
          };
          this.clinicSignupAction(
            phoneNumber,
            password,
            doctorName,
            userEmail,
            inviteParam,
            docId,
            signupIntent,
            firstName,
            lastName
          );
        } else {
          this.clinicSignupAction(
            phoneNumber,
            password,
            doctorName,
            userEmail,
            null,
            docId,
            signupIntent,
            firstName,
            lastName
          );
        }
      } else {
        this.setState({ showNetworkError: true });
      }
    } else {
      return;
    }
  };

  /**
   * @function onTimer
   * @desc This method starts timer after 5 sec,
   * and runs every 5sec to check if processing state has changed ot not
   */
  onTimer = () => {
    const timer = setInterval(() => {
      if (!this.state.isProcessing) {
        seconds = 0;
        clearInterval(timer);
      } else {
        seconds++;
        if (seconds > 10) {
          // if after 10 seconds processing prop has not changes to false, then show network connectivity issue
          if (!navigator.onLine) {
            this.setState({ showNetworkError: true });
          }
        }
      }
    }, 5000);
  };

  /**
   * @function onChange
   * @desc This handles handles checkbox input change
   */
  onChange = e => {
    this.setState({
      agree: e.target.checked
    });
  };

  /**
   * @function phoneNumberHint
   * @desc This handles showing phone number input hint
   */
  phoneNumberHint = () => {
    return (
      <div>
        <p>{strings.phoneNumberHint1}</p>
        <p>{strings.phoneNumberHint2}</p>
      </div>
    );
  };

  /**
   * @function goToSignIn
   * @desc This function handles redirection to given page
   */
  goToSignIn = e => {
    if (e) e.preventDefault();
    let redirectUrl = `/sign-in`;
    const {
      utmSource,
      utmMedium,
      utmContent,
      utmCampaign,
      inviteCode,
      doctorName,
      docId,
      newsId,
      signupIntent,
      testValue,
      accountTypePatient,
      accountTypeHospital
    } = this.state;
    if (utmSource) redirectUrl = `${redirectUrl}?utm_source=${utmSource}`;
    if (utmMedium) redirectUrl = `${redirectUrl}&utm_medium=${utmMedium}`;
    if (utmContent) redirectUrl = `${redirectUrl}&utm_content=${utmContent}`;
    if (utmCampaign) redirectUrl = `${redirectUrl}&utm_campaign=${utmCampaign}`;
    if (inviteCode) redirectUrl = `${redirectUrl}?kic=${inviteCode}`;
    if (doctorName) redirectUrl = `${redirectUrl}?dname=${doctorName}`;
    if (docId)
      redirectUrl = `${redirectUrl}${doctorName ? "&" : "?"}did=${docId}`;

    if (newsId) redirectUrl = `${redirectUrl}?nid=${newsId}`;
    if (signupIntent) {
      redirectUrl = redirectUrl.includes("?")
        ? `${redirectUrl}&su_int=${signupIntent}`
        : `${redirectUrl}?su_int=${signupIntent}`;
    }
    if (testValue) {
      redirectUrl = redirectUrl.includes("?")
        ? `${redirectUrl}&a6t=${testValue}`
        : `${redirectUrl}?a6t=${testValue}`;
    }
    if (accountTypePatient) {
      redirectUrl = redirectUrl.includes("?")
        ? `${redirectUrl}&acc=patient`
        : `${redirectUrl}?acc=patient`;
    }
    if (accountTypeHospital) {
      redirectUrl = redirectUrl.includes("?")
        ? `${redirectUrl}&acc=hospital`
        : `${redirectUrl}?acc=hospital`;
    }
    history.push(redirectUrl);
  };

  /**
   * @function getNewsDetails
   * @desc This function gets news details
   */
  getNewsDetails = newsId => {
    if (newsId) {
      getNewsDetails(newsId).then(response => {
        if (response.data.success) {
          this.setState({ newsLink: response.data.data.source_link });
        }
      });
    }
  };

  /**
   * @function handleNewsClickEvent
   * @desc This function handles mixpanel event
   */
  handleNewsClickEvent = () => {
    mixpanel.track("News Viewed");
  };

  handleSelectProvider = value => {
    this.setState({ signedUpAs: value });
  };

  render() {
    const {
      newsId,
      newsLink,
      utmCampaign,
      signupIntent,
      showTestView,
      signupSuccess,
      countryCode,
      errorText,
      signedUpAs,
      staffAccPreselected,
      accountTypePatient,
      phoneNumber,
      accountTypeHospital
    } = this.state;
    return (
      <>
        {showTestView ? (
          <ClinicSignupIntentView
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            isProcessing={this.state.isProcessing}
            feError={this.state.feError}
            feErrorMsg={this.state.feErrorMsg}
            phoneNumber={this.state.phoneNumber}
            password={this.state.password}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            serverError={this.state.serverError}
            submitErrors={this.state.submitErrors}
            passwordDetails={this.state.passwordDetails}
            screenType={this.state.screenType}
            agree={this.state.agree}
            onChange={this.onChange}
            // forStaff={this.state.forStaff}
            refreshPage={this.refreshPage}
            showNetworkError={this.state.showNetworkError}
            passwordStrengthContent={this.passwordStrengthContent}
            phoneNumberHint={this.phoneNumberHint}
            checkPasswordStrength={this.checkPasswordStrength}
            userEmail={this.state.userEmail}
            inValidEmail={this.state.inValidEmail}
            goToSignIn={this.goToSignIn}
            newsId={newsId}
            newsLink={newsLink}
            handleNewsClickEvent={this.handleNewsClickEvent}
            utmCampaign={utmCampaign}
            signupIntent={signupIntent}
            errorText={errorText}
            signupSuccess={signupSuccess}
          />
        ) : (
          <ClinicSignupView
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            isProcessing={this.state.isProcessing}
            feError={this.state.feError}
            feErrorMsg={this.state.feErrorMsg}
            phoneNumber={this.state.phoneNumber}
            password={this.state.password}
            doctorName={this.state.doctorName}
            serverError={this.state.serverError}
            submitErrors={this.state.submitErrors}
            passwordDetails={this.state.passwordDetails}
            screenType={this.state.screenType}
            agree={this.state.agree}
            onChange={this.onChange}
            forStaff={this.state.forStaff}
            refreshPage={this.refreshPage}
            showNetworkError={this.state.showNetworkError}
            passwordStrengthContent={this.passwordStrengthContent}
            phoneNumberHint={this.phoneNumberHint}
            checkPasswordStrength={this.checkPasswordStrength}
            userEmail={this.state.userEmail}
            inValidEmail={this.state.inValidEmail}
            goToSignIn={this.goToSignIn}
            newsId={newsId}
            newsLink={newsLink}
            handleNewsClickEvent={this.handleNewsClickEvent}
            utmCampaign={utmCampaign}
            signupIntent={signupIntent}
            signupSuccess={signupSuccess}
            countryCode={countryCode}
            eventType={this.state.eventType}
            errorText={errorText}
            handleSelectProvider={this.handleSelectProvider}
            signedUpAs={signedUpAs}
            staffAccPreselected={staffAccPreselected}
            accountTypePatient={accountTypePatient}
            accountTypeHospital={accountTypeHospital}
          />
        )}
      </>
    );
  }
}

export default ClinicSignupContainer;
