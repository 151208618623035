import React, { Component } from "react";
import { getUser, strings } from "../../helpers";

class MagicLinkSentView extends Component {
  render() {
    const user = getUser();
    let userEmail = localStorage.getItem("user_email");

    if (!userEmail && user) {
      userEmail = user.email;
    }

    return (
      <div className="magic-link-wrapper">
        <div className="magic-link-sent-wrapper mr-40-percent">
          <h3 className="color-white">{strings.magicLink.almostThere}</h3>
          <div className="sent-mail-image-box" />

          <div className="sent-magic-link-text color-white">
            {strings.formatString(
              strings.magicLink.magicLinkSentText,
              <span>{strings.magicLink.laptopOrDesktop}</span>,
              <span>{userEmail}</span>
            )}
          </div>
          <div className="validity-text color-white">
            {strings.magicLink.linkValidFor}
          </div>
        </div>
      </div>
    );
  }
}

export default MagicLinkSentView;
