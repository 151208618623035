import { forgotPasswordConstants } from "./ForgotClinicPasswordConstants";
import {
  resetPasswordInstructions,
  verifyForgotPasswordCode,
  changePassword
} from "../../../services";
import {
  history,
  encryptId,
  showSuccessNotification,
  strings
} from "../../../helpers";
import { userLoggedIn, logoutAction } from "../../login/loginActions";
import {
  setCookieRelatedData,
  redirectToLoggedInSite
} from "../Signin/ClinicLoginActions";

export const forgotPasswordProcessing = isProcessing => ({
  type: forgotPasswordConstants.FORGOT_PASSWORD_PROCESSING,
  isProcessing
});
export const forgotPasswordSuccess = data => ({
  type: forgotPasswordConstants.FORGOT_PASSWORD_SUCCESS,
  data
});
export const forgotPasswordFailure = error => ({
  type: forgotPasswordConstants.FORGOT_PASSWORD_FAILURE,
  error
});

export const verifyForgotPasswordCodeProcessing = isProcessing => ({
  type: forgotPasswordConstants.VERIFY_FORGOT_PASSWORD_CODE_PROCESSING,
  isProcessing
});
export const verifyForgotPasswordCodeSuccess = data => ({
  type: forgotPasswordConstants.VERIFY_FORGOT_PASSWORD_CODE_SUCCESS,
  data
});
export const verifyForgotPasswordCodeFailure = error => ({
  type: forgotPasswordConstants.VERIFY_FORGOT_PASSWORD_CODE_FAILURE,
  error
});

export const changePasswordProcessing = isProcessing => ({
  type: forgotPasswordConstants.CHANGE_PASSWORD_PROCESSING,
  isProcessing
});
export const changePasswordSuccess = status => ({
  type: forgotPasswordConstants.CHANGE_PASSWORD_SUCCESS,
  status
});
export const changePasswordFailure = error => ({
  type: forgotPasswordConstants.CHANGE_PASSWORD_FAILURE,
  error
});

export const resendForgotPasswordCodeProcessing = isProcessing => ({
  type: forgotPasswordConstants.RESEND_FORGOT_PASSWORD_CODE_PROCESSING,
  isProcessing
});
export const resendForgotPasswordCodeSuccess = isCodeSent => ({
  type: forgotPasswordConstants.RESEND_FORGOT_PASSWORD_CODE_SUCCESS,
  isCodeSent
});
export const resendForgotPasswordCodeFailure = error => ({
  type: forgotPasswordConstants.RESEND_FORGOT_PASSWORD_CODE_FAILURE,
  error
});

/**
 * @function forgotPasswordAction
 * @param countryCode
 * @param phoneNumber
 * @desc This function takes in the phone number and send verification
 *  code to user, to allow user to change the password
 */
export const forgotPasswordAction = (countryCode, phoneNumber) => {
  return dispatch => {
    dispatch(forgotPasswordProcessing(true));
    resetPasswordInstructions(countryCode, phoneNumber).then(response => {
      if (response.data && response.data.success === true) {
        const responseObj = response.data;
        const id = encryptId(responseObj.data.id);
        dispatch(forgotPasswordSuccess(responseObj.data.data));
        dispatch(forgotPasswordProcessing(false));
        const url = `/verify-phone-number-for-clinic?id=${id}&phone_number=${phoneNumber}`;
        history.push(url);
      } else {
        dispatch(forgotPasswordFailure(response.data.errors));
        dispatch(forgotPasswordProcessing(false));
      }
    });
  };
};

/**
 * @function verifyForgotPasswordCodeAction
 * @param userId
 * @param verificationCode
 * @desc This function takes in the verification code sent to user
 *  and redirects user to change/ reset password screen
 */
export const verifyForgotPasswordCodeAction = (userId, verificationCode) => {
  return dispatch => {
    dispatch(resendForgotPasswordCodeSuccess());
    dispatch(verifyForgotPasswordCodeProcessing(true));
    verifyForgotPasswordCode(userId, verificationCode).then(response => {
      if (response.data && response.data.success) {
        const responseObj = response.data;
        const id = encryptId(userId);
        localStorage.setItem("atk", responseObj?.data?.t);
        dispatch(verifyForgotPasswordCodeFailure(null));
        dispatch(verifyForgotPasswordCodeSuccess(responseObj.data));
        dispatch(verifyForgotPasswordCodeProcessing(false));
        const url = `/reset-password-for-clinic?id=${id}`;
        history.push(url);
      } else {
        dispatch(verifyForgotPasswordCodeFailure(response.data.errors));
        dispatch(verifyForgotPasswordCodeProcessing(false));
      }
    });
  };
};

/**
 * @function changePasswordAction
 * @param userId
 * @param password
 * @desc This function allows user to change
 * current password - change password flow
 * or reset password - forgot password flow
 */
export const changePasswordAction = (
  userId,
  password,
  currentPassword = null
) => {
  return dispatch => {
    dispatch(changePasswordProcessing(true));
    dispatch(changePasswordSuccess(false));
    changePassword(userId, password, currentPassword).then(response => {
      if (response.data && response.data.success === true) {
        dispatch(changePasswordSuccess(true));
        dispatch(changePasswordProcessing(false));
        localStorage.removeItem("atk");
        if (currentPassword) {
          dispatch(changePasswordSuccess(false));
          // logout user in case of change password
          logoutAction(dispatch);
        } else {
          // set user data in case of reset password success
          const responseObj = response.data;
          // localStorage.setItem("authToken", `Bearer ${responseObj.auth_token}`);
          // localStorage.setItem("user", JSON.stringify(responseObj.data.user));
          // setUserDetails(responseObj, dispatch);
          // setCookieRelatedData(responseObj);
          redirectToLoggedInSite(responseObj);
          // dispatch userLoggedIn method, to update the Top nav
          // dispatch(userLoggedIn(true));
        }
      } else {
        dispatch(changePasswordFailure(response.data.errors));
        dispatch(changePasswordProcessing(false));
        dispatch(changePasswordSuccess(false));
      }
    });
  };
};

/**
 * @function resendForgotPasswordCodeAction
 * @param userId
 * @desc This function takes userid and allows user to resend verification code
 */
export const resendForgotPasswordCodeAction = userId => {
  return dispatch => {
    const encryptedId = encryptId(userId);
    dispatch(resendForgotPasswordCodeSuccess(false));
    dispatch(resendForgotPasswordCodeProcessing(true));
    dispatch(verifyForgotPasswordCodeFailure(null));
    resetPasswordInstructions("", encryptedId).then(
      response => {
        showSuccessNotification(strings.code_sent_success);
        if (response.data && response.data.success === true) {
          dispatch(resendForgotPasswordCodeSuccess(true));
          dispatch(resendForgotPasswordCodeProcessing(false));
          
        } else if (response.data && response.data.success === false) {
          dispatch(resendForgotPasswordCodeFailure(response.data.errors));
          dispatch(resendForgotPasswordCodeProcessing(false));
          dispatch(resendForgotPasswordCodeSuccess(false));
        }
      },
      error => {
        dispatch(resendForgotPasswordCodeFailure(error));
        dispatch(resendForgotPasswordCodeProcessing(false));
      }
    );
  };
};
