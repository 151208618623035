import axios from "axios";
import { getBasicAuthHeaders, getAuthHeaders } from "../helpers";

export const postFreshChatId = (clinicId, chatIdentifier) => {
  const obj = {
    chat_identifier: chatIdentifier
  };

  return axios.post(
    `${process.env.REACT_APP_SERVER_URL}/clinics/${clinicId}/save_chat_identifier`,
    obj,
    {
      headers: getAuthHeaders()
    }
  );
};

/**
 * @function getExpandedUrl
 * @param shortUrlId
 * @desc Will return expanded url
 */
export const getExpandedUrl = shortUrlId => {
  return axios.get(
    `${process.env.REACT_APP_SERVER_URL}/expand_url?short_url=${shortUrlId}`,
    {
      headers: getBasicAuthHeaders()
    }
  );
};

/**
 * @function getNewsDetails
 * @param newsId
 * @desc Will return news detail
 */
export const getNewsDetails = newsId => {
  return axios.get(
    `${process.env.REACT_APP_SERVER_URL}/news_articles/${newsId}`,
    {
      headers: getBasicAuthHeaders()
    }
  );
};
