import axios from "axios";
import { getAuthHeaders } from "../helpers/auth";

/**
 * @function sendMixpanelData
 * @param eventName
 * @param userId
 * @param mixpanelData
 * @desc This will send mixpanel data
 */
export const sendMixpanelData = (eventName, userId, mixpanelData) => {
  const params = {
    event_name: eventName,
    mixpanel_data: mixpanelData
  };

  const url = `${process.env.REACT_APP_SERVER_URL}/users/${userId}/mixpanel_events`;
  return axios.post(url, params, {
    headers: getAuthHeaders()
  });
};

/**
 * @function downloadPatientReport
 * @param "clinicId", "fileId"
 * @description This function  will download the clinic patients csv file
 */
export const downloadPatientReport = (param, mixpanelData) => {
  const params = {
    mixpanel_data: mixpanelData
  }
  let url = `${process.env.REACT_APP_SERVER_URL}`;
  if(param.clinic_id){
    url = `${url}/clinics/${param.clinic_id}/export_files/${param.file_id}/download`;
  }
  else if(param.user_id){
    url = `${url}/users/${param.user_id}/export_files/${param.file_id}/download`;
  }

  return axios.post(url, params , {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${param.token}`
    },
    responseType: "arraybuffer"
  });
}

/**
 * @function verifyCodeForExport
 * @param "exportObj", "fileId"
 * @description This function will verify code for downloading the patient/ business data
 */
export const verifyCodeForExport = exportObj => {
  const params = {
    verification_code: exportObj.verification_code
  }
  let url = `${process.env.REACT_APP_SERVER_URL}`;

  if(exportObj.clinic_id){
    url = `${url}/clinics/${exportObj.clinic_id}/export_files/${exportObj.file_id}/verify`;
  }
  else if(exportObj.user_id){
    url = `${url}/users/${exportObj.user_id}/export_files/${exportObj.file_id}/verify`;
  }
  
  return axios.post(
    url,
    params
  );
}
