import React, { Component } from "react";
import { Col, Row, Form, Alert, Button, Spin } from "antd";
import NumericInput from "../../helpers/NumericInput";
import { strings } from "../../helpers";

const FormItem = Form.Item;

class VerifyUserView extends Component {
  render() {
    const { props } = this;
    const { getFieldDecorator } = this.props.form;
    const buttonDisabled = props.verificationCode.trim === "";
    return (
      <div className="auth-form">
        {/* <div className="auth-form-wrapper"> */}
        <Row
          type="flex"
          justify="center"
          align="middle"
          className="auth-form-row"
        >
          <Col xs={20} md={12} sm={16} lg={8}>
            <div className="login-form-wrapper">
              <h2 className="login-title text-center">
                {strings.verify_code_title}
              </h2>

              <div className="form-alerts-wrapper">
                {props.error && props.errorMsg && (
                  <Alert message={props.errorMsg} type="error" showIcon />
                )}
                {props.serverError === true && props.verifyCodeError && (
                  <Alert
                    message={strings.invalid_verify_code}
                    type="error"
                    showIcon
                  />
                )}
                {props.serverError === true && props.resendCodeError && (
                  <Alert
                    message={strings.verify_code_error}
                    type="error"
                    showIcon
                  />
                )}

                {props.showSendSuccessMsg && (
                  <Alert
                    message={strings.code_sent_success}
                    type="success"
                    showIcon
                  />
                )}
              </div>

              <Form onSubmit={props.handleSubmit} className="login-form">
                <div className="resend-wrap text-right">
                  {!props.showTimer && (
                    <a href="#" onClick={e => props.handleResendCode(e)}>
                      {strings.resend_code}
                    </a>
                  )}
                  {props.showTimer && (
                    <span className="timer-count">{`${props.timerCount} ${strings.signup.sec}`}</span>
                  )}
                  {props.isResendCodeProcessing && (
                    <span>
                      <Spin size="small" />
                    </span>
                  )}
                </div>

                <FormItem>
                  {getFieldDecorator("verificationCode", {
                    initialValue: `${props.verificationCode}`,
                    rules: [
                      {
                        required: true,
                        message: `${strings.empty_code_error}`
                      }
                    ]
                  })(
                    <NumericInput
                      name="verificationCode"
                      onChange={props.handleChange}
                      placeholder={strings.enter_code}
                    />
                  )}
                </FormItem>

                <FormItem>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={24}>
                      <Button
                        disabled={buttonDisabled}
                        loading={props.isProcessing}
                        type="primary"
                        htmlType="submit"
                        className="login-form-button text-uppercase"
                      >
                        {strings.verify}
                      </Button>
                    </Col>
                  </Row>
                </FormItem>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
export default Form.create()(VerifyUserView);
