import React, { Component } from "react";
import { getUser, goToLoggedInUrl, history, getQueryParams } from "./helpers";

class CheckAuth extends Component {
  componentDidMount() {
    const user = getUser();
    const pathName = window.location.pathname;
    const urlParams = getQueryParams(window.location.search);

    const shortLinkParam = urlParams["s"];

    if (user) {
      if (shortLinkParam && pathName != "/k") {
        history.push(`/k?c=${shortLinkParam}`);
      } else if (
        pathName !== "/magic-link-signin" &&
        pathName !== "/expired-magic-link" &&
        pathName !== "/k" &&
        pathName !== "/export-patient-report"
      ) {
        if (urlParams["su_int"] === "c10h" && pathName.includes("sign-up"))
          goToLoggedInUrl(
            `${process.env.REACT_APP_CMS_SITE_BASE_URL}/${process.env.REACT_APP_GROWTH_ROUTE_URL}/your-profile`
          );
        else goToLoggedInUrl();
      }
    } else {
      if (shortLinkParam && pathName != "/k") {
        history.push(`/k?c=${shortLinkParam}`);
        return false;
      } else if (pathName != "/k") {
        history.push("/sign-in");
      }
    }
  }

  render() {
    return <div />;
  }
}

export default CheckAuth;
