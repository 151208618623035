import React, { Component } from "react";
import { strings } from "../../helpers";

class MagicLinkView extends Component {
  render() {
    const {
      showInterstitialPage,
      sendMagicLinkToUser,
      logoutUser,
      signInCount,
      handleRedirectToAppStore
    } = this.props;
    return (
      <div>
        {showInterstitialPage && (
          <div className="interstitial-page-wrapper">
            {/* <img
              src="https://kulcare-assets.s3-ap-southeast-1.amazonaws.com/images/interstitial.gif"
              alt=""
            /> */}
          </div>
        )}

        {!showInterstitialPage && (
          <div className="magic-link-wrapper">
            <div className="send-magic-link-wrapper">
              <h3>
                {signInCount !== 1
                  ? strings.welcomeBack
                  : strings.thanksForJoining}
              </h3>
              <div className="mobile-view-message">
                {signInCount !== 1
                  ? strings.magicLink.looksLikeMobileDevice2
                  : strings.magicLink.looksLikeMobileDevice}
              </div>
              <div className="magic-link-get-started grey mt-50">
                {signInCount !== 1
                  ? strings.magicLink.toUseKulcare
                  : strings.magicLink.toGetStarted}
                ,
              </div>
              <div className="magic-link-get-started green underlined">
                <a href="javascript:;" onClick={sendMagicLinkToUser}>
                  {strings.magicLink.emailMeMagicLink}
                </a>
              </div>

              <div className="mobile-view-message mt-50">
                {strings.orDownloadApp}
                <div className="ptb-10">
                  <a
                    href="#"
                    className="app-icon"
                    onClick={handleRedirectToAppStore}
                  >
                    {/* <img
                      src="https://kulcare-assets.s3-ap-southeast-1.amazonaws.com/images/gplay_icon.svg"
                      alt=""
                    /> */}
                  </a>
                  <a
                    href="#"
                    className="app-icon"
                    onClick={handleRedirectToAppStore}
                  >
                    {/* <img
                      src="https://kulcare-assets.s3-ap-southeast-1.amazonaws.com/images/app_store_icon.svg"
                      alt=""
                    /> */}
                  </a>
                </div>
              </div>

              <div className="magic-link-footer">
                <a href="javascript:;" onClick={logoutUser}>
                  {strings.magicLink.logMeOut}
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MagicLinkView;
