import React, { Component } from "react";
import qs from "qs";
import FileSaver from "file-saver";
import { Spin } from "antd";
import { decryptId, getBrowserDetails, strings } from "../../helpers";
import { downloadPatientReport, verifyCodeForExport } from "../../services";
import ExportPatientDataView from "./ExportPatientDataView";

class ExportPatientData extends Component {
  state = {
    isProcessing: false,
    fileId: null,
    fileName: null,
    verifyCode: null,
    clinicId: null,
    showErrorBlock: false,
    errorMsg: null,
    userId: null
  };

  componentDidMount() {
    const params = qs.parse(this.props.location.search.slice(1));
    const { fid, fn, c, cid, uid } = params;

    this.setState(
      {
        fileId: fid,
        fileName: fn || null,
        verifyCode: c || null,
        fileId: fid ? decryptId(fid) : null,
        clinicId: cid ? decryptId(cid) : null,
        userId: uid ? decryptId(uid) : null
      },
      () => {
        this.handleCodeVerification();
      }
    );
  }

  /**
   * @function handleCSVFileDownload
   * @param "token"
   * @description This function handles downloading of patient/ billing data
   */
  handleCSVFileDownload = token => {
    const { clinicId, fileId, fileName, userId } = this.state;
    const mixpanelData = getBrowserDetails();

    const params = {
      file_id: fileId,
      token
    };
    if(clinicId) params.clinic_id = clinicId;
    else if(userId) params.user_id = userId;

    downloadPatientReport(params, mixpanelData)
      .then(res => {
        this.setState({ isProcessing: false });

        if (res && res.data && res.data.success && res.data.success == false) {
          this.setState({ showErrorBlock: true, errorMsg: "file_not_found" });
          return false;
        }

        const blob = new Blob([res.data], {
          type: "application/vnd.ms-excel;charset=utf-8"
        });

        let successMsg = null;

        if (fileName.indexOf("patient") !== -1) {
          successMsg = strings.patientFileDownload;
        } else if (fileName.indexOf("billing") !== -1) {
          successMsg = strings.billingFileDownload;
        }else{
          successMsg = strings.fileDownload;
        }

        this.setState({ successMsg });
        FileSaver.saveAs(blob, `${fileName}`);
      })
      .catch(() => {
        this.setState({
          isProcessing: false,
          showErrorBlock: true,
          errorMsg: "file_not_found"
        });
      });
  };

  /**
   * @function handleCodeVerification
   * @description This function handles verifying code for downloading patient/ billing data
   */
  handleCodeVerification = () => {
    const { clinicId, fileId, verifyCode, userId } = this.state;

    const params = {
      clinic_id: clinicId,
      file_id: fileId,
      verification_code: verifyCode
    };
    if(clinicId) params.clinic_id = clinicId;
    else if(userId) params.user_id = userId;

    this.setState({ isProcessing: true });
    verifyCodeForExport(params)
      .then(response => {
        if (response && response.data && response.data.success) {
          const { t } = response.data.data;
          this.handleCSVFileDownload(t);
        } else {
          this.setState({ showErrorBlock: true, errorMsg: "verify_code" });
        }
      })
      .catch(() => {
        this.setState({
          isProcessing: false,
          showErrorBlock: true,
          errorMsg: "verify_code"
        });
      });
  };

  render() {
    const { isProcessing, showErrorBlock, errorMsg, successMsg } = this.state;

    return (
      <Spin spinning={isProcessing}>
        <ExportPatientDataView
          showErrorBlock={showErrorBlock}
          errorMsg={errorMsg}
          successMsg={successMsg}
        />
      </Spin>
    );
  }
}

export default ExportPatientData;
