import {
  verifyClinicConstants,
  resendVerifyCodeConstants
} from "./VerifyClinicConstants";

export const clinicVerificationReducer = (
  state = { userSignedIn: false },
  action
) => {
  switch (action.type) {
    case verifyClinicConstants.VERIFY_USER_PROCESSING:
      return {
        ...state,
        isProcessing: action.isProcessing
      };
    case verifyClinicConstants.VERIFY_USER_SUCCESS:
      return {
        ...state,
        user: action.user
      };
    case verifyClinicConstants.USER_LOGGED_IN:
      return {
        ...state,
        userSignedIn: action.userSignedIn
      };
    case verifyClinicConstants.VERIFY_USER_FAILURE:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};

export const resendVerifyClinicCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case resendVerifyCodeConstants.RESEND_VERIFY_CODE_PROCESSING:
      return {
        ...state,
        isProcessing: action.isProcessing
      };
    case resendVerifyCodeConstants.RESEND_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        isCodeSent: action.isCodeSent
      };
    case resendVerifyCodeConstants.RESEND_VERIFY_CODE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};
