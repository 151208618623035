import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoggedIn, userRole } from "../../helpers";

/**
 * @function redirectUser
 * @param "Component", "props"
 * @desc This function handles redirection of logged in user
 *  to select clinic page/ to passed component
 */
const redirectUser = (Component, props) => {
  if (isLoggedIn() && !userRole()) {
    const user = localStorage.getItem("user");
    let redirectToClinicSelection = false;
    if (user) {
      const loggedInUser = JSON.parse(user);
      // if user exists and if more than one clinic exists
      // then redirect user to "Select clinic"
      // since user role is not present
      const clinicAccount = loggedInUser.user_accounts.filter(ua => {
        return ua.account_type === "clinic";
      });
      if (
        clinicAccount.length > 1 &&
        window.location.pathname !== "/select-clinic"
      ) {
        redirectToClinicSelection = true;
      }
      if (redirectToClinicSelection) {
        // return <Redirect to="/select-clinic" />;
        return <Redirect to="/dashboard" />;
      }
    }
  }
  // if logged out user is accessing any
  // public page then render respective component
  return <Component {...props} />;
};

// Makes sure that logged out routes are not accessible to logged in users
export const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn() && userRole() ? (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      ) : (
        redirectUser(Component, props)
      )
    }
  />
);
