import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin } from "antd";
import qs from "qs";
import mixpanel from "mixpanel-browser";
import MagicLinkView from "./MagicLinkView";
import { sendMagicLink } from "../../services";
import {
  showErrorNotification,
  getUser,
  history,
  showSuccessNotification,
  decryptId,
  strings
} from "../../helpers";
import { logoutAction } from "../login/loginActions";

class MagicLinkContainer extends Component {
  state = {
    isProcessing: false,
    fromMobileSignIn: false,
    userId: "",
    userEmail: null,
    signInCount: 1
  };

  componentDidMount() {
    const user = getUser();
    if (user) {
      this.setState({ signInCount: user.sign_in_count });
    }
    const params = qs.parse(this.props.location.search.slice(1));
    if (params) {
      const { mobile, u, e } = params;
      if (mobile && u && e) {
        const userId = decryptId(u);
        this.setState({ fromMobileSignIn: true, userEmail: e, userId });
        localStorage.setItem("user_email", e);
      }
    }
  }

  /**
   * @function sendMagicLinkToUser
   * @desc This function handles sending magic link to the user
   */
  sendMagicLinkToUser = () => {
    const { userId } = this.state;
    // const user = getUser();

    const ifUserIsfromSignUpPage = localStorage.getItem("from_signup");
    // if the user is logged in and provided email, then send the magic link
    // if (user && user.email) {
    //   mixpanel.track("Clicked_send_magic_link_signup");
    //   this.handleSendMagicLink(user.id);
    // }
    // ==> TODO: commented out the following code, not required here in auth for logged in user
    // if the user and user email is is not present, then get the user email
    // if (!user || (user && !user.email)) {
    //   // ask for email from the user, so that magic link can be sent
    //   history.push("/get-user-email");
    // }

    // if logged out user views this page, here userId is fetched from the url params

    this.handleSendMagicLink();
  };

  /**
   * @function handleSendMagicLink
   * @param userId
   * @desc This function handles sending magic link to the user
   */
  handleSendMagicLink = () => {
    const { userId, userEmail } = this.state;
    if (userId) {
      this.setState({ isProcessing: true });
      sendMagicLink(userId, userEmail).then(response => {
        this.setState({ isProcessing: false });
        if (response && response.data.success) {
          showSuccessNotification(strings.magicLink.magicLinkSentSuccess);
          history.push("/magic-link-sent");
        } else {
          showErrorNotification(response.data.errors[0].title);
        }
      });
    }
  };

  /**
   * @function logoutUser
   * @desc This function logs out the user
   */
  logoutUser = () => {
    this.props.logout();
  };

  /**
   * @function handleRedirectToAppStore
   * @desc This function handles redirect user to play store/ app store
   */
  handleRedirectToAppStore = e => {
    if (e) {
      e.preventDefault();
    }
    window.open("https://kulcre.app.link/f1OvxkLyq0", "_blank");
  };

  render() {
    const { isProcessing, signInCount } = this.state;
    return (
      <Spin spinning={isProcessing}>
        <MagicLinkView
          sendMagicLinkToUser={this.sendMagicLinkToUser}
          logoutUser={this.logoutUser}
          signInCount={signInCount}
          handleRedirectToAppStore={this.handleRedirectToAppStore}
        />
      </Spin>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logoutAction)
  };
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(MagicLinkContainer);

export default connectedPage;
