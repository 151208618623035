export default {
  no_internet_connection: "No internet connection!",
  preview: "Preview",
  back: "back",
  LabsList: "Labs list",
  staffList: "Staff list",
  staffSubtitle:
    "Invite a staff member using their mobile number to add them to your clinic. A staff member account cannot access business and patient details.",
  doctorsList: "Doctor list",
  doctorsListSubtitle:
    "Search a doctor using their mobile number to add them as a consultant to your clinic. A consultant account can only view the data related to their patients in the clinic.",
  LabsListSubtitle:
    "Search for a lab and add them to your network. You can then send a prescription to these labs directly from the CMS.",
  PatientsSubtitle:
    "This is a list of all the patients that visited your clinic. You can add a new patient or edit an existing patient's details.",
  vitalsSubtitle:
    "Add the vitals that you want to record for a patient visiting the clinic.",
  servicesSubtitle:
    "Add the services that your clinic offers. You can select these while generating the bills.",
  pharmaciesSubtitleOne:
    "Search for a pharmacy and add them to your network. You can then send a prescription to these pharmacies directly from the CMS.",
  pharmaciesSubtitleTwo:
    "You can then send a prescription to these pharmacies directly from the CMS.",
  Patients_List: "Patient List",
  on_call: "On call",
  walk_in: "Walk in",
  yes: "Yes",
  no: "No",
  info_title: "Info",
  success_title: "Success!",
  error_title: "Error!",
  save: "Save",
  add: "Add",
  enter_mobile_number: "Please enter your mobile number",
  phone_number: "Mobile number",
  your_mobile_number: "your mobile number",
  mobile_number: "Mobile number",
  phone_number_short: "Mobile no",
  password: "Password",
  your_email: "Email",
  verification_code: "Enter OTP",
  by_clicking_signup: "By clicking “Sign up,” you agree to the",
  phone_and_password_required: "Mobile number and password are required.",
  language: "Language",
  forgot_password: "Forgot password?",
  invalid_valid_phone_number: "Enter valid mobile number.",
  sign_up: "Sign up",
  sign_up_heading: "Create account now",
  sign_up_sub_heading_1:
    "Easily manage & grow your clinic with a kulcare account.",
  sign_up_sub_heading_2:
    "Just enter your clinic details below and click Sign up.",
  sign_up_sub_heading_3: "Already have an account?",
  error_occured: "Error occured",
  error_msg: "There was some error while processing the request.",
  loading: "Loading",
  invalid_verify_code: "Verification code is invalid.",
  verify_code_error: "There was some error while resending verification code.",
  code_sent_success: "Verification code is sent successfully.",
  verify_code_title: "We have sent you a code to validate that its you!",
  enter_code: "Enter",
  verify: "Verify",
  resend_code: "Resend code",
  empty_code_error: "Please enter the verification code.",
  param_missing: "Mobile number or country code is missing.",
  verify_success: "User is verified successfully, please create your profile.",
  password_hint:
    "You are requested to use a strong password. Password must be more than 8 characters, with 1 uppercase and 1 numeric or special character.",
  is_account_existing: "Already have an account?",
  existing_account: "User is already signed up.",
  weak: "Weak",
  medium: "Medium",
  strong: "Strong",
  error: "Error",
  page_not_found: "Page not found",
  required_phone_number: "Please enter the mobile number",
  forgot_pwd_title:
    "Please enter your mobile number to receive instructions for resetting your password.",
  account_error: "Account does not exist",
  cancel: "Cancel",
  skip: "Skip",
  next: "Next",
  new: "New",
  password_required: "Please enter the password.",
  confirm_password_error: "Re-entered password do not match.",
  password_error: "New password should not be same as previous one.",
  follow_pwd_hint: "Please follow the password hint.",
  error_saving_pwd: "Error saving password.",
  reset: "Reset",
  reset_pwd: "Reset password",
  enter_new_pwd: "Please enter a new password for your account",
  confirm_password: "Re-enter password",
  verify_contact: "Verify mobile number",
  complete_verification: "Enter OTP",
  verify_contact_text: "A verification code has been sent on your mobile",
  invalid_code: "Invalid verification code",
  invalid_username_pwd: "Mobile number or password is invalid.",
  invalid_password: "Password is invalid.",
  short_pwd_error: "Password is too short.",
  patientName: "Patient name",
  doctorName: "Doctor's name",
  all: "All",
  searchByPatientName: "Search by patient name",
  sign_in: "Sign in",
  log_in: "Sign in",
  terms_condition: "Terms & conditions",
  contactUs: "Contact us",
  status: {
    open: "Open",
    lab_Accepted: "Lab accepted",
    lab_report_generated: "Lab report generated",
    sample_picked_up: "Sample picked up",
    closed: "Closed",
    cancelled: "Cancelled",
    out_for_delivery: "Out for delivery",
    delivered: "Delivered",
    ready_for_pickup: "Ready for pickup",
    confirmed: "Confirmed"
  },
  signup: {
    password_must_be: "Password must be",
    password_must_1: "more than 8 characters",
    password_must_3: "1 numeric or special character",
    doctorName: "Clinic name",
    enter_name: "Please enter name",
    enter_phone_number: "Please enter mobile number",
    enter_your_email: "Please enter email",
    agree_to: "Agree to",
    terms_n_conditions: "Terms & conditions",
    have_account: "Do you have an account?",
    serverError: "There was some error while processing your request",
    sec: "sec",
    agree_to_error: "Please agree to Kulcare terms to sign up"
  },
  signin: {
    sign_in_heading_text: "Please sign in with your mobile number and password",
    keep_me_logged_in: "Keep me logged in",
    need_have_account: "Need an account?",
    enter_your_password: "Please enter your password"
  },
  forgotPassword: {
    forgot_heading_text: "Enter your mobile number to reset your password"
  },
  changePassword: {
    text: "Change password",
    submit: "Submit",
    current_password: "Current password",
    new_password: "New password",
    change_password_success_msg: "You have changed your password successfully",
    current_pwd_incorrect: "Current password is incorrect"
  },
  generalNote: "Do you want to use a custom patient ID?",
  generalPrefix: "What number should the patient IDs start from?",
  general: "General",
  startFormOne: "(The patient IDs will start from '1')",
  profile: {
    invitationSent: "Invitation sent to doctor.",
    invite: "Invite",
    find: "Find",
    enterCity: "Please enter city!",
    emptyText: "cannot be empty",
    firstAddressLine: "Address line 1",
    zipcode: "Pincode",
    open24: "Open 24x7",
    timings: "Timings",
    save: "Save",
    city: "City",
    searchPlaces: "Search places",
    state: "State",
    schedule: "Schedule",
    mon: "Mon",
    tue: "Tue",
    wed: "Wed",
    thu: "Thu",
    fri: "Fri",
    sat: "Sat",
    sun: "Sun",
    startTime: "Start time",
    closingTime: "Closing time",
    discounted_schedule: "Discounted timings",
    regular_schedule: "Regular timings",
    complete_profile: "Please complete clinic profile",
    confirm: "confirm"
  },
  support: {
    helpText:
      "Hi!, Please tell us what you want to know and we will call you in a short while to help you out",
    msgError: "Please type something for us to to help you out",
    msgPlaceholder: "The problem I face is",
    offlineMsg:
      "Hi! you are offline at the moment. You can send us a message or call us on this number +91-9773905392",
    successMsg: "We have received your problem and we will call back shortly"
  },
  doctors: {
    errorMsg: "Please enter a valid 10 digit mobile number.",
    noDocFound:
      "No doctor registered on Kulcare with this number. Invite them!",
    wantToAdd: "Do you want to add?",
    wantToRefer: "Do you want to refer?",
    addDoctor: "Add doctor",
    typeToSearchDoc: "Enter the 10 digit mobile number of the doctor",
    daily: "Daily",
    name: "Name",
    speciality: "Speciality",
    fees: "Fees",
    feesPlaceholder: "Enter fees",
    days: "Days",
    saveDoctor: "Save doctor",
    saveDoctorSuccess: "Doctor info saved successfully",
    selectDoctorError: "Please search and select a doctor",
    updateFees: "Update fees",
    emptyFeesError: "Please enter fee amount",
    updatePatientCount: "Update patient count",
    inValidPatientCount: "Please enter valid patient count",
    updateDoctor: "Update doctor info",
    updateDoctorSuccess: "Doctor info updated successfully",
    removeDoctor: "Remove doctor",
    removeDoctorSuccess: "Doctor removed successfully",
    noMatch: "No match found",
    addSchedule: "Add schedule",
    backToList: "Back to list",
    notAvailable: "not available",
    patientsPerHour: "Patients per hour",
    id: "id",
    gender: "Gender",
    removeDoctorConfirmation: "Are you sure you want to remove this doctor?",
    remove: "Remove",
    updateSchedule: "Update schedule",
    removed: "removed successfully",
    scheduleInfo: "Schedule info",
    addedSuccess: "added successfully",
    emptyDays: "Please select days",
    emptyTimings: "Please select timings",
    invalidTiming: "Please select valid timings",
    removeScheduleConfirmText: "Are you sure you want to remove this schedule?",
    selectLocationError: "Please enter the city",
    doctorName: "Doctor name",
    phoneNumber: "Mobile number",
    noDoc: "No doctor found...",
    doctor_info: "Doctor info",
    education: "Main education",
    experience_years: "Years of experience",
    resend: "Resend"
  },
  leftnav: {
    Doctors: "Doctors",
    Billing: "Billing",
    Patients: "Patients",
    Todays_Queue: "Today's queue",
    Settings: "Settings",
    Appointments: "Appointments",
    Labs: "Labs",
    Pharmacies: "Pharmacies",
    staff: "Staff",
    Referrals: "Referrals",
    Business: "Business",
    Help: "Help"
  },
  serviceName: "Service name",
  price: "Price",
  action: "Action",
  mandatoryService: "Service name cannot be empty",
  mandatoryPrice: "Price cannot be empty",
  billNo: "Bill #",
  patientId: "Patient id",
  billDate: "Bill date",
  billStatus: "Bill dtatus",
  unitPrice: "Unit price",
  service: "Service",
  discount: "Discount",
  printBill: "Print bill",
  print: "Print",
  print_patient_details: "Print Patient Details",
  add_next_visit: "Add next visit",
  print_patient_details_confirm:
    "Are you sure you want to print the Patient details?",
  gst: "GST",
  billAmount: "Amount",
  amountReceived: "Amount Received",
  amountPending: "Amount Pending",
  noBills: "No bills",
  total: "Total",
  cash: "Cash",
  card: "Card",
  noAmount: "No Amount",
  others: "Others",
  billingSummary: "MODE OF PAYMENT",
  billingAmount: "EARNINGS SUMMARY",
  noData: "No data",
  services: "Services",
  modePayment: "Mode of payment",
  mode: "Mode",
  view: "View",
  serviceList: "SERVICES",
  paid: "Paid",
  unpaid: "Unpaid",
  male: "Male",
  services_already_exists: "Service already exists.",
  service_add_success_msg: "Service added successfully",
  service_add_error_msg: "There was some error while adding service",
  service_edit_success_msg: "Service updated successfully",
  service_edit_error_msg: "There was some error while updating service",
  service_delete_success_msg: "Service was deleted successfully",
  service_delete_error_msg: "There was some error while deleting service",
  service_form_validation_msg: "Either Name or price is not valid.",
  no_bill_text: "No bills generated during the selected period.",
  no_bill_patient_text: "No bills generated for this patient.",
  download_report: "Download Report",
  queue: {
    select_doctor: "Select a doctor",
    no_timings_text: "No timings available for Dr. {doctorName}",
    no_timings_for_doc: "No timings available for the doctor yet.",
    no_appointment_text: "No patients in the queue",
    no_doctors_text: "Doctors not available",
    WithDoc: "With Doc",
    InQueue: "In Queue",
    Check_out: "Check-out",
    CheckedOut: "Checked Out",
    CheckedIn: "Checked In",
    Remove: "No Show",
    all: "All",
    NotConfirmed: "Not Confirmed",
    token_number: "Token Number",
    ref_by: "Ref by",
    generate_bill: "Generate bill",
    view_bill: "View bill",
    new_appointment: "New Appointment!",
    new_patient: "Patient added",
    new_appointment_desc: "Appointment has been created successfully",
    new_patient_desc: "New patient has been added to the queue",
    no_bills_error: "No bill generated for this appointment.",
    payment_recieved: "Payment received successfully",
    add_referral_code: "Add Referral Code",
    referral_code: "Referral Code",
    empty_referral_error: "Please enter referral code.",
    confirmDeleteAppointment:
      "Are you sure you want to delete this appointment?",
    confirmDeleteFollowUpAppointment:
      "Are you sure you want to cancel this followup?",
    deleteAppointmentSuccess: "Appointment deleted successfully.",
    add_vitals: "Add Vitals",
    docCheckedInMsg: "Doctor checked in successfully",
    checkIn: "Check in",
    add_patient: "Add patient",
    no_patient_with_filter: `No patients with "{status}" status in the queue`,
    view_all: "View All"
  },
  selectServiceName: "Please select service name",
  discountExceeded: "Discount value should not exceed the price",
  receivePayment: "Receive Payment",
  recieve_payment_confirm:
    "After generating bill, you cannot edit it. Do you want to proceed?",
  emptyPrice: "Please enter price",
  patientsPerHourNo: "patients/ hour",
  please_confirm: "Please Confirm",
  queue_status_confirm_desc:
    "Are you sure you want to {status} this patient from the queue?",
  age: "age",
  lastAppointment: "Last appointment",
  AppointmentBillDetails: {
    YourReceipt: "Your Receipt",
    PatientName: "Patient Name",
    PatientID: "Patient ID",
    DoctorsName: "Doctor's Name",
    BillDate: "Bill Date",
    BillNo: "Bill No",
    BillStatus: "Bill Status",
    ServiceDetails: "Service Details",
    BillAmount: "Amount",
    GST: "GST",
    Discount: "Discount",
    Total: "Total",
    PoweredBy: "Powered by",
    Bill: "Bill",
    Amount: "Amount",
    NoSignatureText:
      "This is a computer generated document. No signature required."
  },
  today: "Today",
  year: "Years",
  month: "Months",
  unidentifiedService: "Unidentified service",
  billGenerated: "Bill saved successfully.",
  serviceAlreadyAdded: "Service already added.",
  paymentReceived: "Payment received successfully.",
  addAppointmentsView: {
    AppointmentTime: "Appointment Time",
    SelectAppointmentType: "Select appointment type!",
    SelectAppointmentTime: "Select appointment time!",
    Male: "Male",
    Female: "Female",
    Other: "Other",
    SelectPatientGender: "Select patient's gender!",
    PatientDOB: "Patient DOB",
    EnterPatientName: "Enter patient name",
    EnterPhoneNumber: "Enter mobile number",
    invalidPhone: "Invalid mobile number!",
    SearchPatient: "Search patient",
    AddAppointment: "Add Appointment",
    UpdateAppointment: "Update Appointment",
    year: "Years",
    month: "Months",
    day: "Days",
    patientPhoneNumber: "Patient Mobile number",
    patientPhonenumber: "Patient Mobile Number",
    dateOfBirth: "Date of Birth",
    WalkIn: "Walk In",
    OnCall: "On Call",
    Telephonic: "Telephonic",
    fixed: "Fixed",
    typeOfAppointment: "Type of appt.",
    type: "Type",
    time_to_doctor: "Time to Doctor",
    newAppointmentError: "There was some error while creating new appointment",
    addressLineOne: "Address Line 1",
    addressLineTwo: "Address Line 2",
    invalid_date_error: "Please enter a valid DOB"
  },
  unauthorized: "Unauthorized",
  signInContinue: "Please sign in to continue",
  Labs: {
    LabsListContainer: {
      LabAdded: "Lab Added!",
      addedSuccessfully: "has been added successfully.",
      LabAlreadyExists: "Lab already exists",
      LabDeleted: "Lab Deleted!",
      LabHasBeenDeletedSucessfully: "Lab has been Deleted successfully.",
      AddLab: "Add Lab",
      LabFromYourList: "from your list?",
      LabToYourList: "to your list?",
      DeleteLab: "Delete Lab"
    },
    LabsListView: {
      SearchWithLabName: "Search with lab name"
    }
  },
  Pharmacy: {
    PharmacyListContainer: {
      success: "Success",
      PharmacyAdded: "Pharmacy Added!",
      addedSuccessfully: " has been added successfully.",
      error: "error",
      Error: "Error!",
      pharmacyExists: "Pharmacy already exists",
      pharmacyAddedSuccessfully: "Pharmacy has been added successfully.",
      PharmacyDeleted: "Pharmacy Deleted!",
      PharmacyDeletedSuccessfully: "Pharmacy has been Deleted successfully.",
      DoYouWantToAdd: "Do you want to add",
      PharmacyFromList: "from your list?",
      PharmacyToList: "to your list?",
      DoYouWantToDelete: "Do you want to Delete ",
      AddPharmacy: "Add Pharmacy",
      DeletePharmacy: "Delete Pharmacy",
      EnterPhoneNumber: "Enter a valid Mobile Number",
      EnterMobileNumber: "Enter a valid Mobile Number",
      EnterName: "Enter Name",
      EnterAddressLine1: "Enter Address Line1",
      EnterAddressLine2: "Enter Address Line2",
      EnterZipcode: "Please enter pincode",
      SearchPlaces: "Search Places"
    },
    PharmacyListView: {
      Name: "Name",
      PhoneNumber: "Mobile number",
      Location: "Location",
      Delete: "Delete",
      SearchWithPharmacyName: "Search with pharmacy name"
    }
  },
  vitals: {
    delete: "Delete",
    text: "Vitals",
    vital_name: "Vital Name",
    vital_name_empty: "Vitals cannot be empty",
    remove_vital: "Delete Vital",
    remove_vital_confirmation: "Are you sure you want to delete vital?",
    vitals_not_present: "Vitals not present"
  },
  patients: {
    search: "Search",
    searchText: "Search by name, ID or mobile number",
    photos_attached: "You have attached photos",
    patient_visit: "Patient visited you"
  },
  careplan: {
    clear_prescription: "Clear prescription",
    backSetup: "Back to Start Guide",
    prescriptionTemplate: "Prescription templates",
    templateSubtitle:
      "Select a doctor and set their prescription templates. These templates can be used while generating prescriptions so that the doctor does not have to type everything again every time.",

    adviceGroupAdded: "Advice group added",
    adviceGroupSuccess: "Advice group added successfully",
    unableToAdd: "Unable to add",
    errorAddingGroup: "Error adding group",
    updatedAdviceGroup: "Updated advice group",
    updateAdviceGroup: "Advice group updated successfully",
    errorUpdatingGroup: "Error updating group",
    unableToUpdate: "Unable to update",
    adviceGroupExists: "Group already exists",
    groupDeleted: "Advice group deleted",
    adviceDeleted: "Advice group deleted successfully",
    adviceGroupName: "Advice Group name",
    adviceSubtitle: "",
    emptyAdvice: "Advice group cannot be empty.",
    enterAdviceGroupName: "Enter a group name",
    emptyAdviceName: "Advice cannot be empty.",
    enterAdviceName: "Enter advice",
    dietGroupAdded: "Diet Group added",
    dietGroupAddedSuccess: "Diet Group added successfully",
    dietGroupUpdated: "Diet group updated",
    dietGroupUpdatedSuccess: "Diet group updated successfully.",
    errorUpdatingDietGroup: "Error updating diet group",
    dietGroupExists: "Diet group already exists",
    errorAddingDiet: "Error adding diet group",
    dietGroupDeleted: "Diet group deleted",
    dietGroupDeletedSuccess: "Diet group deleted successfully",
    errorDeletionDiet: "Error deleting diet group",
    errorCommonDiet: "There was some error while deleting Diet Group",
    dietGroupname: "Diet group name",
    dietGroups: "Diet Groups",
    dietGroupSubtitle: "",
    emptyDietGroupName: "Diet group name cannot be empty.",
    enterDietGroupName: "Enter a group name",
    noEmptyDiet: "Diet cannot be empty.",
    enterDiet: "Enter diet",
    medDeleted: "Medicine deleted",
    medDeletedSuccess: "Medicine deleted successfully",
    medEmptyFields: "Empty Fields",
    medMandatoryFields: "Please input mandatory fields",
    medUpdatedSuccess: "Medicine updated successfully",
    medUpdated: "Medicine updated",
    medAddedSuccess: "Medicine added successfully",
    medAdded: "Medicine added",
    medicineSubtitle: "",
    medGroupExists: "Medicine Group already exists!",
    medUnableAdd: "Unable to add group",
    numbericValue: "Numeric value",
    invalidInputMed: "Please enter valid input",
    medGroupAddSuccess: "Medicine group added successfully",
    medGroupAdded: "Medicine group added",
    medGroupUpdateSuccess: "Medicine group added successfully",
    medGroupUpdated: "Medicine group added",
    medGroupDeleteSuccess: "Medicine group deleted successfully",
    medGroupDeleted: "Medicine Group Deleted",
    medGroupName: "Medicine group name",
    medicineGroups: "Medicine Groups",
    medGroupSubtitle: "Subtitle",
    emptyGroupName: "Medicine group cannot be empty",
    medGroupNamePlaceholder: "Enter a Medicine group name",
    medNameEmpty: "Medicine cannot be empty",
    addSelectMed: "Add one or more medicines",
    medicines: "Medicines",
    testUnabletoAdd: "Unable to add test group",
    testGroupExists: "Test Group already exists",
    testGroupUpdated: "Test group updated",
    testGroupUpdateSuccess: "Test group updated successfully",
    testGroupAdded: "Test group added",
    testGroupAddedSuccess: "Test group added successfully",
    testGroupDeletSuccess: "Test group deleted successfully",
    testGroupDeleted: "Group Deleted",
    testGroupName: "Test group name",
    testGroups: "Test Groups",
    testGroupSubtitle: "Subtitle",
    enterTestGroupName: "Enter group name",
    selectTests: "Select tests",
    addSelectTest: "Add one or more tests",
    testsEmptyError: "Tests cannot be empty.",

    sendPrescriptionTo: "Send prescription to:",
    printPrescription: "Print prescription",
    sendPrescription: "Send prescription",
    pharmacies: "Pharmacies",
    labs: "Labs",
    printConfirmation: "Are you sure you want to print the Prescription?",
    noGoBack: "No, go back",
    yesPrint: "Yes, print",
    PrescriptionSentTo: "Prescription for {patientName} has been sent to:",
    okPrint: "Ok, print",
    noPrint: "No, Print",
    continue: "Continue",
    printConfirmationTitle: "Print confirmation",
    sendPrint: "Send & Print",
    saveDone: "Save & Done",
    emptyProviderListText:
      'You can add one or more "{provider}" to send this prescription from the "{providers}" tab on the left nav',
    complaintErrorDesc: "Please enter an observation to save this prescription",
    complaintErrorTitle: "Save prescription error",
    saveCareplanTitle: "Save prescription",
    saveCareplanDesc: "Prescription saved successfully",
    saveCareplanErrorTitle: "Save prescription error",
    saveCareplanErrorDesc: "Prescription could not be saved",
    careplanText: "Prescription",
    noPrevCareplan: "Previous prescription not found",
    careplanPopulatedTitle: "Prescription populated",
    careplanPopulatedDesc:
      "Prescription populated with previous data successfully",
    complaints: "Complaints",
    vitals: "Vitals",
    tests: "Tests",
    medicines: "Medicines",
    noKeyAddedPrev1: "No {keyVal} added previously",
    advice: "advice",
    Advice: "Advice",
    diet: "Diet",
    unsavedDataWarning:
      "You have unsaved content. Are you sure you want to leave this page?",
    observation: "Observation",
    investigation: "Investigation",
    medication: "Medication",
    dietExercise: "Diet & Exercise",
    nextVisit: "Next Visit",
    adviceGroups: "Advice Groups",
    prevAdvice: "Prev Advice",
    addAdvice: "Add Advice",
    select: "Select",
    addFromAdviceGroup: "Add from advice group",
    addFromPreviousCareplan: "Add from previous prescription",
    addFromDietGroup: "Add from diet group",
    dietGroup: "Diet Group",
    prevDiet: "Prev Copy",
    addDiet: "Add Diet",
    addFromTestGroup: "Add from test groups",
    testGroups: "Test groups",
    prevTests: "Prev Tests",
    notes: "Notes",
    addToTestList: "Add to test list",
    testName: "Test name",
    emptyTestError: "Please enter tests",
    alternateMedicine: "Alternate Medicine",
    when: "When",
    whenPlaceholder: "4 times a day",
    duration: "Duration",
    durationPlaceholder: "e.g. 10",
    frequency: "Frequency",
    addFromMedsGroup: "Add from meds group",
    medsGroup: "Medicine Group",
    selectMedsFromGroup: "Select Medicines from Meds Group",
    medicineName: "Medicine name",
    instructions: "Select or type instructions",
    alternate: "Enter a alternate medicine",
    specialInstructions: "Special Instructions",
    enterMedicineName: "Enter a medicine name",
    addFromPrevMeds: "Add from previous meds",
    prevMeds: "Prev Meds",
    nextVisitAfter: "Next Visit After",
    complaint: "Complaint",
    sinceWhen: "Since when",
    day: "day",
    days: "Days",
    month: "month",
    months: "Months",
    week: "week",
    weeks: "Weeks",
    addFromPrevComplaints: "Add from previous complaints",
    prevComplaints: "Prev Complaints",
    emptyComplaintsError: "Please enter complaints",
    physicalExamination: "Physical examination/observation",
    diagnosis: "Diagnosis",
    pharmacyOrLab: "Pharmacy/ Lab",
    pharmaciesOrLabs: "Pharmacies/ Labs",
    lab: "Lab",
    pharmacy: "Pharmacy",
    prevPres: "Copy previous",
    back: "back",
    since: "Since",
    back_to_setup: "Back to Start Guide"
  },
  Patient: {
    PatientListView: {
      Id: "Id",
      NameGenderAge: "Name/Gender/Age",
      LastAppointment: "Last Appointment",
      SearchWith: "Search with Patient Name/Mobile Number/Patient ID",
      Observations: "Observations",
      PrescribedBy: "Prescribed By",
      PrescriptionFor: "Prescription for",
      PatientHistory: "Patient History",
      PhysicalExamination: "Physical examination",
      LabResults: "Lab results",
      ViewReport: "View report",
      NoRecords: "No records present for this patient",
      youCreatedPrescription: "You created prescription"
    }
  },
  Settings: {
    careplanTemplate: "Careplan Template",
    headerHeight: "Header Height(Inches)",
    footherHeight: "Footer Height(Inches)",
    customFormat: "Custom",
    kulcare: "Kulcare",
    prescriptionOption:
      "What do you want to print on the patient's prescription?",
    prescriptionFormat: "What type of prescription layout do you want to use?",
    prescription: "Prescription",
    Tests: "Tests",
    Medicines: "Medicines",
    network: "Network",
    clinic_settings: "Clinic Settings",
    users: "Users",
    settings_saved_msg: "Settings saved successfully.",
    left_margin: "Left Margin(Inches)",
    right_margin: "Right Margin(Inches)",
    left_margin_error: "Left margin should not be greater than 3",
    right_margin_error: "Right margin should not be greater than 3"
  },
  carePlanTemplate: "Careplan Template",
  carePlan: "Prescription",
  history: "History",
  tags_placehoder: "Type & select OR press enter to add",
  profileTxt: "Profile",
  logout: "Logout",
  test_results_for: "{labName} sent results for",
  test_result: "Test result",
  test_result_image_loading: "loading...",
  test_result_loading: "Test results loading...",
  select_clinic: "Select Clinic",
  staff: {
    add_staff: "Add Staff Member",
    saveStaffSuccess: "Staff member added successfully.",
    removeDoctorConfirmation: "Are you sure you want to remove staff member?",
    status: "Status",
    removeStaffSuccess: "Staff member removed successfully",
    delete_staff: "Remove Staff member"
  },
  referrals: {
    referralNotes: "Referral notes",
    viewNotes: "View notes",
    referredBy: "Referred by",
    referPatient: "Refer patient",
    refer: "Refer",
    referSuccess: "We will put the doctor and the patient in touch",
    referThisPatient: "Refer this patient",
    noReferralNotes: "No referral notes added by doctor",
    referPatientNotificationHeading: "Referral successful!",
    referred_to_you: "Referred to",
    referred_by_you: "Referred by",
    patientDetail: "Patient details",
    referredTo: "Referred to",
    referredOn: "Referred on",
    status: "Order status"
  },
  confirm: "Confirm",
  future: "Future",
  followup: "Followup",
  AppointmentDate: "Appointment Date",
  confirmFollowupAppointmentModalTitle: "Confirm Followup Appointment",
  selectDate: "Select Date",
  ok: "OK",
  followUps: "Follow ups",
  fixed_appts: "Scheduled Appointments",
  visitDueOn: "Visit due on",
  result: "result",
  results: "results",
  patient: "patient",
  referredToText: "The patients you referred to other doctors.",
  referredByText: "The patients referred to you by other doctors.",
  pharmacyReferrelText:
    "Patients who were referred to a pharmacy by a doctor in this clinic.",
  labReferrelText:
    "Patients who were referred to a lab by a doctor in this clinic.",
  networkPharmaciesText: "Search for a pharmacy and add them to your network.",
  networkLabsText:
    "Search for a lab and add them to your network to refer patients to them.",
  doctorUserText:
    "Search for a doctor by mobile number that you want to add to your clinic.",
  staffUserText:
    "Invite a staff by mobile number that you want to add to your clinic.",
  savePrescriptionWarning:
    "You won’t be able to edit the prescription afterwards. You can find this prescription in patient’s history. Continue?",
  selectDoctor: "Select Doctor",
  createPrescriptionFor: "Create Prescription for",
  editPatient: "Edit Patient",
  patientAdded: "You have successfully added a new patient to the list",
  patientUpdated: "You have successfully updated the patient info.",
  infoUpdated: "Information Updated",
  edit: "Edit",
  resendInvite: "Resend Invite",
  reinviteDoctorConfirmation: "Are you sure you want to reinvite this doctor?",
  inviteDoctor: "Invite Doctor",
  allFieldsRequired: "All fields required",
  inviteSuccess: "Doctor invited successfully!",
  perPage: "Per page",
  booked: "Booked",
  nextVisit: "Next Visit",
  nextVisitSaved: "Next Visit Saved",
  nextVisitSaveDesc: "Next visit saved successfully.",
  nextVisitSaveDescError: "Unable to save next visit.",
  nextVisitUpdateSaved: "Next Visit Updated",
  nextVisitUpdateSaveDesc: "Next visit updated successfully.",
  nextVisitUpdateSaveDescError: "Unable to update next visit.",
  nextVisitDeleteSaved: "Next Visit Deleted",
  nextVisitDeleteSaveDesc: "Next visit deleted successfully.",
  nextVisitDeleteSaveDescError: "Unable to delete next visit.",
  update: "Update",
  delete: "Delete",
  enterVisitAfter: "Enter next visit details",
  manageNextVisit: "Manage next visit",
  days: "Days",
  photo: "Photo",
  photos: "Photos",
  inside: "inside",
  click_to_open: "Click to open",
  forPatients: "For Patients",
  forPharmacies: "For Pharmacies",
  forLabs: "For Labs",
  downloadApp: "Download the app",
  signUpHeading:
    "You take care of your patients, and we’ll take care of your operational challenges",
  signUpSubHeading:
    "Kulcare offers the simplest and the easiest clinic management software. It helps you digitize your practice in the most cost effective way.",
  yourPhoneNumber: "Your mobile number",
  referredBySomeone: "Referred by someone?",
  enterReferralCode: "Enter referral code",

  phoneNumberVerification: "Mobile number verification",
  sentCodeOn: "We have sent you an OTP on {0}",
  enterVerificationCode: "Enter OTP",
  // generated_bill_amount: "Generated bills amount",
  unbilled_amount: "Unbilled amount",
  service_date: "Service Date",
  amount_collected: "Amount Collected",
  in_rupees: "In Rupees",
  generated_bill_amount: "Billed amount",
  na: "NA",
  delete_bill_confirm: "Are you sure to delete the bill?",
  providerInfo: "Provide your Info!",
  provideClinicInfo: "Provide your Clinic Info!",
  yourName: "Your Name",
  createPassword: "Create your Password",
  confirmPassword: "Confirm password",
  step: "Step",
  patientPerHourTip:
    "Enter avg. number of patients seen by the doctor every hour, to estimate waiting time for the queue.",
  yourAvailablity: "Your availability in the clinic",
  consultationFees: "Your consultation fees",
  patientYouSee: "Patients you see per hour",
  allDone: "All Done",
  addAvailability: "Add availability",
  addMore: "Add more",
  done: "Done",
  congratulations: "Congratulations!",
  allSetText: "{0} is all set to be managed through Kulcare.",
  takeYouThroughtKulcare: "Let us take you through it once.",
  showMeAround: "Show me around",
  // figureOutMyself: "I will figure it out myself",
  agreeToTerms: "I agree to {0}",
  kulcareTerms: "Kulcare terms",
  confirmPwdRequired: "Please enter the confirm password",
  nonExistingAccount: "Account for this user does not exist",
  addDaysTimings: "Please select days and timings for a schdule",
  overlapTimingsError:
    "Entered schedule timings overlaps with some other schedule",
  addScheduleError: "Please add atleast one schedule to add another",
  addAvailabilityError: "Please add doctor's schedule",
  amountCollectedValidation: "Amount cannot be greater than total amount",
  billDiscountValidation: "Discount cannot be greater than total amount",
  checkBillPaid: "Mark the bill as paid",
  receive_payment_confirm: "Are you sure you want to mark the bill as paid?",
  tour: {
    popover_btn_title: "Your tour guide",
    popover_btn_content:
      "You can click on this button anytime to start the app tour to help you understand the different components of this tab.",
    queue_tour: {
      step1: {
        title: "Add Patients",
        content:
          "You can add a patient to queue by clicking on the add button. Add patient name, mobile number, gender, age and its done."
      },
      step2: {
        title: "Patient info",
        content:
          "This part of the row immediately gives you the basic patient information to help you differentiate between patients."
      },
      step3: {
        title: "Time to doctor",
        content:
          "This is the approximate time when the patient can see the doctor. It is calculated based on various queue parameters."
      },
      step4: {
        title: "Billing amount",
        content:
          "This shows the amount patient has paid (in green) and the amount pending (in red) to be collected. This will get reflected once you add the billing info."
      },
      step5: {
        title: "Patient Status",
        content: {
          p1:
            "This is the patient status in the queue that you can change anytime.",
          p2_title: "In Queue",
          p2_text: "Patients who are in the queue but not in the clinic.",
          p3_title: "Checked In",
          p3_text: "Patients who are in the clinic.",
          p4_title: "With doc",
          p4_text: "Patient inside the doctor’s chamber.",
          p5_title: "Checked out",
          p5_text: "Patients who have completed their visits.",
          p6_title: "No show",
          p6_text: "Patient who couldn’t come to the clinic."
        }
      },
      step6: {
        title: "Actions",
        content: {
          p1: "These are the actions you can take on a patient.",
          p2_title: "Billing",
          p2_text:
            "You can add services, generate bills and view generated bills.",
          p3_title: "Add Vitals",
          p3_text: "You can add vitals of the patient.",
          p4_title: "Print Patient details",
          p4_text:
            "You can just print the patient details on your prescription pad.",
          p5_title: "Next Visit",
          p5_text:
            "You can add the next visit of the patient here. We will help you bring the patient back for a followup visit."
        }
      },
      step7: {
        title: "Checked Out/No Show queue",
        content:
          "The patients that you check out off the queue or the ones who did not come to the clinic will be listed here. You can add them back to the queue."
      },
      step8: {
        title: "Your tour guide",
        content:
          "You can click on this button anytime to start the tour to help you understand the different sections of this tab."
      }
    },
    appointments_tour: {
      step1: {
        title: "Add Appointment",
        content:
          "You can add a new appointment using this button. Add a patient (or search from existing) and  select a time slot."
      },
      step2: {
        title: "Added appointment",
        content:
          "You will see all the added appointments here. Time slots are calculated by considering the average number of patients the doctor can see in one hour."
      },
      step3: {
        title: "Follow ups",
        content:
          'These are the possible follow ups added based on your input for "Next Visit" for a patient. You can call the patient and confirm or cancel the followup. Red dots show yesterday’s follow ups Green dots for today’s and Orange dots for tomorrow’s.'
      }
    }
  },
  Pharmacies_List: "Pharmacies List",
  alreadyVerified: "This account already exists with us",
  youHaveAvailability: "You added 1 availability",
  youHaveAvailabilities: "You added {0} availabilities",
  roles: {
    owner: "Owner",
    consultant: "Consultant",
    staff: "Staff"
  },
  email: "Email",
  yourEmail: "Your email",
  emailToolTip:
    "Email is important, because this is where you will get most of the communication from us. Especially, the great features, that you’ll love, coming up soon!",

  enter_valid_email: "Please enter valid email",
  networkError: "Network connectivity issue. please try again!",
  clickToReload: "Click to reload",
  newEntry: "New Entry",
  previousUnbilled: "Previous Unbilled",
  createNewBill: "Create a new bill",
  createNewBillText:
    "There is already an unbilled entry for today. Do you really want to create a new one?",
  previousBilled: "Previous billed",
  saveBillDataWarning: "Do you want to save bill data?",
  serviceDateError: "Please select a service date",
  phoneNumberTipText:
    "We will send a text message to the Doctor with a link to signup on Kulcare.",
  addaDoctor: "Add a Doctor",
  enterDoctorName: "Enter doctor's name",
  enterDoctorPhoneNumber: "Enter doctor's mobile number",
  enterDoctorAvailabilty: "Enter doctor's availability in the practice",
  patientPerHourPlaceholder: "Average number of patients seen per hour",
  existingUser: "Existing User?",
  doctorPracticeNamePlaceholder: "Your practice name eg. XYZ Clinic",
  practicePhoneNumber: "Practice mobile number",
  setPassword: "Set a password",
  youAreSetUp: "You are all set up!",
  quickTourText: "Let us give you a quick tour of the tool.",
  tourBestSeenOn:
    "Kulcare Practice Management works best on a laptop/desktop browser or a tablet browser. You can also download our mobile apps.",
  tourModalContent:
    "Your practice is setup and you are ready to smoothly manage your practice with {0}",
  signUpSuccessText: "You have successfully signed up as {0}.",
  oneStepMoreText: "Just {0}",
  letUsAddDoctorText: "Let's add a Doctor to your clinic.",
  oneStepMoreContent: "One last step...",
  looksLikeYou: "Looks like you are viewing this on a mobile phone.",
  enterDoctorAvailabiltyTruncated: "Enter doctor's availability",
  add_timings_later: "You can add more later",
  add_doctors_later: "You can add more doctors later",
  go_home: "Go home",
  selectPrescription: "Select a prescription",
  clear: "Clear",
  clearPrescription: "Clear selected prescription",
  clearPrescriptionConfirm:
    "Are you sure you want to clear this prescription? Current progress will be lost.",
  optionalText: "optional",
  locality: "Locality",
  area: "Area & City",
  localityPlaceholder: "Sector 21, Chandigarh",
  emptyFollowUpText:
    "When you add the next visit for a patient, they will show up here accordingly.",
  prompts: {
    take_me_there: "Ok, take me there",
    do_it_later: "I will do it later",
    change_print_setings_title: "Change print settings",
    change_print_settings_desc_1:
      "You can change the print layout according to your prescription in the settings.",
    change_print_settings_desc_2:
      "Ask a staff or the admin account to change the settings.",
    update_address_title: "Update clinic address",
    update_address_desc:
      "You haven't added your clinic's address. The bill will only show the name of the clinic and not the address.",
    med_reg_no_validation: "Medical reg. number required",
    med_reg_no_placeholder: "Medical reg. number",
    med_reg_modal_title: "Add medical registration number",
    med_reg_modal_desc:
      "You haven't added the doctor's medical registration number. It is required to be printed on the prescription."
  },
  setUp: {
    welcome: "Welcome to Kulcare!",
    setUpSubtitle:
      "Let us help you setup your account. Watch the videos and complete the steps below.",
    setUpQueue: "Setup for queue management",
    setUpQueueOne: "Queue is now setup",

    // setUpQueueDescription:
    //   "Queue is your patient register at the clinic. Everything starts by adding a patient to the queue. Complete these tasks to setup your queue.",
    howToSetUpQueue: "How to setup for queue management?",
    addConsultantDoctor: "Add one consultant doctor",
    // goTo: "Go to",
    addTimingsPatientsPerHour: "Add doctor’s timings and patients per hour",
    addDoctorFees: "Add doctor’s fees",
    setUpBilling: "Setup billing",
    setUpBillingOne: "Billing is now ready",

    // setUpBillingDesc:
    //   "A quick setup will let you manage your Billings with a few clicks. Save the services you rendered for every patient and manage your daily, weekly or monthly earnings with ease.",
    howToSetUpClinic: "How to setup billing?",
    addClinicServices: "Add services that your clinic offers",
    addClinicAddress: "Add clinic’s address to be printed on the bills",
    clinicProfile: "Clinic Profile",
    setUpCareplan: "Setup for digital prescription",
    setUpCareplanOne: "Prescription settings are now complete",

    // setUpCareplanDesc:
    //   "A prescription is specific to a doctor. A doctor can add templates to generate prescriptions in seconds. The prescription templates of a doctor always remain with them even if they go to another clinic.",
    howToSetUpCareplan: "How to setup for digital prescription?",
    addCareplanTemplates: "Add prescription templates",
    careplanTemplates: "Prescription templates",
    addVitals: "Add vitals you want to capture",
    addPrintSettings: "Add prescription print settings",
    prescriptions: "Prescriptions",
    setUpText: "Start Guide",
    left: "left"
  },
  help: {
    how_help_text: "How can we help you?",
    recomended_text: "Recommended for you",
    search_placeholder: "Search faqs",
    faq_text: "FAQ's",
    queue: "Queue",
    patient: "Patient",
    appointment: "Appointment",
    billing: "Billing",
    prescription: "Prescription",
    searched_text: "Showing results for '{searchText}'",
    empty_results_text1: "We could not find anything that matches your query.",
    empty_results_text2: "Don’t worry! Click on this icon",
    empty_results_text3: "at the bottom right corner and send us a message",
    empty_results_text4: "We will help you right away."
  },
  growthProfile: {
    switchToGrowthProfile: "Switch to Growth Profile",
    switchToClinicProfile: "Switch to Clinic Profile",
    services: {
      services_name: "SERVICE NAME",
      delete: "Delete",
      text: "Vitals",
      vital_name_empty: "Services cannot be empty",
      remove_vital: "Delete Service",
      remove_vital_confirmation: "Are you sure you want to delete service?",
      vitals_not_present: "Services not present"
    },
    clinicWebsite: "Clinic Website",
    patientReviews: "Patient Reviews",
    help: "Help",
    packages: "Packages",
    createYourWebsite: "Create your free website",
    manageYourClinic: "Manage your clinic",
    clinicDetails: {
      reception: "Reception",
      opd: "OPD",
      emergency: "Emergency",
      address: "Address",
      about: "About",
      profileSaved: "Profile saved successfully",
      imageSizeWarningTitle: "Image size warning",
      imageSizeWarningInfo: "Image size should not exceed 5MB",
      clickToUpload: "Click to Upload",
      markAsCoverPhoto: "Set as cover photo",
      markedAsCover: "Cover Photo",
      clinicInfo: "Clinic Info",
      maxPhotosLimitError: "Only 10 photos are allowed to be uploaded at once",
      maxPhotosTitle: "Max limit reached",
      see_site_live: "See your site's preview"
    },
    startGuide: {
      welcomeSubtitle:
        "Thanks for choosing Kulcare. Watch the video and complete the steps below to create your free website.",
      completeClinicInfo: "Complete clinic’s basic information",
      addConsultingDoctor: "Add consulting doctors and their information",
      addOfferedServices: "Add services offered by the clinic",
      addPackages: "Add any packages or offers the clinic offers",
      lookAtPreview: "Look at the Preview of your website",
      publishWebsite: "Publish your website",
      seeSampleWebsite: "See a sample website",
      howToCreateWebsite: "How to create your website?"
    }
  },
  shortLinkErrorMessage: "Oops! Your link is invalid",
  referredByKulcare: "Referred by Kulcare",
  phoneNumberHint1:
    "Use your personal number. It will not be visible to your patients and will be your username to sign in.",
  phoneNumberHint2: "We will send an OTP to verify the number.",
  magicLink: {
    magicLinkSentSuccess: "Magic link sent successfully!",
    linkExpiryTitle: "Oops! Your magic link to sign-in has expired",
    linkExpiryTexLine1:
      "Magic link can be used only once and is valid for upto 8 hours.",
    linkExpiryTexLine2: "You can generate another link or {0} manually",
    requestAnotherLink: "Request Another Link",
    signIn: "sign-in",
    almostThere: "Almost there!",
    magicLinkSentText:
      "Just check your email on your {0} and click the link we sent to {1}",
    laptopOrDesktop: "laptop or desktop",
    linkValidFor: "Link is valid for 5 days",
    looksLikeMobileDevice:
      "Looks like you have signed up from a mobile device. Kulcare works best on a desktop or a laptop.",
    toUseKulcare: "To use Kulcare",
    toGetStarted: "To get started",
    emailMeMagicLink:
      "email me a magic link to sign in on my laptop or desktop",
    logMeOut: "Log me out",
    userDoesNotExist: "User does not exist",
    thisIsAwkward: "Well, this is awkward…",
    sendMeMagicLink: "Send me the magic link",
    enterEmail: "Enter Email",
    doNotHaveEmail: "We do not have your {0} address yet",
    looksLikeMobileDevice2:
      "Looks like you have signed in from a mobile device. Kulcare works best on a desktop or a laptop."
  },
  enterSpeciality: "Please enter a speciality",
  enterClinicName: "Please enter clinic name",
  enterAddress: "Please enter clinic address",
  errorWhileUploading: "Error while uploading",
  errorWhileDeleting: "Error while deleting",
  deletePhoto: "Delete photo",
  confirmRemovePhoto: "Are you sure you want to remove this photo?",
  recievedAmountError: "Some amount already collected, please check",
  collectedAmountError: "Collected amount cannot exceed total amount",
  noEditLater: "You won't be able to edit the bill later.",
  addPhotos: {
    attachPhotos: "Attach Photos",
    selectPhotos: "Select Photos",
    addNote: "Add a note...",
    dateError: "Please choose date",
    photosError: "Please choose photos",
    maxPhotosError: "You cannot upload more than 10 photos at once",
    photosSuccess: "Photos attached successfully!"
  },
  doctorDetail: "Doctor Details",
  selectAccount: {
    selectAccountHeading: "I want to",
    growthText: "Grow My Practice",
    clinicText: "Manage My Practice",
    growthDescription:
      "Create your website to get online, manage your reviews & listings and get more patients...",
    clinicDescription:
      "Take appointments, manage patients & prescriptions, analyze your business & more..."
  },
  welcomeBack: "Welcome back!",
  thanksForJoining: "Thanks for joining Kulcare!",
  orDownloadApp: "Or, download the app",
  questionsText: "Questions?",
  copyrightText: "Copyright © 2020 Kulcare. All rights reserved.",
  newAppVersionTitle: "A new version of Kulcare is available",
  refreshTo: "Refresh to get the latest version",
  laterText: "Later",
  refreshText: "Refresh",
  sentVerifyCodeOn:
    "We have sent you an OTP on your mobile number {0} and on your email {1}",
  patientFileDownload: "Patients data downloaded successfully.",
  billingFileDownload: "Billing data downloaded successfully.",
  errorInDownloading: "Error in downloading patient data.",
  fileExistError: "Requested file does not exist.",
  internetRestored: "Internet connection restored",
  phoneErrorMsg: "Please enter a valid 10 digit mobile number.",
  newTokulcare: "New to kulcare",
  createAccount: "Sign up",
  alreadyMember: "Already member",
  tryItFree: "Try it free",
  nameError: "Please enter the valid name",
  fileDownload: "File downloaded successfully"
};
