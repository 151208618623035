export const forgotPasswordConstants = {
  FORGOT_PASSWORD_PROCESSING: "FORGOT_PASSWORD_PROCESSING",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",

  VERIFY_FORGOT_PASSWORD_CODE_PROCESSING:
    "VERIFY_FORGOT_PASSWORD_CODE_PROCESSING",
  VERIFY_FORGOT_PASSWORD_CODE_SUCCESS: "VERIFY_FORGOT_PASSWORD_CODE_SUCCESS",
  VERIFY_FORGOT_PASSWORD_CODE_FAILURE: "VERIFY_FORGOT_PASSWORD_CODE_FAILURE",

  CHANGE_PASSWORD_PROCESSING: "CHANGE_PASSWORD_PROCESSING",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

  RESEND_FORGOT_PASSWORD_CODE_PROCESSING:
    "RESEND_FORGOT_PASSWORD_CODE_PROCESSING",
  RESEND_FORGOT_PASSWORD_CODE_SUCCESS: "RESEND_FORGOT_PASSWORD_CODE_SUCCESS",
  RESEND_FORGOT_PASSWORD_CODE_FAILURE: "RESEND_FORGOT_PASSWORD_CODE_FAILURE"
};
