import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Select, Dropdown, Menu, Avatar, Button } from "antd";
import { history, strings, setCookie, getAppEnv, getUser } from "../../helpers";

const { Option } = Select;
// Top Nav Component
class TopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languages: [
        { key: 0, text: "English", value: "en" },
        { key: 1, text: "हिंदी", value: "hi" },
        { key: 2, text: "ਪੰਜਾਬੀ", value: "pb" }
      ],
      current_language: strings.getLanguage(),
      screenSize: "big"
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  /**
   * @function changeLanguage
   * @param value
   * @return nothing
   * @desc This function sets the Language preference
   */
  changeLanguage = value => {
    const appEnv = getAppEnv();
    setCookie(`${appEnv}_current_language`, value);
    localStorage.setItem("current_language", value);
    this.props.changeLanguage(value);
  };

  /**
   * @function goToSignIn
   * @desc This function handles redirection of user when user clicks on sign in link from top nav
   */
  goToSign = e => {
    e.preventDefault();
    // is user is from clinic sign up
    if (this.props.showHeaderForClinic) {
      history.push("/sign-in");
    }
    // is user is from growth sign up
    if (this.props.showGrowthHeader) {
      history.push("/growth-sign-in");
    }
    // is user is from regular sign up
    if (!this.props.showHeaderForClinic && !this.props.showGrowthHeader) {
      history.push("/sign-in");
    }
  };

  /**
   * @function goToSignUp
   * @desc This function handles redirection of user when user clicks on sign up link from top nav
   */
  goToSignUp = e => {
    e.preventDefault();
    // is user is from clinic sign in
    history.push("/sign-up");
  };

  /**
   * @function gotoHome
   * @desc This function handles logo click
   */
  gotoHome = e => {
    e.preventDefault();
    window.open("https://kulcare.com", "_self");
  };

  /**
   * @function handleResize
   * @desc This funtion sets the screen type
   * in local state
   */
  handleResize = () => {
    const windowSize = window.innerWidth;
    const screenSize = windowSize > 768 ? "big" : "small";
    this.setState({ screenSize });
  };

  /**
   * @function menuClick
   * @return nothing
   * @desc This function handles redirections when user clicks on top right user menu
   */
  menuClick = e => {
    switch (e.key) {
      case "1":
        this.props.logout();
        break;
      default:
        break;
    }
  };

  render() {
    const { currentPage, authPagesRendered } = this.props;
    const userPresent = getUser();

    let isPatientLink = false;
    let url = window.location.search;
    url = url.replace("?", ""); // remove the ?
    if (url.includes("patient")) isPatientLink = true;

    const menu = (
      <Menu className="top-user-menu" onClick={this.menuClick}>
        {userPresent && userPresent.phone_number && (
          <Menu.Item key="2">
            <p className="top-phone-no">{userPresent.phone_number}</p>
          </Menu.Item>
        )}

        <Menu.Item key="1">
          <span>{strings.logout}</span>
        </Menu.Item>
      </Menu>
    );
    return (
      <>
        {authPagesRendered ? (
          <div className="kc-topnav">
            <div className="kc-logo">
              <span>
                <img
                  className="logo-img"
                  src={require("../../images/kc-logo.svg")}
                ></img>
              </span>
            </div>
          </div>
        ) : (
          <div className="top-nav-container">
            <Row>
              <Col>
                <div className="kc-topnav-full">
                  <div className="kc-logo">
                    <span>
                      <img
                        className="logo-img"
                        src={require("../../images/kc-logo.svg")}
                      ></img>
                    </span>
                  </div>
                  <div className="kc-options-wrap">
                    <div className="kc-options-text">
                      {currentPage == "sign-in" ? (
                        <>
                          <Button
                            type="default"
                            size="small"
                            className="ml-5"
                            onClick={this.goToSignUp}
                          >
                            {strings.createAccount}
                          </Button>
                        </>
                      ) : (
                        <>
                          <a
                            href={
                              isPatientLink
                                ? "/sign-up"
                                : "/sign-up?acc=patient"
                            }
                            className="mr-5"
                          >
                            For {isPatientLink ? "Providers" : "Patients"}
                          </a>

                          <Button
                            type="default"
                            size="small"
                            className="ml-5"
                            onClick={this.goToSign}
                          >
                            {strings.sign_in}
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row type="flex" justify="start" align="middle" className="top-nav">
              {/* {currentPage !== "expired-magic-link" &&
              currentPage !== "magic-link-signin" && (
                <Col lg={8} md={8} sm={8} xs={10}>
                  <a href="#" onClick={this.gotoHome}>
                    <span className="unselectable top-logo">
                      {" "}
                      <img
                        src={require("../../images/kc-logo.svg")}
                        alt="kulcare logo"
                      />
                    </span>
                  </a>
                </Col>
              )} */}

              {/* {!showOnlyLogo && (
              <Col
                lg={16}
                md={16}
                sm={16}
                xs={14}
                className="text-right top-right-col"
              >
                <Row type="flex" justify="end" align="middle" gutter={20}>
                  {!hideExtraTopNavOptions && currentPage !== "select-account" && (
                    <Col className="lang-dd" id="lang-cont">
                      <Select
                        size="small"
                        defaultValue={this.state.current_language}
                        className={screenSize === "big" ? "width-90" : "width-52"}
                        onChange={this.changeLanguage}
                        dropdownClassName="lang-menu dropdown-with-borders"
                        getPopupContainer={() =>
                          document.getElementById("lang-cont")
                        }
                      >
                        {this.state.languages.map(lang => {
                          return (
                            <Option key={lang.key} value={lang.value}>
                              {screenSize === "big" ? lang.text : lang.value}
                            </Option>
                          );
                        })}
                      </Select>
                    </Col>
                  )}

                  {currentPage === "select-account" && (
                    <Col id="avator">
                      <Dropdown
                        overlay={menu}
                        trigger={["click"]}
                        className="dropdown-spacing-top"
                        getPopupContainer={() =>
                          document.getElementById("avator")
                        }
                      >
                        <a
                          className="ant-dropdown-link top-user-avatar"
                          href="javascript:;"
                        >
                          <Avatar
                            size="small"
                            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCIgdmlld0JveD0iMCAwIDMwIDMwIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PGNpcmNsZSBpZD0iYSIgY3g9IjE1IiBjeT0iMTUiIHI9IjE1Ii8+PC9kZWZzPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PG1hc2sgaWQ9ImIiIGZpbGw9IiNmZmYiPjx1c2UgeGxpbms6aHJlZj0iI2EiLz48L21hc2s+PHVzZSBmaWxsPSIjRkZGIiB4bGluazpocmVmPSIjYSIvPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMgMykiIG1hc2s9InVybCgjYikiPjxwYXRoIGZpbGw9IiM1MjVGN0YiIGQ9Ik0xMiAxNC4zMDhhNS4wNzcgNS4wNzcgMCAxIDAgMC0xMC4xNTQgNS4wNzcgNS4wNzcgMCAwIDAgMCAxMC4xNTR6Ii8+PHBhdGggZmlsbD0iI0FBQjdDNCIgZD0iTTIwLjkxNiAyMC4wMzJBMTEuOTcgMTEuOTcgMCAwIDEgMTIgMjRhMTEuOTcgMTEuOTcgMCAwIDEtOC45MTYtMy45NjhDNC4xNCAxOC4wNjQgNy43MzIgMTYuNjE1IDEyIDE2LjYxNWM0LjI2OCAwIDcuODYgMS40NDkgOC45MTYgMy40MTd6Ii8+PC9nPjwvZz48L3N2Zz4="
                          />
                        </a>
                      </Dropdown>
                    </Col>
                  )}


                  

                </Row>
              </Col>
            )} */}
            </Row>
          </div>
        )}
      </>
    );
  }
}

export default TopNav;
