import { combineReducers } from "redux";
import recycleState from "redux-recycle"; // reset reducers to initailstate
import { loginReducer } from "./components/login/loginReducer";
import {
  verifyUserReducer,
  resendVerifyCodeReducer
} from "./components/verifyUser/verifyUserReducer";
import {
  signupReducer,
  checkUserExistenceReducer
} from "./components/signup/signupReducer";
import { navigationReducer } from "./components/body/Navigation";
import {
  forgotPasswordReducer,
  verifyForgotPasswordCodeReducer,
  changePasswordReducer,
  resendForgotPasswordCodeReducer
} from "./components/forgotPassword/forgotPasswordReducer";
import {
  clinicVerificationReducer,
  resendVerifyClinicCodeReducer
} from "./components/Onboarding/VerifyClinic/VerifyClinicReducer";
import { clinicLoginReducer } from "./components/Onboarding/Signin/ClinicLoginReducer";
import {
  forgotClinicPasswordReducer,
  verifyForgotClinicPasswordCodeReducer,
  changeClinicPasswordReducer,
  resendForgotClinicPasswordCodeReducer
} from "./components/Onboarding/ForgotClinicPassword/ForgotClinicPasswordReducer";
import {
  clinicSignupReducer,
  checkClinicExistenceReducer
} from "./components/Onboarding/Signup/ClinicSignupReducer";

const appReducer = combineReducers({
  navigation: navigationReducer,
  login: recycleState(loginReducer, ["RESET_REDUCERS"], {}),
  clinicLogin: recycleState(clinicLoginReducer, ["RESET_REDUCERS"], {}),
  signup: signupReducer,
  userExistence: checkUserExistenceReducer,
  verifyUser: verifyUserReducer,
  resendVerifyCode: resendVerifyCodeReducer,
  forgotPassword: forgotPasswordReducer,
  verifyForgotPasswordCode: verifyForgotPasswordCodeReducer,
  changePassword: changePasswordReducer,
  resendForgotPasswordCode: resendForgotPasswordCodeReducer,
  // profile: recycleState(profileReducer, ["RESET_REDUCERS"], {}),
  verifyClinic: clinicVerificationReducer,
  resendVerifyClinicCode: resendVerifyClinicCodeReducer,
  forgotClinicPassword: forgotClinicPasswordReducer,
  verifyForgotClinicPasswordCode: verifyForgotClinicPasswordCodeReducer,
  changeClinicPassword: changeClinicPasswordReducer,
  resendForgotClinicPasswordCode: resendForgotClinicPasswordCodeReducer,
  clinicsignup: clinicSignupReducer,
  clinicExistence: checkClinicExistenceReducer
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
