import React, { Component } from "react";
import WebUpgradeView from "./WebUpgradeView";

export default class WebUpgradeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * @function refreshApp
   * @desc This function handles refreshing App
   */
  refreshApp = (e, isReloadApp) => {
    if (e) e.preventDefault();

    if (!isReloadApp) {
      this.props.handleWebUpgradePrompt();
    } else {
      // hard reload
      window.location.reload(true);
    }
  };

  render() {
    return <WebUpgradeView refreshApp={this.refreshApp} />;
  }
}
