import _ from "lodash";
import mixpanel from "mixpanel-browser";
import {
  history,
  isMobile,
  isResponsiveWidth,
  setMixpanelProfileInfo,
  setCookie,
  getCookie,
  encryptId,
  setRollbarProfile,
  getAppEnv,
  buildUrl,
  goToLoggedInUrl,
  logMixpanelEvent,
  createDomainLink,
  cmsIntentParams
} from "../../../helpers";
import { loginConstants } from "./ClinicLoginConstants";

import { postLogin, getClinicDoctors, createClinic } from "../../../services";

export const loginProcessing = isProcessing => ({
  type: loginConstants.LOGIN_PROCESSING,
  isProcessing
});
export const loginSuccess = user => ({
  type: loginConstants.LOGIN_SUCCESS,
  user
});
export const loginFailure = error => ({
  type: loginConstants.LOGIN_FAILURE,
  error
});
export const userLoggedIn = userSignedIn => ({
  type: loginConstants.USER_LOGGED_IN,
  userSignedIn
});

// export const showFreshChat = (clinicHasDoctors, clinic) => {
//   if (clinicHasDoctors) {
//     localStorage.setItem("open_chat_popup", true);
//   }
//   setFreshChatUser(clinic);
// };

export const setCookieRelatedData = responseObj => {
  const { user } = responseObj.data;
  const appEnv = getAppEnv();

  setCookie(`${appEnv}_authToken`, `Bearer ${responseObj.auth_token}`);
  setCookie(`${appEnv}_id_tkn`, `${responseObj.refresh_token}`);

  // check if current language is being selected by the user, hence store it in cookies
  const selectedLanguage = getCookie(`${appEnv}_current_language`)
    ? getCookie(`${appEnv}_current_language`)
    : "en";

  setCookie(`${appEnv}_current_language`, selectedLanguage);
  setCookie(`${appEnv}_user`, JSON.stringify({ id: user.id }));
  // setCookie(`${getAppEnv()}_is_provider`, true);
};

/**
 * @function loginAction
 * @param phoneNumber
 * @param password
 * @desc This logins the user.
 */
const countryCode = "91";
export const loginAction = (phoneNumber, password, utmParams) => {
  return dispatch => {
    // if the user has signed in from mobile, then prompt user to continue rest of the app through web
    dispatch(loginProcessing(true));
    postLogin(phoneNumber, password, utmParams).then(response => {
      // login successful
      if (response.data && response.data.data && response.data.data.user) {
        const responseObj = response.data;
        // setCookieRelatedData(responseObj);
        dispatch(loginProcessing(false));
        redirectToLoggedInSite(responseObj, null, null, "login");
      } else if (response.data && response.data.errors) {
        const { errors } = response.data;
        let error = errors;
        if (Array.isArray(errors)) {
          // eslint-disable-next-line prefer-destructuring
          error = errors[0];
        }

        switch (error.source) {
          case "account":
            if (error.title.toLowerCase() === "account not verified") {
              // Unverified user tries to sign in - Take user to the verification page
              history.push(
                `/verify-clinic?ph=${phoneNumber}&cc=${countryCode}`
              );
            }
            break;
          default:
            dispatch(loginProcessing(false));
            dispatch(loginFailure(error));
            dispatch(userLoggedIn(false));
        }
      }
    });
  };
};

export const isClinicAccountCreated = clinicAccountPresent => ({
  type: loginConstants.HAS_CLINIC_ACCOUNT,
  hasClinicAccount: clinicAccountPresent
});

export const magicLinkSignInAction = (
  responseObj,
  pageName,
  videoUrl = null,
  sendgridCategory = null,
  link_type,
  clinic_id = null
) => {
  return dispatch => {
    const appEnv = getAppEnv();
    if (link_type === "billing") {
      dispatch(showLoader(false));
      setCookie(`${appEnv}_link_type`, "billing");
    }

    if (pageName) {
      setCookie(`${appEnv}_page_name`, pageName);
    }
    if (videoUrl) {
      setCookie(`${appEnv}_video_url`, videoUrl);
    }
    if (sendgridCategory) {
      setCookie(`${appEnv}_sendgrid_category`, sendgridCategory);
    }
    // list all the clinic related to the user and set cookie related to "selectedClinic"
    const { user } = responseObj.data;
    // fetch clinic owner from "user" object

    const clinicOwnerAccounts = user.user_accounts.filter(ua => {
      return ua.account_type === "clinic" && ua.role === "owner";
    });

    if (clinicOwnerAccounts.length === 1) {
      const selectedClinicObj = {
        id: clinicOwnerAccounts[0].clinic_id,
        name: clinicOwnerAccounts[0].clinic_name,
        role: clinicOwnerAccounts[0].role,
        logo_url: clinicOwnerAccounts[0].logo_url,
        timezone: clinicOwnerAccounts[0].timezone,
        virtual_clinic: clinicOwnerAccounts[0].virtual_clinic
      };
      // set the clinic cookie only if clinic owner account === 1, this is will fetched as
      // "selectedClinic" in other kulcare projects ("cms", "growth")
      // if clinic owner account is more than 1 then we need to show the "select-clinic" screen
      // so will need not set the selected clinic in cookie
      setCookie(`${appEnv}_clinic`, JSON.stringify(selectedClinicObj));
    } else {
      setCookie(`${appEnv}_clinic`, null);
    }

    // setCookieRelatedData(responseObj);
    redirectToLoggedInSite(responseObj, link_type, clinic_id);
  };
};

/**
 * @function showLoader
 * @param showLoadingState
 * @desc This function handles showing and hide loader, on full page, when user comes first time in the project
 */
export const showLoader = showLoadingState => {
  return {
    type: loginConstants.SHOW_LOADER,
    showLoadingState
  };
};

/**
 * @function redirectDirectToApp
 * @param responseObj
 * @desc This function handles redirecting user(consultant/ staff) to CMS directly,
 *  to whom Growth is not to be shown
 */
export const redirectDirectToApp = responseObj => {
  setCookieRelatedData(responseObj);

  // log mixpanel event
  // sign up and login events are being logged from backend itself, so removing it from here
  // if(fromAction) logEvent(responseObj, fromAction, appTypeParam);
  // if redirect url is present then go to that requested url
  // build url
  // Redirect to CMS directly for now for production

  // New changes:
  // Redirect user to "doctor web app", if user is consultant
  // New doctors signed up post new sign up flow launch will be redirected to doctor web app, for MR verification process

  // fetch user from responseObj
  const { user } = responseObj.data;

  const redirectUrl = createDomainLink(user);
  handleRedirection(redirectUrl);

  // Redirect user to "CMS", if user is clinic owner or staff
  // const clinicOwnerAccount = user.user_accounts.filter(ua => {
  //   return ua.account_type === "clinic" && ua.role === "owner";
  // });
  // if(clinicOwnerAccount.length > 0) redirectUrl = buildUrl("cms");

  // check if any return url is present in cookie, so that user can directly land into that return url
  // check if cookie exists "appenv_return_url"
  // const appEnv = getAppEnv();
  // const returnUrl = getCookie(`${appEnv}_return_url`);
  // if(returnUrl) window.open(returnUrl, "_self")
  // else window.open(`${redirectUrl}`, "_self");
};

const handleRedirection = redirectUrl => {
  localStorage.removeItem("referrer");
  showLoader(false);
  const appEnv = getAppEnv();
  const returnUrl = getCookie(`${appEnv}_return_url`);
  if (returnUrl) window.open(returnUrl, "_self");
  else window.open(`${redirectUrl}`, "_self");
};

export const redirectToPatientSite = async (fromSignup, responseObj) => {
  if (fromSignup) {
    mixpanel.track("Patient Onboarding Intiated");
    setCookie(`${getAppEnv()}_patient_onboard`, true);
  }
  setCookie(`${getAppEnv()}_is_patient`, true);
  setCookieRelatedData(responseObj);
  window.open(process.env.REACT_APP_PATIENT_SITE_BASE_URL, "_self");
};

/**
 * @function redirectToLoggedInSite
 * @param "responseObj", "link_type"
 * @desc This function handles redirecting user to respective page ("select-account/ CMS/ growth"),
 */
export const redirectToLoggedInSite = async (
  responseObj,
  link_type,
  clinic_id,
  fromAction
) => {
  // and then handle redirections
  let { user } = responseObj.data;
  const appEnv = getAppEnv();
  // const dummyClinicObj = {
  //   id: 201853,
  //   name: "Test Clinic",
  //   role: "owner",
  //   logo_url:
  //     "https://kulcare-stag-profile-images.s3.ap-south-1.amazonaws.com/clinic_logo_images/images/1657177312323_bellafill_email_modal.png",
  //   timezone: "Asia/Calcutta",
  //   virtual_clinic: false,
  //   ipd_enabled: true
  // };

  if(user?.dummy_clinic){
    setCookie(
      `${appEnv}_dummy_clinic`,
      JSON.stringify({
        ...user.dummy_clinic,
        id: user.dummy_clinic.clinic_id,
        name: user.dummy_clinic.clinic_name
      })
    );
  }
  

  // list all the clinic related to the user and set cookie related to "selectedClinic"
  // fetch clinic owner from "user" object
  let clinicOwnerAccounts = [];
  let staffAccounts = [];
  let consultantAccounts = [];
  let hospitalAdminAccounts = [];
  if (user.user_accounts.length > 0) {
    clinicOwnerAccounts = user.user_accounts.filter(ua => {
      return (
        ua.account_type === "clinic" &&
        ua.role === "owner" &&
        !ua.virtual_clinic
      );
    });

    staffAccounts = user.user_accounts.filter(ua => {
      return ua.account_type === "clinic" && ua.role === "staff";
    });

    hospitalAdminAccounts = user.user_accounts.filter(ua => {
      return ua.account_type === "clinic" && ua.role === "admin";
    });

    consultantAccounts = user.user_accounts.filter(ua => {
      return ua.account_type === "clinic" && ua.role === "consultant";
    });
  }

  // handle clinic creation if no user account or no clinic owner account is present and signup intent is that related to CMS
  if (
    // user.sign_up_intent &&
    // cmsIntentParams.includes(user.sign_up_intent) &&
    user.user_accounts.length === 0 ||
    (clinicOwnerAccounts.length === 0 &&
      staffAccounts.length === 0 &&
      hospitalAdminAccounts.length === 0 &&
      consultantAccounts.length === 0)
  ) {
    // create clinic first - this is to remove clinic creation step in CMS onboarding
    const finalObj = {
      clinic_details: {
        name: `Dr. ${user?.name}'s clinic`
      },
      onboarding: true,
      tkn: responseObj.auth_token
    };
    mixpanel.track("Onboarding Intiated");
    // hit the save profile api here
    const response = await createClinic(finalObj);

    if (response && response.data.success) {
      const clinicObj = response?.data?.data;
      user.user_accounts = clinicObj.user_accounts;
    }
  }

  const clinicAccounts = user.user_accounts.filter(ua => {
    return ua.account_type === "clinic";
  });

  const rollbarUserObj = {
    id: user.id,
    name: clinicAccounts.length > 0 ? clinicAccounts[0].clinic_name : ""
  };
  // set rollbar
  setRollbarProfile(rollbarUserObj);
  // log mixpanel event
  // sign up and login events are being logged from backend itself, so removing it from here
  // logEvent(responseObj, fromAction, appTypeParam, mixpanelFromParam);

  if (
    clinicOwnerAccounts.length === 1 ||
    user.signed_up_as === "hospital_clinic"
  ) {
    const selectedClinicObj = {
      id: clinicOwnerAccounts[0].clinic_id,
      name: clinicOwnerAccounts[0].clinic_name,
      role: clinicOwnerAccounts[0].role,
      logo_url: clinicOwnerAccounts[0].logo_url,
      timezone: clinicOwnerAccounts[0].timezone,
      virtual_clinic: clinicOwnerAccounts[0].virtual_clinic,
      ipd_enabled: clinicOwnerAccounts[0].ipd_enabled
    };

    // set the clinic cookie only if clinic owner account === 1, this is will fetched as
    // "selectedClinic" in other kulcare projects ("cms", "growth")
    // if clinic owner account is more than 1 then we need to show the "select-clinic" screen
    // so will need not set the selected clinic in cookie

    setCookie(`${appEnv}_clinic`, JSON.stringify(selectedClinicObj));
  }

  if (fromAction === "signup") setCookie(`${appEnv}_user_onboarded`, `true`);

  // check if user has clinic account and has doctors
  // if (clinicAccounts.length > 0) {
  //   // hit the api to check whether clinic doctor are added in the clinic or not
  //   const doctorsRes = await getClinicDoctors(
  //     clinicAccounts[0].clinic_id,
  //     responseObj.auth_token
  //   );
  //   // if clinic has doctors
  //   if (doctorsRes && doctorsRes.data.success) {
  //     clinicHasDoctors = true;
  //   }
  // }

  // if (isMobile && !isResponsiveWidth()) {
  //   if (clinicAccounts.length > 0 && clinicHasDoctors) {
  //     // if clinic account is created, and has doctors then directly go to magic link
  //     localStorage.setItem("user_email", user.email);
  //     const encryptedUserId = encryptId(user.id);
  //     history.push(
  //       `/magic-link?mobile=true&u=${encryptedUserId}&e=${user.email}`
  //     );
  //     return false;
  //   }
  // }

  const referrer = localStorage.getItem("referrer");
  let redirectUrl = "/";
  // in case user is not from mobile
  if (
    referrer !== "null" &&
    referrer !== "undefined" &&
    referrer !== null &&
    referrer !== undefined
  ) {
    // set the cookie related data first and then redirect to CMS site
    setCookieRelatedData(responseObj);
    // log mixpanel event

    // if redirect url is present then go to that requested url
    // build url
    redirectUrl = buildUrl(referrer);
    localStorage.removeItem("referrer");
    // window.open(`${redirectUrl}`, "_self");
    goToLoggedInUrl(redirectUrl);
  } else {
    if (link_type) {
      // set the cookie related data first and then redirect to "link_type" site
      setCookieRelatedData(responseObj);
      // if redirect url is present then go to that requested url
      // build url
      // Redirect to CMS directly for now for production
      redirectUrl = clinic_id
        ? buildUrl(link_type, clinic_id)
        : buildUrl(link_type);
      localStorage.removeItem("referrer");
      // log mixpanel event
      // logEvent(responseObj, fromAction, appTypeParam);
      // window.open(`${redirectUrl}`, "_self");
      goToLoggedInUrl(redirectUrl);
    } else {
      redirectDirectToApp(responseObj);
    }
  }
};

/**
 * @function logEvent
 * @param "responseObj", "fromAction", "appTypeParam"
 * @desc This function handles logging mixpanel event post login or signup
 */
export const logEvent = (
  responseObj,
  fromAction,
  appTypeParam,
  mixpanelFromParam = null
) => {
  // log mixpanel event
  if (fromAction == "login")
    logMixpanelEvent(
      "Logged In Successfully",
      null,
      null,
      null,
      responseObj.data.user.signed_up_as,
      responseObj.data.user.id
    );
  if (fromAction == "signup")
    logMixpanelEvent(
      "Signed Up Successfully",
      null,
      mixpanelFromParam,
      null,
      appTypeParam,
      responseObj.data.user.id
    );
};
