import React from "react";
import { Button, notification, Avatar, Icon } from "antd";
import { strings } from "./locales";
import { postFreshChatId, sendMixpanelData } from "../services";
import { history } from "./history";
import { getBrowserDetails } from "../helpers";
import NewsFeedImage from "../images/slider/newsfeed.png";
import CMSImage from "../images/slider/cms.png";
import GrowthImage from "../images/slider/cms.png";
import VideoCallImage from "../images/slider/free_tools_1.png";
import PaymentImage from "../images/slider/free_tools_2.png";
import RecordsImage from "../images/slider/free_tools_1.png";
import PrescriptionImage from "../images/slider/free_tools_2.png";
import DefaultImage from "../images/slider/free_tools_1.png";

/** @function hideLeftNav
 * @desc - This will return true of false
 */
export const hideLeftNav = () => {
  const path = window.location.pathname.replace("/", "");
  let hideNav = false;
  switch (path) {
    case "sign-in":
    case "sign-up":
    case "forgot-password":
    case "verify-phone-number":
    case "reset-password":
    case "select-clinic":
    case "verify-user":
    case "verify-clinic":
    case "onboard-success":
    case "clinic-signup-success":
    case "add-doctor":
    case "prescription-templates":
    case "prompt-for-web-view":
    case "growth-clinic-website-preview":
    case "growth-clinic-website-sample":
    case "magic-link":
    case "magic-link-sent":
    case "get-user-email":
    case "magic-link-signin":
    case "expired-magic-link":
      hideNav = true;
      break;
    default:
      hideNav = false;
  }
  return hideNav;
};

/**
 * @function isAllowedRoute
 * @desc - This function will check if user is
 *  allowed to see the route or not
 */
export const isAllowedRoute = () => {
  const urlsNotAllowedToStaff = [
    "billing",
    "history",
    "careplan",
    "onboard-success"
  ];
  const urlsNotAllowedToConsultant = ["settings", "billing", "profile"];

  let userAllowed = false;
  let currentPage = null;
  const pathName = window.location.pathname;
  const userRole = localStorage.getItem("user_role");
  // highlight navigation on pageload
  const pathStr = (pathName.match(new RegExp("/", "g")) || []).length;
  switch (pathStr) {
    case 1:
      currentPage = pathName.replace("/", "");
      break;
    case 2:
      currentPage = pathName.substring(1).split("/")[0];
      break;
    case 3:
      currentPage = pathName.substring(1).split("/")[1];
      break;
    default:
      break;
  }
  // if user role is not present
  if (!userRole) {
    return userAllowed;
  }

  // if role is staff
  if (
    (userRole === "staff" || userRole === "admin") &&
    urlsNotAllowedToStaff.indexOf(currentPage) === -1
  ) {
    userAllowed = true;
  }
  // if role is consultant
  if (
    userRole === "consultant" &&
    urlsNotAllowedToConsultant.indexOf(currentPage) === -1
  ) {
    userAllowed = true;
  }
  // if role is owner
  if (userRole === "owner") {
    userAllowed = true;
  }
  return userAllowed;
};

/**
 * @function userRole
 * @desc - This function gets the user role
 */
export const userRole = () => localStorage.getItem("user_role");

export const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item]
    }),
    {}
  );

/** *
 * @function setFreshChatUser
 * @param clinic
 * @desc This method will set fresh chat user
 */
export const setFreshChatUser = clinic => {
  // window.fcWidget.init({
  //   restoreId: clinic.chat_identifier || null,
  //   token: `${process.env.REACT_APP_FRESHCHAT_TOKEN}`,
  //   host: "https://wchat.freshchat.com",
  //   externalId: clinic.id || clinic.clinic_id, // user’s id unique to your system
  //   firstName: clinic.name || clinic.clinic_name, // user’s first name
  //   phone: clinic.phone_number,
  //   phoneCountryCode: clinic.country_code,
  // });
  // window.fcWidget.user.get(resp => {
  //   const userStatus = resp && resp.status;
  //   if (userStatus !== 200) {
  //     window.fcWidget.on("user:created", res => {
  //       const reqStatus = res && res.status;
  //       const data = res && res.data;
  //       if (reqStatus === 200) {
  //         if (data.restoreId) {
  //           postFreshChatId(clinic.id || clinic.clinic_id, data.restoreId);
  //           window.fcWidget.user.setProperties({
  //             firstName: clinic.name || clinic.clinic_name, // user’s first name,
  //             phone: clinic.phone_number,
  //             phoneCountryCode: clinic.country_code
  //           });
  //         }
  //       }
  //     });
  //   }
  // });
  // // open chat popup
  // if (localStorage.getItem("open_chat_popup") === "true") {
  //   setTimeout(() => {
  //     window.fcWidget.open();
  //   }, 3000);
  //   localStorage.setItem("open_chat_popup", false);
  // }
};

/** *
 * @function clearFreshChatUser
 * @desc This method will clear fresh chat user
 */
export const clearFreshChatUser = () => {
  // if (window.fcWidget && window.fcWidget.user) {
  //   window.fcWidget.user.clear();
  //   window.fcWidget.destroy();
  // }
};

const goToSetUp = () => {
  if (localStorage.getItem("logged_in_as_clinic_owner_for_growth") === "true") {
    history.push("/growth/setup");
  } else {
    history.push("/set-up");
  }
};

/** *
 * @function clearFreshChatUser
 * @desc This method will return back button
 */
export const backToSetupBtn = () => {
  return (
    <Button
      type="primary"
      size="small"
      className="mb-10"
      onClick={() => {
        goToSetUp();
      }}
    >
      <Icon type="left" /> {strings.careplan.back_to_setup}
    </Button>
  );
};

/** *
 * @function clearFreshChatUser
 * @desc This method will show back to setp btn
 */
export const showBackToSetup = (_this, stateObj) => {
  const showBack = localStorage.getItem("back_to_setup");
  if (showBack && showBack === "true") {
    _this.setState({
      [stateObj]: true
    });
    localStorage.removeItem("back_to_setup");
  }
};

/** *
 * @function clearFtruncateTextreshChatUser
 * @desc This method will truncate text
 */
export const truncateText = (text, truncateLength = 100) => {
  const truncated = {
    less: "",
    more: "",
    full: text
  };
  if (text.length > truncateLength) {
    truncated.less = text.substring(0, truncateLength);
    truncated.more = text.substring(truncateLength, text.length);
  } else {
    truncated.less = text;
  }

  return truncated;
};

/** *
 * @function showPrompt
 * @desc This method will show propmt
 */
export const showPrompt = (text, handleClick) => {
  notification.destroy();
  notification.open({
    description: (
      <div className="prompt-content-wrapper">
        <div className="prompt-top">
          <Avatar src="https://randomuser.me/api/portraits/men/78.jpg" />{" "}
          <b>Lakshay&nbsp;</b> from Kulcare
        </div>
        <div className="prompt-content">{text}</div>
        <div className="prompt-link text-center mt-20">
          <Button type="primary">Check it out</Button>
        </div>
      </div>
    ),
    className: "custom-prompt",
    duration: 0,
    placement: "bottomLeft",
    style: {
      width: 300,
      paddingTop: "0px"
    }
  });
};

/**
 * @function logMixpanelEvent
 * @description This function logs events to mixpanel
 */
export const logMixpanelEvent = (
  eventName,
  typeOfEvent = null,
  fromParam = null,
  taskParam = null,
  appParam = null,
  userId = null
) => {
  const mixpanelData = getBrowserDetails(userId);
  if (typeOfEvent) {
    mixpanelData.type = typeOfEvent;
  }

  if (fromParam) {
    mixpanelData.from = fromParam;
  }
  if (taskParam) {
    mixpanelData.task = taskParam;
  }
  if (appParam) {
    mixpanelData.app = appParam;
  }

  sendMixpanelData(eventName, mixpanelData.user_id, mixpanelData);
};

/**
 * @function ActionableIntents
 * @description These are actionable intents
 */
export const ActionableIntents = [
  "nwf",
  "cms",
  "grt",
  "ft_vdc",
  "ft_pyt",
  "ft_rcs",
  "ft_erx",
  "ft_all",
  "cos",
  "t7y",
  "d13t",
  "p10s",
  "c10h",
  "psychsignup"
];

/**
 * @function ActionableIntentsWithImages
 * @description These are actionable intents with images array
 */
export const ActionableIntentsWithImages = [
  { name: "nwf", image: NewsFeedImage },
  { name: "cms", image: CMSImage },
  { name: "grt", image: GrowthImage },
  { name: "ft_vdc", image: VideoCallImage },
  { name: "ft_pyt", image: PaymentImage },
  { name: "ft_rcs", image: RecordsImage },
  { name: "ft_erx", image: PrescriptionImage },
  { name: "ft_all", image: PrescriptionImage },
  { name: "cos", image: PrescriptionImage },
  { name: "t7y", image: PrescriptionImage },
  { name: "p10s", image: PrescriptionImage },
  { name: "d13t", image: PrescriptionImage },
  { name: "c10h", image: PrescriptionImage }
];

/**
 * @function ActionableIntentsWithDesc
 * @description These are actionable intents with desc array
 */
export const ActionableIntentsWithDesc = [
  {
    name: "nwf",
    text: "Complete sign up to get your personalized newsfeed!"
  },
  { name: "cms", text: "Complete sign up and start managing your practice!" },
  {
    name: "grt",
    text: "Complete sign up and start growing your practice today!"
  },
  {
    name: "ft_vdc",
    text: "Complete sign up and start taking virtual consultations!"
  },
  {
    name: "ft_pyt",
    text: "Complete sign up and start collecting payments!"
  },
  {
    name: "ft_rcs",
    text: "Complete sign up to get your records intake tools!"
  },
  {
    name: "ft_erx",
    image: "Complete sign up and start writing digital prescriptions!"
  },
  {
    name: "ft_all",
    image: "Complete sign up and start writing digital prescriptions!"
  },
  {
    name: "cos",
    image: "Complete sign up and start writing digital prescriptions!"
  },
  {
    name: "c10h",
    image: "Get listed as doctor on web!"
  }
];

/**
 * @function authImageSrc
 * @description This will return auth image
 */
export const authImageSrc = intent => {
  let authImage = DefaultImage;
  if (ActionableIntents.includes(intent)) {
    const index = ActionableIntentsWithImages.findIndex(
      item => item.name === intent
    );
    authImage = ActionableIntentsWithImages[index].image;
  }
  return authImage;
};

/**
 * @function signupDescription
 * @description This will return sign description
 */
export const signupDescription = intent => {
  let text = "Complete sign up to access all your tools!";
  if (ActionableIntents.includes(intent)) {
    const index = ActionableIntentsWithDesc.findIndex(
      item => item.name === intent
    );
    text = ActionableIntentsWithDesc[index].text;
  }
  return text;
};
