import React, { Component } from "react";
import { strings } from "../../../helpers";
import { IoLogoApple, IoLogoGooglePlaystore } from "react-icons/io5";
class FooterView extends Component {
  render() {
    return (
      <div className="footer">
        <div className="top-nav-container">
          <div className="footer-inner">
            <div className="copyright_text">
              © 2021 kulcare. All rights reserved
            </div>
            <div className="footer-links">
              <div className="download_text">Download mobile app</div>
              <a
                href="https://apps.apple.com/in/app/kulcare-for-doctors/id1256481906"
                target="_blank"
              >
                <IoLogoApple
                  className="font-color-primary vertically-middle"
                  size={"25px"}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.kulcare.doctors"
                target="_blank"
              >
                <IoLogoGooglePlaystore
                  className="font-color-primary vertically-middle"
                  size={"25px"}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default FooterView;
