import React, { Component } from "react";
import AppContent from "../body/AppContent";
import "./app.css";
import { history, getQueryParams } from "../../helpers";

export default class AppBody extends Component {
  componentDidMount() {
    const urlParams = getQueryParams(window.location.search);
    const shortLinkParam = urlParams["s"];
    if (shortLinkParam) {
      history.push(`/k?c=${shortLinkParam}`);
    }
  }

  render() {
    return <AppContent currentLanguage={this.props.currentLanguage} />;
  }
}
