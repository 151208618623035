import React, { Component } from "react";
import { Col, Row } from "antd";
import { strings } from "../../helpers";

class ExportPatientDataView extends Component {
  render() {
    const { showErrorBlock, errorMsg, successMsg } = this.props;

    return (
      <div className="auth-form">
        <Row
          type="flex"
          justify="center"
          align="middle"
          className="auth-form-row mt-100"
        >
          <Col>
            <div className="download-success-wrapper">
              {!showErrorBlock && (
                <>
                  <div className="big-heading-text text-center">
                    <svg
                      class="checkmark"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 52 52"
                    >
                      <circle
                        class="checkmark__circle"
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                      />
                      <path
                        class="checkmark__check"
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      />
                    </svg>
                  </div>
                  <p className="text-center mt-10">{successMsg}</p>
                </>
              )}

              {showErrorBlock && (
                <>
                  <div className="big-heading-text text-center">
                    <svg
                      class="cross_mark"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 130.2 130.2"
                    >
                      <circle
                        class="path circle"
                        fill="none"
                        stroke="#f63774"
                        stroke-width="6"
                        stroke-miterlimit="10"
                        cx="65.1"
                        cy="65.1"
                        r="62.1"
                      />
                      <line
                        class="path line"
                        fill="none"
                        stroke="#f63774"
                        stroke-width="6"
                        stroke-linecap="round"
                        stroke-miterlimit="10"
                        x1="34.4"
                        y1="37.9"
                        x2="95.8"
                        y2="92.3"
                      />
                      <line
                        class="path line"
                        fill="none"
                        stroke="#f63774"
                        stroke-width="6"
                        stroke-linecap="round"
                        stroke-miterlimit="10"
                        x1="95.8"
                        y1="38"
                        x2="34.4"
                        y2="92.2"
                      />
                    </svg>
                  </div>
                  <p className="text-center mt-10">
                    {errorMsg == "verify_code"
                      ? strings.errorInDownloading
                      : strings.fileExistError}
                  </p>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ExportPatientDataView;
