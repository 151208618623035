import React from "react";
import { notification, Icon } from "antd";
import { strings } from "./locales";

/**
 * @function showInfoNotification
 * @param title
 * @param description
 * @description Show Notification for showing info
 */
export const showInfoNotification = (description, title) => {
  notification.destroy();
  notification.info({
    message: title || strings.info_title,
    description,
    duration: 8
  });
};

/**
 * @function showSuccessNotification
 * @param description
 * @description Show Notification for showing success message to user
 */
export const showSuccessNotification = (description, duration) => {
  notification.destroy();
  notification.success({
    message: strings.success_title,
    description,
    duration: duration ? duration : 4.5
  });
};

/**
 * @function showErrorNotification
 * @param description
 * @description Show Notification for showing error message to user
 */
export const showErrorNotification = description => {
  notification.destroy();
  notification.error({
    message: strings.error_title,
    description,
    icon: <Icon />
  });
};
