import React from "react";
import { Input, Popover, Icon } from "antd";
import { strings } from "./locales/index";

export class PasswordInput extends React.Component {
  state = {
    isPasswordLengthValid: false,
    // passwordHasUppercase: false,
    hasNumericOrSpecialChar: false,
    passwordEntered: ""
  };

  /**
   * @function checkPasswordLength
   * @param value
   * @desc This method checks if password length is greater than 8
   */
  checkPasswordLength = value => {
    let isValid = false;
    if (value.length > 8) {
      isValid = true;
    }
    return isValid;
  };

  /**
   * @function passwordContainsUppercaseLetter
   * @param value
   * @desc This method checks if password contains any uppercase letter.
   */
  passwordContainsUppercaseLetter = value => {
    const upperCaseRegex = /(?=.*[A-Z])/;
    return upperCaseRegex.test(value);
  };

  /**
   * @function passwordContainsNumber
   * @param value
   * @desc This method checks if password contains any number
   */
  passwordContainsNumber = value => {
    const digitsRegex = /(?=.*\d)/;
    return digitsRegex.test(value);
  };

  /**
   * @function passwordContainsSpecialChars
   * @param value
   * @desc This method checks if password contains any special character
   */
  passwordContainsSpecialChars = value => {
    const specialCharRegex = /[^\w\s]/gi;
    return specialCharRegex.test(value);
  };

  /**
   * @function handleChange
   * @param e
   * @desc This method handles input changes in password field
   */
  handleChange = e => {
    const { value } = e.target;
    const isPasswordLengthValid = this.checkPasswordLength(value);
    this.setState({ isPasswordLengthValid });

    // const passwordHasUppercase = this.passwordContainsUppercaseLetter(value);
    // this.setState({ passwordHasUppercase });

    const passwordHasNumber = this.passwordContainsNumber(value);
    const passwordHasSpecialChar = this.passwordContainsSpecialChars(value);

    const hasNumericOrSpecialChar = passwordHasNumber || passwordHasSpecialChar;
    this.setState({ hasNumericOrSpecialChar, passwordEntered: value });

    this.props.onChange(e);
  };

  /**
   * @function passwordStrengthContent
   * @desc This function will generate
   *  content of password strength popover
   */
  passwordStrengthContent = () => (
    <ul className="password-list">
      <li>
        <div
          className={`hint-icon ${
            this.state.isPasswordLengthValid ? "checked-icon" : "close-icon"
          }`}
        />

        {strings.signup.password_must_1}
      </li>
      {/* <li>
        <div
          className={`hint-icon ${
            this.state.passwordHasUppercase ? "checked-icon" : "close-icon"
          }`}
        />
        {strings.signup.password_must_2}
      </li> */}
      <li>
        <div
          className={`hint-icon ${
            this.state.hasNumericOrSpecialChar ? "checked-icon" : "close-icon"
          }`}
        />
        {strings.signup.password_must_3}
      </li>
    </ul>
  );

  render() {
    return (
      <Popover
        content={this.passwordStrengthContent()}
        title={strings.signup.password_must_be}
        trigger="focus"
        placement={this.props.placement}
        getPopupContainer={this.props.getpopupcontainer}
        // overlayClassName={this.props.overlayClassName}
        overlayClassName="wizard-popover"
        visible={
          (!this.state.isPasswordLengthValid ||
            !this.state.hasNumericOrSpecialChar) &&
          this.state.passwordEntered
        }
      >
        <Input
          {...this.props}
          type="text"
          placeholder={this.props.placeholdertext}
          name={this.props.nametext}
          onChange={this.handleChange}
          autoComplete={this.props.autocompletetext}
        />
      </Popover>
    );
  }
}
