import axios from "axios";
import { error } from "react-notification-system-redux";
import { logoutAction } from "../components/login/loginActions";
import { showInfoNotification, strings } from "../helpers";

/**
 * @function InterceptorService
 * @param props
 * @desc The request interceptor blocks invalid outgoing requests to avoid 403 or 401 errors
 * The response interceptor catches the errors and shows it on UI in the form of notifications
 */

const InterceptorService = props => {
  // Request interceptor
  axios.interceptors.request.use(
    function(config) {
      //Ignore the listed URLs
      let skipUrls = [
        "auth/sign_up",
        "auth/sign_in",
        "auth/verify_user",
        "auth/resend_verification_code",
        "auth/reset_password",
        "auth/verify_reset_password_code",
        "/auth/change_password",
        "users/user_exists"
      ];

      const url = config.url;

      for (var i = 0; i < skipUrls.length; i++) {
        if (url.indexOf(skipUrls[i]) > -1) {
          return config;
        }
      }

      // skip url that has "export_files" in the url
      if(url.indexOf("export_files") !== -1){
        return config;
      }

      // Block the requests which do not have Authorization in request headers
      if (config && config.headers && config.headers["Authorization"] !== undefined) {
        return config;
      } else {
        return Promise.reject(error);
      }
    },
    function(error) {
      // Catch errors
      return Promise.reject(error);
    }
  );

  // Response interceptor
  axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(errorMsg) {
      if (
        // Logout on 401 and 403 errors
        (errorMsg.response && errorMsg.response.status === 401) ||
        (errorMsg.response && errorMsg.response.status === 403)
      ) {
        props.dispatch(logoutAction);
        showInfoNotification(strings.signInContinue, strings.unauthorized);
        return Promise.reject(errorMsg);
      } else {
        return Promise.reject(errorMsg);
      }
    }
  );
};
export { InterceptorService };
