import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Form, Alert, Button } from "antd";
import { strings } from "../../helpers";
import NumericInput from "../../helpers/NumericInput";

const FormItem = Form.Item;

class ForgotPasswordView extends Component {
  render() {
    const { props } = this;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="auth-form">
        <Row
          type="flex"
          justify="center"
          align="middle"
          className="auth-form-row"
        >
          <Col xs={20} md={12} sm={16} lg={8}>
            <div className="login-form-wrapper">
              <h2 className="login-title text-center">
                {strings.forgot_password}
              </h2>
              <h4 className="login-sub-title text-center">
                {strings.forgotPassword.forgot_heading_text}
              </h4>
              <div className="form-alerts-wrapper">
                {props.error && props.errorMsg && (
                  <Alert
                    type="error"
                    message={strings[props.errorMsg]}
                    showIcon
                  />
                )}
                {props.serverError && props.forgotPasswordError && (
                  <Alert
                    type="error"
                    message={strings.account_error}
                    showIcon
                  />
                )}
              </div>

              <Form onSubmit={props.handleSubmit} className="login-form">
                <FormItem>
                  {getFieldDecorator("phoneNumber", {
                    initialValue: `${props.phoneNumber}`,
                    rules: [
                      {
                        required: true,
                        message: `${strings.signup.enter_phone_number}`
                      }
                    ]
                  })(
                    <NumericInput
                      name="phoneNumber"
                      onChange={props.handleChange}
                      onBlur={props.checkUserExistence}
                      placeholder={strings.phone_number}
                    />
                  )}
                </FormItem>
                <FormItem>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={24}>
                      <Button
                        loading={props.isProcessing}
                        type="primary"
                        htmlType="submit"
                        className="login-form-button text-uppercase"
                      >
                        {strings.next}
                      </Button>
                    </Col>
                    <Col span={24}>
                      <h4 className="login-sub-title text-center">
                        {strings.signup.have_account}
                        <Link to="sign-in"> {strings.sign_in}</Link>
                      </h4>
                    </Col>
                  </Row>
                </FormItem>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Form.create()(ForgotPasswordView);
