import React, { Component } from "react";
import { Spin } from "antd";
import { getExpandedUrl } from "../../services";
import qs from "qs";

import { showErrorNotification, history } from "../../helpers";
import ExpiredShortLinkView from "./ExpiredShortLinkView";

export default class ShortLinkContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isProcessing: true,
      isError: false
    };
  }

  componentDidMount() {
    // check if "s" short link paramter is present in the url
    // then, redirect user to "/k" route
    // so that the link can be expanded
    // and be redirected to generated expanded url
    const url = qs.parse(
      this.props.location && this.props.location.search.slice(1)
    );
    const urlParam = url && (url.s || url.c);
    if(urlParam) this.handleShortLink(urlParam);
  }

  /**
   * @function handleShortLink
   * @param urlId
   * @desc function returns link
   */
  handleShortLink = urlId => {
    this.setState({ isProcessing: true });
    getExpandedUrl(urlId)
      .then(response => {
        this.setState({ isProcessing: false });
        if (response && response.data.success) {
          const { data } = response.data;
          window.location.replace(data);
        } else {
          this.setState({ isError: true });
        }
      })
      .catch(() => {
        this.setState({ isError: true, isProcessing: false });
      });
  };
  render() {
    const { isProcessing, isError } = this.state;
    
    return (
      <div>
        {isProcessing && !isError && (
          <div className="page-loader">
            <div className="lds-roller">
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        )}
        {isError && <ExpiredShortLinkView />}
      </div>
    );
  }
}
