import React, { Component } from "react";
import { Layout } from "antd";
import { connect } from "react-redux";
import { logoutAction } from "../login/loginActions";

import TopNav from "./TopNav";

const { Header } = Layout;
class AppHeader extends Component {
  render() {
    const { showOnlyLogo, hideExtraTopNavOptions, authPagesRendered } = this.props;
    return (
      <>
      {
        authPagesRendered ? 
          <TopNav
            changeLanguage={this.props.changeLanguage}
            currentLanguage={this.props.currentLanguage}
            showHeaderForClinic={this.props.showHeaderForClinic}
            currentPage={this.props.currentPage}
            showOnlyLogo={showOnlyLogo}
            hideExtraTopNavOptions={hideExtraTopNavOptions}
            logout={this.props.logout}
            authPagesRendered={authPagesRendered}
          />: 
        <Header>
          <TopNav
            changeLanguage={this.props.changeLanguage}
            currentLanguage={this.props.currentLanguage}
            showHeaderForClinic={this.props.showHeaderForClinic}
            currentPage={this.props.currentPage}
            showOnlyLogo={showOnlyLogo}
            hideExtraTopNavOptions={hideExtraTopNavOptions}
            logout={this.props.logout}
            authPagesRendered={authPagesRendered}
          />
        </Header>
      }
      </>
      
    );
  }
}

const mapStateToProps = state => {
  return {
    isProcessing: state.login.isProcessing
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logoutAction)
  };
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppHeader);
export { connectedPage as AppHeader };
