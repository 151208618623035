import React, { Component } from "react";
import qs from "qs";
import _ from "lodash";
import { Modal } from "antd";
import {
  redirectToPatientSite,
  redirectToLoggedInSite
} from "./ClinicLoginActions";
import ClinicLoginView from "./ClinicLoginView";
import {
  history,
  decryptId,
  setPageMetaDesc,
  encryptId
} from "../../../helpers";
import { postLogin } from "../../../services";
import { FaHospital, FaUserAlt } from "react-icons/fa";

class ClinicLoginContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneNumber: "",
      password: "",
      error: false,
      errorMsg: "",
      serverErrorText: "",
      serverError: false,
      utmSource: null,
      utmMedium: null,
      utmContent: null,
      utmCampaign: null,
      inviteCode: null,
      doctorName: null,
      docId: null,
      signupIntent: null,
      testValue: null,
      isSignedIn: false,
      showSelectAccountModal: false,
      signInResponse: null,
      accountType: null
    };
  }

  componentDidMount() {
    document.title = "Sign In";
    // set meta description for login page
    setPageMetaDesc("Sign in to Kulcare");
    this.fetchUTMParams();
  }

  /**
   * @function loginAction
   * @param phoneNumber
   * @param password
   * @desc This logins the user.
   */
  loginAction = (phoneNumber, password, utmParams) => {
    const countryCode = "91";
    this.setState({ isProcessing: true });
    // if the user has signed in from mobile, then prompt user to continue rest of the app through web
    postLogin(phoneNumber, password, utmParams).then(response => {
      // login successful
      this.setState({ isProcessing: false });
      if (response.data && response.data.data && response.data.data.user) {
        const responseObj = response.data;
        let userAccounts = responseObj?.data?.user?.user_accounts;

        // if (process.env.REACT_APP_ENVIRONMENT !== "production") {
        //   if (!responseObj?.data?.user?.pwd_set_by_user) {
        //     const id = encryptId(responseObj?.data?.user?.id);
        //     localStorage.setItem("atk", responseObj?.auth_token);
        //     history.push(`/reset-password-for-clinic?id=${id}`);
        //   } else redirectToLoggedInSite(responseObj, null, null, "login");
        // } else {
        const patientAccount = userAccounts?.filter(obj => {
          if (obj.account_type === "patient") return obj;
        });

        if (userAccounts.length > 1 && patientAccount.length > 0) {
          this.setState({
            showSelectAccountModal: true,
            signInResponse: responseObj
          });
        } else if (!responseObj?.data?.user?.pwd_set_by_user) {
          const id = encryptId(responseObj?.data?.user?.id);
          localStorage.setItem("atk", responseObj?.auth_token);
          localStorage.setItem("pwd_set_by_user", responseObj?.data?.user?.pwd_set_by_user)
          history.push(`/reset-password-for-clinic?id=${id}`);
        } else if (responseObj?.data?.user?.signed_up_as === "patient") {
          redirectToPatientSite(false, responseObj);
        } else redirectToLoggedInSite(responseObj, null, null, "login");
        // }
      } else if (response.data && response.data.errors) {
        this.processErrors(response);
      }
    });
  };

  /**
   * @function fetchUTMParams
   * @desc This funtion fetches utm params in url
   */
  fetchUTMParams = () => {
    const params = qs.parse(this.props.location.search.slice(1));

    if (params && !_.isEmpty(params)) {
      const {
        utm_source,
        utm_medium,
        utm_content,
        utm_campaign,
        kic,
        dname,
        did,
        initiated_from,
        su_int,
        a6t,
        acc
      } = params;
      this.setState({
        utmSource: utm_source || null,
        utmMedium: utm_medium || null,
        utmContent: utm_content || null,
        utmCampaign: utm_campaign || null,
        inviteCode: kic || null,
        doctorName: dname || null,
        docId: did ? decryptId(did) : null,
        signupIntent: su_int || null,
        testValue: a6t || null,
        accountType: acc || null
      });
    }
  };

  /**
   * @function handleChange
   * @param e
   * @desc This handles the phone number and password input changes
   */
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value.replace(/\s/g, ""),
      serverErrorText: ""
    });
  };

  /**
   * @function setStateForInvalidData
   * @param errorText
   * @return nothing
   * @desc This method sets error messages state
   */
  setStateForInvalidData = errorText => {
    this.setState({
      //
      serverErrorText: errorText
    });
  };

  /**
   * @function handleSubmit
   * @param e
   * @desc This handles the submission of login parameters, calls login action
   */
  handleSubmit = e => {
    e.preventDefault();
    const {
      phoneNumber,
      password,
      utmSource,
      utmMedium,
      utmContent,
      utmCampaign
    } = this.state;
    let utmParams = null;
    if (!phoneNumber || !password) {
      this.setStateForInvalidData("Mobile number and password are required");
      return;
    }

    if (phoneNumber && phoneNumber.length < 10) {
      this.setStateForInvalidData("Enter valid mobile number");
      return;
    }

    if (password && password.length < 8) {
      this.setStateForInvalidData("Password is too short");
      return;
    }

    if (utmSource || utmMedium || utmContent || utmCampaign) {
      utmParams = {
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
        utm_content: utmContent,
        param_type: "utm"
      };
    }

    if (phoneNumber && password) {
      this.loginAction(phoneNumber, password, utmParams);
    }
  };

  /**
   * @function processErrors
   * @param errors
   * @param nothing
   * @desc This handles setting state
   *  of variable which
   * is to be shown to the user
   *  when api returns some error
   */
  processErrors = errors => {
    switch (errors.data.errors[0].source) {
      case "password":
        break;
      case "username_or_password":
      case "mobile_number_or_password":
        this.setState({
          serverErrorText: errors.data.errors[0].title
        });
        break;
      case "account":
        this.setState({
          isSignedIn: true
        });
        break;
      default:
        this.setState({
          serverErrorText: errors.data.errors[0].title
        });
        break;
    }
  };

  /**
   * @function goToSignIn
   * @desc This function handles redirection to given page
   */
  redirectUserToPage = (e, pageString) => {
    if (e) e.preventDefault();
    let redirectUrl = `/`;
    switch (pageString) {
      case "signup":
        redirectUrl = `/sign-up`;
        break;
      case "forgot":
        redirectUrl = `/forgot-password-for-clinic`;
        break;
      default:
        break;
    }

    const {
      utmSource,
      utmMedium,
      utmContent,
      utmCampaign,
      inviteCode,
      doctorName,
      docId,
      signupIntent,
      testValue,
      accountType
    } = this.state;
    if (utmSource) redirectUrl = `${redirectUrl}?utm_source=${utmSource}`;
    if (utmMedium) redirectUrl = `${redirectUrl}&utm_medium=${utmMedium}`;
    if (utmContent) redirectUrl = `${redirectUrl}&utm_content=${utmContent}`;
    if (utmCampaign) redirectUrl = `${redirectUrl}&utm_campaign=${utmCampaign}`;
    if (inviteCode) redirectUrl = `${redirectUrl}?kic=${inviteCode}`;
    if (doctorName) redirectUrl = `${redirectUrl}?dname=${doctorName}`;
    if (docId)
      redirectUrl = `${redirectUrl}${doctorName ? "&" : "?"}did=${docId}`;
    if (signupIntent) {
      redirectUrl = redirectUrl.includes("?")
        ? `${redirectUrl}&su_int=${signupIntent}`
        : `${redirectUrl}?su_int=${signupIntent}`;
    }
    if (testValue) {
      redirectUrl = redirectUrl.includes("?")
        ? `${redirectUrl}&a6t=${testValue}`
        : `${redirectUrl}?a6t=${testValue}`;
    }
    if (accountType) {
      redirectUrl = redirectUrl.includes("?")
        ? `${redirectUrl}&acc=${accountType}`
        : `${redirectUrl}?acc=${accountType}`;
    }
    history.push(redirectUrl);
  };

  toggleModal = () => {
    this.setState({ showSelectAccountModal: false });
  };

  render() {
    let fromStaff = false;

    if (this.props.location) {
      fromStaff = this.props.location.pathname === "/sign-in/fs";
    }
    const {
      isSignedIn,
      countryCode,
      showSelectAccountModal,
      signInResponse,
      accountType
    } = this.state;
    return (
      <>
        <ClinicLoginView
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          isProcessing={this.state.isProcessing}
          error={this.state.error}
          errorMsg={this.state.errorMsg}
          phoneNumber={this.state.phoneNumber}
          password={this.state.password}
          loginError={this.props.loginError}
          serverError={this.state.serverError}
          serverErrorText={this.state.serverErrorText}
          fromStaff={fromStaff}
          redirectUserToPage={this.redirectUserToPage}
          isSignedIn={isSignedIn}
          countryCode={countryCode}
          accountType={accountType}
        />
        <Modal
          title={null}
          visible={showSelectAccountModal}
          footer={null}
          onCancel={this.toggleModal}
          className="kc-modal-medium no-footer text-type-modal"
          centered
        >
          <div className="website-modal-wrapper">
            <div className="heading text-center mb-20">
              Choose an account to continue with
            </div>
            <div className="description mb-0">
              <ul className="account-types-list">
                <li>
                  <div
                    onClick={e => {
                      if (e) e.preventDefault();
                      redirectToLoggedInSite(
                        signInResponse,
                        null,
                        null,
                        "login"
                      );
                    }}
                  >
                    <div className="icon-box">
                      <FaHospital size={30} color="#656565" />
                    </div>
                    <div>Practice</div>
                  </div>
                </li>
                <li>
                  <div
                    onClick={e => {
                      if (e) e.preventDefault();
                      redirectToPatientSite(false, signInResponse);
                    }}
                  >
                    <div className="icon-box">
                      <FaUserAlt size={30} color="#656565" />
                    </div>
                    <div>Patient</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
export default ClinicLoginContainer;
