import { loginConstants } from "./ClinicLoginConstants";

export const clinicLoginReducer = (
  state = { userSignedIn: false, hasClinicAccount: false },
  action
) => {
  switch (action.type) {
    case loginConstants.LOGIN_PROCESSING:
      return {
        ...state,
        isProcessing: action.isProcessing
      };
    case loginConstants.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user
      };
    case loginConstants.USER_LOGGED_IN:
      return {
        ...state,
        userSignedIn: action.userSignedIn
      };
    case loginConstants.LOGIN_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case loginConstants.HAS_CLINIC_ACCOUNT:
      return {
        ...state,
        hasClinicAccount: action.hasClinicAccount
      };
    case loginConstants.SHOW_LOADER:
      return {
        ...state,
        showLoadingState: action.showLoadingState
      };
    default:
      return state;
  }
};
