import React, { Component } from "react";
import { connect } from "react-redux";
import mixpanel from "mixpanel-browser";
import {
  verifyUserAction,
  resendVerificationCodeAction
} from "./VerifyClinicActions";
import { strings } from "../../../helpers";
import VerificationView from "./VerificationView";

class VerifyClinicContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verificationCode: "",
      error: false,
      errorMsg: "",
      phoneNumber: "",
      countryCode: "91",
      showSendSuccessMsg: false,
      resendServerError: false,
      verifyServerError: false,
      showTimer: true,
      timerCount: 60,
      emailId: null,
      mixpanelFromParam: ""
    };
  }

  componentDidMount() {
    this.onTimer();
    // const params = qs.parse(this.props.location.search.slice(1));
    // const phoneNumber = params.ph;
    // const countryCode = params.cc;
    // const emailId = params.eid || null;
    // const mixpanelFromParam = params.event_type || "";
    this.setState({
      phoneNumber: this.props.phoneNumber,
      countryCode: this.state.countryCode,
      emailId: this.props.userEmail,
      mixpanelFromParam: this.props.eventType
    });
    // log mixpanel event
    mixpanel.track("OTP Screen Viewed");
  }

  /**
   * @function handleChange
   * @param e
   * @return nothing
   * @desc This method handles input changes in verification code screen
   */
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value.replace(/\s/g, ""),
      error: false,
      errorMsg: "",
      verifyServerError: false,
      resendServerError: false
    });
  };

  handleOTPChange = otp => {
    this.setState({
      verificationCode: otp,
      error: false,
      errorMsg: "",
      verifyServerError: false,
      resendServerError: false
    });
  };

  /**
   * @function onTimer
   * @desc This method shows the timer for 60 seconds
   */
  onTimer = () => {
    const timer = setInterval(() => {
      let count = this.state.timerCount;
      count -= 1;
      if (count < 0) {
        this.setState({
          showTimer: false,
          timerCount: 60
        });
        clearInterval(timer);
      } else {
        this.setState({
          timerCount: count
        });
      }
    }, 1000);
  };

  /**
   * @function setStateForInvalidData
   * @param errorText
   * @return nothing
   * @desc This method sets error messages state
   */
  setStateForInvalidData = errorText => {
    this.setState({
      error: true,
      errorMsg: errorText,
      showSendSuccessMsg: false,
      verifyServerError: false,
      resendServerError: false
    });
  };

  /**
   * @function handleSubmit
   * @param e
   * @return nothing
   * @desc This method checks for valid input and
   *  submits verify code for verification
   */
  handleSubmit = e => {
    e.preventDefault();
    const {
      verificationCode,
      phoneNumber,
      countryCode,
      mixpanelFromParam
    } = this.state;
    const { accountTypePatient } = this.props;

    if (!verificationCode) {
      this.setStateForInvalidData(strings.empty_code_error);
      return;
    }

    if (phoneNumber && countryCode && verificationCode) {
      this.props.verifyUser(
        phoneNumber,
        countryCode,
        verificationCode,
        mixpanelFromParam,
        accountTypePatient
      );
    } 
    else {
      this.setStateForInvalidData(strings.param_missing);
    }
  };

  /**
   * @function handleResendCode
   * @param e
   * @return nothing
   * @desc This method handles resend verification code
   */
  handleResendCode = e => {
    e.preventDefault();
    const { phoneNumber, countryCode } = this.state;
    this.props.resendCode(phoneNumber, countryCode);
  };

  processErrors = errorObj => {
    switch (errorObj.source) {
      case "account":
        this.setState({ errorMsg: strings.alreadyVerified });
        break;
      default:
        this.setState({ errorMsg: strings.invalid_verify_code });
        break;
    }
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.verifyUserError &&
      newProps.verifyUserError !== this.props.verifyUserError
    ) {
      this.setState({
        error: false,
        errorMsg: "",
        showSendSuccessMsg: false,
        verifyServerError: true,
        resendServerError: false
      });
      setTimeout(() => {
        this.setState({
          verifyServerError: false
        });
      }, 5000);

      this.processErrors(newProps.verifyUserError);
    }
    if (
      newProps.resendCodeError &&
      newProps.resendCodeError !== this.props.resendCodeError
    ) {
      this.setState({
        error: false,
        errorMsg: "",
        showSendSuccessMsg: false,
        resendServerError: true,
        verifyServerError: false
      });
      setTimeout(() => {
        this.setState({
          resendServerError: false
        });
      }, 5000);

      this.processErrors(newProps.resendCodeError);
    }
    if (
      newProps.verifyCodeSent &&
      newProps.verifyCodeSent === true &&
      newProps.verifyCodeSent !== this.props.verifyCodeSent
    ) {
      this.setState({
        showSendSuccessMsg: true,
        error: false,
        errorMsg: "",
        verifyServerError: false,
        resendServerError: false,
        showTimer: true
      });
      this.onTimer();
      setTimeout(() => {
        this.setState({
          showSendSuccessMsg: false
        });
      }, 3000);
    }
  }

  render() {
    return (
      <VerificationView
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        isProcessing={this.props.isProcessing}
        error={this.state.error}
        errorMsg={this.state.errorMsg}
        verificationCode={this.state.verificationCode}
        handleResendCode={this.handleResendCode}
        isResendCodeProcessing={this.props.isResendCodeProcessing}
        showSendSuccessMsg={this.state.showSendSuccessMsg}
        showTimer={this.state.showTimer}
        timerCount={this.state.timerCount}
        phoneNumber={this.state.phoneNumber}
        verifyServerError={this.state.verifyServerError}
        resendServerError={this.state.resendServerError}
        emailId={this.state.emailId}
        handleOTPChange={this.handleOTPChange}
      />
    );
  }
}
// export default VerifyClinicContainer;

const mapStateToProps = state => ({
  isProcessing: state.verifyClinic.isProcessing,
  verifyUserError: state.verifyClinic.error,
  isResendCodeProcessing: state.resendVerifyClinicCode.isProcessing,
  resendCodeError: state.resendVerifyClinicCode.error,
  verifyCodeSent: state.resendVerifyClinicCode.isCodeSent
});

const mapDispatchToProps = dispatch => ({
  verifyUser: (
    phoneNumber,
    countryCode,
    verificationCode,
    mixpanelFromParam,
    accountTypePatient
  ) =>
    dispatch(
      verifyUserAction(
        phoneNumber,
        countryCode,
        verificationCode,
        mixpanelFromParam,
        accountTypePatient
      )
    ),
  resendCode: (phoneNumber, countryCode) =>
    dispatch(resendVerificationCodeAction(phoneNumber, countryCode))
});

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyClinicContainer);
export { connectedPage as default };
