import React, { Component } from "react";
import { Button } from "antd";
import { strings } from "../../helpers";

class ExpiredShortLinkView extends Component {
  render() {
    return (
      <div className="appointment-content-container">
        <div className="expired-link-wrapper centered-text">
          <div className="expiry-link-image-box mtb-80" />
          <div className="expiry-link-title">
            {strings.shortLinkErrorMessage}
          </div>
        </div>
      </div>
    );
  }
}

export default ExpiredShortLinkView;
