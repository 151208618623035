import React, { Component } from "react";
import { Col, Row, Form, Alert, Button, Input } from "antd";
import { strings, PasswordInput } from "../../../helpers";
import AuthIntro from "../AuthIntro";

const FormItem = Form.Item;

class ResetClinicPasswordView extends Component {
  render() {
    const { props } = this;
    const { getFieldDecorator } = this.props.form;
    const buttonDisabled =
      props.password.trim() === "" || props.confirmPassword.trim() === "";
    return (
      <div className="auth-form-section signup-wrapper">
        {/* <div className="auth-form-subtext">
          {props.emailId ? strings.formatString(
            strings.sentVerifyCodeOn,
            <span>{props.phoneNumber}</span>,
            <span>{props.emailId}</span>
          ) : strings.formatString(
            strings.sentCodeOn,
            <span>{props.phoneNumber}</span>
          )}
        </div> */}

        <Row type="flex" justify="center" align="middle">
          <AuthIntro />
          <div className="signup-right-section">
            {/* sign up form start */}
            <div className="auth-form-main-wrapper">
              <div className="auth-form-top-heading">
                {props.isFirstLogin
                  ? "Update your password!"
                  : strings.reset_pwd}
              </div>
              {props.isFirstLogin && (
                <p className="mt-10">
                  You need to update your password because this is the first
                  time you are signing in.
                </p>
              )}
              <div className="form-alerts-wrapper mt-20">
                {props.error && (
                  <Alert
                    message={strings[props.errorMsg]}
                    type="error"
                    showIcon
                  />
                )}

                {props.serverError && (
                  <Alert
                    message={strings[props.serverErrorText]}
                    type="error"
                    showIcon
                  />
                )}
              </div>
              <Form onSubmit={props.handleSubmit} autoComplete="off">
                <div className="auth-form-input-block-container password-icon mt-20">
                  <FormItem>
                    <label className="label-prop">New password</label>
                    {getFieldDecorator("password", {
                      initialValue: `${props.password}`,
                      rules: [
                        {
                          required: true,
                          message: `${strings.password_required}`
                        }
                      ]
                    })(
                      <PasswordInput
                        screentype={props.screenType}
                        onChange={props.handleChange}
                        placement={props.screenType === "big" ? "right" : "top"}
                        getpopupcontainer={triggerNode =>
                          triggerNode.parentNode
                        }
                        autocompletetext="current-password"
                        nametext="password"
                        // placeholdertext={strings.password}
                        className="input-with-border password-key"
                      />
                    )}
                  </FormItem>
                </div>

                <div className="auth-form-input-block-container password-icon">
                  <FormItem>
                    <label className="label-prop">Confirm password</label>
                    {getFieldDecorator("confirmPassword", {
                      initialValue: `${props.confirmPassword}`,
                      rules: [
                        {
                          required: true,
                          message: `${strings.password_required}`
                        }
                      ]
                    })(
                      <Input
                        type="text"
                        // placeholder={strings.confirm_password}
                        name="confirmPassword"
                        onChange={props.handleChange}
                        autoComplete="new-password"
                        className="input-with-border password-key"
                      />
                    )}
                  </FormItem>
                </div>

                <div className="mt-20">
                  <Button
                    type="primary"
                    className="form-primary-btn"
                    htmlType="submit"
                    disabled={buttonDisabled}
                    loading={props.isProcessing}
                  >
                    {strings.reset}
                  </Button>
                </div>
              </Form>
              {/* auth secondary button */}
            </div>
          </div>
        </Row>
        {/* sign up form end */}
      </div>
    );
  }
}

export default Form.create()(ResetClinicPasswordView);
