import React, { Component } from "react";
import { Input, Button, Form } from "antd";
import { strings } from "../../helpers";

const FormItem = Form.Item;

class UserEmailView extends Component {
  render() {
    const { getFieldDecorator } = this.props.form;
    const { handleSubmit, handleChange, userEmail } = this.props;
    const disabledBtn = userEmail.trim() === "";

    return (
      <div className="magic-link-wrapper">
        <div className="magic-link-sent-wrapper mr-40-percent">
          <h3>{strings.magicLink.thisIsAwkward}</h3>

          <Form>
            <div className="sent-magic-link-text pd-0 mt-68">
              {strings.formatString(
                strings.magicLink.doNotHaveEmail,
                <span>{strings.email}</span>
              )}
              <FormItem className="left-aligned-text">
                {getFieldDecorator("userEmail", {
                  rules: [
                    {
                      type: "email",
                      message: strings.enter_valid_email
                    },
                    {
                      required: true,
                      message: strings.enter_valid_email
                    }
                  ]
                })(
                  <Input
                    name="userEmail"
                    placeholder={strings.magicLink.enterEmail}
                    className="magic-link-input mt-30"
                    onChange={handleChange}
                  />
                )}
              </FormItem>
            </div>

            <div className="send-link-btn-wrap">
              <Button
                loading={false}
                type="primary"
                className="send-magic-link-btn"
                disabled={disabledBtn}
                onClick={handleSubmit}
              >
                {strings.magicLink.sendMeMagicLink}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default UserEmailView;
