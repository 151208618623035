import React, { Component } from "react";
import { Button } from "antd";
import { strings } from "../../helpers";

class ExpiredMagicLinkView extends Component {
  render() {
    const { handleSendMagicLink, goToSignIn } = this.props;
    return (
      <div className="appointment-content-container">
        <div className="expired-link-wrapper centered-text">
          <div className="expiry-link-image-box-new mtb-40" />
          <div className="expiry-link-title color-white">
            {strings.magicLink.linkExpiryTitle}
          </div>
          <div className="expiry-link-subtitle color-white">
            <div>{strings.magicLink.linkExpiryTexLine1}</div>
            <div>
              {" "}
              {strings.formatString(
                strings.magicLink.linkExpiryTexLine2,
                <a
                  href="javascript:;"
                  onClick={goToSignIn}
                  className="color-white text-bold"
                >
                  {strings.magicLink.signIn}
                </a>
              )}
            </div>
          </div>

          <div className="request-link-wrapper">
            <Button
              className="request-magic-link-btn"
              onClick={handleSendMagicLink}
            >
              {strings.magicLink.requestAnotherLink}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default ExpiredMagicLinkView;
