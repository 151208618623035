import { notification } from "antd";
import mixpanel from "mixpanel-browser";
import { history } from "./index";

export const openNotificationWithIcon = (type, title, description) => {
  notification.destroy();
  notification[type]({
    message: title,
    description
  });
};

// expression for "minimum 1 numeric or a special character"
export const strongRegularExp = /^(?=.*?[a-z]).*?[\W\d].*/;

export const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);

// expression for email
export const emailRegularExp = /\S+@\S+\.\S+/;

// function that checks if email is valid or not
// check for valid email expression and restricts email from certain domains
export const isValidMailAddress = email => {
  var match = /^\w+[-\.\w]*@(\w+[-\.\w]*?\.\w{2,4})$/.exec(email);
  if (!match) return false;

  var forbiddenDomains = ["yopmail.com"];
  if (
    process.env.REACT_APP_ENVIRONMENT === "production" &&
    forbiddenDomains.indexOf(match[1].toLowerCase()) >= 0
  )
    return false;

  return true;
};

export const isResponsiveWidth = () => {
  let isResponsive = false;
  const w =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  if (w >= 768) {
    isResponsive = true;
  }
  return isResponsive;
};

export const handleUserToRequestedPage = pageName => {
  const sendGridCategory = localStorage.getItem(
    "sendgrid_category_for_magic_link"
  );
  const eventName = `from_email_${sendGridCategory}`;
  mixpanel.track(eventName);
  switch (pageName) {
    case "business":
      history.push("/billing");
      break;
    case "help":
      history.push("/help");
      break;
    case "setup":
      history.push("/set-up");
      break;
    default:
      break;
  }
};

/**
 * @function getAppEnv
 * @desc This returns the current application environment:
 * @retuns dev, stg or prod
 */
export const getAppEnv = () => {
  let appEnv = "";
  if (window && window.location) {
    const winProtocol = window.location.protocol;
    const winHostname = window.location.hostname;
    if (winHostname === process.env.REACT_APP_DEV_HOST_NAME) {
      appEnv = winProtocol === "http:" ? "dev" : "stg";
    }
    if (winHostname === process.env.REACT_APP_PROD_HOST_NAME) {
      appEnv = "prod";
    }
  } else {
    appEnv = process.env.REACT_APP_ENV;
  }
  return appEnv;
};

/**
 * @function getUrlParam
 * @param param
 * @desc This returns url parameter value
 */
export const getUrlParam = param => {
  const urlParamValue = new URL(window.location.href).searchParams.get(param);
  return urlParamValue;
};

/**
 * @function buildUrl
 * @param param
 * @desc This returns url strings where user is to be redirected after account selection, or incase referrer is present
 */
export const buildUrl = (param, clinic_id = null) => {
  let url = process.env.REACT_APP_CMS_SITE_BASE_URL;

  // switch (param) {
  //   case "cms":
  //     url = process.env.REACT_APP_CMS_SITE_BASE_URL;
  //     break;
  //   case "growth":
  //     url = process.env.REACT_APP_GROWTH_SITE_BASE_URL;
  //     break;
  //   case "doctors":
  //     url = process.env.REACT_APP_DOCTORS_SITE_BASE_URL;
  //     break;
  //   case "billing":
  //     url = clinic_id
  //       ? `${process.env.REACT_APP_BILLING_SITE_BASE_URL}?c=${clinic_id}`
  //       : process.env.REACT_APP_BILLING_SITE_BASE_URL;
  //     break;

  //   default:
  //     break;
  // }
  return url;
};

export const isAuthPageRendered = currentPage => {
  let authPageRendered = false;
  if (
    currentPage === "sign-up" ||
    currentPage === "sign-in" ||
    currentPage === "verify-clinic" ||
    currentPage === "change-password" ||
    currentPage === "reset-password" ||
    currentPage === "verify-phone-number-for-clinic" ||
    currentPage === "forgot-password" ||
    currentPage === "reset-password-for-clinic" ||
    currentPage === "verify-phone-number" ||
    currentPage === "forgot-password-for-clinic"
  )
    authPageRendered = true;
  return authPageRendered;
};

/**
 * @function getQueryParams
 * @param url
 * @desc This returns query string params as an object
 */
export const getQueryParams = url => {
  return url
    .substr(1)
    .split("&")
    .reduce((qs, query) => {
      var chunks = query.split("=");
      var key = chunks[0];
      var value = decodeURIComponent(chunks[1] || "");
      var valueLower = value.trim().toLowerCase();
      if (valueLower === "true" || value === "false") {
        value = Boolean(value);
      } else if (!isNaN(Number(value))) {
        value = Number(value);
      }
      return (qs[key] = value), qs;
    }, {});
};

/**
 * @function setPageMetaDesc
 * @param desc
 * @desc This function sets page meta description
 */
export const setPageMetaDesc = desc => {
  const el = document.querySelector("meta[name='description']");
  el.setAttribute("content", desc);
};

/**
 * @function buildUrlWithIntent
 * @param param
 * @desc This returns url strings where user is to be redirected after account selection according to the intent of the user
 */
export const buildUrlWithIntent = signUpIntent => {
  let url = process.env.REACT_APP_CMS_SITE_BASE_URL;
  // let url = process.env.REACT_APP_DOCTORS_SITE_BASE_URL;
  // switch (signUpIntent) {
  //   case "newsfeed":
  //   case "videocall_tools":
  //   case "payments_tools":
  //   case "records_tools":
  //   case "rx_tools":
  //   case "nwf":
  //   case "ft_vdc":
  //   case "ft_pyt":
  //   case "ft_rcs":
  //   case "ft_erx":
  //     // if intent is "CMS" then send user to Manage Practice in doctors app
  //     url = process.env.REACT_APP_DOCTORS_SITE_BASE_URL;
  //     break;
  //   case "grt":
  //     // if intent is "Growth" then send user to growth app
  //     url = process.env.REACT_APP_GROWTH_SITE_BASE_URL;
  //     break;
  //   case "cos":
  //   case "cms":
  //   case "t7y":
  //   case "d13t":
  //   case "p10s":
  //     // if intent is "cos" then send user to CMS app
  //     url = process.env.REACT_APP_CMS_SITE_BASE_URL;
  //     break;
  //   default:
  //     break;
  // }
  return url;
};

export const cmsIntentParams = ["cos", "cms", "t7y", "d13t", "p10s"];

export const doctorIntentParams = ["nwf", "ft_vdc", "ft_pyt", "ft_rcs", "ft_erx"];
export const growthIntentParams = ["grt"];
export const therapistIntentParams = ["t7y"];
