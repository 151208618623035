import React, { Component } from "react";
import qs from "qs";
import { Spin } from "antd";
import { connect } from "react-redux";
import mixpanel from "mixpanel-browser";
import {
  isMobile,
  history,
  encryptId,
  isResponsiveWidth
} from "../../helpers";
import { magicLinkSignIn } from "../../services";

import {
  magicLinkSignInAction,
  showLoader
} from "../Onboarding/Signin/ClinicLoginActions";

class MagicLinkSignInContainer extends Component {
  state = {
    isProcessing: false,
    magicLinkParams: null
  };

  componentDidMount() {
    // clear the cookies and localstorage here
    // deleteAllCookies();
    // localStorage.clear();
    const params = qs.parse(this.props.location.search.slice(1));

    // t = auth_token
    // u = user id
    // e = email address
    // r = page name, where the user should be redirected
    // video_url = video url for help tab
    // link_type = "cms", "billing", "growth" - where we want to redirect the user
    const { t, u, e, r, c, video_url, link_type, clinic_id } = params;

    const pathName = window.location.pathname;
    const magicLinkParams = window.location.search;
    this.setState({ magicLinkParams });

    // if the user has opened the magic link from email from a mobile device then, redirect user to '/magin-link' page, that asks user to send magic link again and user kulcare from laptop/ computer
    const mobileUserFromSignIn = pathName === "/magic-link-signin" && isMobile && !isResponsiveWidth();

    // c = sendgrid category like : engagement_add_patient, engagement_manage_staff, etc

    // set this "c" in cms only, in Check Auth component,
    // since user will be redirected to CMS and mixpanel tracking will be happening there

    // if user opens this page from mobile
    // if (mobileUserFromSignIn && u && e && !link_type) {
    //   const encryptedUserId = encryptId(u);
    //   history.push(`/magic-link?mobile=true&u=${encryptedUserId}&e=${e}`);
    // }

    if (link_type && mobileUserFromSignIn) {
      // if (getUser()) {
      //   window.location.replace(process.env.REACT_APP_SITE_BASE_URL);
      // } else {
      this.handleMagicSignIn(t, u, e, r, video_url, c, link_type, clinic_id);
      // }
    }

    // if user is present already, then simple take user to cms site
    // if (getUser() ) {
    //   window.location.replace(process.env.REACT_APP_CMS_SITE_BASE_URL);
    // }
    // if user is viewing this from laptop/ computer, then handle it

    if (!mobileUserFromSignIn && t && u && e) {
      // hit the magic sign in api
      this.handleMagicSignIn(t, u, e, r, video_url, c, link_type);
    }
  }

  /**
   * @function handleMagicSignIn
   * @param "authToken", "userId", "email"
   * @desc This function handles the magic link sign in, if api returns true then login the user and redirect to queue, else show expired magic link page
   */
  handleMagicSignIn = (
    authToken,
    userId,
    email,
    pageName,
    videoUrl,
    sendgridCategory,
    link_type,
    clinic_id = null
  ) => {
    const encryptedUserId = encryptId(userId);
    this.setState({ isProcessing: true });
    const params = {
      auth_token: authToken
    };
    this.props.showLoaderAtStart(true);
    magicLinkSignIn(params).then(response => {
      this.props.showLoaderAtStart(false);
      this.setState({ isProcessing: false });
      if (response && response.data.data && response.data.data.user) {
        const responseObj = response.data;
        const { magicLinkParams } = this.state;
        // set the sign in related data in localstorage and dispatch logged in method, to redirect user to profile/ queue
        localStorage.setItem("user_email", responseObj.data.user.email);
        if (videoUrl) {
          this.props.signInUser(
            responseObj,
            pageName,
            videoUrl,
            sendgridCategory,
            link_type
          );
        } else {
          this.props.signInUser(
            responseObj,
            pageName,
            null,
            sendgridCategory,
            link_type,
            clinic_id
          );
        }
      } else {
        this.props.showLoaderAtStart(false);
        localStorage.setItem("user_email", email);
        let url = `/expired-magic-link?u=${encryptedUserId}`;

        if (pageName) {
          url = `${url}&r=${pageName}`;
        }
        if (sendgridCategory) {
          url = `${url}&sendgrid_category=${sendgridCategory}`;
        }
        if (link_type) {
          url = `${url}&link_type=${link_type}`;
        }
        history.push(`${url}`);
      }
    });
  };

  render() {
    const { showPageLoading } = this.props;

    return (
      <div>
        {showPageLoading && (
          <div className="page-loader">
            <div className="lds-roller">
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signInUser: (
      responseObj,
      pageName,
      videoUrl,
      sendgridCategory,
      link_type,
      clinic_id
    ) =>
      dispatch(
        magicLinkSignInAction(
          responseObj,
          pageName,
          videoUrl,
          sendgridCategory,
          link_type,
          clinic_id
        )
      ),
    showLoaderAtStart: isLoaderLoading => dispatch(showLoader(isLoaderLoading))
  };
};

const mapStateToProps = state => {
  return {
    showPageLoading: state.clinicLogin.showLoadingState
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MagicLinkSignInContainer);
