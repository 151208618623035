import { clinicSignupConstants } from "./ClinicSignupConstants";

export const clinicSignupReducer = (state = {}, action) => {
  switch (action.type) {
    case clinicSignupConstants.CLINIC_SIGNUP_PROCESSING:
      return {
        ...state,
        isProcessing: action.isProcessing
      };
    case clinicSignupConstants.CLINIC_SIGNUP_SUCCESS:
      return {
        ...state,
        data: action.user
      };
    case clinicSignupConstants.CLINIC_SIGNUP_FAILURE:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};

export const checkClinicExistenceReducer = (state = {}, action) => {
  switch (action.type) {
    case clinicSignupConstants.CHECK_USER_EXISTENCE_PROCESSING:
      return {
        ...state,
        isProcessing: action.isProcessing
      };
    case clinicSignupConstants.CHECK_USER_EXISTENCE_SUCCESS: {
      return {
        ...state,
        user_exists: action.data.user_exists
      };
    }
    case clinicSignupConstants.CHECK_USER_EXISTENCE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};
