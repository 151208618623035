export const verifyClinicConstants = {
  VERIFY_USER_PROCESSING: "VERIFY_USER_PROCESSING",
  VERIFY_USER_SUCCESS: "VERIFY_USER_SUCCESS",
  VERIFY_USER_FAILURE: "VERIFY_USER_FAILURE"
};

export const resendVerifyCodeConstants = {
  RESEND_VERIFY_CODE_PROCESSING: "RESEND_VERIFY_CODE_PROCESSING",
  RESEND_VERIFY_CODE_SUCCESS: "RESEND_VERIFY_CODE_SUCCESS",
  RESEND_VERIFY_CODE_FAILURE: "RESEND_VERIFY_CODE_FAILURE"
};
