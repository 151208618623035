import { forgotPasswordConstants } from "./forgotPasswordConstants";
import {
  resetPasswordInstructions,
  verifyForgotPasswordCode,
  changePassword
} from "../../services";

import { history, encryptId, isMobile } from "../../helpers";
import { logoutAction } from "../login/loginActions";

/**
 * @function forgotPasswordAction
 * @param countryCode
 * @param phoneNumber
 * @desc This function takes in the phone number and send verification code to user, to allow user to change the password
 */
export const forgotPasswordAction = (countryCode, phoneNumber) => {
  return dispatch => {
    dispatch(forgotPasswordProcessing(true));
    resetPasswordInstructions(countryCode, phoneNumber).then(response => {
      if (response.data && response.data.success === true) {
        let responseObj = response.data;
        let id = encryptId(responseObj.data.id);
        dispatch(forgotPasswordSuccess(responseObj.data.data));
        dispatch(forgotPasswordProcessing(false));
        history.push("/verify-phone-number?id=" + id);
      } else {
        dispatch(forgotPasswordFailure(response.data.errors));
        dispatch(forgotPasswordProcessing(false));
      }
    });
  };
};

export const forgotPasswordProcessing = isProcessing => {
  return {
    type: forgotPasswordConstants.FORGOT_PASSWORD_PROCESSING,
    isProcessing
  };
};
export const forgotPasswordSuccess = data => {
  return { type: forgotPasswordConstants.FORGOT_PASSWORD_SUCCESS, data };
};
export const forgotPasswordFailure = error => {
  return { type: forgotPasswordConstants.FORGOT_PASSWORD_FAILURE, error };
};

/**
 * @function verifyForgotPasswordCodeAction
 * @param userId
 * @param verificationCode
 * @desc This function takes in the verification code sent to user and redirects user to change/ reset password screen
 */
export const verifyForgotPasswordCodeAction = (userId, verificationCode) => {
  return dispatch => {
    dispatch(resendForgotPasswordCodeSuccess());
    dispatch(verifyForgotPasswordCodeProcessing(true));
    verifyForgotPasswordCode(userId, verificationCode).then(response => {
      if (response.data && response.data.success === true) {
        const responseObj = response.data;
        const id = encryptId(userId);
        dispatch(verifyForgotPasswordCodeSuccess(responseObj.data));
        dispatch(verifyForgotPasswordCodeProcessing(false));
        history.push(`/reset-password?id=${id}`);
      } else {
        dispatch(verifyForgotPasswordCodeFailure(response.data.errors));
        dispatch(verifyForgotPasswordCodeProcessing(false));
      }
    });
  };
};

export const verifyForgotPasswordCodeProcessing = isProcessing => {
  return {
    type: forgotPasswordConstants.VERIFY_FORGOT_PASSWORD_CODE_PROCESSING,
    isProcessing
  };
};
export const verifyForgotPasswordCodeSuccess = data => {
  return {
    type: forgotPasswordConstants.VERIFY_FORGOT_PASSWORD_CODE_SUCCESS,
    data
  };
};
export const verifyForgotPasswordCodeFailure = error => {
  return {
    type: forgotPasswordConstants.VERIFY_FORGOT_PASSWORD_CODE_FAILURE,
    error
  };
};

export const changePasswordProcessing = isProcessing => {
  return {
    type: forgotPasswordConstants.CHANGE_PASSWORD_PROCESSING,
    isProcessing
  };
};
export const changePasswordSuccess = status => {
  return { type: forgotPasswordConstants.CHANGE_PASSWORD_SUCCESS, status };
};
export const changePasswordFailure = error => {
  return { type: forgotPasswordConstants.CHANGE_PASSWORD_FAILURE, error };
};

/**
 * @function changePasswordAction
 * @param userId
 * @param password
 * @desc This function allows user to change
 * current password - change password flow
 * or reset password - forgot password flow
 */
export const changePasswordAction = (
  userId,
  password,
  currentPassword = null
) => {
  return dispatch => {
    dispatch(changePasswordProcessing(true));
    dispatch(changePasswordSuccess(false));
    changePassword(userId, password, currentPassword).then(response => {
      if (response.data && response.data.success === true) {
        dispatch(changePasswordSuccess(true));
        dispatch(changePasswordProcessing(false));
        if (currentPassword) {
          dispatch(changePasswordSuccess(false));
          // logout user in case of change password
          // const loggedInAsGrowthProfileUser = localStorage.getItem(
          //   "logged_in_as_clinic_owner_for_growth"
          // );

          // if (loggedInAsGrowthProfileUser) {
          //   logoutFromGrowthProfileAction(dispatch);
          // } else {
          logoutAction(dispatch);
          // }
        } else {
          // set user data in case of reset password success
          const responseObj = response.data;
          // if the user has signed in from mobile, then prompt user to continue rest of the app through web
          // if (isMobile) {
          //   history.push("/prompt-for-web-view");
          //   return false;
          // }
          // localStorage.setItem("authToken", `Bearer ${responseObj.auth_token}`);
          // localStorage.setItem("user", JSON.stringify(responseObj.data.user));
          // setCookieRelatedData(responseObj);
          // setUserDetails(responseObj, dispatch);
          // dispatch userLoggedIn method, to update the Top nav
          // dispatch(userLoggedIn(true));
        }
      } else {
        dispatch(changePasswordFailure(response.data.errors.title));
        dispatch(changePasswordProcessing(false));
        dispatch(changePasswordSuccess(false));
      }
    });
  };
};

/**
 * @function resendForgotPasswordCodeAction
 * @param userId
 * @desc This function takes userid and allows user to resend verification code
 */
export const resendForgotPasswordCodeAction = userId => {
  return dispatch => {
    let encryptedId = encryptId(userId);
    dispatch(resendForgotPasswordCodeSuccess(false));
    dispatch(resendForgotPasswordCodeProcessing(true));
    resetPasswordInstructions("", encryptedId).then(
      response => {
        if (response.data && response.data.success === true) {
          dispatch(resendForgotPasswordCodeSuccess(true));
          dispatch(resendForgotPasswordCodeProcessing(false));
        } else if (response.data && response.data.success === false) {
          dispatch(resendForgotPasswordCodeFailure(response.data.errors));
          dispatch(resendForgotPasswordCodeProcessing(false));
          dispatch(resendForgotPasswordCodeSuccess(false));
        }
      },
      error => {
        dispatch(resendForgotPasswordCodeFailure(error));
        dispatch(resendForgotPasswordCodeProcessing(false));
      }
    );
  };
};

export const resendForgotPasswordCodeProcessing = isProcessing => {
  return {
    type: forgotPasswordConstants.RESEND_FORGOT_PASSWORD_CODE_PROCESSING,
    isProcessing
  };
};
export const resendForgotPasswordCodeSuccess = isCodeSent => {
  return {
    type: forgotPasswordConstants.RESEND_FORGOT_PASSWORD_CODE_SUCCESS,
    isCodeSent
  };
};
export const resendForgotPasswordCodeFailure = error => {
  return {
    type: forgotPasswordConstants.RESEND_FORGOT_PASSWORD_CODE_FAILURE,
    error
  };
};
