import React, { Component } from "react";
import { Form, Spin } from "antd";
import mixpanel from "mixpanel-browser";
import UserEmailView from "./UserEmailView";
import {
  getUser,
  showSuccessNotification,
  showErrorNotification,
  history,
  strings
} from "../../helpers";
import { sendMagicLink } from "../../services";

class UserEmailContainer extends Component {
  state = {
    userEmail: "",
    isProcessing: false
  };

  componentDidMount() {
    // check whether user has already added an email from "/get-user-email" page
    // and do not allow the user to send a new email again and request for the magic link
    this.checkIfEmailProvidedAfterSignup();
  }

  checkIfEmailProvidedAfterSignup = () => {
    const emailVal = localStorage.getItem("user_email");

    const user = getUser();
    if (emailVal) {
      history.push("/magic-link-sent");
    }

    // if user has email id already provided, then do not allow the user to access this page
    if (user && user.email) {
      history.push("/queue");
    }
  };

  /**
   * @function handleSubmit
   * @param "e"
   * @desc This function handles submitting email for requesting the magic link
   */
  handleSubmit = e => {
    if (e) {
      e.preventDefault();
    }

    this.props.form.validateFieldsAndScroll(async (err, value) => {
      if (!err) {
        const user = getUser();
        const email = value.userEmail;

        if (user) {
          this.setState({ isProcessing: true });
          sendMagicLink(user.id, email).then(response => {
            this.setState({ isProcessing: false });
            if (response && response.data.success) {
              // store the email in the localStorage to read it in the sent magic link success page
              localStorage.setItem("user_email", email);
              showSuccessNotification(strings.magicLink.magicLinkSentSuccess);
              history.push("/magic-link-sent");
            } else {
              showErrorNotification(response.data.errors[0].title);
            }
          });
        } else {
          showErrorNotification(strings.magicLink.userDoesNotExist);
        }
      }
    });
  };

  /**
   * @function handleChange
   * @param "e"
   * @desc This function handles change in input field
   */
  handleChange = e => {
    const { name, value } = e.target;
    // set value in state for email validation
    this.setState({
      [name]: value
    });
    // set form fields
    this.props.form.setFieldsValue({
      [name]: value
    });
  };

  render() {
    const { form } = this.props;
    const { isProcessing, userEmail } = this.state;

    return (
      <Spin spinning={isProcessing}>
        <UserEmailView
          form={form}
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          userEmail={userEmail}
        />
      </Spin>
    );
  }
}

export default Form.create()(UserEmailContainer);
