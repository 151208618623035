import React, { Component } from "react";
import { connect } from "react-redux";
import qs from "qs";
import _ from "lodash";
import { forgotPasswordAction } from "./ForgotClinicPasswordActions";
import ForgotClinicPasswordView from "./ForgotClinicPasswordView";
import { history, decryptId, encryptId } from "../../../helpers";
import { resetPasswordInstructions } from "../../../services";

class ForgotClinicPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMsg: "",
      phoneNumber: "",
      countryCode: "91",
      serverError: false,
      utmSource: null,
      utmMedium: null,
      utmContent: null,
      utmCampaign: null,
      inviteCode: null,
      doctorName: null,
      docId: null,
      hasForgot: false,
      isProcessing: false,
      accountType: null
    };
  }

  componentDidMount() {
    this.fetchUTMParams();
  }

  /**
   * @function forgotPasswordAction
   * @param countryCode
   * @param phoneNumber
   * @desc This function takes in the phone number and send verification
   *  code to user, to allow user to change the password
   */

  forgotPasswordAction = (countryCode, phoneNumber) => {
    this.setState({ isProcessing: true });
    resetPasswordInstructions(countryCode, phoneNumber).then(response => {
      this.setState({ isProcessing: false });
      if (response.data && response.data.success === true) {
        const responseObj = response.data;
        const userId = encryptId(responseObj.data.id);
        this.setState({
          hasForgot: true,
          userId
        });
      } else {
        this.setState({
          hasForgot: true
        });
      }
    });
  };

  /**
   * @function fetchUTMParams
   * @desc This funtion fetches utm params in url
   */
  fetchUTMParams = () => {
    const params = qs.parse(this.props.location.search.slice(1));

    if (params && !_.isEmpty(params)) {
      const {
        utm_source,
        utm_medium,
        utm_content,
        utm_campaign,
        kic,
        dname,
        did,
        acc
      } = params;
      this.setState({
        utmSource: utm_source || null,
        utmMedium: utm_medium || null,
        utmContent: utm_content || null,
        utmCampaign: utm_campaign || null,
        inviteCode: kic || null,
        doctorName: dname || null,
        docId: did ? decryptId(did) : null,
        accountType: acc || null
      });
    }
  };

  /**
   * @function handleChange
   * @param e
   * @return nothing
   * @desc This method handles input changes in forgot password screen
   */
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value.replace(/\s/g, ""),
      error: false,
      errorMsg: "",
      serverError: false,
      isProcessing: false
    });
  };

  /**
   * @function setStateForInvalidData
   * @param errorText
   * @return nothing
   * @desc This method sets error messages state
   */
  setStateForInvalidData = errorText => {
    this.setState({
      error: true,
      errorMsg: errorText,
      serverError: false
    });
  };

  /**
   * @function handleSubmit
   * @param e
   * @return nothing
   * @desc This method checks for valid input and
   * submits phone number so that user
   *  recieved the verification code to
   *  change the password
   */
  handleSubmit = e => {
    e.preventDefault();

    const { phoneNumber, countryCode } = this.state;

    if (!phoneNumber) {
      this.setStateForInvalidData("required_phone_number");
      return;
    }

    if (phoneNumber && countryCode) {
      this.forgotPasswordAction(countryCode, phoneNumber);
    } else {
      this.setStateForInvalidData("param_missing");
    }
  };

  /**
   * @function handleResendCode
   * @param e
   * @return nothing
   * @desc This method handles resend verification code
   */
  handleResendCode = e => {
    e.preventDefault();
    const { phoneNumber, countryCode } = this.state;
    this.props.resendCode(phoneNumber, countryCode);
  };

  /**
   * @function handleCancel
   * @param e
   * @param nothing
   * @desc This handles redirection to sign in page
   */
  handleCancel = () => {
    history.push("/sign-in");
  };

  /**
   * @function goToSignIn
   * @desc This function handles redirection to given page
   */
  goToSignIn = e => {
    if (e) e.preventDefault();
    let redirectUrl = `/sign-in`;
    
    const {
      utmSource,
      utmMedium,
      utmContent,
      utmCampaign,
      inviteCode,
      doctorName,
      docId,
      accountType
    } = this.state;
    if (utmSource) redirectUrl = `${redirectUrl}?utm_source=${utmSource}`;
    if (utmMedium) redirectUrl = `${redirectUrl}&utm_medium=${utmMedium}`;
    if (utmContent) redirectUrl = `${redirectUrl}&utm_content=${utmContent}`;
    if (utmCampaign) redirectUrl = `${redirectUrl}&utm_campaign=${utmCampaign}`;
    if (inviteCode) redirectUrl = `${redirectUrl}?kic=${inviteCode}`;
    if (doctorName) redirectUrl = `${redirectUrl}?dname=${doctorName}`;
    if (docId) {
      redirectUrl = `${redirectUrl}${doctorName ? "&" : "?"}did=${docId}`;
    }

    if (accountType) {
      redirectUrl = redirectUrl.includes("?")
        ? `${redirectUrl}&acc=${accountType}`
        : `${redirectUrl}?acc=${accountType}`;
    }
    history.push(redirectUrl);
  };

  render() {
    const { hasForgot, countryCode, accountType } = this.state;
    return (
      <ForgotClinicPasswordView
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        handleCancel={this.handleCancel}
        isProcessing={this.state.isProcessing}
        error={this.state.error}
        errorMsg={this.state.errorMsg}
        forgotPasswordError={this.props.forgotPasswordError}
        serverError={this.state.serverError}
        phoneNumber={this.state.phoneNumber}
        goToSignIn={this.goToSignIn}
        countryCode={countryCode}
        hasForgot={hasForgot}
        userId={this.state.userId}
        accountType={accountType}
      />
    );
  }
}

export default ForgotClinicPasswordContainer;
