import { notification } from "antd";
import "antd/dist/antd.css";
import mixpanel from "mixpanel-browser";
import Pusher from "pusher-js";
import React from "react";
import ReactDOM from "react-dom";
import { MixpanelProvider } from "react-mixpanel";
import { setPusherClient } from "react-pusher";
import { Detector, Online } from "react-detect-offline";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import App from "./App";
import ScrollToTop from "./components/app/ScrollToTop";
import "./css/animate.css";
import "./css/authForm.css";
// all the custom style other than theme will go here
import "./css/theme/index.css";
import "./css/custom.css";
import "./css/select_account.css";
import "./css/page-loader.css";
import "./css/user_prompt.css";
import "./css/signup.css";
import "./css/responsive.css";
import { history, store } from "./helpers";
import { InterceptorService } from "./services/interceptor";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// initialize mixpanel
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
  document.body.classList.add("firefox");
}
window.store = store;

InterceptorService(store);
// initialize google analytics

// set notification placement globally
notification.config({
  placement: "topLeft"
});

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN_URL,
    release: process.env.REACT_APP_LATEST_RELEASE,
    ignoreErrors: [
      "Cannot read property 'audioPlayer' of null",
      "Network Error",
      "Unable to find node on an unmounted component.",
      "Error: Unable to find node on an unmounted component.",
      "Request failed with status code 401",
      "Request failed with status code 404",
      "Request failed with status code 500"
    ],
    beforeSend(event, _hint) {
      if (!event.message && !event.exception) {
        return null;
      }
      if (event.exception) {
        const exceptionValue = event.exception.values[0].value;
        // ignore error
        if (exceptionValue) {
          if (
            exceptionValue.match(/audioPlayer/i) ||
            exceptionValue.match(/Network Error/i) ||
            exceptionValue.match(/unmounted component/i) ||
            exceptionValue.match(/getReadModeRender/i) ||
            exceptionValue.match(/getReadModeConfig/i) ||
            exceptionValue.match(/getReadModeExtract/i) ||
            exceptionValue.match(/188/i) ||
            exceptionValue.match(/#188/i) ||
            exceptionValue.match(/querySelector/i) ||
            exceptionValue.match(/getInstance/i) ||
            exceptionValue.match(/null is not an object/i) ||
            exceptionValue.match(/focus/i) ||
            exceptionValue.match(/tgetT/i)
          ) {
            return null;
          }
        }
      }
      return event;
    },
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()]
  });
}

// facebook pixel
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false // enable logs
};
ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, null, options);
ReactPixel.pageView(); // For tracking page view

// setup pusher
const pusherClient = new Pusher(process.env.REACT_APP_PUSHER_KEY);
setPusherClient(pusherClient);

ReactDOM.render(
  <MixpanelProvider mixpanel={mixpanel}>
    <Provider store={store}>
      <Router history={history}>
        <ScrollToTop>
          <Detector
            render={({ online }) => {
              return <App online={online} />;
            }}
          />
        </ScrollToTop>
      </Router>
    </Provider>
  </MixpanelProvider>,
  document.getElementById("root")
);
