export default {
  no_internet_connection: "ਕੋਈ ਇੰਟਰਨੈਟ ਕਨੈਕਸ਼ਨ ਨਹੀਂ!",
  back: "वापस",
  LabsList: "ਲੈਬ ਸੂਚੀ",
  staffList: "ਸਟਾਫ਼ਸ ਲਿਸਟ",
  staffSubtitle:
    "ਆਪਣੇ ਕਲੀਨਿਕ ਵਿੱਚ ਸ਼ਾਮਲ ਕਰਨ ਲਈ ਉਨ੍ਹਾਂ ਦੇ ਮੋਬਾਈਲ ਨੰਬਰ ਦੀ ਵਰਤੋਂ ਕਰਦਿਆਂ ਇੱਕ ਸਟਾਫ ਮੈਂਬਰ ਨੂੰ ਸੱਦਾ ਦਿਓ. ਇੱਕ ਸਟਾਫ ਮੈਂਬਰ ਖਾਤਾ ਕਾਰੋਬਾਰ ਅਤੇ ਮਰੀਜ਼ਾਂ ਦੇ ਵੇਰਵਿਆਂ ਤੱਕ ਨਹੀਂ ਪਹੁੰਚ ਸਕਦਾ.",
  doctorsList: "ਡਾਕਟਰ ਸੂਚੀ",
  doctorsListSubtitle:
    "ਆਪਣੇ ਕਲੀਨਿਕ ਵਿੱਚ ਸਲਾਹਕਾਰ ਵਜੋਂ ਸ਼ਾਮਲ ਕਰਨ ਲਈ ਉਨ੍ਹਾਂ ਦੇ ਮੋਬਾਈਲ ਨੰਬਰ ਦੀ ਵਰਤੋਂ ਕਰਦਿਆਂ ਇੱਕ ਡਾਕਟਰ ਦੀ ਭਾਲ ਕਰੋ. ਇੱਕ ਸਲਾਹਕਾਰ ਖਾਤਾ ਸਿਰਫ ਕਲੀਨਿਕ ਵਿੱਚ ਆਪਣੇ ਮਰੀਜ਼ਾਂ ਨਾਲ ਜੁੜੇ ਡੇਟਾ ਨੂੰ ਵੇਖ ਸਕਦਾ ਹੈ.",
  LabsListSubtitle:
    "ਕਿਸੇ ਲੈਬ ਦੀ ਖੋਜ ਕਰੋ ਅਤੇ ਆਪਣੇ ਨੈਟਵਰਕ ਤੇ ਜੋੜੋ ਫਿਰ ਤੁਸੀਂ ਇਹਨਾਂ ਲੈਬਾਂ ਨੂੰ ਸਿੱਧੇ CMS ਤੋਂ ਇੱਕ ਤਜਵੀਜ਼ ਭੇਜ ਸਕਦੇ ਹੋ",
  PatientsSubtitle:
    "ਇਹ ਉਨ੍ਹਾਂ ਸਾਰੇ ਮਰੀਜ਼ਾਂ ਦੀ ਸੂਚੀ ਹੈ ਜੋ ਤੁਹਾਡੇ ਕਲੀਨਿਕ ਵਿੱਚ ਗਏ ਸਨ. ਤੁਸੀਂ ਇੱਕ ਨਵਾਂ ਮਰੀਜ਼ ਜੋੜ ਸਕਦੇ ਹੋ ਜਾਂ ਮੌਜੂਦਾ ਮਰੀਜ਼ ਦੇ ਵੇਰਵੇ ਨੂੰ ਸੋਧ ਸਕਦੇ ਹੋ.",
  vitalsSubtitle:
    "ਉਨ੍ਹਾਂ ਵੈਦਾਂ ਨੂੰ ਜੋੜੋ ਜਿਹੜੇ ਤੁਸੀਂ ਮਰੀਜ਼ ਨੂੰ ਕਲੀਨਿਕ ਵਿਚ ਜਾਣ ਲਈ ਰਿਕਾਰਡ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ",
  servicesSubtitle:
    "ਉਹ ਸੇਵਾਵਾਂ ਸ਼ਾਮਲ ਕਰੋ ਜੋ ਤੁਹਾਡਾ ਕਲੀਨਿਕ ਪੇਸ਼ਕਸ਼ ਕਰਦਾ ਹੈ. ਤੁਸੀਂ ਬਿਲਾਂ ਬਣਾਉਣ ਵੇਲੇ ਇਨ੍ਹਾਂ ਦੀ ਚੋਣ ਕਰ ਸਕਦੇ ਹੋ.",
  pharmaciesSubtitleOne:
    "ਫਾਰਮੇਸੀ ਦੀ ਭਾਲ ਕਰੋ ਅਤੇ ਉਹਨਾਂ ਨੂੰ ਆਪਣੇ ਨੈਟਵਰਕ ਵਿੱਚ ਜੋੜੋ ਫਿਰ ਤੁਸੀਂ ਇਹਨਾਂ ਫਾਰਮੇਸੀਆਂ ਨੂੰ ਸਿੱਧਾ CMS ਤੋਂ ਇੱਕ ਪ੍ਰਿੰਸੀਪਲ ਭੇਜ ਸਕਦੇ ਹੋ",
  pharmaciesSubtitleTwo:
    "ਫਿਰ ਤੁਸੀਂ ਇਹਨਾਂ ਫਾਰਮੇਸੀਆਂ ਨੂੰ ਸਿੱਧਾ CMS ਤੋਂ ਇੱਕ ਪ੍ਰਿੰਸੀਪਲ ਭੇਜ ਸਕਦੇ ਹੋ",
  edit: "ਐਡਿਟ",
  Patients_List: "ਮਰੀਜ਼ਾਂ ਦੀ ਸੂਚੀ",
  on_call: "ਕਾਲ 'ਤੇ",
  walk_in: "ਵਾਲ ਇਨ",
  yes: "ਹਾਂ",
  no: "ਨਹੀਂ",
  info_title: "ਜਾਣਕਾਰੀ",
  success_title: "ਸਫਲਤਾ!",
  error_title: "ਗਲਤੀ!",
  save: "ਸੇਵ ਕਰੋ",
  add: "ਜੋੜਨਾ",
  phone_number: "ਮੋਬਾਈਲ ਨੰਬਰ",
  mobile_number: "ਮੋਬਾਈਲ ਨੰਬਰ",
  your_mobile_number: "ਤੁਹਾਡਾ ਮੋਬਾਈਲ ਨੰਬਰ",
  phone_number_short: "ਮੋਬਾਈਲ ਨੰ",
  password: "ਪਾਸਵਰਡ",
  your_email: "ਈਮੇਲ",
  by_clicking_signup: "ਸਾਈਨ ਅਪ ਤੇ ਕਲਿਕ ਕਰਕੇ ਤੁਸੀਂ ਸਹਿਮਤ ਹੋ",
  phone_and_password_required: "ਮੋਬਾਈਲ ਨੰਬਰ ਅਤੇ ਪਾਸਵਰਡ ਦੀ ਲੋੜ ਹੈ।",
  language: "ਭਾਸ਼ਾ",
  forgot_password: "ਪਾਸਵਰਡ ਭੁੱਲ ਗਏ",
  invalid_valid_phone_number: "ਪ੍ਰਮਾਣਿਤ ਮੋਬਾਈਲ ਨੰਬਰ ਦਾਖਲ ਕਰੋ",
  sign_up: "ਸਾਈਨ ਅਪ",
  sign_up_heading: "ਹੁਣ ਖਾਤਾ ਬਣਾਓ",
  sign_up_sub_heading_1:
    "ਕੁਲਕੇਅਰ ਅਕਾਉਂਟ ਨਾਲ ਆਪਣੇ ਕਲੀਨਿਕ ਨੂੰ ਅਸਾਨੀ ਨਾਲ ਪ੍ਰਬੰਧਿਤ ਕਰੋ ਅਤੇ ਵਧਾਓ.",
  sign_up_sub_heading_2:
    "ਬੱਸ ਆਪਣੇ ਕਲੀਨਿਕ ਦੇ ਵੇਰਵੇ ਹੇਠਾਂ ਦਰਜ ਕਰੋ ਅਤੇ ਸਾਈਨ ਅਪ ਤੇ ਕਲਿਕ ਕਰੋ.",
  sign_up_sub_heading_3: "ਪਹਿਲਾਂ ਤੋਂ ਹੀ ਕੋਈ ਖਾਤਾ ਹੈ?",
  error_occured: "ਗਲਤੀ",
  error_msg: "ਬੇਨਤੀ ਦੀ ਪ੍ਰਕਿਰਿਆ ਕਰਨ ਦੌਰਾਨ ਕੁਝ ਅਸ਼ੁੱਧੀ ਹੋਈ ਸੀ।",
  loading: "ਲੋਡ ਹੋ ਰਿਹਾ ਹੈ",
  invalid_verify_code: "ਵੇਰਿਫਿਕੇਸ਼ਨ ਕੋਡ ਅਵੈਧ ਹੈ।",
  verify_code_error: "ਵੇਰਿਫਿਕੇਸ਼ਨ ਕੋਡ ਦੁਬਾਰਾ ਭੇਜਣ ਦੌਰਾਨ ਕੁਝ ਗਲਤੀ ਆਈ ਸੀ।",
  code_sent_success: "ਵੇਰਿਫਿਕੇਸ਼ਨ ਕੋਡ ਸਫਲਤਾ ਨਾਲ ਭੇਜਿਆ ਗਿਆ ਹੈ।",
  verify_code_title: "ਅਸੀਂ ਤੁਹਾਨੂੰ ਪ੍ਰਮਾਣਿਤ ਕਰਨ ਲਈ ਇੱਕ ਕੋਡ ਭੇਜਿਆ ਹੈ!",
  enter_code: "ਕੋਡ ਦਰਜ ਕਰੋ",
  verify: "ਵੈਰੀਫਾਈ",
  resend_code: "ਕੋਡ ਦੁਬਾਰਾ ਭੇਜੋ",
  empty_code_error: "ਕਿਰਪਾ ਕਰਕੇ ਵੇਰਿਫਿਕੇਸ਼ਨ ਕੋਡ ਦਰਜ ਕਰੋ।",
  param_missing: "ਮੋਬਾਈਲ ਨੰਬਰ ਜਾਂ ਦੇਸ਼ ਕੋਡ ਗਲਤ ਹੈ।",
  verify_success:
    "ਯੂਜ਼ਰ ਦੀ ਸਫਲਤਾਪੂਰਵਕ ਤਸਦੀਕ ਕੀਤੀ ਗਈ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਪ੍ਰੋਫ਼ਾਈਲ ਬਣਾਉ।",
  password_hint:
    "ਤੁਹਾਨੂੰ ਇੱਕ ਮਜ਼ਬੂਤ ​​ਪਾਸਵਰਡ ਵਰਤਣ ਦੀ ਬੇਨਤੀ ਕੀਤੀ ਜਾਂਦੀ ਹੈ. ਪਾਸਵਰਡ 8 ਅੱਖਰਾਂ ਤੋਂ ਵੱਧ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ, 1 ਅਪਰਕੇਸ ਅਤੇ 1 ਅੰਕੀ ਜਾਂ ਵਿਸ਼ੇਸ਼ ਅੱਖਰ ਦੇ ਨਾਲ।",
  is_account_existing: "ਪਹਿਲਾਂ ਹੀ ਖਾਤਾ ਹੈ?",
  existing_account: "ਯੂਜ਼ਰ ਪਹਿਲਾਂ ਹੀ ਸਾਈਨ ਅੱਪ ਕੀਤਾ ਹੈ",
  weak: "ਕਮਜ਼ੋਰ",
  medium: "ਮੱਧਮ",
  strong: "ਮਜ਼ਬੂਤ",
  error: "ਗ਼ਲਤ ਸਫ਼ਾ",
  page_not_found: "ਪੰਨਾ ਮਿਲਿਆ ਨਹੀਂ",
  required_phone_number: "ਕਿਰਪਾ ਕਰਕੇ ਮੋਬਾਈਲ ਨੰਬਰ ਦਰਜ ਕਰੋ",
  forgot_pwd_title:
    "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਪਾਸਵਰਡ ਰੀਸੈਟ ਕਰਨ ਲਈ ਨਿਰਦੇਸ਼ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ ਆਪਣਾ ਮੋਬਾਈਲ ਨੰਬਰ ਦਰਜ ਕਰੋ.",
  account_error: "ਅਕਾਊਂਟ ਮੌਜੂਦ ਨਹੀਂ ਹੈ",
  cancel: "ਰੱਦ ਕਰੋ",
  skip: "ਛੱਡੋ",
  next: "ਅਗਲਾ",
  new: "ਨਵਾਂ",
  password_required: "ਕਿਰਪਾ ਕਰਕੇ ਪਾਸਵਰਡ ਦਾਖਲ ਕਰੋ।",
  confirm_password_error: "ਦੁਬਾਰਾ ਦਾਖਲ ਹੋਇਆ ਪਾਸਵਰਡ ਮੇਲ ਨਹੀਂ ਖਾਂਦਾ।",
  password_error: "ਨਵਾਂ ਪਾਸਵਰਡ ਪਿਛਲੇ ਇੱਕ ਵਰਗਾ ਨਹੀਂ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ।",
  follow_pwd_hint: "ਕਿਰਪਾ ਕਰਕੇ ਪਾਸਵਰਡ ਸੰਕੇਤ ਦੀ ਪਾਲਣਾ ਕਰੋ।",
  error_saving_pwd: "ਪਾਸਵਰਡ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰਨ ਵਿੱਚ ਗਲਤੀ।",
  reset: "ਰੀਸੈਟ ਕਰੋ",
  reset_pwd: "ਪਾਸਵਰਡ ਰੀਸੈਟ ਕਰੋ",
  enter_new_pwd: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਖਾਤੇ ਲਈ ਇੱਕ ਨਵਾਂ ਪਾਸਵਰਡ ਦਾਖਲ ਕਰੋ",
  confirm_password: "ਪਾਸਵਰਡ ਫਿਰਤੋਂ ਭਰੋ",
  verify_contact: "ਮੋਬਾਈਲ ਨੰਬਰ ਪ੍ਰਮਾਣਿਤ ਕਰੋ",
  verify_contact_text: "ਇੱਕ ਪੁਸ਼ਟੀਕਰਣ ਕੋਡ ਤੁਹਾਡੇ ਮੋਬਾਈਲ ਤੇ ਭੇਜਿਆ ਗਿਆ ਹੈ",
  invalid_code: "ਅਯੋਗ ਪੁਸ਼ਟੀਕਰਣ ਕੋਡ",
  invalid_username_pwd: "ਮੋਬਾਈਲ ਨੰਬਰ ਜਾਂ ਪਾਸਵਰਡ ਅਵੈਧ ਹੈ।",
  invalid_password: "ਪਾਸਵਰਡ ਅਵੈਧ ਹੈ।",
  short_pwd_error: "ਪਾਸਵਰਡ ਬਹੁਤ ਛੋਟਾ ਹੈ।",
  patientName: "ਮਰੀਜ਼ ਦਾ ਨਾਮ",
  doctorName: "ਡਾਕਟਰ ਦਾ ਨਾਂ",
  all: "ਸਭ",
  searchByPatientName: "ਮਰੀਜ਼ਨਾਂ ਦੁਆਰਾ ਖੋਜ ਕਰੋ",
  sign_in: "ਲਾਗਿਨ",
  log_in: "ਲਾਗਿਨ",
  terms_condition: "ਨਿਯਮ ਅਤੇ ਸਥਿਤੀ",
  contactUs: "ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
  status: {
    open: "ਓਪਨ",
    lab_Accepted: "ਲੈਬ ਨੇ ਸਵੀਕਾਰ ਕੀਤਾ",
    lab_report_generated: "ਲੈਬ ਰਿਪੋਰਟ ਤਿਆਰ ਕੀਤੀ ਗਈ",
    sample_picked_up: "ਸੈਮਪਲ ਚੁੱਕਿਆ",
    closed: "ਬੰਦ ਹੋ ਗਿਆ",
    cancelled: "ਰੱਦ ਕੀਤਾ",
    out_for_delivery: "ਡਿਲੀਵਰੀ ਲਈ ਬਾਹਰ",
    delivered: "ਦਿਲਿਵਰਡ",
    ready_for_pickup: "ਪਿਕਅੱਪ ਲਈ ਤਿਆਰ",
    confirmed: "ਪੱਕਾ"
  },
  signup: {
    sign_up_text: "ਸਾਈਨ ਅੱਪ",
    password_must_be: "ਪਾਸਵਰਡ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ",
    password_must_1: "8 ਤੋਂ ਵੱਧ ਅੱਖਰ",
    password_must_3: "1 ਅੰਕ ਜਾਂ ਵਿਸ਼ੇਸ਼ ਅੱਖਰ",
    doctorName: "ਡਾਕਟਰ ਦਾ ਨਾਂ",
    enter_name: "ਕਿਰਪਾ ਕਰਕੇ ਨਾਂ ਦਾਖਲ ਕਰੋ",
    enter_phone_number: "ਕਿਰਪਾ ਕਰਕੇ ਮੋਬਾਈਲ ਨੰਬਰ ਦਾਖਲ ਕਰੋ",
    agree_to: "ਸਹਿਮਤ ਹੋਵੋ",
    terms_n_conditions: "ਨਿਯਮ ਅਤੇ ਸ਼ਰਤਾਂ",
    have_account: "ਕੀ ਤੁਹਾਡੇ ਕੋਲ ਖਾਤਾ ਹੈ?",
    serverError: "ਤੁਹਾਡੀ ਬੇਨਤੀ ਦੀ ਪ੍ਰਕ੍ਰਿਆ ਕਰਦੇ ਸਮੇਂ ਕੁਝ ਅਸ਼ੁੱਧੀ ਹੋਈ ਸੀ",
    sec: "ਸੈਕੰਡ",
    agree_to_error: "ਕਿਰਪਾ ਕਰਕੇ ਕੁਲਕਰੇਸ ਦੀਆਂ ਸ਼ਰਤਾਂ ਤੇ ਸਾਈਨ ਅਪ ਕਰੋ"
  },
  signin: {
    sign_in_heading_text:
      "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਮੋਬਾਈਲ ਨੰਬਰ ਅਤੇ ਪਾਸਵਰਡ ਨਾਲ ਲਾਗਿਨ ਕਰੋ",
    keep_me_logged_in: "ਮੇਰਾ ਲਾਗਿਨ ਬਨ੍ਹਾਏ ਰੱਖੋ",
    need_have_account: "ਇੱਕ ਖਾਤੇ ਦੀ ਲੋੜ ਹੈ?"
  },
  forgotPassword: {
    forgot_heading_text: "ਆਪਣਾ ਮੋਬਾਈਲ ਨੰਬਰ ਦਰਜ ਕਰੋ"
  },
  changePassword: {
    text: "ਪਾਸਵਰਡ ਬਦਲੋ",
    submit: "ਜਮ੍ਹਾਂ ਕਰੋ",
    current_password: "ਮੌਜੂਦਾ ਪਾਸਵਰਡ",
    new_password: "ਨਵਾਂ ਪਾਸਵਰਡ",
    change_password_success_msg: "ਤੁਸੀਂ ਆਪਣਾ ਪਾਸਵਰਡ ਸਫਲਤਾਪੂਰਵਕ ਬਦਲ ਲਿਆ ਹੈ",
    current_pwd_incorrect: "ਮੌਜੂਦਾ ਪਾਸਵਰਡ ਗਲਤ ਹੈ"
  },
  profile: {
    invitationSent: "ਡਾਕਟਰ ਨੂੰ ਸੱਦਾ ਭੇਜਿਆ ਗਿਆ.",
    invite: "ਸੱਦਾ",
    find: "Find",
    enterCity: "ਕਿਰਪਾ ਕਰਕੇ ਸ਼ਹਿਰ ਦਾਖਲ ਕਰੋ!",
    emptyText: "ਖਾਲੀ ਨਹੀਂ ਹੋ ਸਕਦਾ",
    firstAddressLine: "ਐਡਰੈੱਸ ਲਾਈਨ 1",
    zipcode: "ਪਿੰਨਕੋਡ",
    open24: "ਓਪਨ 24x7",
    timings: "ਸਮੇਂ",
    save: "ਸੇਵ",
    city: "ਸ਼ਹਿਰ",
    searchPlaces: "ਥਾਵਾਂ ਲੱਭੋ",
    state: "ਰਾਜ",
    schedule: "ਅਨੁਸੂਚੀ",
    mon: "ਸੋਮ",
    tue: "ਮੰਗਲ",
    wed: "ਬੁੱਧ",
    thu: "ਵੀਰ",
    fri: "ਸ਼ੁੱਕਰ",
    sat: "ਸ਼ਨੀ",
    sun: "ਐਤ",
    startTime: "ਸ਼ੁਰੂਆਤੀ ਸਮਾਂ",
    closingTime: "ਸਮਾਪਤੀ ਸਮਾਂ",
    discounted_schedule: "ਛੂਟ ਵਾਲਾ ਸਮਾਂ",
    regular_schedule: "ਨਿਯਮਤ ਸਮਾਂ",
    complete_profile: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਕਲੀਨਿਕ ਪ੍ਰੋਫਾਈਲ ਪੂਰਾ ਕਰੋ",
    confirm: "confirm"
  },
  support: {
    helpText:
      "ਹੈਲੋ, ਕਿਰਪਾ ਕਰਕੇ ਸਾਨੂੰ ਦੱਸੋ ਕਿ ਤੁਸੀਂ ਕੀ ਜਾਣਨਾ ਚਾਹੁੰਦੇ ਹੋ ਅਤੇ ਅਸੀਂ ਤੁਹਾਡੀ ਮਦਦ ਲਈ ਥੋੜ੍ਹੇ ਸਮੇਂ ਵਿੱਚ ਸਾਨੂੰ ਕਾਲ ਕਰਾਂਗੇ",
    msgError: "ਕਿਰਪਾ ਕਰਕੇ ਤੁਹਾਡੀ ਮਦਦ ਲਈ ਕੁਝ ਲਿਖੋ",
    msgPlaceholder: "ਸਮੱਸਿਆ ਦਾ ਮੈਂ ਸਾਹਮਣਾ ਕਰਦਾ ਹਾਂ",
    offlineMsg:
      "ਹੈਲੋ! ਤੁਸੀਂ ਇਸ ਸਮੇਂ ਔਫਲਾਈਨ ਹੋ ਤੁਸੀਂ ਸਾਨੂੰ ਇੱਕ ਸੰਦੇਸ਼ ਭੇਜ ਸਕਦੇ ਹੋ ਜਾਂ ਇਸ ਨੰਬਰਾਂ 'ਤੇ + ​​91-9773905392' ਤੇ ਸਾਨੂੰ ਕਾਲ ਕਰ ਸਕਦੇ ਹੋ",
    successMsg: "ਸਾਨੂੰ ਤੁਹਾਡੀ ਸਮੱਸਿਆ ਆਈ ਹੈ ਅਤੇ ਅਸੀਂ ਛੇਤੀ ਹੀ ਵਾਪਸ ਕਾਲ ਕਰਾਂਗੇ"
  },
  generalNote: "ਕੀ ਤੁਸੀਂ ਕਲੀਨਿਕ ਵਿਸ਼ੇਸ਼ ID ਤਿਆਰ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
  generalPrefix: "ਕਲੀਨਿਕ ਵਿਸ਼ੇਸ਼ ID ਪ੍ਰੀਫਿਕਸ",
  general: "ਜਨਰਲ",
  doctors: {
    errorMsg: "ਕਿਰਪਾ ਕਰਕੇ ਵੈਧ 10 ਡਿਜ਼ੀਟ ਮੋਬਾਇਲ ਨੰਬਰ ਦਾਖਲ ਕਰੋ.",
    phoneNumber: "ਮੋਬਾਈਲ ਨੰਬਰ",
    noDocFound:
      "ਇਸ ਨੰਬਰ ਨਾਲ ਕੁੁਲਕੇਅਰ ਤੇ ਕੋਈ ਡਾਕਟਰ ਰਜਿਸਟਰ ਨਹੀਂ ਹੋਇਆ. ਉਨ੍ਹਾਂ ਨੂੰ ਸੱਦਾ ਦਿਓ!",
    wantToAdd: "ਕੀ ਤੁਸੀਂ ਜੋੜਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    wantToRefer: "ਕੀ ਤੁਸੀਂ ਸੰਦਰਭਨਾ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    addDoctor: "ਐੱਡ ਡਾਕਟਰ",
    typeToSearchDoc: "ਡਾਕਟਰ ਦੇ 10 ਅੰਕਾਂ ਦਾ ਮੋਬਾਈਲ ਨੰਬਰ ਦਾਖਲ ਕਰੋ",
    daily: "ਰੋਜ਼ਾਨਾ",
    name: "ਨਾਮ",
    speciality: "ਸਪੈਸ਼ਲਿਟੀ",
    fees: "ਫੀਸ",
    feesPlaceholder: "ਫੀਸ ਦਾਖਲ ਕਰੋ",
    days: "ਦਿਨ",
    saveDoctor: "ਸੇਵ ਡਾਕਟਰ",
    saveDoctorSuccess: "ਡਾਕਟਰ ਦੀ ਜਾਣਕਾਰੀ ਸਫਲਤਾਪੂਰਵਕ ਸੰਭਾਲੀ ਗਈ",
    selectDoctorError: "ਕਿਰਪਾ ਕਰਕੇ ਡਾਕਟਰ ਦੀ ਭਾਲ ਕਰੋ ਅਤੇ ਚੁਣੋ",
    updateFees: "ਅਪਡੇਟ ਫੀਸ",
    emptyFeesError: "ਕਿਰਪਾ ਕਰਕੇ ਫ਼ੀਸ ਦੀ ਰਕਮ ਦਾਖ਼ਲ ਕਰੋ",
    updatePatientCount: "ਮਰੀਜ਼ ਦੀ ਗਿਣਤੀ ਨੂੰ ਅਪਡੇਟ ਕਰੋ",
    inValidPatientCount: "ਕਿਰਪਾ ਕਰਕੇ ਵੈਧ ਮਰੀਜ਼ ਦੀ ਗਿਣਤੀ ਦਾਖਲ ਕਰੋ",
    updateDoctor: "ਡਾਕਟਰ ਦੀ ਜਾਣਕਾਰੀ ਅਪਡੇਟ ਕਰੋ",
    updateDoctorSuccess: "ਡਾਕਟਰ ਦੀ ਜਾਣਕਾਰੀ ਸਫਲਤਾਪੂਰਵਕ ਅਪਡੇਟ ਕੀਤੀ ਗਈ",
    removeDoctor: "ਡਾਕਟਰ ਹਟਾਓ",
    removeDoctorSuccess: "ਡਾਕਟਰ ਹਟਾ ਦਿੱਤਾ ਗਯਾ ਹੈ",
    noMatch: "ਕੋਈ ਮੇਲ ਨਹੀਂ ਮਿਲਿਆ",
    addSchedule: "ਅਨੁਸੂਚੀ ਜੋੜੋ",
    backToList: "ਸੂਚੀ ਵਿੱਚ ਵਾਪਸ ਜਾਓ",
    notAvailable: "ਉਪਲਭਦ ਨਹੀ",
    patientsPerHour: "ਮਰੀਜ਼/ ਘੰਟਾ",
    id: "ਆਈਡੀ",
    gender: "ਲਿੰਗ",
    removeDoctorConfirmation:
      "ਕੀ ਤੁਸੀਂ ਨਿਸ਼ਚਤ ਰੂਪ ਤੋਂ ਇਸ ਡਾਕਟਰ ਨੂੰ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    remove: "ਹਟਾਓ",
    updateSchedule: "ਅਪਡੇਟ ਅਨੁਸੂਚੀ",
    removed: "ਸਫਲਤਾਪੂਰਵਕ ਹਟਾ ਦਿੱਤਾ ਗਿਆ",
    scheduleInfo: "ਅਨੁਸੂਚੀ ਦੀ ਜਾਣਕਾਰੀ ",
    addedSuccess: "ਸਫਲਤਾ ਨਾਲ ਸ਼ਾਮਿਲ ਕੀਤਾ ਗਿਆ",
    emptyDays: "ਦਿਨ ਚੁਣੋ ਜੀ",
    emptyTimings: "ਕਿਰਪਾ ਕਰਕੇ ਸਮੇਂ ਦੀ ਚੋਣ ਕਰੋ",
    invalidTiming: "ਕਿਰਪਾ ਕਰਕੇ ਵੈਧ ਸਮਾਂ ਚੁਣੋ",
    removeScheduleConfirmText:
      "ਕੀ ਤੁਸੀਂ ਨਿਸ਼ਚਤ ਰੂਪ ਨਾਲ ਇਸ ਅਨੁਸੂਚੀ ਨੂੰ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    selectLocationError: "ਕਿਰਪਾ ਕਰਕੇ ਸ਼ਹਿਰ ਸ਼ਾਮਿਲ ਕਰੋ",
    doctorName: "ਡਾਕਟਰ ਦਾ ਨਾਂ",
    noDoc: "ਕੋਈ ਡਾਕਟਰ ਨਹੀਂ ਮਿਲਿਆ ...",
    doctor_info: "ਡਾਕਟਰ ਦੀ ਜਾਣਕਾਰੀ",
    resend: "ਦੁਬਾਰਾ ਭੇਜੋ"
  },
  leftnav: {
    Doctors: "ਡਾਕਟਰ",
    Billing: "ਬਿਲਿੰਗ",
    Patients: "ਮਰੀਜ਼",
    Todays_Queue: "ਅੱਜ ਦੀ ਕਤਾਰ",
    Settings: "ਸੈਟਿੰਗ",
    Appointments: "ਮੁਲਾਕਾਤਾਂ",
    Labs: "ਲੈਬਜ਼",
    Pharmacies: "ਫਾਰਮੇਸੀਆਂ",
    Referrals: "ਰੈਫਰਲ",
    staff: "ਸਟਾਫ਼",
    Business: "ਕਾਰੋਬਾਰ",
    Help: "ਮਦਦ"
  },
  serviceName: "ਸੇਵਾ ਦਾ ਨਾਮ",
  price: "ਕੀਮਤ",
  action: "ਐਕਸ਼ਨ",
  mandatoryService: "ਸੇਵਾ ਨਾਮ ਖਾਲੀ ਨਹੀਂ ਹੋ ਸਕਦਾ",
  mandatoryPrice: "ਕੀਮਤ ਖਾਲੀ ਨਹੀਂ ਹੋ ਸਕਦੀ",
  billNo: "ਬਿਲ #",
  patientId: "ਮਰੀਜ਼ ਆਈਡੀ",
  billDate: "ਬਿਲ ਤਾਰੀਖ",
  billStatus: "ਬਿਲ ਸਥਿਤੀ",
  unitPrice: "ਯੂਨਿਟ ਮੁੱਲ",
  service: "ਸੇਵਾ",
  discount: "ਛੂਟ",
  printBill: "ਪ੍ਰਿੰਟ ਬਿਲ",
  print_patient_details: "ਮਰੀਜ਼ ਦੇ ਵੇਰਵੇ ਛਾਪੋ",
  add_next_visit: "ਅਗਲੀ ਮੁਲਾਕਾਤ ਸ਼ਾਮਿਲ ਕਰੋ",
  print_patient_details_confirm:
    "ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ ਤੇ ਰੋਗੀ ਵੇਰਵੇ ਛਾਪਣੀ ਚਾਹੁੰਦੇ ਹੋ?",
  print: "ਪ੍ਰਿੰਟ",
  gst: "ਜੀਐਸਟੀ",
  billAmount: "ਬਿਲ ਮਾਤਰਾ",
  amountReceived: "ਪ੍ਰਾਪਤ ਕੀਤੀ ਰਕਮ",
  amountPending: "ਰਕਮ ਬਾਕੀ",
  noBills: "ਕੋਈ ਬਿਲ ਨਹੀਂ",
  total: "ਕੁੱਲ",
  cash: "ਨਕਦ",
  card: "ਕਾਰਡ",
  noAmount: "ਕੋਈ ਰਕਮ ਨਹੀਂ",
  others: "ਹੋਰ",
  billingSummary: "ਭੁਗਤਾਨ ਦਾ .ੰਗ",
  billingAmount: "ਕਮਾਈ ਦਾ ਸਾਰ",
  noData: "ਕੋਈ ਡਾਟਾ ਨਹੀਂ",
  services: "ਸੇਵਾਵਾਂ",
  modePayment: "ਭੁਗਤਾਨ ਦੀ ਵਿਧੀ",
  mode: "ਵਿਧੀ",
  view: "ਵੇਖੋ",
  serviceList: "ਸੇਵਾਵਾਂ",
  paid: "ਵੇਤਨਕ",
  unpaid: "ਅਵੇਤਨਕ",
  male: "ਮਰਦ",
  services_already_exists: "ਸੇਵਾ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ",
  service_add_success_msg: "ਸੇਵਾ ਸਫਲਤਾਪੂਰਵਕ ਸ਼ਾਮਿਲ ਕੀਤੀ ਗਈ",
  service_add_error_msg: "ਸੇਵਾ ਜੋੜਦੇ ਸਮੇਂ ਕੁਝ ਗਲਤੀ ਆਈ ਸੀ",
  service_edit_success_msg: "ਸੇਵਾ ਸਫਲਤਾਪੂਰਵਕ ਅਪਡੇਟ ਕੀਤੀ ਗਈ",
  service_edit_error_msg: "ਸੇਵਾ ਅਪਡੇਟ ਕਰਦੇ ਸਮੇਂ ਕੁਝ ਤਰੁਟੀ ਸੀ",
  service_delete_success_msg: "ਸੇਵਾ ਸਫਲਤਾਪੂਰਵਕ ਹਟਾਈ ਗਈ",
  service_delete_error_msg: "ਸੇਵਾ ਹਟਾਉਣ ਦੌਰਾਨ ਕੁਝ ਅਸ਼ੁੱਧੀ ਹੋਈ ਸੀ",
  service_form_validation_msg: "ਕੋਈ ਨਾਂ ਜਾਂ ਕੀਮਤ ਵੈਧ ਨਹੀਂ ਹੈ",
  no_bill_text: "ਚੁਣੀ ਗਈ ਅਵਧੀ ਦੇ ਦੌਰਾਨ ਕੋਈ ਬਿਲ ਨਹੀਂ ਉਤਪੰਨ ਕੀਤਾ ਗਿਆ",
  no_bill_patient_text: "ਇਸ ਮਰੀਜ਼ ਲਈ ਕੋਈ ਬਿੱਲ ਤਿਆਰ ਨਹੀਂ",
  download_report: "Download Report",
  queue: {
    select_doctor: "ਡਾਕਟਰ ਦੀ ਚੋਣ ਕਰੋ",
    no_timings_text: "ਡਾ {doctorName} ਲਈ ਕੋਈ ਸਮਾਂ ਉਪਲਬਧ ਨਹੀਂ",
    no_timings_for_doc: "ਡਾ ਲਈ ਕੋਈ ਸਮਾਂ ਉਪਲਬਧ ਨਹੀਂ",
    no_appointment_text: "ਕਤਾਰ ਵਿਚ ਕੋਈ ਮਰੀਜ਼ ਨਹੀਂ",
    no_doctors_text: "ਡਾਕਟਰ ਉਪਲੱਬਧ ਨਹੀਂ ਹਨ",
    WithDoc: "ਡਾਕੋਟਰ ਨਾਲ",
    InQueue: "ਕਤਾਰ ਵਿਚ",
    Check_out: "ਚੈੱਕ ਆਉਟ",
    CheckedOut: "ਚੈੱਕ ਆਉਟ",
    CheckedIn: "ਚੈੱਕ ਇਨ",
    NotConfirmed: "ਪੱਕਾ ਨਹੀਂ ਹੈ",
    Remove: "ਹਟਾਓ",
    all: "ਸਭ",
    token_number: "ਟੋਕਨ ਨੰਬਰ",
    ref_by: "ਦੁਆਰਾ",
    generate_bill: "ਬਿਲ ਬਣਾਉ",
    view_bill: "ਬਿੱਲ ਵੇਖੋ",
    new_appointment: "ਨਵੀਂ ਨਿਯੁਕਤੀ!",
    new_appointment_desc: "ਨਿਯੁਕਤੀ ਸਫਲਤਾਪੂਰਵਕ ਬਣਾਈ ਗਈ ਹੈ",
    new_patient_desc: "New patient has been added to the queue",
    new_patient: "ਮਰੀਜ਼ ਨੂੰ ਜੋੜਿਆ ਗਿਆ",
    new_patient_desc: "ਨਵੀਂ ਮਰੀਜ਼ ਨੂੰ ਕਤਾਰ ਵਿੱਚ ਸ਼ਾਮਲ ਕੀਤਾ ਗਿਆ ਹੈ",
    no_bills_error: "ਇਸ ਨਿਯੁਕਤੀ ਲਈ ਬਿੱਲ ਤਿਆਰ ਨਹੀਂ ਕੀਤਾ ਗਿਆ ਹੈ",
    payment_recieved: "ਭੁਗਤਾਨ ਸਫਲਤਾਪੂਰਵਕ ਪ੍ਰਾਪਤ ਹੋਇਆ",
    add_referral_code: "ਰੈਫ਼ਰਲ ਕੋਡ ਜੋੜੋ",
    referral_code: "ਰੈਫ਼ਰਲ ਕੋਡ",
    empty_referral_error: "ਕਿਰਪਾ ਕਰਕੇ ਰੈਫ਼ਰਲ ਕੋਡ ਦਾਖਲ ਕਰੋ.",
    confirmDeleteAppointment:
      "ਕੀ ਤੁਸੀਂ ਨਿਸ਼ਚਤ ਰੂਪ ਤੋਂ ਇਸ ਅਪੋਇੰਟਮੈਂਟ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    confirmDeleteFollowUpAppointment:
      "ਕੀ ਤੁਸੀਂ ਨਿਸ਼ਚਤ ਰੂਪ ਤੋਂ ਇਸ ਅਪੋਇੰਟਮੈਂਟ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    deleteAppointmentSuccess: "ਅਪੋਇੰਟਮੈਂਟ ਸਫਲਤਾਪੂਰਵਕ ਹਟਾ ਦਿੱਤੀ ਗਈ।",
    add_vitals: "ਵਏਟਲਸ ਐੱਡ ਕਰੋ",
    checkIn: "ਚੈਕ ਇਨ",
    docCheckedInMsg: "ਡਾਕਟਰ ਨੇ ਸਫਲਤਾ ਨਾਲ ਚੈਕ ਇਨ ਕੀਤਾ",
    add_patient: "ਮਰੀਜ਼ ਨੂੰ ਸ਼ਾਮਿਲ ਕਰੋ",
    no_patient_with_filter: `ਕੋਈ ਮਰੀਜ਼ ਦਰਜ ਨੀ ਹੂਯਾ "{status}" ਕਤਾਰ ਚ`,
    view_all: "ਸਭ ਨੂੰ ਵੇਖੋ"
  },
  selectServiceName: "ਕਿਰਪਾ ਕਰਕੇ ਸੇਵਾ ਦਾ ਨਾਮ ਚੁਣੋ",
  discountExceeded: "ਛੂਟ ਮੁੱਲ ਕੀਮਤ ਤੋਂ ਵੱਧ ਨਹੀਂ ਹੋਣੇ ਚਾਹੀਦੇ",
  receivePayment: "ਭੁਗਤਾਨ ਪ੍ਰਾਪਤ ਕਰੋ",
  recieve_payment_confirm:
    "ਬਿਲ ਬਣਾਉ ਹੋਣ ਤੋਂ ਬਾਅਦ, ਤੁਸੀਂ ਇਸ ਬਿਲ ਨੂੰ ਸੰਪਾਦਿਤ ਨਹੀਂ ਕਰ ਸਕਦੇ. ਕੀ ਤੁਸੀਂ ਅੱਗੇ ਵੱਧਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  emptyPrice: "ਕਿਰਪਾ ਕਰਕੇ ਕੀਮਤ ਦਾਖਲ ਕਰੋ",
  patientsPerHourNo: "ਮਰੀਜ਼ / ਘੰਟਾ",
  please_confirm: "ਕ੍ਰਿਪਾ ਕਰਕੇ ਪੁਸ਼ਟੀ ਕਰੋ",
  queue_status_confirm_desc:
    "ਕੀ ਤੁਸੀਂ ਨਿਸ਼ਚਤ ਰੂਪ ਤੋਂ ਇਸ ਮਰੀਜ਼ ਨੂੰ {status} ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
  age: "ਉਮਰ",
  lastAppointment: "ਪਿਛਲੀ ਅਪੋਇੰਟਮੈਂਟ",
  AppointmentBillDetails: {
    YourReceipt: "ਤੁਹਾਡੀ ਰਸੀਦ",
    PatientName: "ਮਰੀਜ਼ ਦਾ ਨਾਮ",
    PatientID: "ਰੋਗੀ ਆਈਡੀ",
    DoctorsName: "ਡਾਕਟਰ ਦਾ ਨਾਂ",
    BillDate: "ਬਿਲ ਤਾਰੀਖ",
    BillNo: "ਬਿਲ ਨੰ",
    BillStatus: "ਬਿਲ ਸਥਿਤੀ",
    ServiceDetails: "ਸੇਵਾ ਦੇ ਵੇਰਵੇ",
    BillAmount: "ਬਿਲ ਮਾਤਰਾ",
    GST: "ਜੀਐਸਟੀ",
    Discount: "ਛੂਟ",
    Total: "ਕੁੱਲ",
    PoweredBy: "ਦੁਆਰਾ ਸੰਚਾਲਿਤ",
    Bill: "ਬਿਲ",
    Amount: "ਦੀ ਰਕਮ",
    NoSignatureText:
      "ਇਹ ਇੱਕ ਕੰਪਿਊਟਰ ਦੁਆਰਾ ਤਿਆਰ ਦਸਤਾਵੇਜ਼ ਹੈ। ਕੋਈ ਹਸਤਾਖਰ ਦੀ ਲੋੜ ਨਹੀਂ।"
  },
  today: "ਅੱਜ",
  year: "ਸਾਲ",
  month: "ਮਹੀਨਾ",
  unidentifiedService: "ਅਣਪਛਾਤੇ ਸੇਵਾ",
  billGenerated: "ਬਿਲ ਸਫਲਤਾਪੂਰਵਕ ਸੁਰੱਖਿਅਤ ਹੋਇਆ",
  serviceAlreadyAdded: "ਸੇਵਾ ਪਹਿਲਾਂ ਹੀ ਸ਼ਾਮਿਲ ਕੀਤੀ ਗਈ ਹੈ",
  paymentReceived: "ਭੁਗਤਾਨ ਸਫਲਤਾਪੂਰਵਕ ਪ੍ਰਾਪਤ ਹੋਇਆ",
  addAppointmentsView: {
    AppointmentTime: "ਮੁਲਾਕਾਤ ਦਾ ਸਮਾਂ",
    SelectAppointmentType: "ਅਪਾਇੰਟਮੈਂਟ ਪ੍ਰਕਾਰ ਚੁਣੋ!",
    SelectAppointmentTime: "ਨਿਯੁਕਤੀ ਸਮਾਂ ਚੁਣੋ!",
    Male: "ਮਰਦ",
    Female: "ਔਰਤ",
    SelectPatientGender: "ਮਰੀਜ਼ ਦਾ ਲਿੰਗ ਚੁਣੋ!",
    PatientDOB: "ਮਰੀਜ਼ ਡਾਇਬ",
    EnterPatientName: "ਮਰੀਜ਼ ਦਾ ਨਾਮ ਦਰਜ ਕਰੋ",
    EnterPhoneNumber: "ਮੋਬਾਈਲ ਨੰਬਰ ਦਾਖਲ ਕਰੋ",
    invalidPhone: "ਅਵੈਧ ਮੋਬਾਈਲ ਨੰਬਰ!",
    SearchPatient: "ਮਰੀਜ਼ ਨੂੰ ਲੱਭੋ",
    AddAppointment: "ਨਿਯੁਕਤੀ ਸ਼ਾਮਲ ਕਰੋ",
    UpdateAppointment: "ਨਿਯੁਕਤੀ ਅਪਡੇਟ ਕਰੋ",
    year: "ਸਾਲ",
    month: "ਮਹੀਨਾ",
    day: "ਦਿਨ",
    patientPhoneNumber: "ਪੇਸ਼ੈਂਟ ਮੋਬਾਈਲ ਨੰਬਰ",
    patientPhonenumber: "ਪੇਸ਼ੈਂਟ ਮੋਬਾਈਲ ਨੰਬਰ",
    dateOfBirth: "ਜਨਮ ਤਾਰੀਖ",
    WalkIn: "ਵਾਲਕ ਇਨ ",
    OnCall: "ਓੰ ਕਾਲ ",
    Telephonic: "ਟੈਲੀਮੋਬਾਈਲਿਕ",
    fixed: "ਫ਼ਿਕਸਦ",
    time_to_doctor: "ਡਾਕਟਰ ਨੂੰ ਮਿਲਣ ਦਾ ਸਮਾਂ",
    typeOfAppointment: "ਅਪਾਇੰਟਮੈਂਟ ਦੀ ਕਿਸਮ",
    type: "ਕਿਸਮ",
    newAppointmentError: "ਨਵੀਂ ਨਿਯੁਕਤੀ ਬਣਾਉਣ ਸਮੇਂ ਕੁਝ ਗਲਤੀ ਹੋਈ ਸੀ",
    addressLineOne: "ਐਡਰੈੱਸ ਲਾਈਨ 1",
    addressLineTwo: "ਐਡਰੈੱਸ ਲਾਈਨ 2",
    invalid_date_error: "ਕਿਰਪਾ ਕਰਕੇ ਜਨਮ ਦੀ ਵੈਧ ਮਿਤੀ ਦਰਜ ਕਰ"
  },
  unauthorized: "ਅਣਅਧਿਕਾਰਤ",
  signInContinue: "ਜਾਰੀ ਰੱਖਣ ਲਈ ਕਿਰਪਾ ਕਰਕੇ ਲਾਗਿਨ ਕਰੋ",
  Labs: {
    LabsListContainer: {
      LabAdded: "ਲੈਬ ਜੋੜੀ ਗਈ!",
      addedSuccessfully: " ਨੂੰ ਸਫਲਤਾ ਨਾਲ ਸ਼ਾਮਿਲ ਕੀਤਾ ਗਿਆ ਹੈ.",
      LabAlreadyExists: "ਲੈਬ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ",
      LabDeleted: "ਲੈਬ ਮਿਟਾਈ ਗਈ!",
      LabHasBeenDeletedSucessfully: "ਲੈਬ ਸਫਲਤਾਪੂਰਵਕ ਮਿਟਾ ਦਿੱਤਾ ਗਿਆ ਹੈ.",
      AddLab: "ਲੈਬ ਸ਼ਾਮਲ ਕਰੋ",
      LabFromYourList: "ਤੁਹਾਡੀ ਸੂਚੀ ਤੋਂ?",
      LabToYourList: "ਤੁਹਾਡੀ ਸੂਚੀ ਤੋਂ?",
      DeleteLab: "ਲੈਬ ਹਟਾਓ"
    },
    LabsListView: {
      SearchWithLabName: "ਲੈਬ ਨਾਮ ਨਾਲ ਖੋਜੋ"
    }
  },
  Pharmacy: {
    PharmacyListContainer: {
      success: "ਸਫ਼ਲਤਾ",
      PharmacyAdded: "ਫਾਰਮੇਸੀ ਜੋੜੀ ਗਈ!",
      addedSuccessfully: " ਨੂੰ ਸਫਲਤਾ ਨਾਲ ਸ਼ਾਮਿਲ ਕੀਤਾ ਗਿਆ ਹੈ.",
      error: "ਗਲਤੀ",
      Error: "ਗਲਤੀ!",
      pharmacyExists: "ਫਾਰਮੇਸੀ ਪਹਿਲਾਂ ਤੋਂ ਮੌਜੂਦ ਹੈ",
      pharmacyAddedSuccessfully: "ਫਾਰਮੇਸੀ ਨੂੰ ਸਫਲਤਾ ਨਾਲ ਸ਼ਾਮਿਲ ਕੀਤਾ ਗਿਆ ਹੈ.",
      PharmacyDeleted: "ਫਾਰਮੇਸੀ ਹਟਾਈ ਗਈ!",
      PharmacyDeletedSuccessfully: "ਫਾਰਮੇਸੀ ਨੂੰ ਸਫਲਤਾ ਨਾਲ ਮਿਟਾ ਦਿੱਤਾ ਗਿਆ ਹੈ.",
      DoYouWantToAdd: "ਕੀ ਤੁਸੀਂ ਜੋੜਨਾ ਚਾਹੁੰਦੇ ਹੋ",
      PharmacyFromList: "ਤੁਹਾਡੀ ਸੂਚੀ ਤੋਂ?",
      PharmacyToList: "ਤੁਹਾਡੀ ਸੂਚੀ ਤੋਂ?",
      DoYouWantToDelete: "ਕੀ ਤੁਸੀਂ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ? ",
      AddPharmacy: "ਫਾਰਮੇਸੀ ਜੋੜੋ",
      DeletePharmacy: "ਫਾਰਮੇਸੀ ਮਿਟਾਓ",
      EnterPhoneNumber: "ਇੱਕ ਵੈਧ ਮੋਬਾਈਲ ਨੰਬਰ ਦਰਜ ਕਰੋ",
      EnterMobileNumber: "ਇੱਕ ਵੈਧ ਮੋਬਾਈਲ ਨੰਬਰ ਦਾਖਲ ਕਰੋ",
      EnterName: "ਨਾਮ ਦਰਜ ਕਰੋ",
      EnterAddressLine1: "ਐਡਰੈੱਸ ਲਾਈਨ 1 ਦਰਜ ਕਰੋ",
      EnterAddressLine2: "ਐਡਰੈੱਸ ਲਾਈਨ 2 ਦਰਜ ਕਰੋ",
      EnterZipcode: "ਪਿੰਨਕੋਡ ਦਰਜ ਕਰੋ",
      SearchPlaces: "ਸਥਾਨਾਂ ਦੀ ਖੋਜ ਕਰੋ"
    },
    PharmacyListView: {
      Name: "ਨਾਮ",
      PhoneNumber: "ਮੋਬਾਈਲ ਨੰਬਰ",
      Location: "ਸਥਾਨ",
      Delete: "ਮਿਟਾਓ",
      SearchWithPharmacyName: "ਫਾਰਮੇਸੀ ਨਾਮ ਨਾਲ ਖੋਜ ਕਰੋ"
    }
  },
  vitals: {
    delete: "ਮਿਟਾਓ",
    text: "ਵਾਏਟਲ",
    vital_name: "ਵਾਏਟਲ ਦਾ ਨਾਮ",
    vital_name_empty: "ਵਾਏਟਲ ਖਾਲੀ ਨਹੀਂ ਹੋ ਸਕਦੇ",
    remove_vital: "ਵਾਏਟਲ ਹਟਾਓ",
    remove_vital_confirmation:
      "ਕੀ ਤੁਸੀਂ ਨਿਸ਼ਚਤ ਰੂਪ ਤੋਂ ਜ਼ਰੂਰੀ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    vitals_not_present: "ਵਿਤਲਸ ਮੌਜੂਦ ਨਹੀ ਹੈ"
  },
  patients: {
    search: "ਖੋਜ",
    searchText: "ਨਾਮ, ਆਈਡੀ ਜਾਂ ਮੋਬਾਈਲ ਨੰਬਰ ਨਾਲ ਖੋਜ",
    photos_attached: "ਤੁਸੀਂ ਫੋਟੋਆਂ ਨੂੰ ਜੋੜਿਆ ਹੈ",
    patient_visit: "ਰੋਗੀ ਨੇ ਤੁਹਾਨੂੰ ਦੌਰਾ ਕੀਤਾ"
  },
  careplan: {
    clear_prescription: "ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਹਟਾਓ",
    backSetup: "ਸ਼ੁਰੂਆਤੀ ਗਾਈਡ ਤੇ ਵਾਪਸ",
    prescriptionTemplate: "ਡਾਕਟਰੀ ਟੈਪਲੇਟ",
    templateSubtitle:
      "ਕਿਸੇ ਡਾਕਟਰ ਦੀ ਚੋਣ ਕਰੋ ਅਤੇ ਉਹਨਾਂ ਦੇ ਪ੍ਰਿੰਸਟ੍ਰਕਸ਼ਨ ਖਾਕੇ ਸੈੱਟ ਕਰੋ. ਇਹ ਟੈਂਪਲੇਟਾਂ ਨੁਸਖ਼ਾ ਤਿਆਰ ਕਰਨ ਵੇਲੇ ਵਰਤੀਆਂ ਜਾ ਸਕਦੀਆਂ ਹਨ ਤਾਂ ਜੋ ਡਾਕਟਰ ਹਰ ਵਾਰ ਹਰ ਚੀਜ਼ ਨੂੰ ਦੁਬਾਰਾ ਟਾਈਪ ਨਾ ਕਰ ਸਕਣ.",
    adviceGroupAdded: "ਸਲਾਹ ਗਰੁੱਪ ਜੋੜਿਆ ਗਿਆ",
    adviceGroupSuccess: "ਸਲਾਹ ਸਮੂਹ ਸਫਲਤਾਪੂਰਵਕ ਜੋੜਿਆ ਗਿਆ",
    unableToAdd: "ਜੋੜਨ ਵਿੱਚ ਅਸਮਰੱਥ",
    errorAddingGroup: "ਗਰੁੱਪ ਜੋੜਨ ਵਿੱਚ ਗਲਤੀ",
    updatedAdviceGroup: "ਅਪਡੇਟ ਕੀਤੇ ਸਲਾਹ ਸਮੂਹ",
    updateAdviceGroup: "ਸਲਾਹ ਸਮੂਹ ਸਫਲਤਾਪੂਰਕ ਅਪਡੇਟ ਕੀਤਾ ਗਿਆ",
    errorUpdatingGroup: "ਗਰੁੱਪ ਨੂੰ ਅਪਡੇਟ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ",
    unableToUpdate: "ਅਪਡੇਟ ਕਰਨ ਵਿੱਚ ਅਸਮਰੱਥ",
    adviceGroupExists: "ਗਰੁੱਪ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ",
    groupDeleted: "ਸਲਾਹ ਸਮੂਹ ਮਿਟਾਇਆ",
    adviceDeleted: "ਸਲਾਹ ਸਮੂਹ ਸਫਲਤਾਪੂਰਵਕ ਮਿਟਾਇਆ ਗਿਆ",
    adviceGroupName: "ਸਲਾਹ ਗਰੁੱਪ ਦਾ ਨਾਮ",
    adviceSubtitle: "ਉਪਸਿਰਲੇਖ",
    emptyAdvice: "ਸਲਾਹ ਗਰੁੱਪ ਖਾਲੀ ਨਹੀਂ ਹੋ ਸਕਦਾ.",
    enterAdviceGroupName: "ਇੱਕ ਸਮੂਹ ਦਾ ਨਾਂ ਦਾਖਲ ਕਰੋ",
    emptyAdviceName: "ਸਲਾਹ ਖਾਲੀ ਨਹੀਂ ਹੋ ਸਕਦੀ",
    enterAdviceName: "ਸਲਾਹ ਦਰਜ ਕਰੋ",
    dietGroupAdded: "ਡਾਈਟ ਗਰੁੱਪ ਸ਼ਾਮਿਲ ਕੀਤਾ",
    dietGroupAddedSuccess: "ਡਾਈਟ ਗਰੁੱਪ ਸਫਲਤਾ ਨਾਲ ਸ਼ਾਮਿਲ ਕੀਤਾ ਗਿਆ ਹੈ",
    dietGroupUpdated: "ਡਾਈਟ ਗਰੁੱਪ ਅਪਡੇਟ ਕੀਤਾ",
    dietGroupUpdatedSuccess: "ਡਾਇਟ ਗਰੁੱਪ ਸਫਲਤਾਪੂਰਵਕ ਅਪਡੇਟ ਕੀਤਾ ਗਿਆ",
    errorUpdatingDietGroup: "ਡਾਈਟ ਗਰੁੱਪ ਨੂੰ ਅਪਡੇਟ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ",
    dietGroupExists: "ਖ਼ੁਰਾਕ ਗਰੁੱਪ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ",
    errorAddingDiet: "ਡਾਈਟ ਗਰੁੱਪ ਨੂੰ ਜੋੜਨ ਵਿੱਚ ਗਲਤੀ",
    dietGroupDeleted: "ਡਾਇਟ ਸਮੂਹ ਮਿਟਾਇਆ",
    dietGroupDeletedSuccess: "ਡਾਇਟ ਗਰੁੱਪ ਸਫਲਤਾਪੂਰਵਕ ਹਟਾਇਆ ਗਿਆ",
    errorDeletionDiet: "ਡਾਈਟ ਗਰੁੱਪ ਨੂੰ ਮਿਟਾਉਣ ਵਿੱਚ ਗਲਤੀ",
    errorCommonDiet: "ਡਾਈਟ ਗਰੁੱਪ ਹਟਾਉਣ ਦੌਰਾਨ ਕੁਝ ਗਲਤੀ ਹੋਈ ਸੀ",
    dietGroupname: "ਡਾਈਟ ਗਰੁੱਪ ਨਾਮ",
    dietGroups: "ਖ਼ੁਰਾਕ ਸਮੂਹ",
    dietGroupSubtitle: "ਉਪਸਿਰਲੇਖ",
    emptyDietGroupName: "ਡਾਇਟ ਗਰੁੱਪ ਨਾਮ ਖਾਲੀ ਨਹੀਂ ਹੋ ਸਕਦਾ.",
    enterDietGroupName: "ਕਿਰਪਾ ਕਰਕੇ ਖੁਰਾਕ ਸਮੂਹ ਦਾ ਨਾਂ ਦਾਖਲ ਕਰੋ",
    noEmptyDiet: "ਖੁਰਾਕ ਖਾਲੀ ਨਹੀਂ ਹੋ ਸਕਦੀ",
    enterDiet: "ਖੁਰਾਕ ਦਾਖਲ ਕਰੋ",
    medDeleted: "ਦਵਾਈ ਮਿਟਾਈ ਗਈ",
    medDeletedSuccess: "ਦਵਾਈ ਮਿਟਾ ਦਿੱਤੀ",
    medEmptyFields: "ਖਾਲੀ ਖੇਤਰ",
    medMandatoryFields: "ਕਿਰਪਾ ਕਰਕੇ ਜ਼ਰੂਰੀ ਖੇਤਰਾਂ ਨੂੰ ਇਨਪੁਟ ਕਰੋ",
    medUpdatedSuccess: "ਦਵਾਈ ਨੂੰ ਸਫਲਤਾ ਨਾਲ ਅਪਡੇਟ ਕੀਤਾ ਗਿਆ",
    medUpdated: "ਦਵਾਈਆਂ ਅਪਡੇਟ ਕੀਤੀਆਂ ਗਈਆਂ",
    medAddedSuccess: "ਮੈਡੀਸਨ ਸਫਲਤਾ ਨਾਲ ਸ਼ਾਮਿਲ ਕੀਤਾ ਗਿਆ",
    medAdded: "ਮੈਡੀਸਨ ਜੋੜਿਆ ਗਿਆ",
    medicineSubtitle:
      "ਤੁਸੀਂ ਇੱਕ ਅਜਿਹੀ ਦਵਾਈ ਪਾ ਸਕਦੇ ਹੋ ਜੋ ਸਾਡੇ ਡੇਟਾਬੇਸ ਵਿੱਚ ਨਹੀਂ ਹੈ ਜਾਂ ਤੁਸੀਂ ਸੰਬੰਧਿਤ ਜਾਣਕਾਰੀ (ਵਾਰਵਾਰਤਾ, ਅਵਧੀ ਆਦਿ) ਨੂੰ ਜੋੜ ਸਕਦੇ ਹੋ ਤਾਂ ਜੋ ਜਦੋਂ ਤੁਸੀਂ ਦਵਾਈ ਦੇ ਨਾਲ ਦਵਾਈ ਚੁਣਦੇ ਹੋ ਤੁਹਾਨੂੰ ਦੁਬਾਰਾ ਅਤੇ ਦੁਬਾਰਾ ਟਾਈਪ ਕਰਨ ਦੀ ਲੋੜ ਨਹੀਂ ਹੈ",
    medGroupExists: "ਦਵਾਈ ਦਾ ਸਮੂਹ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ!",
    medUnableAdd: "ਸਮੂਹ ਜੋੜਨ ਵਿੱਚ ਅਸਮਰੱਥ",
    numbericValue: "ਅੰਕੀ ਵੈਲਯੂ",
    invalidInputMed: "ਕਿਰਪਾ ਕਰਕੇ ਸਹੀ ਇਨਪੁਟ ਦਾਖਲ ਕਰੋ",
    medGroupAddSuccess: "ਦਵਾਈ ਗਰੁੱਪ ਸਫਲਤਾਪੂਰਵਕ ਜੁੜਿਆ",
    medGroupAdded: "ਦਵਾਈ ਗਰੁੱਪ ਜੋੜਿਆ",
    medGroupUpdateSuccess: "ਦਵਾਈ ਗਰੁੱਪ ਸਫਲਤਾਪੂਰਵਕ ਜੁੜਿਆ",
    medGroupUpdated: "ਦਵਾਈ ਗਰੁੱਪ ਜੋੜਿਆ",
    medGroupDeleteSuccess: "ਦਵਾਈਆਂ ਦਾ ਸਮੂਹ ਸਫਲਤਾਪੂਰਵਕ ਮਿਟਾਇਆ",
    medGroupDeleted: "ਦਵਾਈਆਂ ਦੇ ਗਰੁੱਪ ਨੂੰ ਮਿਟਾ ਦਿੱਤਾ ਗਿਆ",
    medGroupName: "ਦਵਾਈ ਗਰੁੱਪ ਦਾ ਨਾਮ",
    medicineGroups: "ਦਵਾਈਆਂ ਗਰੁੱਪ",
    medGroupSubtitle: "ਉਪਸਿਰਲੇਖ",
    emptyGroupName: "ਦਵਾਈ ਗਰੁੱਪ ਖਾਲੀ ਨਹੀਂ ਹੋ ਸਕਦਾ",
    medGroupNamePlaceholder: "ਦਵਾਈ ਗਰੁੱਪ ਦਾ ਨਾਮ",
    medNameEmpty: "ਦਵਾਈ ਖਾਲੀ ਨਹੀਂ ਹੋ ਸਕਦੀ",
    addSelectMed: "ਇੱਕ ਜਾਂ ਇੱਕ ਤੋਂ ਵੱਧ ਦਵਾਈਆਂ ਸ਼ਾਮਲ ਕਰੋ",
    medicines: "ਦਵਾਈਆਂ",
    testUnabletoAdd: "ਟੈਸਟ ਸਮੂਹ ਜੋੜਨ ਵਿੱਚ ਅਸਮਰੱਥ",
    testGroupExists: "ਟੈਸਟ ਸਮੂਹ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ",
    testGroupUpdated: "ਟੈਸਟ ਸਮੂਹ ਅਪਡੇਟ ਕੀਤਾ",
    testGroupUpdateSuccess: "ਟੈਸਟ ਸਮੂਹ ਸਫਲਤਾਪੂਰਕ ਅਪਡੇਟ ਕੀਤਾ ਗਿਆ",
    testGroupAdded: "ਟੈਸਟ ਸਮੂਹ ਜੋੜਿਆ",
    testGroupAddedSuccess: "ਟੈਸਟ ਸਮੂਹ ਸਫਲਤਾਪੂਰਵਕ ਜੋੜਿਆ ਗਿਆ",
    testGroupDeletSuccess: "ਟੈਸਟ ਸਮੂਹ ਸਫਲਤਾਪੂਰਕ ਹਟਾਇਆ ਗਿਆ",
    testGroupDeleted: "ਸਮੂਹ ਹਟਾਇਆ ਗਿਆ",
    testGroupName: "ਟੈਸਟ ਸਮੂਹ ਦਾ ਨਾਮ",
    testGroups: "ਟੈਸਟ ਸਮੂਹ",
    testGroupSubtitle: "ਉਪਸਿਰਲੇਖ",
    enterTestGroupName: "ਗਰੁੱਪ ਦਾ ਨਾਮ ਦਰਜ ਕਰੋ",
    selectTests: "ਟੈਸਟ ਚੁਣੋ",
    addSelectTest: "ਇੱਕ ਜਾਂ ਵੱਧ ਟੈਸਟ ਸ਼ਾਮਲ ਕਰੋ",

    sendPrescriptionTo: "ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਭੇਜੋ:",
    printPrescription: "ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਪ੍ਰਿੰਟ ਕਰੋ",
    sendPrescription: "ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਭੇਜੋ",
    pharmacies: "ਫਾਰਮੇਸੀ",
    labs: "ਲੈਬ",
    printConfirmation:
      "ਕੀ ਤੁਸੀਂ ਨਿਸ਼ਚਤ ਰੂਪ ਤੋਂ ਕੇਯਰ ਪਲਾਨ ਪ੍ਰਿੰਟ ਨੂੰ ਛਾਪਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    noGoBack: "ਨਹੀਂ, ਵਾਪਸ ਜਾਓ",
    yesPrint: "ਹਾਂ, ਪ੍ਰਿੰਟ",
    PrescriptionSentTo: "{patientName} ਲਈ ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਭੇਜਿਆ ਗਿਆ ਹੈ:",
    okPrint: "ਠੀਕ ਹੈ, ਪ੍ਰਿੰਟ",
    noPrint: "ਨਹੀਂ, ਪ੍ਰਿੰਟ",
    continue: "ਜਾਰੀ ਰੱਖੋ",
    printConfirmationTitle: "ਪ੍ਰਿੰਟ ਕੰਫਰਮੈਂਸ਼ਨ ",
    sendPrint: "ਭੇਜੋ ਅਤੇ ਪ੍ਰਿੰਟ ਕਰੋ",
    saveDone: "ਸੇਵ ਅਤੇ ਪੂਰਾ ਕਰੋ",
    emptyProviderListText:
      'ਤੁਸੀਂ ਇਸ ਪ੍ਰਿੰਸੀਪਲ ਨੂੰ ਖੱਬੇ ਨੈਵੀ ਦੇ "{providers}" ਟੈਬ ਤੋਂ ਭੇਜਣ ਲਈ ਇੱਕ ਜਾਂ ਵੱਧ "{ਪ੍ਰਦਾਤਾ}" ਜੋੜ ਸਕਦੇ ਹੋ',
    complaintErrorDesc:
      "ਕਿਰਪਾ ਕਰਕੇ ਇਸ ਕੇਅਰਪਲੈਨ ਨੂੰ ਸੇਵ ਕਰਨ ਲਈ ਇੱਕ ਨਿਰੀਖਣ ਦਰਜ ਕਰੋ",
    complaintErrorTitle: "ਸੇਵ ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਗਲਤੀ",
    saveCareplanTitle: "ਸੇਵ ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ",
    saveCareplanDesc: "ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਸੇਵਦ ਸਫਲਤਾਪੂਰਵਕ",
    saveCareplanErrorTitle: "ਸੇਵ ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਗਲਤੀ",
    saveCareplanErrorDesc: "ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਸੇਵ ਨਹੀਂ ਹੋਯਾ",
    careplanText: "ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ",
    noPrevCareplan: "ਪਿਛਲਾ ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਨਹੀਂ ਮਿਲਾ",
    careplanPopulatedTitle: "ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਪੋਪੂਲੇਟਡ",
    careplanPopulatedDesc: "ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਪੋਪੂਲੇਟਡ ਹੋ ਗਯਾ ਪਿਛਲੇ ਡਾਟਾ ਕੇ ਸਾਥ",
    complaints: "ਸ਼ਿਕਾਇਤਾਂ",
    vitals: "ਵਿਤਲਸ",
    tests: "ਟੈਸਟ",
    medicines: "ਦਵਾਈਆਂ",
    noKeyAddedPrev1: "ਕੋਈ ਪਿਛਲੇ {keyVal} ਦਰਜ ਨਹੀਂ ਹੁਈ ਹੈ",
    advice: "ਸਲਾਹ",
    Advice: "ਸਲਾਹ",
    diet: "ਖ਼ੁਰਾਕ",
    unsavedDataWarning:
      "ਤੁਹਾਡੇ ਕੋਲ ਅਸੁਰੱਖਿਅਤ ਡਾਟਾ ਹੈ. ਕੀ ਤੁਸੀਂ ਨਿਸ਼ਚਤ ਰੂਪ ਤੋਂ ਇਸ ਪੰਨੇ ਨੂੰ ਛੱਡਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    observation: "ਨਿਰੀਖਣ",
    investigation: "ਜਾਂਚ",
    medication: "ਦਵਾਈ",
    dietExercise: "ਖ਼ੁਰਾਕ ਤੇ ਕਸਰਤ",
    nextVisit: "ਅਗਲਾ ਜਾਓ",
    adviceGroups: "ਸਲਾਹ ਸਮੂਹ",
    prevAdvice: "ਪਿਛਲਾ ਸਮੂਹ",
    addAdvice: "ਜੋੜੋ ਸਮੂਹ",
    select: "ਚੁਣੋ",
    addFromAdviceGroup: "ਜੋੜੋ ਸਲਾਹ ਸਮੂਹ ਸੇ",
    addFromPreviousCareplan: "ਜੋੜੋ ਪਿਛਲਾ ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਸੇ",
    addFromDietGroup: "ਜੋੜੋ ਖ਼ੁਰਾਕ ਸਮੂਹ ਸੇ",
    dietGroup: "ਖ਼ੁਰਾਕ ਸਮੂਹ",
    prevDiet: "ਪਿਛਲਾ ਖ਼ੁਰਾਕ",
    addDiet: "ਜੋੜੋ ਖ਼ੁਰਾਕ",
    addFromTestGroup: "ਜੋੜੋ ਟੈਸਟ ਸਮੂਹ ਸੇ",
    testGroups: "ਟੈਸਟ ਸਮੂਹ",
    prevTests: "ਪਿਛਲਾ ਟੈਸਟ",
    notes: "ਨੋਟਸ",
    addToTestList: "ਜੋੜੋ ਟੈਸਟ ਸੂਚੀ ਮੈ",
    testName: "ਟੈਸਟ ਨਾਮ",
    emptyTestError: "ਕ੍ਰਿਪਾ ਟੈਸਟ ਦਰਜ ਕਰੋ",
    alternateMedicine: "ਵਿਕਲਪਿਕ ਦਵਾਈ",
    when: "ਜਦੋਂ",
    whenPlaceholder: "ਦਿਨ ਵਿਚ ਚਾਰ ਵਾਰ",
    duration: "ਮਿਆਦ",
    durationPlaceholder: "ਉਦਾਹਰਨ, 10",
    frequency: "ਫਰੇਕੁਇੰਸੀ",
    addFromMedsGroup: "ਜੋੜੋ ਦਵਾਈ ਸਮੂਹ ਸੇ",
    medsGroup: "ਦਵਾਈ ਸਮੂਹ",
    selectMedsFromGroup: "ਚੁਣੋ ਦਵਾਈ ਸਮੂਹ ਸੇ",
    medicineName: "ਦਵਾਈ ਦਾ ਨਾਮ",
    instructions: "ਨਿਰਦੇਸ਼ ਚੁਣੋ ਜਾਂ ਟਾਈਪ ਕਰੋ",
    alternate: "ਇੱਕ ਵਿਕਲਪਕ ਦਵਾਈ ਦਾਖਲ ਕਰੋ",
    specialInstructions: "ਵਿਸ਼ੇਸ਼ ਨਿਰਦੇਸ਼",
    enterMedicineName: "ਦਰਜ ਕਰੋ ਦਵਾਈ ਦਾ ਨਾਮ",
    addFromPrevMeds: "ਦਰਜ ਕਰੋ ਪੀਚਲੀ ਦਵਾਈ",
    prevMeds: "ਪੀਚਲੀ ਦਵਾਈ",
    nextVisitAfter: "ਅਗਲਾ ਜਾਓ ਬਾਅਦ",
    complaint: "ਸ਼ਿਕਾਇਤ",
    sinceWhen: "ਬਾਅਦ ਤੋਂ",
    day: "ਦਿਨ",
    days: "ਦੀਨ",
    month: "ਮਹੀਨਾ",
    months: "ਮਹੀਨੇ",
    week: "ਹਫਤਾ",
    weeks: "ਹਫ਼ਤੇ",
    addFromPrevComplaints: "ਦਰਜ ਕਰੋ ਪੀਚਲੀ ਸ਼ਿਕਾਇਤਾਂ ਸੇ",
    prevComplaints: "ਪੀਚਲੀ ਸ਼ਿਕਾਇਤਾਂ",
    emptyComplaintsError: "ਕ੍ਰਿਪਾ ਦਰਜ ਕਰੋ ਸ਼ਿਕਾਇਤਾਂ",
    physicalExamination: "ਸਰੀਰਕ ਪ੍ਰੀਖਿਆ/ਨਿਰੀਖਣ:",
    diagnosis: "ਨਿਦਾਨ",
    pharmacyOrLab: "ਫਾਰਮੇਸੀ/ ਲੈਬ",
    pharmaciesOrLabs: "ਫਾਰਮਾਸਿਯਾ/ ਲੈਬਾਂ",
    lab: "ਲੈਬ",
    pharmacy: "ਫਾਰਮੇਸੀ",
    prevPres: "ਪਿਛਲੇ ਨਕਲ ਕਰੋ",
    back: "ਵਾਪਸ ਜਾਓ",
    since: "ਬਾਅਦ ਤੋਂ",
    back_to_setup: "ਸ਼ੁਰੂਆਤੀ ਗਾਈਡ ਤੇ ਵਾਪਸ"
  },
  Patient: {
    PatientListView: {
      Id: "ਆਈਡੀ",
      NameGenderAge: "ਨਾਮ/ਲਿੰਗ/ਉਮਰ",
      LastAppointment: "ਪਿਛਲੀ ਅਪੋਇੰਟਮੈਂਟ",
      SearchWith: "ਖੋਜ ਕਰੇ ਮਰੀਜ਼ ਨਾਮ/ਮੋਬਾਈਲ ਨੰਬਰ/ਮਰੀਜ਼ ਆਈਡੀ",
      Observations: "ਨਿਰੀਖਣ",
      PrescribedBy: "ਪ੍ਰੇਸਕ੍ਰਾਇਬਦ ਬਾਈ",
      PrescriptionFor: "ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਲਈ",
      PatientHistory: "ਆਈਡੀ ਇਤਿਹਾਸ",
      PhysicalExamination: "ਸਰੀਰਕ ਪ੍ਰੀਖਿਆ",
      LabResults: "ਲੈਬ ਨਤੀਜੇ",
      ViewReport: "ਰਿਪੋਰਟ ਵੇਖੋ",
      NoRecords: "ਇਸ ਮਰੀਜ਼ ਲਈ ਕੋਈ ਰਿਕਾਰਡ ਮੌਜੂਦ ਨਹੀਂ",
      youCreatedPrescription: "ਤੁਸੀਂ ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਬਣਾਇ"
    }
  },
  Settings: {
    headerHeight: "ਹੈਡਰ ਦੀ ਉਚਾਈ(ਇੰਚ)",
    footherHeight: "ਫੁੱਟਰ ਉਚਾਈ(ਇੰਚ)",
    kulcare: "ਕੁਲਕੇਰ",
    customFormat: "ਕਸਟਮ",
    prescriptionFormat: "ਤੁਸੀਂ ਕਿਸ ਕਿਸਮ ਦਾ ਨੁਸਖਾ ਲੇਆਉਟ ਵਰਤਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    prescriptionOption: "ਤੁਸੀਂ ਮਰੀਜ਼ ਦੇ ਨੁਸਖੇ 'ਤੇ ਕੀ ਛਾਪਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    prescription: "ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ",
    Tests: "ਟੈਸਟ",
    Medicines: "ਦਵਾਈਆਂ",
    network: "ਨੈੱਟਵਰਕ",
    clinic_settings: "ਕਲੀਨਿਕ ਸੈਟਿੰਗਜ਼",
    users: "ਉਪਭੋਗਤਾ",
    settings_saved_msg: "ਸੈਟਿੰਗਜ਼ ਸਫਲਤਾਪੂਰਵਕ ਸੁਰੱਖਿਅਤ ਕੀਤੀਆਂ ਗਈਆਂ ਹਨ.",
    left_margin: "ਖੱਬਾ ਮਾਰਜਿਨ(ਇੰਚ)",
    right_margin: "ਸੱਜਾ ਮਾਰਜਿਨ(ਇੰਚ)",
    left_margin_error: "ਖੱਬਾ ਮਾਰਜਿਨ 3 ਤੋਂ ਵੱਧ ਨਹੀਂ ਹੋਣੇ ਚਾਹੀਦੇ",
    right_margin_error: "ਸੱਜਾ ਮਾਰਜਿਨ 3 ਤੋਂ ਵੱਧ ਨਹੀਂ ਹੋਣੇ ਚਾਹੀਦੇ"
  },
  carePlan: "ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ",
  history: "ਇਤਿਹਾਸ",
  tags_placehoder: "ਟਾਈਪ ਕਰੋ ਅਤੇ ਚੁਣੋ ਜਾਂ ਸ਼ਾਮਲ ਕਰਨ ਲਈ ਐਂਟਰ ਦਬਾਓ",
  profileTxt: "ਪ੍ਰੋਫਾਈਲ",
  logout: "ਲਾੱਗ ਆਊਟ",
  test_results_for: "{labName} ਲਈ ਭੇਜਿਆ ਨਤੀਜਾ",
  test_result: "ਟੈਸਟ ਨਤੀਜੇ",
  test_result_image_loading: "ਲੋਡ ਕਰ ਰਿਹਾ ਹੈ ...",
  test_result_loading: "ਟੈਸਟ ਨਤੀਜੇ ਆਰੇ ਨੇ...",
  select_clinic: "ਕਲੀਨਿਕ ਦੀ ਚੋਣ ਕਰੋ",
  staff: {
    add_staff: "ਸਟਾਫ ਸ਼ਾਮਲ ਕਰੋ",
    saveStaffSuccess: "ਸਟਾਫ ਸਫਲਤਾਪੂਰਵਕ ਸ਼ਾਮਿਲ ਕੀਤਾ ਗਿਆ",
    removeDoctorConfirmation:
      "ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ ਤੇ ਸਟਾਫ ਮੈਂਬਰ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
    status: "ਸਥਿਤੀ",
    removeStaffSuccess: "ਸਟਾਫ ਮੈਂਬਰ ਸਫ਼ਲਤਾਪੂਰਵਕ ਹਟਾ ਦਿੱਤਾ ਗਿਆ",
    delete_staff: "ਸਟਾਫ ਮੈਂਬਰ ਨੂੰ ਹਟਾਓ"
  },
  ok: "ਠੀਕ ਹੈ",
  referrals: {
    referralNotes: "ਰੈਫਰਲ ਨੋਟਸ",
    viewNotes: "ਨੋਟ ਵੇਖੋ",
    referredBy: "ਦੁਆਰਾ ਦਾ ਜ਼ਿਕਰ",
    referPatient: "ਰੈਫਰ ਮਰੀਜ਼",
    refer: "ਰੈਫਰ",
    referSuccess: "ਅਸੀਂ ਡਾਕਟਰ ਅਤੇ ਮਰੀਜ਼ ਨੂੰ ਸੰਪਰਕ ਵਿਚ ਰੱਖਾਂਗੇ",
    referThisPatient: "ਰੈਫਰ ਇਹ ਮਰੀਜ਼",
    noReferralNotes: "ਕੋਈ ਰੈਫਲੇਰਸ ਨੋਟਸ ਡਾਕਟਰ ਨੇ ਦਰਜ ਨਹੀਂ ਕੀਤੇ ਹੈ",
    referPatientNotificationHeading: "ਰੈਫਰਲ ਸਫਲ!",
    patientDetail: "ਮਰੀਜ਼ ਵੇਰਵੇਾ",
    referredTo: "ਦਾ ਜ਼ਿਕਰ",
    referredOn: "ਤੇ ਜ਼ਿਕਰ ਕੀਤਾ",
    referred_to_you: "ਦਾ ਜ਼ਿਕਰ",
    referred_by_you: "ਦੁਆਰਾ",
    status: "ਸਥਿਤੀ"
  },
  confirm: "ਪੁਸ਼ਟੀ ਕਰੋ",
  future: "ਭਵਿੱਖ",
  followup: "ਫਾਲੋਉਪ",
  AppointmentDate: "ਮੁਲਾਕਾਤ ਤਾਰੀਖ",
  confirmFollowupAppointmentModalTitle: "ਪੁਸ਼ਟੀ ਕਰੋ ਫਾਲੋਉਪ ਮੁਲਾਕਾਤ",
  selectDate: "ਚੁਣੋ ਤਾਰੀਖ",
  followUps: "ਫਾਲੋਉਪ",
  fixed_appts: "ਅਨੁਸੂਚਿਤ ਨਿਯੁਕਤੀਆਂ",
  visitDueOn: "ਜਾਓ ਕਾਰਨ ਹੈ",
  result: "ਨਤੀਜਾ",
  results: "ਨਤੀਜੇ",
  patient: "ਮਰੀਜ਼",
  referredToText: "ਜਿਹੜੇ ਮਰੀਜ਼ ਤੁਸੀਂ ਦੂਜੇ ਡਾਕਟਰਾਂ ਨੂੰ ਭੇਜੇ ਸਨ.",
  referredByText: "ਮਰੀਜ਼ਾਂ ਨੂੰ ਹੋਰ ਡਾਕਟਰਾਂ ਦੁਆਰਾ ਤੁਹਾਨੂੰ ਭੇਜਿਆ ਗਿਆ",
  pharmacyReferrelText:
    "ਮਰੀਜ਼ ਜਿਨ੍ਹਾਂ ਨੂੰ ਇਸ ਕਲੀਨਿਕ ਵਿੱਚ ਡਾਕਟਰ ਦੁਆਰਾ ਫਾਰਮੇਸੀ ਕੋਲ ਭੇਜਿਆ ਗਿਆ ਸੀ.",
  labReferrelText:
    "ਮਰੀਜ਼ ਜਿਨ੍ਹਾਂ ਨੂੰ ਇਸ ਕਲੀਨਿਕ ਵਿਚ ਇਕ ਡਾਕਟਰ ਦੁਆਰਾ ਲੌਟ ਕੀਤਾ ਗਿਆ ਸੀ.",
  networkPharmaciesText:
    "ਫਾਰਮੇਸੀ ਦੀ ਭਾਲ ਕਰੋ ਅਤੇ ਉਹਨਾਂ ਨੂੰ ਮਰੀਜ਼ਾਂ ਨੂੰ ਉਹਨਾਂ ਦੇ ਕੋਲ ਭੇਜਣ ਲਈ ਉਹਨਾਂ ਨੂੰ ਆਪਣੇ ਨੈਟਵਰਕ ਵਿੱਚ ਜੋੜੋ",
  networkLabsText:
    "ਕਿਸੇ ਲੈਬ ਦੀ ਖੋਜ ਕਰੋ ਅਤੇ ਉਹਨਾਂ ਨੂੰ ਮਰੀਜ਼ਾਂ ਨੂੰ ਉਹਨਾਂ ਦੇ ਕੋਲ ਭੇਜਣ ਲਈ ਉਹਨਾਂ ਨੂੰ ਆਪਣੇ ਨੈਟਵਰਕ ਵਿੱਚ ਜੋੜੋ.",
  doctorUserText:
    "ਫ਼ੋਨ ਨੰਬਰ ਰਾਹੀਂ ਡਾਕਟਰ ਦੀ ਭਾਲ ਕਰੋ ਜਿਸ ਨੂੰ ਤੁਸੀਂ ਆਪਣੇ ਕਲੀਨਿਕ ਵਿਚ ਜੋੜਨਾ ਚਾਹੁੰਦੇ ਹੋ.",
  staffUserText:
    "ਫ਼ੋਨ ਨੰਬਰ ਦੁਆਰਾ ਸਟਾਫ ਨੂੰ ਸੱਦਾ ਦਿਓ ਜਿਸ ਨੂੰ ਤੁਸੀਂ ਆਪਣੇ ਕਲੀਨਿਕ ਵਿਚ ਜੋੜਨਾ ਚਾਹੁੰਦੇ ਹੋ.",
  savePrescriptionWarning:
    "ਤੁਸੀਂ ਬਾਅਦ ਵਿੱਚ ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਨੂੰ ਸੰਪਾਦਿਤ ਕਰਨ ਦੇ ਯੋਗ ਨਹੀਂ ਹੋਵੋਗੇ. ਤੁਸੀਂ ਮਰੀਜ਼ ਦੇ ਇਤਿਹਾਸ ਵਿਚ ਇਹ ਨੁਸਖ਼ਾ ਲੱਭ ਸਕਦੇ ਹੋ. ਕੀ ਜਾਰੀ ਰੱਖਣਾ ਹੈ?",
  selectDoctor: "ਡਾਕਟਰ ਚੁਣੋ",
  createPrescriptionFor: "ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਬਣਾਓ",
  editPatient: "ਮਰੀਜ਼ ਨੂੰ ਐਡਿਟ ਕਰੋ",
  patientAdded: "ਤੁਸੀਂ ਸੂਚੀ ਵਿੱਚ ਸਫਲਤਾਪੂਰਵਕ ਇੱਕ ਨਵਾਂ ਮਰੀਜ਼ ਜੋੜ ਲਿਆ ਹੈ",
  patientUpdated: "ਤੁਸੀਂ ਮਰੀਜ਼ਾਂ ਦੀ ਜਾਣਕਾਰੀ ਸਫਲਤਾਪੂਰਵਕ ਅਪਡੇਟ ਕੀਤੀ ਹੈ",
  infoUpdated: "ਜਾਣਕਾਰੀ ਅਪਡੇਟ ਕੀਤੀ ਗਈ",
  resendInvite: "ਦੁਬਾਰਾ ਸੱਦਾ ਭੇਜੋ",
  reinviteDoctorConfirmation:
    "ਕੀ ਤੁਸੀਂ ਨਿਸ਼ਚਿਤ ਹੋ ਕਿ ਤੁਸੀਂ ਇਸ ਡਾਕਟਰ ਨੂੰ ਦੁਬਾਰਾ ਸੱਦਾ ਭੇਜਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  inviteDoctor: "ਡਾਕਟਰ ਨੂੰ ਸੱਦੋ",
  inviteSuccess: "ਡਾਕਟਰ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਸੱਦਾ ਦਿੱਤਾ ਗਿਆ!",
  perPage: "ਪ੍ਰਤੀ ਸਫ਼ਾ",
  booked: "ਬੁੱਕ ਹੋਇਆ",
  nextVisit: "ਅਗਲੀ ਮੁਲਾਕਾਤ",
  nextVisitSaved: "ਅਗਲੀ ਮੁਲਾਕਾਤ ਸੰਭਾਲੇਗਾ",
  nextVisitSaveDesc: "ਅਗਲੀ ਮੁਲਾਕਾਤ ਸਫਲਤਾ ਨਾਲ ਸੰਭਾਲੀ ਗਈ",
  nextVisitUpdateSaved: "ਅਗਲਾ ਫੇਅਰ ਅਪਡੇਟ ਕੀਤਾ ਗਿਆ",
  nextVisitUpdateSaveDesc: "ਅਗਲਾ ਦੌਰਾ ਸਫਲਤਾਪੂਰਵਕ ਅਪਡੇਟ ਕੀਤਾ ਗਿਆ.",
  nextVisitDeleteSaved: "ਅਗਲੀ ਮੁਲਾਕਾਤ ਮਿਟਾਏ ਗਏ",
  nextVisitDeleteSaveDesc: "ਅਗਲਾ ਦੌਰਾ ਸਫਲਤਾਪੂਰਵਕ ਹਟਾਇਆ ਗਿਆ",
  update: "ਅਪਡੇਟ ਕਰੋ",
  delete: "ਮਿਟਾਓ",
  enterVisitAfter: "ਅਗਲੇ ਵਿਜਿਟ ਦੇ ਵੇਰਵੇ ਦਰਜ ਕਰੋ",
  manageNextVisit: "ਅਗਲੀ ਮੁਲਾਕਾਤ ਵਿਵਸਥਿਤ ਕਰੋ",
  nextVisitUpdateSaveDescError: "ਅਗਲੀ ਮੁਲਾਕਾਤ ਨੂੰ ਅਪਡੇਟ ਕਰਨ ਵਿੱਚ ਅਸਮਰੱਥ",
  nextVisitSaveDescError: "ਅਗਲੀ ਮੁਲਾਕਾਤ ਨੂੰ ਬਚਾਉਣ ਵਿੱਚ ਅਸਮਰੱਥ",
  nextVisitDeleteSaveDescError: "ਅਗਲੇ ਵਿਜ਼ਿਟ ਨੂੰ ਮਿਟਾਉਣ ਵਿੱਚ ਅਸਮਰੱਥ",
  photo: "ਫੋਟੋ",
  photos: "ਫੋਟੋਆਂ",
  inside: "ਅੰਦਰ",
  click_to_open: "ਖੋਲ੍ਹਣ ਲਈ ਕਲਿੱਕ ਕਰੋ",
  days: "ਦਿਨ",
  forPatients: "ਫਓਰ ਮਰੀਜ਼ਾਂ",
  forPharmacies: "ਫਓਰ ਫਾਰਮੇਸੀਆਂ",
  forLabs: "ਫਓਰ ਲੈਬਜ਼",
  downloadApp: "ਐਪ ਨੂੰ ਡਾਉਨਲੋਡ ਕਰੋ",
  signUpHeading:
    "ਤੁਸੀਂ ਆਪਣੇ ਮਰੀਜ਼ਾਂ ਦੀ ਦੇਖਭਾਲ ਕਰਦੇ ਹੋ, ਅਤੇ ਅਸੀਂ ਤੁਹਾਡੀਆਂ ਸੰਭਾਵੀ ਚੁਣੌਤੀਆਂ ਦਾ ਧਿਆਨ ਰੱਖਾਂਗੇ",
  signUpSubHeading:
    "ਕੁਲਕੇਅਰ ਸਧਾਰਨ ਅਤੇ ਸਭ ਤੋਂ ਆਸਾਨ ਕਲੀਨਿਕ ਪ੍ਰਬੰਧਨ ਸਾਫਟਵੇਅਰ ਪੇਸ਼ ਕਰਦਾ ਹੈ। ਇਹ ਤੁਹਾਡੇ ਪ੍ਰਥਾ ਨੂੰ ਸਭ ਤੋਂ ਵੱਧ ਲਾਗਤ ਵਾਲੇ ਤਰੀਕੇ ਨਾਲ ਡਿਜਿਟ ਕਰਨ ਵਿੱਚ ਸਹਾਇਤਾ ਕਰਦਾ ਹੈ।",
  yourPhoneNumber: "ਤੁਹਾਡਾ ਮੋਬਾਈਲ ਨੰਬਰ",
  referredBySomeone: "ਕਿਸੇ ਦੁਆਰਾ ਦਰਸਾਇਆ ਗਿਆ?",
  enterReferralCode: "ਰੈਫ਼ਰਲ ਕੋਡ ਦਰਜ ਕਰੋ",
  phoneNumberVerification: "ਮੋਬਾਈਲ ਨੰਬਰ ਪ੍ਰਮਾਣਿਤ",
  sentCodeOn: "ਅਸੀਂ ਤੁਹਾਨੂੰ {0} ਤੇ ਇੱਕ ਪ੍ਰਮਾਣਿਤ ਕੋਡ ਭੇਜਿਆ ਹੈ",
  enterVerificationCode: "ਤਸਦੀਕ ਕੋਡ ਦਰਜ ਕਰੋ",
  na: "ਲਾਗੂ ਨਹੀਂ",
  delete_bill_confirm: "ਕੀ ਤੁਸੀਂ ਨਿਸ਼ਚਤ ਰੂਪ ਨਾਲ ਇਸ ਬਿੱਲ ਨੂੰ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  providerInfo: "ਆਪਣੀ ਜਾਣਕਾਰੀ ਪ੍ਰਦਾਨ ਕਰੋ!",
  provideClinicInfo: "ਆਪਣੀ ਕਲੀਨਿਕ ਜਾਣਕਾਰੀ ਪ੍ਰਦਾਨ ਕਰੋ!",
  yourName: "ਤੁਹਾਡਾ ਨਾਮ",
  createPassword: "ਆਪਣਾ ਪਾਸਵਰਡ ਬਣਾਓ",
  confirmPassword: "ਪਾਸਵਰਡ ਪੱਕਾ ਕਰੋ",
  step: "ਕਦਮ",
  patientPerHourTip:
    ".ਸਤ ਦਰਜ ਕਰੋ. ਕਤਾਰ ਦੇ ਇੰਤਜ਼ਾਰ ਸਮੇਂ ਦਾ ਅਨੁਮਾਨ ਲਗਾਉਣ ਲਈ, ਹਰ ਘੰਟੇ ਡਾਕਟਰ ਦੁਆਰਾ ਵੇਖੇ ਮਰੀਜ਼ਾਂ ਦੀ ਗਿਣਤੀ.",
  yourAvailablity: "ਕਲੀਨਿਕ ਵਿੱਚ ਤੁਹਾਡੀ ਉਪਲਬਧਤਾ",
  consultationFees: "ਤੁਹਾਡੀ ਸਲਾਹ-ਮਸ਼ਵਰਾ ਫੀਸ",
  patientYouSee: "ਮਰੀਜ਼ ਤੁਹਾਨੂੰ ਪ੍ਰਤੀ ਘੰਟਾ ਵੇਖਦੇ ਹਨ",
  allDone: "ਸਭ ਹੋ ਗਿਆ",
  addAvailability: "ਉਪਲਬਧਤਾ ਜੋੜੋ",
  addMore: "ਹੋਰ ਜੋੜੋ",
  done: "ਹੋ ਗਿਆ",
  congratulations: "ਮੁਬਾਰਕ!",
  allSetText: "{0} ਕੁਲਕੇਰ ਦੁਆਰਾ ਪ੍ਰਬੰਧਨ ਲਈ ਪੂਰੀ ਤਰ੍ਹਾਂ ਤਿਆਰ ਹੈ",
  takeYouThroughtKulcare: "ਆਓ ਆਪਾਂ ਇਸ ਨੂੰ ਇਕ ਵਾਰ ਫੜੀਏ.",
  showMeAround: "ਮੈਨੂੰ ਦਿਖਾਓ",
  // figureOutMyself: "ਮੈਂ ਇਸ ਨੂੰ ਆਪਣੇ ਆਪ ਹੀ ਸਮਝ ਲਵਾਂਗਾ",
  agreeToTerms: "ਮੈਂ {0} ਲਈ ਸਹਿਮਤ ਹਾਂ",
  kulcareTerms: "ਕੁਲਕੇਰ ਸ਼ਰਤਾਂ",
  confirmPwdRequired: "ਪੁਸ਼ਟੀ ਪਾਸਵਰਡ ਦਿਓ ਜੀ",
  nonExistingAccount: "ਇਸ ਉਪਭੋਗਤਾ ਲਈ ਖਾਤਾ ਮੌਜੂਦ ਨਹੀਂ ਹੈ",
  addDaysTimings: "ਕਿਰਪਾ ਕਰਕੇ ਸਕੈਡਿਊਲ ਲਈ ਦਿਨ ਅਤੇ ਸਮਾਂ ਚੁਣੋ",
  overlapTimingsError:
    "ਦਰਜ਼ ਕੀਤੇ ਅਨੁਸੂਚੀ ਸਮਾਂ ਕੁਝ ਹੋਰ ਅਨੁਸੂਚੀ ਦੇ ਨਾਲ ਓਵਰਲੈਪ ਕਰਦਾ ਹੈ",
  addScheduleError: "ਕਿਰਪਾ ਕਰਕੇ ਇਕ ਹੋਰ ਜੋੜਨ ਲਈ ਘੱਟੋ ਘੱਟ ਇਕ ਅਨੁਸੂਚੀ ਜੋੜੋ",
  addAvailabilityError: "ਕਿਰਪਾ ਕਰਕੇ ਡਾਕਟਰ ਦੀ ਅਨੁਸੂਚੀ ਜੋੜੋ",
  amountCollectedValidation: "ਰਕਮ ਕੁਲ ਰਾਸ਼ੀ ਤੋਂ ਵੱਧ ਨਹੀਂ ਹੋ ਸਕਦੀ",
  billDiscountValidation: "ਛੂਟ ਕੁੱਲ ਰਕਮ ਤੋਂ ਵੱਧ ਨਹੀਂ ਹੋ ਸਕਦੀ",
  checkBillPaid: "ਅਦਾਇਗੀ ਦੇ ਤੌਰ ਤੇ ਬਿੱਲ ਨੂੰ ਚਿੰਨ੍ਹਿਤ ਕਰੋ",
  unbilled_amount: "ਅਣ-ਬਿਲਡ ਰਕਮ",
  service_date: "ਸੇਵਾ ਤਾਰੀਖ",
  amount_collected: "ਇਕੱਤਰ ਕੀਤੀ ਰਕਮ",
  in_rupees: "ਰੁਪਏ ਵਿਚ",
  generated_bill_amount: "ਅਦਾਇਗੀ ਬਿਲ ਦੀ ਰਕਮ",
  receive_payment_confirm:
    "ਕੀ ਤੁਸੀਂ ਨਿਸ਼ਚਤ ਰੂਪ ਤੋਂ ਬਿਲ ਦੇ ਤੌਰ ਤੇ ਭੁਗਤਾਨ ਕੀਤੇ ਗਏ ਨੂੰ ਨਿਸ਼ਾਨਬੱਧ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
  tour: {
    popover_btn_title: "ਤੁਹਾਡੀ ਟੂਰ ਗਾਈਡ",
    popover_btn_content:
      "ਤੁਸੀਂ ਇਸ ਟੈਬ 'ਤੇ ਵੱਖ-ਵੱਖ ਭਾਗਾਂ ਨੂੰ ਸਮਝਣ ਵਿੱਚ ਸਹਾਇਤਾ ਲਈ ਐਪ ਟੂਰ ਸ਼ੁਰੂ ਕਰਨ ਲਈ ਕਿਸੇ ਵੀ ਸਮੇਂ ਇਸ ਬਟਨ ਤੇ ਕਲਿਕ ਕਰ ਸਕਦੇ ਹੋ",
    queue_tour: {
      step1: {
        title: "ਏਡ ਮਰੀਜ਼ਾਂ",
        content:
          "ਤੁਸੀਂ ਐਡ ਬਟਨ ਤੇ ਕਲਿੱਕ ਕਰਕੇ ਕਤਾਰ ਲਈ ਮਰੀਜ਼ ਨੂੰ ਜੋੜ ਸਕਦੇ ਹੋ.ਮਰੀਜ਼ ਨਾਂ, ਮੋਬਾਈਲ ਨੰਬਰ ਲਿੰਗ ਅਤੇ ਉਮਰ ਸ਼ਾਮਲ ਕਰੋ ਅਤੇ ਇਸਦੇ ਦੁਆਰਾ ਕਰੋ."
      },
      step2: {
        title: "ਮਰੀਜ਼ ਜਾਣਕਾਰੀ",
        content:
          "ਸਲੀਬ ਦਾ ਇਹ ਹਿੱਸਾ ਤੁਰੰਤ ਮਰੀਜ਼ਾਂ ਦੇ ਵਿਚਕਾਰ ਫਰਕ ਕਰਨ ਵਿੱਚ ਤੁਹਾਡੀ ਮੱਦਦ ਕਰਨ ਲਈ ਮੁੱਢਲੀ ਮਰੀਜ਼ ਜਾਣਕਾਰੀ ਪ੍ਰਦਾਨ ਕਰਦਾ ਹੈ."
      },
      step3: {
        title: "ਡਾਕਟਰ ਲਈ ਸਮਾਂ",
        content:
          "ਇਹ ਅਨੁਮਾਨਤ ਸਮਾਂ ਹੈ ਜਦੋਂ ਮਰੀਜ਼ ਡਾਕਟਰ ਨੂੰ ਦੇਖ ਸਕਦਾ ਹੈ. ਇਹ ਵੱਖ ਵੱਖ ਕਿਊ ਪੈਰਾਮੀਟਰਾਂ ਦੇ ਆਧਾਰ ਤੇ ਮਾਪਿਆ ਜਾਂਦਾ ਹੈ. ਸਥਾਈ ਨਿਯੁਕਤੀ ਵਾਲੇ ਮਰੀਜ਼ਾਂ ਲਈ ਇਹ ਉਹਨਾਂ ਦੀ ਨਿਯੁਕਤੀ ਦਾ ਸਮਾਂ ਦਰਸਾਏਗਾ."
      },
      step4: {
        title: "ਬਿਲਿੰਗ ਦੀ ਰਕਮ",
        content:
          "ਇਹ ਦਰਸਾਉਂਦਾ ਹੈ ਕਿ ਮਰੀਜ਼ ਨੂੰ ਕਿੰਨੀ ਅਦਾਇਗੀ ਕੀਤੀ ਗਈ ਹੈ (ਹਰੀ ਵਿਚ) ਅਤੇ ਰਕਮ ਇਕੱਠੀ ਕੀਤੀ ਜਾਣੀ (ਲਾਲ ਵਿਚ) ਇਕੱਠੀ ਕੀਤੀ ਜਾਣੀ ਹੈ. ਜਦੋਂ ਤੁਸੀਂ ਬਿਲਿੰਗ ਜਾਣਕਾਰੀ ਜੋੜ ਲੈਂਦੇ ਹੋ ਤਾਂ ਇਹ ਪ੍ਰਤੀਬਿੰਬ ਹੋ ਜਾਵੇਗਾ"
      },
      step5: {
        title: "ਮਰੀਜ਼ ਦੀ ਹਾਲਤ",
        content: {
          p1:
            "ਇਹ ਕਤਾਰ ਵਿੱਚ ਮਰੀਜ਼ ਦੀ ਸਥਿਤੀ ਹੈ ਜੋ ਤੁਸੀਂ ਕਿਸੇ ਵੀ ਸਮੇਂ ਸੰਪਾਦਿਤ ਕਰ ਸਕਦੇ ਹੋ.",
          p2_title: "ਇਨ ਕ਼ੁਇਉ",
          p2_text: "ਮਰੀਜ਼ ਜਿਹੜੇ ਕਤਾਰ ਵਿਚ ਹਨ ਪਰ ਕਲੀਨਿਕ ਵਿਚ ਨਹੀਂ ਹਨ.",
          p3_title: "ਚੈੱਕ ਇਨ",
          p3_text: "ਮਰੀਜ਼ ਜਿਹੜੇ ਕਲੀਨਿਕ ਵਿੱਚ ਹਨ",
          p4_title: "ਵਿੱਥ ਡਾਕ",
          p4_text: "ਡਾਕਟਰ ਦੇ ਕਮਰੇ ਅੰਦਰ ਰੋਗੀ",
          p5_title: "ਚੈੱਕ ਆਊਟ",
          p5_text: "ਜਿਨ੍ਹਾਂ ਮਰੀਜ਼ਾਂ ਨੇ ਆਪਣੀਆਂ ਮੁਲਾਕਾਤਾਂ ਪੂਰੀਆਂ ਕੀਤੀਆਂ ਹਨ",
          p6_title: "ਨੌ ਸ਼ੋਓ",
          p6_text: "ਮਰੀਜ਼ ਜੋ ਕਿ ਕਲੀਨਿਕ ਵਿੱਚ ਨਹੀਂ ਪਹੁੰਚ ਸਕੇ"
        }
      },
      step6: {
        title: "ਐਕਸ਼ਨ",
        content: {
          p1: "ਇਹ ਉਹ ਕਿਰਿਆ ਹਨ ਜੋ ਤੁਸੀਂ ਮਰੀਜ਼ ਤੇ ਲੈ ਸਕਦੇ ਹੋ.",
          p2_title: "ਬਿਲਿੰਗ",
          p2_text:
            "ਤੁਸੀਂ ਸੇਵਾਵਾਂ ਸ਼ਾਮਿਲ ਕਰ ਸਕਦੇ ਹੋ, ਬਿਲ ਬਣਾ ਸਕਦੇ ਹੋ ਅਤੇ ਤਿਆਰ ਬਿੱਲਾਂ ਨੂੰ ਦੇਖ ਸਕਦੇ ਹੋ.",
          p3_title: "ਐਡ ਵਅਇਤਲ",
          p3_text: "ਤੁਸੀਂ ਮਰੀਜ਼ ਦੀ ਬਿਮਾਰੀ ਨੂੰ ਜੋੜ ਸਕਦੇ ਹੋ.",
          p4_title: "ਪ੍ਰਿੰਟ ਮਰੀਜ਼ ਵੇਰਵੇ",
          p4_text:
            "ਤੁਸੀਂ ਮਰੀਜ਼ਾਂ ਦੇ ਵੇਰਵੇ ਨੂੰ ਆਪਣੇ ਡਾਕਟਰ ਦੇ ਹਿਸਾਬ ਨਾਲ ਛਾਪ ਸਕਦੇ ਹੋ.",
          p5_title: "ਅਗਲੀ ਵੀਯਡ",
          p5_text:
            "ਤੁਸੀਂ ਇੱਥੇ ਮਰੀਜ਼ ਦੀ ਅਗਲੀ ਫੇਰੀ ਨੂੰ ਜੋੜ ਸਕਦੇ ਹੋ. ਅਸੀਂ ਫੌਲੋਪ ਫੇਰੀ ਲਈ ਮਰੀਜ਼ ਨੂੰ ਵਾਪਸ ਲਿਆਉਣ ਵਿਚ ਤੁਹਾਡੀ ਮਦਦ ਕਰਾਂਗੇ."
        }
      },
      step7: {
        title: "ਚੈਕ ਆਊਟ / ਨਹੀਂ ਦਿਖਾਓ ਕਤਾਰ",
        content:
          "ਮਰੀਜ਼ ਜਿਨ੍ਹਾਂ ਨੂੰ ਤੁਸੀਂ ਕਤਾਰ ਤੋਂ ਬਾਹਰ ਚੈੱਕ ਕਰਦੇ ਹੋ ਜਾਂ ਜੋ ਕਲੀਨਿਕ ਵਿਚ ਨਹੀਂ ਆਇਆ ਉਹ ਇੱਥੇ ਸੂਚੀਬੱਧ ਹੋਣਗੇ. ਤੁਸੀਂ ਉਹਨਾਂ ਨੂੰ ਵਾਪਸ ਕਤਾਰ ਵਿੱਚ ਜੋੜ ਸਕਦੇ ਹੋ"
      },
      step8: {
        title: "ਤੁਹਾਡਾ ਟੂਰ ਗਾਈਡ",
        content:
          "ਤੁਸੀਂ ਇਸ ਟੈਬ 'ਤੇ ਵੱਖ-ਵੱਖ ਭਾਗਾਂ ਨੂੰ ਸਮਝਣ ਵਿੱਚ ਸਹਾਇਤਾ ਲਈ ਐਪ ਟੂਰ ਸ਼ੁਰੂ ਕਰਨ ਲਈ ਕਿਸੇ ਵੀ ਸਮੇਂ ਇਸ ਬਟਨ ਤੇ ਕਲਿਕ ਕਰ ਸਕਦੇ ਹੋ"
      }
    },
    appointments_tour: {
      step1: {
        title: "ਅਡ ਅਪਯਮੈਂਟ",
        content:
          "ਤੁਸੀਂ ਇਸ ਬਟਨ ਨੂੰ ਵਰਤ ਕੇ ਇੱਕ ਨਵੀਂ ਮੁਲਾਕਾਤ ਸ਼ਾਮਲ ਕਰ ਸਕਦੇ ਹੋ. ਕਿਸੇ ਮਰੀਜ਼ ਨੂੰ ਜੋੜੋ (ਜਾਂ ਮੌਜੂਦਾ ਤੋਂ ਖੋਜ ਕਰੋ) ਅਤੇ ਇੱਕ ਸਮਾਂ ਨਿਰਧਾਰਿਤ ਕਰੋ"
      },
      step2: {
        title: "ਅੱਦੇਦ ਅਪਯਮੈਂਟ",
        content:
          "ਸਾਰੇ ਸ਼ਾਮਲ ਮੁਲਾਕਾਤਾਂ ਨੂੰ ਸਮਾਂ ਦੇ ਸਲਾਟ ਦੇ ਅਧਾਰ ਤੇ ਜੋੜਿਆ ਜਾਵੇਗਾ. ਡਾਕਟਰਾਂ ਨੂੰ ਇਕ ਘੰਟੇ ਵਿਚ ਦੇਖੇ ਜਾ ਸਕਣ ਵਾਲੇ ਮਰੀਜ਼ਾਂ ਦੀ ਔਸਤ ਗਿਣਤੀ ਨੂੰ ਧਿਆਨ ਵਿਚ ਰੱਖਦੇ ਹੋਏ ਟਾਈਮ ਸਲੋਟ ਦੀ ਗਣਨਾ ਕੀਤੀ ਜਾਂਦੀ ਹੈ."
      },
      step3: {
        title: "ਫਾਲੋ ਅਪ",
        content:
          "ਮਰੀਜ਼ ਲਈ ਅਗਲੀ ਫੇਸ ਲਈ ਤੁਹਾਡੇ ਇਨਪੁਟ ਦੇ ਅਧਾਰ ਤੇ ਇਹ ਸੰਭਵ ਮੇਲ ਖੜ੍ਹੇ ਹਨ. ਤੁਸੀਂ ਮਰੀਜ਼ ਨੂੰ ਕਾਲ ਕਰ ਸਕਦੇ ਹੋ ਅਤੇ ਫਾਲੋਪਸ ਦੀ ਪੁਸ਼ਟੀ ਜਾਂ ਰੱਦ ਕਰ ਸਕਦੇ ਹੋ. ਲਾਲ ਬਿੰਦੀਆਂ ਕੱਲ੍ਹ ਦੇ ਲਈ ਅੱਜ ਦੇ ਅਤੇ ਔਰੇਂਜ ਬਿੰਦੂਆਂ ਲਈ ਕੱਲ੍ਹ ਦੇ ਫਾਲੋਪਸ ਗ੍ਰੀਨ ਡੌਟਸ ਦਿਖਾਉਂਦੇ ਹਨ."
      }
    }
  },
  Pharmacies_List: "ਫਾਰਮੇਸੀਆਂ ਦੀ ਸੂਚੀ",
  alreadyVerified: "ਇਹ ਖਾਤਾ ਪਹਿਲਾਂ ਹੀ ਸਾਡੇ ਨਾਲ ਮੌਜੂਦ ਹੈ",
  youHaveAvailability: "ਤੁਸੀਂ 1 ਉਪਲਬਧਤਾ ਨੂੰ ਜੋੜਿਆ",
  youHaveAvailabilities: "ਤੁਸੀਂ {0} ਲਾਭ ਲੈਣ ਲਈ ਸ਼ਾਮਿਲ ਕੀਤਾ",
  roles: {
    owner: "ਮਾਲਕ",
    consultant: "ਸਲਾਹਕਾਰ",
    staff: "ਸਟਾਫ਼"
  },
  email: "ਈ-ਮੇਲ",
  yourEmail: "ਤੁਹਾਡੀ ਈ-ਮੇਲ",
  emailToolTip:
    "ਈ-ਮੇਲ ਮਹੱਤਵਪੂਰਣ ਹੈ, ਕਿਉਂਕਿ ਇਹ ਉਹ ਥਾਂ ਹੈ ਜਿੱਥੇ ਤੁਹਾਨੂੰ ਸਾਡੇ ਤੋਂ ਵਧੇਰੇ ਸੰਚਾਰ ਪ੍ਰਾਪਤ ਹੋਵੇਗਾ. ਖ਼ਾਸ ਕਰਕੇ, ਸ਼ਾਨਦਾਰ ਵਿਸ਼ੇਸ਼ਤਾਵਾਂ, ਜੋ ਤੁਹਾਨੂੰ ਪਸੰਦ ਹੋਣਗੇ, ਛੇਤੀ ਹੀ ਆ ਰਹੇ ਹਨ!",
  enter_valid_email: "ਕਿਰਪਾ ਕਰਕੇ ਪ੍ਰਮਾਣਿਤ ਈਮੇਲ ਦਾਖ਼ਲ ਕਰੋ",
  verification_code: "ਤਸਦੀਕ ਕੋਡ ਦਰਜ ਕਰੋ",
  networkError: "ਨੈਟਵਰਕ ਕਨੈਕਟੀਵਿਟੀ ਸਮੱਸਿਆ। ਮੁੜ ਕੋਸ਼ਿਸ ਕਰੋ ਜੀ!",
  clickToReload: "ਰੀਲੋਡ ਕਰੋ",
  newEntry: "ਨਵਾਂ ਇੰਦਰਾਜ਼",
  previousUnbilled: "ਪਿਛਲੇ ਅਣ-ਬਿਲਟ",
  createNewBill: "ਨਵਾਂ ਬਿਲ ਬਣਾਉ",
  createNewBillText:
    "ਅੱਜ ਦੇ ਲਈ ਪਹਿਲਾਂ ਹੀ ਇੱਕ ਅਣ-ਬਿਲਟ ਐਂਟਰੀ ਹੈ। ਕੀ ਤੁਸੀਂ ਇੱਕ ਨਵਾਂ ਬਣਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  previousBilled: "ਪਿਛਲੇ ਬਿਲਡ",
  saveBillDataWarning: "ਕੀ ਤੁਸੀਂ ਬਿੱਲ ਡੇਟਾ ਸੇਵ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
  serviceDateError: "ਕਿਰਪਾ ਕਰਕੇ ਸੇਵਾ ਦੀ ਤਾਰੀਖ ਚੁਣੋ ਭਰੋ",
  phoneNumberTipText:
    "ਅਸੀਂ ਕੁਲਕੇਰ ਤੇ ਸਾਈਨ ਅਪ ਕਰਨ ਲਈ ਇੱਕ ਲਿੰਕ ਦੇ ਨਾਲ ਡਾਕਟਰ ਨੂੰ ਮੈਸਿਜ ਭੇਜਾਂਗੇ।",
  addaDoctor: "ਐੱਡ ਡਾਕਟਰ",
  enterDoctorName: "ਡਾਕਟਰ ਦਾ ਨਾਮ ਦਰਜ ਕਰੋ",
  enterDoctorPhoneNumber: "ਡਾਕਟਰ ਦਾ ਮੋਬਾਈਲ ਨੰਬਰ ਦਿਓ",
  enterDoctorAvailabilty: "ਪ੍ਰੈਕਟਿਸ ਵਿਚ ਡਾਕਟਰ ਦੀ ਉਪਲਬਧਤਾ ਦਰਜ ਕਰੋ",
  patientPerHourPlaceholder: "ਪ੍ਰਤੀ ਘੰਟੇ ਪ੍ਰਤੀ ਮਰੀਜ਼ਾਂ ਦੀ ਔਸਤ ਗਿਣਤੀ",
  doctorPracticeNamePlaceholder: "ਤੁਹਾਡੇ ਪ੍ਰੈਕਟਿਸ ਦਾ ਨਾਮ eg. XYZ Clinic",
  existingUser: "ਮੌਜੂਦਾ ਯੂਜ਼ਰ?",
  practicePhoneNumber: "ਪ੍ਰੈਕਟਿਸ ਫ਼ੋਨ ਨੰਬਰ",
  setPassword: "ਇੱਕ ਪਾਸਵਰਡ ਸੈਟ ਕਰੋ",
  youAreSetUp: "ਤੁਸੀਂ ਹੁਣ ਬਿਲਕੁਲ ਸੈੱਟ ਉਪ ਹੋ!",
  quickTourText: "ਆਉ ਅਸੀਂ ਤੁਹਾਨੂੰ ਸੰਦ ਦਾ ਇੱਕ ਟੂਰ ਦੇਈਏ.",
  tourBestSeenOn:
    "kulcare ਪ੍ਰੈਕਟਿਸ ਮੈਨੇਜਮੈਂਟ ਇੱਕ ਲੈਪਟਾਪ/ਕੰਪਿਊਟਰ ਬਰਾਉਜ਼ਰ ਜਾਂ ਇੱਕ ਟੈਬ ਬਰਾਉਜ਼ਰ ਤੇ ਵਧੀਆ ਦਿਖਾਈ ਦਿੰਦਾ ਹੈ। ਤੁਸੀਂ ਸਾਡੇ ਮੋਬਾਈਲ ਐਪਸ ਵੀ ਡਾਊਨਲੋਡ ਕਰ ਸਕਦੇ ਹੋ।",
  tourModalContent:
    "ਤੁਹਾਡਾ ਪ੍ਰੈਕਟਿਸ ਸੈਟਅੱਪ ਹੈ ਅਤੇ ਤੁਸੀਂ ਆਪਣੇ ਪ੍ਰੈਕਟਿਸ ਨੂੰ ਸੁਚਾਰ ਢੰਗ ਨਾਲ ਚਲਾਉਣ ਲਈ ਤਿਆਰ ਹੋ {0} ਦੇ ਨਾਲ",
  signUpSuccessText: "ਤੁਸੀਂ {0} ਤੇ ਸਫਲਤਾਪੂਰਵਕ ਸਾਈਨ ਅਪ ਕੀਤਾ ਹੈ.",
  oneStepMoreText: "ਸਿਰਫ {0} ਪੂਰਾ ਕਰਨ ਵਾਸਤੇ",
  letUsAddDoctorText: "ਆਉ ਅਸੀਂ ਤੁਹਾਡੇ ਕਲੀਨਿਕ ਵਿੱਚ ਡਾਕਟਰ ਸ਼ਾਮਲ ਕਰੀਏ.",
  oneStepMoreContent: "ਇਕ ਕਦਮ ਹੋਰ ਅੱਗੇ ਜਾਣ ਲਈ",
  looksLikeYou:
    "ਇੰਜ ਜਾਪਦਾ ਹੈ ਕਿ ਤੁਸੀਂ ਇਸ ਨੂੰ ਕਿਸੇ ਮੋਬਾਈਲ ਮੋਬਾਈਲ 'ਤੇ ਵੇਖ ਰਹੇ ਹੋ।",
  enterDoctorAvailabiltyTruncated: "ਡਾਕਟਰ ਦੀ ਉਪਲਬਧਤਾ ਦਰਜ ਕਰੋ",
  add_timings_later: "ਤੁਸੀਂ ਹੋਰ ਬਾਅਦ ਵਿੱਚ ਸ਼ਾਮਲ ਕਰ ਸਕਦੇ ਹੋ",
  add_doctors_later: "ਤੁਸੀਂ ਹੋਰ ਡਾਕਟਰ ਬਾਅਦ ਵਿੱਚ ਸ਼ਾਮਲ ਕਰ ਸਕਦੇ ਹੋ",
  go_home: "ਘਰ ਜਾਓ",
  selectPrescription: "ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਦੀ ਚੋਣ ਕਰੋ",
  clear: "ਹਟਾਓ",
  clearPrescription: "ਚੁਣੇ ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਨੂੰ ਹਟਾਓ",
  clearPrescriptionConfirm:
    "ਕੀ ਤੁਸੀਂ ਨਿਸ਼ਚਤ ਰੂਪ ਤੋਂ ਚੁਣੀ ਹੋਈ ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਨੂੰ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ? ਮੌਜੂਦਾ ਤਰੱਕੀ ਗੁੰਮ ਜਾਵੇਗੀ.",
  optionalText: "ਵਿਕਲਪਿਕ",
  locality: "ਸਥਾਨ",
  localityPlaceholder: "ਸੈਕਟਰ 21, ਚੰਡੀਗੜ੍ਹ",
  emptyFollowUpText:
    "ਜਦੋਂ ਤੁਸੀਂ ਕਿਸੇ ਮਰੀਜ਼ ਲਈ ਅਗਲੀ ਮੁਲਾਕਾਤ ਸ਼ਾਮਲ ਕਰਦੇ ਹੋ, ਤਾਂ ਉਹ ਉਸ ਅਨੁਸਾਰ ਇੱਥੇ ਪ੍ਰਦਰਸ਼ਿਤ ਹੋਣਗੇ.",
  prompts: {
    take_me_there: "ਠੀਕ ਹੈ, ਮੈਨੂੰ ਉੱਥੇ ਲੈ ਜਾਓ",
    do_it_later: "ਮੈਂ ਇਹ ਬਾਅਦ ਵਿੱਚ ਕਰਾਂਗਾ / ਕਰਾਂਗੀ",
    change_print_setings_title: "ਪ੍ਰਿੰਟ ਸੈਟਿੰਗਜ਼ ਬਦਲੋ",
    change_print_settings_desc_1:
      "ਤੁਸੀਂ ਸੈਟਿੰਗਾਂ ਵਿੱਚ ਆਪਣੇ ਪ੍ਰਾਸਪ੍ਰੰਟ ਅਨੁਸਾਰ ਪ੍ਰਿੰਟ ਲੇਆਉਟ ਨੂੰ ਬਦਲ ਸਕਦੇ ਹੋ.",
    change_print_settings_desc_2:
      "ਸੈਟਿੰਗਜ਼ ਨੂੰ ਬਦਲਣ ਲਈ ਕਿਸੇ ਸਟਾਫ ਜਾਂ ਪ੍ਰਸ਼ਾਸਨ ਖਾਤੇ ਨੂੰ ਪੁੱਛੋ.",
    update_address_title: "ਕਲਿਨਿਕ ਪਤਾ ਅਪਡੇਟ ਕਰੋ",
    update_address_desc:
      "ਤੁਸੀਂ ਆਪਣੇ ਕਲੀਨਿਕ ਦੇ ਪਤੇ ਨੂੰ ਨਹੀਂ ਜੋੜਿਆ ਹੈ. ਬਿੱਲ ਕੇਵਲ ਕਲੀਨਿਕ ਦਾ ਨਾਮ ਹੀ ਦਰਸਾਉਂਦਾ ਹੈ ਨਾ ਕਿ ਪਤਾ.",
    med_reg_no_validation: "ਮੈਡੀਕਲ ਰਜਿਸਟਰੇਸ਼ਨ ਨੰਬਰ ਦੀ ਲੋੜ ਹੈ",
    med_reg_no_placeholder: "ਮੈਡੀਕਲ ਰਜਿਸਟਰੇਸ਼ਨ ਨੰਬਰ",
    med_reg_modal_title: "ਮੈਡੀਕਲ ਰਜਿਸਟਰੇਸ਼ਨ ਨੰਬਰ ਜੋੜੋ",
    med_reg_modal_desc:
      "ਤੁਸੀਂ ਡਾਕਟਰ ਦੀ ਮੈਡੀਕਲ ਰਜਿਸਟਰੇਸ਼ਨ ਨੰਬਰ ਨਹੀਂ ਜੋੜਿਆ ਹੈ ਨੁਸਖ਼ੇ ਉੱਤੇ ਇਸ ਨੂੰ ਛਾਪਣਾ ਜ਼ਰੂਰੀ ਹੈ."
  },
  setUp: {
    welcome: "ਜੀ ਆਇਆਂ ਨੂੰ kulcare ਜੀ!",
    setUpSubtitle:
      "ਆਓ ਆਪਣਾ ਖਾਤਾ ਸਥਾਪਤ ਕਰਨ ਵਿੱਚ ਤੁਹਾਡੀ ਸਹਾਇਤਾ ਕਰੀਏ. ਵੀਡੀਓ ਦੇਖੋ ਅਤੇ ਹੇਠਾਂ ਦਿੱਤੇ ਕਦਮਾਂ ਨੂੰ ਪੂਰਾ ਕਰੋ.",
    setUpQueue: "ਕਤਾਰ ਪ੍ਰਬੰਧਨ ਲਈ ਸੈੱਟਅੱਪ",
    setUpQueueOne: "ਕਤਾਰ ਹੁਣ ਸੈਟਅੱਪ ਹੈ",

    // setUpQueueDescription:
    //   "ਕਲੀਨਿਕ ਦੀ ਕਤਾਰ ਵਿੱਚ ਤੁਹਾਡੇ ਰੋਗੀ ਰਜਿਸਟਰ ਹੈ। ਕਤਾਰ ਵਿੱਚ ਇੱਕ ਮਰੀਜ਼ ਨੂੰ ਐੱਡ ਕਰ ਕੇ ਸਭ ਸ਼ੁਰੂ ਹੁੰਦਾ ਹੈ। ਆਪਣੀ ਕਤਾਰ ਦੀ ਸਥਾਪਨਾ ਕਰਨ ਲਈ ਇਨ੍ਹਾਂ ਕੰਮਾਂ ਨੂੰ ਪੂਰਾ ਕਰੋ।",
    howToSetUpQueue: "ਕਤਾਰ ਪ੍ਰਬੰਧਨ ਲਈ ਕਿਵੇਂ ਸੈੱਟਅੱਪ ਕਰਨਾ ਹੈ?",
    addConsultantDoctor: "ਇਕ ਸਲਾਹਕਾਰ ਡਾਕਟਰ ਨੂੰ ਸੇਵ ਕਰੋ",
    // goTo: "ਗੋ ਟੂ",
    addTimingsPatientsPerHour:
      "ਡਾਕਟਰ ਦੇ ਸਮੇਂ ਅਤੇ ਮਰੀਜ਼ਾਂ ਨੂੰ ਪ੍ਰਤੀ ਘੰਟਾ ਸ਼ਾਮਲ ਕਰੋ",
    addDoctorFees: "ਡਾਕਟਰ ਦੀ ਫੀਸ ਸੇਵ ਕਰੋ",
    setUpBilling: "ਸੈੱਟਅੱਪ ਬਿਲਿੰਗ",
    setUpBillingOne: "ਬਿਲਿੰਗ ਹੁਣ ਤਿਆਰ ਹੈ",

    // setUpBillingDesc:
    //   "ਇੱਕ ਤੁਰੰਤ ਸੈੱਟਅੱਪ ਤੁਹਾਨੂੰ ਕੁਝ ਬਿੱਲਾਂ ਦੇ ਨਾਲ ਆਪਣੇ ਬਿਲਿੰਗਸ ਨੂੰ ਪ੍ਰਬੰਧਨ ਕਰਨ ਦੇਵੇਗਾ। ਹਰੇਕ ਮਰੀਜ਼ ਲਈ ਪੇਸ਼ ਕੀਤੀਆਂ ਸੇਵਾਵਾਂ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰੋ ਅਤੇ ਆਪਣੇ ਰੋਜ਼ਾਨਾ, ਹਫ਼ਤਾਵਾਰੀ ਜਾਂ ਮਹੀਨਾਵਾਰ ਆਮਦਨੀ ਨੂੰ ਆਸਾਨੀ ਨਾਲ ਵਿਵਸਥਿਤ ਕਰੋ।",
    howToSetUpClinic: "ਬਿਲਿੰਗ ਕਿਵੇਂ ਸੈੱਟਅੱਪ ਕਰਨਾ ਹੈ?",
    addClinicServices: "ਉਹ ਸੇਵਾਵਾਂ ਸ਼ਾਮਲ ਕਰੋ ਜੋ ਤੁਹਾਡਾ ਕਲੀਨਿਕ ਪੇਸ਼ ਕਰਦਾ ਹੈ",
    addClinicAddress: "ਬਿਲਾਂ ਤੇ ਪ੍ਰਿੰਟ ਲਈ ਕਲੀਨਿਕ ਦੇ ਪਤੇ ਨੂੰ ਸ਼ਾਮਲ ਕਰੋ",
    clinicProfile: "ਕਲੀਨਿਕ ਪਰੋਫਾਈਲ",
    setUpCareplan: "ਡਿਜੀਟਲ ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਲਈ ਸੈੱਟਅੱਪ",
    setUpCareplanOne: "ਪ੍ਰਿੰਸੀਪਲ ਸੈਟਿੰਗਾਂ ਹੁਣ ਮੁਕੰਮਲ ਹਨ",

    // setUpCareplanDesc:
    //   "ਇੱਕ ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਡਾਕਟਰ ਲਈ ਸਪੇਸੀਫਿਕ ਹੈ। ਇੱਕ ਡਾਕਟਰ ਦੇਖਭਾਲ-ਕੇਂਦਰ ਤਿਆਰ ਕਰਨ ਲਈ ਟੈਂਪਲਿਟ ਸਕਿੰਟਾਂ ਵਿੱਚ ਜੋੜ ਸਕਦਾ ਹੈ। ਕਿਸੇ ਡਾਕਟਰ ਦੀ ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਦੇ ਟੈਂਪਲੇਟ ਹਮੇਸ਼ਾ ਉਨ੍ਹਾਂ ਦੇ ਨਾਲ ਰਹਿੰਦੇ ਹਨ ਭਾਵੇਂ ਕਿ ਉਹ ਕਿਸੇ ਹੋਰ ਕਲੀਨਿਕ ਵਿੱਚ ਜਾਂਦੇ ਹਨ।",
    howToSetUpCareplan: "ਡਿਜੀਟਲ ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਕਿਵੇਂ ਸੈਟਅਪ ਕਰਨਾ ਹੈ?",
    addCareplanTemplates: "ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਟੈਂਪਲੇਟ ਜੋੜੋ",
    careplanTemplates: "ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਟੈਮਪਲੇਟਸ",
    addVitals: "ਵਿਟਟਲ ਸ਼ਾਮਲ ਕਰੋ ਜੋ ਤੁਸੀਂ ਕੈਪਚਰ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ",
    addPrintSettings: "ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ ਪ੍ਰਿੰਟ ਸੈਟਿੰਗਜ਼ ਸੇਵ ਕਰੋ",
    prescriptions: "ਪ੍ਰਿਸਕ੍ਰਿਪਸ਼ਨ",
    setUpText: "ਸ਼ੁਰੂਆਤ ਗਾਈਡ",
    left: "ਬਾਕੀ ਹੈ"
  },
  growthProfile: {
    switchToGrowthProfile: "ਗਰੋਥ ਪ੍ਰੋਫਾਈਲ ਤੇ ਸਵਿਚ ਕਰੋ",
    switchToClinicProfile: "ਕਲੀਨਿਕ ਪ੍ਰੋਫਾਈਲ ਤੇ ਸਵਿਚ ਕਰੋ",
    clinicWebsite: "ਕਲੀਨਿਕ ਵੈੱਬਸਾਈਟ",
    patientReviews: "ਮਰੀਜ਼ ਦੀ ਸਮੀਖਿਆ",
    help: "ਹੈਲਪ",
    packages: "ਪੈਕੇਜ",
    createYourWebsite: "ਆਪਣੀ ਮੁਫਤ ਵੈਬਸਾਈਟ ਬਣਾਓ",
    manageYourClinic: "ਆਪਣੇ ਕਲਿਨਿਕ ਨੂੰ ਮੈਨੇਜ ਕਰੋ ",
    services: {
      services_name: "ਸੇਵਾ ਦਾ ਨਾਮ",
      delete: "ਹਟਾਉ",
      text: "ਵਏਟਲਸ",
      vital_name_empty: "ਸੇਵਾ ਖਾਲੀ ਨਹੀਂ ਹੋ ਸਕਦੇ",
      remove_vital: "ਸੇਵਾ ਹਟਾਉ",
      remove_vital_confirmation:
        "ਕੀ ਤੁਸੀਂ ਨਿਸ਼ਚਤ ਰੂਪ ਤੋਂ ਸੇਵਾ ਨੂੰ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
      vitals_not_present: "ਸੇਵਾਵਾਂ ਮੌਜੂਦ ਨਹੀਂ ਹਨ"
    },
    clinicDetails: {
      reception: "ਰਿਸੈਪਸ਼ਨ",
      opd: "ਓਪੀਡੀ",
      emergency: "ਐਮਰਜੈਂਸੀ",
      address: "ਪਤਾ",
      about: "ਕਲੀਨਿਕ ਬਾਰੇ ਦੱਸੋ",
      profileSaved: "ਪ੍ਰੋਫਾਈਲ ਸਫਲਤਾਪੂਰਵਕ ਸੁਰੱਖਿਅਤ ਕੀਤੀ",
      imageSizeWarningTitle: "ਫੋਟੋ ਆਕਾਰ ਚੇਤਾਵਨੀ",
      imageSizeWarningInfo: "ਫੋਟੋ ਦਾ ਆਕਾਰ 5MB ਤੋਂ ਵੱਧ ਨਹੀਂ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ",
      clickToUpload: "ਅਪਲੋਡ ਕਰਨ ਲਈ ਕਲਿਕ ਕਰੋ",
      markAsCoverPhoto: "ਕਵਰ ਫੋਟੋ ਸੈੱਟ ਕਰੋ",
      markedAsCover: "ਕਵਰ ਫੋਟੋ",
      clinicInfo: "ਕਲੀਨਿਕ ਇੰਫੋ",
      maxPhotosLimitError:
        "ਸਿਰਫ 10 ਫੋਟੋਆਂ ਨੂੰ ਇੱਕ ਵਾਰ 'ਤੇ ਅਪਲੋਡ ਕਰਨ ਦੀ ਆਗਿਆ ਹੈ",
      maxPhotosTitle: "ਅਧਿਕਤਮ ਸੀਮਾ ਪੂਰੀ ਹੋਈ",
      see_site_live: "ਆਪਣੀ ਸਾਈਟ ਝਲਕ ਵੇਖੋ"
    },
    startGuide: {
      welcomeSubtitle:
        "kulcare ਚੁਣਨ ਲਈ ਧੰਨਵਾਦ। ਆਪਣੀ ਮੁਫਤ ਵੈਬਸਾਈਟ ਬਣਾਉਣ ਲਈ ਵੀਡੀਓ ਦੇਖੋ ਅਤੇ ਹੇਠਾਂ ਦਿੱਤੇ ਪਗ਼ਾਂ ਨੂੰ ਪੂਰਾ ਕਰੋ।",
      completeClinicInfo: "ਕਲੀਨਿਕ ਦੀ ਮੂਲ ਜਾਣਕਾਰੀ ਪੂਰੀ ਕਰੋ",
      addConsultingDoctor: "ਸਲਾਹਕਾਰ ਡਾਕਟਰਾਂ ਅਤੇ ਉਨ੍ਹਾਂ ਦੀ ਜਾਣਕਾਰੀ ਸ਼ਾਮਲ ਕਰੋ",
      addOfferedServices: "ਕਲੀਨਿਕ ਦੁਆਰਾ ਦਿੱਤੀਆਂ ਜਾਣ ਵਾਲੀਆਂ ਸੇਵਾਵਾਂ ਸ਼ਾਮਲ ਕਰੋ",
      addPackages: "ਕੋਈ ਵੀ ਪੈਕੇਜ ਕਲੀਨਿਕ ਪੇਸ਼ਕਸ਼ ਸ਼ਾਮਲ ਕਰੋ",
      lookAtPreview: "ਆਪਣੀ ਵੈਬਸਾਈਟ ਨੂੰ ਪ੍ਰਿਵੀਊ ਕਰੋ",
      publishWebsite: "ਆਪਣੀ ਵੈਬਸਾਈਟ ਪਬਲਿਸ਼ ਕਰੋ",
      seeSampleWebsite: "ਇੱਕ ਸੇਮਪਲ ਵੈੱਬਸਾਈਟ ਵੇਖੋ",
      howToCreateWebsite: "ਆਪਣੀ ਵੈਬਸਾਈਟ ਕਿਵੇਂ ਬਣਾਈਏ?"
    }
  },
  referredByKulcare: "ਕੁਲਕੇਅਰ ਦੁਆਰਾ ਰੈਫਰ ਕੀਤਾ ਗਿਆ",
  phoneNumberHint1:
    "ਆਪਣੇ ਨਿੱਜੀ ਨੰਬਰ ਦੀ ਵਰਤੋਂ ਕਰੋ. ਇਹ ਤੁਹਾਡੇ ਮਰੀਜ਼ਾਂ ਲਈ ਦਿਖਾਈ ਨਹੀਂ ਦੇਵੇਗਾ ਅਤੇ ਲੌਗਇਨ ਕਰਨ ਲਈ ਤੁਹਾਡਾ ਉਪਯੋਗਕਰਤਾ ਨਾਮ ਹੋਵੇਗਾ.",
  phoneNumberHint2: "ਅਸੀਂ ਨੰਬਰ ਦੀ ਪੁਸ਼ਟੀ ਕਰਨ ਲਈ ਇੱਕ ਓਟੀਪੀ ਭੇਜਾਂਗੇ.",
  shortLinkErrorMessage: "ਓਹ ਹੋ! ਤੁਹਾਡਾ ਲਿੰਕ ਅਵੈਧ ਹੈ",

  magicLink: {
    magicLinkSentSuccess: "ਮੈਜਿਕ ਲਿੰਕ ਸਫਲਤਾਪੂਰਵਕ ਭੇਜਿਆ ਗਿਆ!",
    linkExpiryTitle:
      "ਓਹ ਹੋ! ਸਾਈਨ-ਇਨ ਕਰਨ ਲਈ ਤੁਹਾਡਾ ਮੈਜਿਕ ਲਿੰਕ ਦੀ ਮਿਆਦ ਖਤਮ ਹੋ ਗਈ ਹੈ",
    linkExpiryTexLine1:
      "ਮੈਜਿਕ ਲਿੰਕ ਸਿਰਫ ਇੱਕ ਵਾਰ ਵਰਤਿਆ ਜਾ ਸਕਦਾ ਹੈ ਅਤੇ 8 ਘੰਟਿਆਂ ਲਈ ਯੋਗ ਹੈ.",
    linkExpiryTexLine2:
      "ਤੁਸੀਂ ਕੋਈ ਹੋਰ ਮੈਜਿਕ ਲਿੰਕ ਤਿਆਰ ਕਰ ਸਕਦੇ ਹੋ ਜਾਂ {0} ਕਰ ਸਕਦੇ ਹੋ",
    requestAnotherLink: "ਇਕ ਹੋਰ ਲਿੰਕ ਦੀ ਬੇਨਤੀ ਕਰੋ",
    signIn: "ਲਾਗਿਨ",
    almostThere: "ਲਗਭਗ ਉਥੇ!",
    magicLinkSentText:
      "ਬੱਸ ਆਪਣੇ ਈਮੇਲ ਨੂੰ ਆਪਣੇ {0} ਤੇ ਚੈੱਕ ਕਰੋ ਅਤੇ ਲਿੰਕ ਤੇ ਕਲਿਕ ਕਰੋ ਜੋ ਅਸੀਂ {1} ਤੇ ਭੇਜਿਆ ਹੈ",
    laptopOrDesktop: "ਲੈਪਟਾਪ ਜਾਂ ਡੈਸਕਟਾਪ",
    linkValidFor: "ਲਿੰਕ 5 ਦਿਨ ਲਈ ਯੋਗ ਹੈ",
    looksLikeMobileDevice:
      "ਲਗਦਾ ਹੈ ਕਿ ਤੁਸੀਂ ਮੋਬਾਈਲ ਡਿਵਾਈਸ ਤੋਂ ਸਾਈਨ ਅਪ ਕੀਤਾ ਹੈ. kulcare ਡੈਸਕਟਾਪ ਜਾਂ ਲੈਪਟਾਪ 'ਤੇ ਵਧੀਆ ਕੰਮ ਕਰਦਾ ਹੈ.",
    toUseKulcare: "ਕੁਲਕੇਅਰ ਦੀ ਵਰਤੋਂ ਕਰਨ ਲਈ",
    toGetStarted: "ਸ਼ੁਰੂ ਕਰਨ ਲਈ",
    emailMeMagicLink:
      "ਮੇਰੇ ਲੈਪਟਾਪ ਜਾਂ ਡੈਸਕਟੌਪ ਤੇ ਲੌਗ ਇਨ ਕਰਨ ਲਈ ਮੈਨੂੰ ਮੈਜਿਕ ਲਿੰਕ ਈਮੇਲ ਕਰੋ",
    logMeOut: "ਮੈਨੂੰ ਲੌਗ ਆਉਟ ਕਰੋ",
    userDoesNotExist: "ਉਪਭੋਗਤਾ ਮੌਜੂਦ ਨਹੀਂ ਹੈ",
    thisIsAwkward: "ਖੈਰ, ਇਹ ਅਜੀਬ ਹੈ…",
    sendMeMagicLink: "ਮੈਨੂੰ ਮੈਜਿਕ ਲਿੰਕ ਭੇਜੋ",
    enterEmail: "ਈਮੇਲ ਦਰਜ ਕਰੋ",
    doNotHaveEmail: "ਸਾਡੇ ਕੋਲ ਅਜੇ ਤੁਹਾਡਾ {0} ਪਤਾ ਨਹੀਂ ਹੈ",
    looksLikeMobileDevice2:
      "ਲਗਦਾ ਹੈ ਕਿ ਤੁਸੀਂ ਮੋਬਾਈਲ ਡਿਵਾਈਸ ਤੋਂ ਲੌਗ ਇਨ ਕੀਤਾ ਹੈ. kulcare ਡੈਸਕਟਾਪ ਜਾਂ ਲੈਪਟਾਪ 'ਤੇ ਵਧੀਆ ਕੰਮ ਕਰਦਾ ਹੈ."
  },
  enterSpeciality: "ਕਿਰਪਾ ਕਰਕੇ ਇਕ ਵਿਸ਼ੇਸ਼ਤਾ ਦਾਖਲ ਕਰੋ",
  enterClinicName: "ਕਲੀਨਿਕ ਦਾ ਨਾਮ ਦਰਜ ਕਰੋ ਜੀ",
  enterAddress: "ਕਲੀਨਿਕ ਦਾ ਪਤਾ ਦਾਖਲ ਕਰੋ ਜੀ",
  errorWhileUploading: "ਅਪਲੋਡ ਕਰਨ ਵੇਲੇ ਗਲਤੀ",
  errorWhileDeleting: "ਹਟਾਉਣ ਦੌਰਾਨ ਗਲਤੀ",
  deletePhoto: "ਫੋਟੋ ਹਟਾਓ",
  confirmRemovePhoto: "ਕੀ ਤੁਸੀਂ ਪੱਕਾ ਇਸ ਫੋਟੋ ਨੂੰ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  recievedAmountError:
    "ਕੁਝ ਰਕਮ ਪਹਿਲਾਂ ਹੀ ਇਕੱਠੀ ਕੀਤੀ ਜਾ ਚੁੱਕੀ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ ਜਾਂਚ ਕਰੋ",
  collectedAmountError: "ਇਕੱਠੀ ਕੀਤੀ ਰਕਮ ਕੁੱਲ ਰਕਮ ਤੋਂ ਵੱਧ ਨਹੀਂ ਹੋ ਸਕਦੀ",
  noEditLater: "ਤੁਸੀਂ ਬਾਅਦ ਵਿੱਚ ਬਿੱਲ ਨੂੰ ਸੰਪਾਦਿਤ ਕਰਨ ਦੇ ਯੋਗ ਨਹੀਂ ਹੋਵੋਗੇ.",
  help: {
    how_help_text: "How can we help you?",
    recomended_text: "Recommended for you",
    search_placeholder: "Search faqs",
    faq_text: "FAQ's",
    queue: "Queue",
    patient: "Patient",
    appointment: "Appointment",
    billing: "Billing",
    prescription: "Prescription",
    searched_text: "Showing results for '{searchText}'",
    empty_results_text1: "We could not find anything that matches your query.",
    empty_results_text2: "Don’t worry! Click on this icon",
    empty_results_text3: "at the bottom right corner and send us a message",
    empty_results_text4: "We will help you right away."
  },
  addPhotos: {
    attachPhotos: "Attach Photos",
    selectPhotos: "Select Photos",
    addNote: "Add a note...",
    dateError: "Please choose date",
    photosError: "Please choose photos",
    maxPhotosError: "You cannot upload more than 10 photos at once",
    photosSuccess: "Photos attached successfully!"
  },
  doctorDetail: "ਡਾਕਟਰ ਵੇਰਵੇਾ",
  selectAccount: {
    selectAccountHeading: "ਅਕਾਊਂਟ ਚੁਣੋ",
    growthText: "मेरा अभ्यास बढ़ाओ",
    clinicText: "मेरा अभ्यास प्रबंधित करें",
    growthDescription:
      "ਆਨਲਾਈਨ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ ਆਪਣੀ ਵੈਬਸਾਈਟ ਬਣਾਓ, ਆਪਣੀਆਂ ਸਮੀਖਿਆਵਾਂ ਅਤੇ ਸੂਚੀਆਂ ਦਾ ਪ੍ਰਬੰਧਨ ਕਰੋ ਅਤੇ ਵਧੇਰੇ ਮਰੀਜ਼ ਪ੍ਰਾਪਤ ਕਰੋ...",
    clinicDescription:
      "ਮੁਲਾਕਾਤਾਂ ਲਓ, ਮਰੀਜ਼ਾਂ ਅਤੇ ਨੁਸਖ਼ਿਆਂ ਦਾ ਪ੍ਰਬੰਧ ਕਰੋ, ਆਪਣੇ ਕਾਰੋਬਾਰ ਦਾ ਵਿਸ਼ਲੇਸ਼ਣ ਕਰੋ ਅਤੇ ਹੋਰ ਵੀ..."
  },
  welcomeBack: "ਵਾਪਸ ਸਵਾਗਤ!",
  thanksForJoining: "ਕਲਕੇਅਰ ਵਿੱਚ ਸ਼ਾਮਲ ਹੋਣ ਲਈ ਧੰਨਵਾਦ!",
  orDownloadApp: "ਜਾਂ, ਐਪ ਨੂੰ ਡਾਉਨਲੋਡ ਕਰੋ",
  questionsText: "ਪ੍ਰਸ਼ਨ?",
  copyrightText: "ਕਾਪੀਰਾਈਟ © 2020 kulcare. ਸਾਰੇ ਹੱਕ ਰਾਖਵੇਂ ਹਨ.",
  newAppVersionTitle: "ਕੁਲਕਰੇ ਦਾ ਇੱਕ ਨਵਾਂ ਸੰਸਕਰਣ ਉਪਲਬਧ ਹੈ",
  refreshTo: "ਤਾਜ਼ਾ ਵਰਜ਼ਨ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ ਤਾਜ਼ਾ ਕਰੋ",
  laterText: "ਬਾਅਦ ਵਿਚ",
  refreshText: "ਰਿਫਰੇਸ਼",
  sentVerifyCodeOn: "ਅਸੀਂ ਤੁਹਾਨੂੰ ਤੁਹਾਡੇ ਮੋਬਾਈਲ ਨੰਬਰ ਅਤੇ {0} ਅਤੇ ਤੁਹਾਡੇ ਈਮੇਲ {1} 'ਤੇ ਇਕ ਤਸਦੀਕ ਕੋਡ ਭੇਜਿਆ ਹੈ",
  patientFileDownload: "ਮਰੀਜ਼ਾਂ ਦਾ ਡਾਟਾ ਸਫਲਤਾਪੂਰਵਕ ਡਾਊਨਲੋਡ ਕੀਤਾ ਗਿਆ.",
  billingFileDownload: "ਬਿਲਿੰਗ ਡੇਟਾ ਸਫਲਤਾਪੂਰਵਕ ਡਾਊਨਲੋਡ ਕੀਤਾ ਗਿਆ.",
  errorInDownloading: "ਮਰੀਜ਼ਾਂ ਦੇ ਡਾਟੇ ਨੂੰ ਡਾਊਨਲੋਡ ਕਰਨ ਵਿੱਚ ਗਲਤੀ.",
  fileExistError: "ਬੇਨਤੀ ਕੀਤੀ ਫਾਈਲ ਮੌਜੂਦ ਨਹੀਂ ਹੈ.",
  internetRestored: "ਇੰਟਰਨੈਟ ਕਨੈਕਸ਼ਨ ਰੀਸਟੋਰ ਕੀਤਾ ਗਿਆ"
};
